import { Plus } from "@gravity-ui/icons"
import { Button, Icon, Sheet } from "@gravity-ui/uikit"
import useFab from "hooks/useFab"
import { GalleryQuery } from "queries"
import { createElement as $, FC } from "react"
import { Route, Routes, useMatch, useNavigate } from "react-router-dom"
import { ImageItemIteratee } from "."
import MobileFabContext from "../Mobile/MobileFabContext"
import Image from "./Image"
import UploadFab, { FabProps } from "./UploadFab"

const Mobile: FC<GalleryQuery> = (data) => { 
  useFab($(UploadFab, { Fab }), MobileFabContext)

  return $('div', { className: 'gallery-images-container' }, 
    data.gallery.map(ImageItemIteratee), 
    $(Edit, data)) 
}

export const Edit: FC<GalleryQuery> = (data) => {
  const match = useMatch('/settings/gallery/:page')
  const navigate = useNavigate()

  return $(Sheet, {
    visible: !!match?.params.page,
    onClose: () => navigate('/settings/gallery')
    },
    $(Routes, null,
      $(Route, {
        path: ':id',
        element: $(Image, data)
      })))
}

const Fab: FC<FabProps> = ({ onClick, props }) => 
  $(Button, { 
    view: 'action', 
    pin: 'clear-round', 
    loading: true,
    size: 'l', 
    onClick,
    ...props
  }, $(Icon, { data: Plus }))

export default Mobile