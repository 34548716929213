import { FloppyDisk } from "@gravity-ui/icons"
import { Button, ButtonProps, Icon, useLayoutContext } from "@gravity-ui/uikit"
import { useFormikContext } from "formik"
import useFab from "hooks/useFab"
import FabContext from "pages/Main/Desktop/FabContext"
import { createElement as $ } from "react"
import MobileFabContext from "./Mobile/MobileFabContext"

const SaveButton = () => {
  const formik = useFormikContext()
  const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's' 
  const context = isMobile 
    ? MobileFabContext
    : FabContext

  const style: ButtonProps = isMobile 
    ? { size: 'l', pin: 'brick-round' }
    : { size: 'xl', pin: 'round-round' }

  const Save = () => $(Button, { 
    onClick: () => formik.submitForm(), 
    view: 'action', 
    ...style,
    disabled: !formik.dirty,
    loading: formik.isSubmitting
    }, 
    $(Icon, { data: FloppyDisk }))

  useFab($(Save), context)

  return null
}

export default SaveButton