
import { DateTime, dateTime } from '@gravity-ui/date-utils'
import useDuration from 'hooks/useDuration'
import { FC, useEffect, useState } from 'react'

const FormattedTimer: FC<FormattedTimerProps> = ({ from, to }) => {
  const [value, setValue] = useState(to || dateTime())
  const result = useDuration('second', value.diff(from) / 1000)
  useEffect(() => {
    const callback = () => setValue(dateTime())
    const interval = setInterval(callback, 60000)
    return () => clearInterval(interval)
  }, [])
  return result
}

// FIXME probably better to rely on date-fns
type FormattedTimerProps = {
  from: DateTime
  to?: DateTime
}

export default FormattedTimer