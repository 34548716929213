import { CheckinFragment } from 'queries'
import { IntlShape } from 'react-intl'

const groupCheckinsByEvents = <T extends CheckinFragment>(
	intl: IntlShape,
	search: string,
	checkins: T[]
): Group<T>[] => {
	const eventless = intl.formatMessage({ id: 'checkins.eventless' })
	const map = new Map<
		string,
		{ checkins: T[]; type: 'booking' | 'culturalEvent' | 'eventless' }
	>()
	const regex = search ? new RegExp(search, 'i') : null

	for (const checkin of checkins) {
		if (regex && !checkin.name?.match(regex)) {
			continue
		} else if (!checkin.attendance.length && !checkin.checkinBooking) {
			pushToMap(map, eventless, 'eventless', checkin)
		} else {
      if (checkin.checkinBooking) {
        const title = checkin.checkinBooking.booking.name
        pushToMap(map, title, 'booking', checkin)
      }
			for (const { culturalEvent } of checkin.attendance) {
				const title = culturalEvent.title ?? eventless
				pushToMap(map, title, 'culturalEvent', checkin)
			}
		}
	}

	if (map.get(eventless)?.checkins.length === 0) map.delete(eventless)

	const result: Group<T>[] = []

	for (const [name, group] of map.entries()) {
		group.checkins.sort((a, b) =>
			new Date(b.checkedInAt) > new Date(a.checkedInAt) ? -1 : 1
		)
		result.push({ name, checkins: group.checkins, type: group.type })
	}

	return result
}

const pushToMap = <T extends CheckinFragment>(
	map: Map<
		string,
		{ checkins: T[]; type: 'booking' | 'culturalEvent' | 'eventless' }
	>,
	key: string,
	type: 'booking' | 'culturalEvent' | 'eventless',
	value: T
) => {
	const current = map.get(key)
	if (!current) {
		map.set(key, { checkins: [value], type })
	} else {
		current.checkins.push(value)
	}
}

export type Group<T> = {
	name: string
	checkins: T[]
	type: 'booking' | 'culturalEvent' | 'eventless'
}

export default groupCheckinsByEvents
