import { Plus } from '@gravity-ui/icons'
import { Button, Flex, Sheet } from '@gravity-ui/uikit'
import { CulturalEventFragment } from 'queries'
import { createElement as $, FC, Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import TicketsForm from '../TicketsForm'

const Mobile: FC<CulturalEventFragment> = ({ id }) => {
  const [visible, setVisible] = useState(false)

  return $(Fragment, null, 
    $(Button, { onClick: () => setVisible(true) },
      $(Flex, { alignItems: 'center', gap: 2 },
        $(Plus),
        $(FormattedMessage, { id: 'culturalEvent.addTickets' }))),
    $(Sheet, { visible, onClose: () => setVisible(false) }, 
      $(TicketsForm, { culturalEventId: id, setAdding: setVisible })))
}

export default Mobile