import { Plus } from "@gravity-ui/icons"
import { Sheet } from "@gravity-ui/uikit"
import CallToAction from "components/MobileMenu/CallToAction"
import { createElement as $, Fragment, useState } from "react"
import { useNavigate } from "react-router-dom"
import Content from "./Content"

const AddEvent = () => {
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()

  return $(Fragment, null, 
    $(CallToAction, { 
      data: Plus, 
      // loading, 
      onClick: (event) => { 
        event.stopPropagation()
        setVisible(!visible) 
      }
    }),
    $(Sheet, {
      visible,
      onClose: () => {
        setVisible(false)
        navigate('/calendar') 
      }},
      $(Content)))
}

export default AddEvent