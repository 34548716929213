import { ChevronDown } from '@gravity-ui/icons'
import { Button, Flex, Icon, Select } from '@gravity-ui/uikit'
import { MessageIds } from 'components/IntlProvider'
import { ImageQuery, useUpdateMediaLocationMutation } from 'queries'
import { createElement as $, FC } from 'react'
import { useIntl } from 'react-intl'
import { MediaLocationEnum } from '..'

const SelectLocation: FC<ImageQuery> = ({ galleryItem }) => {
  const [mutate, { loading }] = useUpdateMediaLocationMutation()
  const intl = useIntl()
	const options = Object.values(MediaLocationEnum).map((value) => {
    const content = intl.formatMessage({ id: `settings.tabs.gallery.location.${value}` })
		return { value, content }
	})
  const currentLocation = options.find((option) => option.value === galleryItem?.location)
  
  if (!galleryItem?.id) return null
	return $(Select, { 
    options, 
    size: 'xl', 
    width: 'max', 
    view: 'clear',
    loading,
    defaultValue: [currentLocation?.value!],
    onUpdate: (value) => {
      value[0] && mutate({
        updateQueries: {
          Image: (prev, { mutationResult }) => {
            const location = mutationResult.data?.updateZiferblatGalleryByPk?.location
            const updated = { galleryItem: { ...prev.galleryItem, location }}
            return updated
          }
        },
        variables: { 
          id: galleryItem.id, 
          location: value[0] 
        }})
    },
    renderControl: ({ onClick, onKeyDown, ref }) => 
      $(Button, {
        ref,
        onClick,
        ...onKeyDown,
        width: 'max',
        size: 'xl',
        view: 'outlined',
        pin: 'round-clear'
      }, 
      $(Flex, { gap: 2, alignItems: 'center' }, 
        intl.formatMessage({ id: `settings.tabs.gallery.location.${currentLocation?.value}` as MessageIds }),
        $(Icon, { data: ChevronDown })))
  },
)
}

export default SelectLocation
