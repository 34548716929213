import { dateTime } from '@gravity-ui/date-utils'
import { Button, Checkbox, Flex, spacing, Text } from '@gravity-ui/uikit'
import FormattedTimer from 'components/FormattedTimer'
import { differenceInSeconds } from 'date-fns'
import { CheckinFragment, useCheckOutMultipleMutation } from 'queries'
import { createElement as $, FC, useState } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

const Content: FC<ContentProps> = ({ name, phone, comment, checkins, setOpen }) => {
  const startsAt = checkins[0].checkinBooking?.booking.startsAt!
  const [checkoutList, updateCheckoutList] = useState<typeof checkins | []>(checkins)
  const objects = checkoutList.map(({ id, sum }) => ({ id, sum }))
  const sum = checkoutList.reduce((acc, { sum }) => acc + (sum ?? 0), 0)
  const [mutate, { loading }] = useCheckOutMultipleMutation({ variables: { objects } })
  const totalTime = differenceInSeconds(new Date(), startsAt)
	const formattedTime = totalTime < 60
    ? $(FormattedMessage, { id: 'checkins.now' })
    : $(FormattedTimer, { from: dateTime({ input: startsAt }) })
  
  const handleUpdate = (checked: boolean, id: string) => {
    const handledCheckin = checkins.filter((checkin) => checkin.id === id)

    checked 
      ? updateCheckoutList([...checkoutList, ...handledCheckin])
      : updateCheckoutList(checkoutList.filter((checkin) => checkin.id !== id))
  }
  
  return $(Flex, { direction: 'column', gap: 3 },
    $(Flex, { 
      direction: 'column',
      style: {
        borderBottom: '1px solid var(--g-color-line-generic)', 
        paddingBottom: 'var(--g-spacing-2)' 
      }},
      $(Text, { variant: 'header-1' }, name),
      $('div', {
        onClick: () => window.open(`tel:${phone}`),
        style: { cursor: 'pointer' },
        className: spacing({ my: 1 })
        }, 
        $(Text, { variant: 'subheader-1', color: 'hint' }, phone)),
      $(Text, {
        variant: 'caption-2',
        color: 'hint'
      }, comment),
      $(Flex, { direction: 'column', className: spacing({ mt: 2 }) },
        $(Text, { variant: 'header-2' },
          $(FormattedNumber, { 
            value: sum,
            style: 'currency', 
            currency: 'RUB', 
            maximumFractionDigits: 0 
          })),
        totalTime >= 0 && 
          $(Text, { variant: 'body-1', color: 'hint' }, formattedTime))),
    $(CheckoutList, { checkins, handleUpdate }),
    $(Flex, { 
      gap: 2,
      direction: 'column',
      justifyContent: 'space-between',
      },
      $(Button, { 
        view: 'action',
        size: 'xl',
        loading,
        disabled: !objects.length,
        style: { width: '100%' },
        onClick: () => mutate().then(() => setOpen(false))
        }, 
        $(FormattedMessage, { id: 'checkins.checkout' }))))
}

const CheckoutList: FC<CheckoutListProps> = ({ checkins, handleUpdate }) => {
  if (!checkins.length) return null
  return checkins.map(({ id, name }) => 
    $(Checkbox, { 
      key: id, 
      size: 'l', 
      defaultChecked: true,
      onUpdate: (checked) => handleUpdate(checked, id)
    }, name))
}

type ContentProps = {
  name: string, 
  phone?: string,
  comment: string | undefined,
  checkins: CheckinFragment[], 
  setOpen: (_arg: boolean) => void 
}

type CheckoutListProps = {
  checkins: CheckinFragment[]
  handleUpdate: (checked: boolean, id: string) => void
}

export default Content