import { Divider, Flex, Text, spacing } from "@gravity-ui/uikit"
import { MessageIds } from "components/IntlProvider"
import { createElement as $, FC, Fragment, useEffect, useRef } from "react"
import { FormattedDate, FormattedMessage } from "react-intl"
import CalendarEventListItemIteratee from "./CalendarEventListItem"
import { CalendarEvent, GroupedEvents } from "./groupCalendarEvents"
import './style.scss'
import { dateTime } from "@gravity-ui/date-utils"

const GroupEventsItem: FC<GroupedEventsProps> = ({ events, shouldShowStatus }) =>
  $(Fragment, null,
    shouldShowStatus && $('div', { className: 'event-status-container'},
      $(FormattedMessage, { id: `culturalEvent.status.Published` as MessageIds })),
    Object.entries(events).sort().reverse().map(([year, month]) => 
      $(EventsThisYear, { key: year, year, month })))

const EventsThisYear: FC<YearContainerProps> = ({ year, month }) => {
  const isNotCurrentYear = (new Date()).getFullYear() !== parseInt(year, 10)

  return $('div', null,
    $('div', { className: 'event-year-container' }, isNotCurrentYear && year), 
    Object.entries(month).sort().reverse().map(([month, dates]) => 
      $(EventsThisMonth, { key: month, dates, isNotCurrentYear })))
}
 
const EventsThisMonth: FC<MonthContainerProps> = ({ dates, isNotCurrentYear }) => {
  const value = new Date(Object.keys(dates)[0])
  
  return $('div', null, 
    $('div', { className: 'events-month-container', style: { top: isNotCurrentYear && '2rem' }},
      $(Text, { style: { fontSize: '16px' }},
        $(FormattedDate, { value, month: 'long' }))),
    Object.entries(dates).map(([date, events]) => 
      $(EventsOnDate, { key: date, date, events, isNotCurrentYear })))
}

const EventsOnDate: FC<EventsProps> = ({ date, events, isNotCurrentYear }) => {
  const isToday = dateTime().isSame(dateTime({ input: date }), 'day')
  const color = isToday ? 'warning' : 'hint'
  const ref = useRef<HTMLDivElement>(null)
  
  useEffect(() => {
    isToday && ref.current?.scrollIntoView({ inline: 'start', block: 'start' })
    window.scrollBy({ top: -2 })
  }, [isToday])
  
  return $(Flex, { ref, direction: 'column', gap: 4 }, 
    isToday && $(Divider, { 
      style: {
        width: '100%', 
        borderBlockStart: '2px solid var(--g-color-base-float)', 
        borderRadius: 'var(--g-border-radius-m)',
        zIndex: -2
      }}),
    $(Flex, { className: spacing({ mb: 4 }) },
      $('div', null,
        $('div', { 
          className: 'events-date-container', 
          style: { 
            top: isNotCurrentYear && '56px'
          }},
          $(Text, { 
            color, 
            style: { 
              fontSize: '24px',
              fontWeight: 'bold', 
              width: '100%' 
            }}, 
            $(FormattedDate, {
              value: date,
              day: 'numeric'
            })),
            ' ',
          $(Text, { color }, 
            $(FormattedDate, {
              value: date,
              weekday: 'long'
            })))),
      $('div', { className: 'events-container' }, 
        events.map(CalendarEventListItemIteratee))))
}

type GroupedEventsProps = { 
  events: GroupedEvents | []
  shouldShowStatus: boolean
}

type EventsProps = {
  date: string
  events: CalendarEvent[]
  isNotCurrentYear: boolean
}

type YearContainerProps = {
  year: string, 
  month: Record<string, Record<string, CalendarEvent[]>>, 
}

type MonthContainerProps = {
  dates: Record<string, CalendarEvent[]>, 
  isNotCurrentYear: boolean 
}

export default GroupEventsItem