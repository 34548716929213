import { Flex } from '@gravity-ui/uikit'
import TextField from 'components/FormikFields/TextField'
import FormRow from 'components/FormRow'
import { Formik, FormikValues } from 'formik'
import { useUpsertZiferblatI18nAddressMutation, useZiferblatAddressQuery } from 'queries'
import { createElement as $, FC } from 'react'
import { useIntl } from 'react-intl'
import { DataProps } from '..'
import SaveButton from '../SaveButton'

const Address: FC<DataProps> = ({ id }) => {
  const { data, loading } = useZiferblatAddressQuery({ variables: { id }})
  const [mutate] = useUpsertZiferblatI18nAddressMutation()
  const { locale } = useIntl()
  const ziferblat = data?.ziferblat
  
  if (!ziferblat || loading) return
  
  const { country, city } = ziferblat.city[0]
  const { street, building, floor, howToGet } = ziferblat.streetAddress[0]

  const initialValues = {
    country,
    city,
    street,
    building,
    floor: floor.toString(),
    howToGet
  }

  const onSubmit = ({ street, building, floor, howToGet }: FormikValues) => {
    mutate({
      updateQueries: {
        ZiferblatAddress: (prev, { mutationResult }) => {
          const prevData = prev.ziferblat.streetAddress[0]
          const newData = mutationResult.data?.insertZiferblatAddressI18n
          const mergedAddress = { ...prevData, ...newData }
          const result = { ziferblat: { ...prev.ziferblat, streetAddress: [mergedAddress] }}
          
          return result
        },
      },
      variables:{
        data: {
          street,
          building,
          floor,
          howToGet,
          slug: ziferblat.addressSlug,
          locale,
        }
      }
    })
  }
  
  return $(Formik, { 
    initialValues, 
    onSubmit, 
    validateOnBlur: true,
    enableReinitialize: true 
    },
    $(Flex, { direction: 'column' },
      $(FormRow, { label: 'ziferblat.form.address.country' },
        $(TextField, { name: 'country', disabled: true })),
      $(FormRow, { label: 'ziferblat.form.address.city' },
        $(TextField, { name: 'city', disabled: true })),
      $(FormRow, { label: 'ziferblat.form.address.street' },
        $(TextField, { name: 'street' })),
      $(FormRow, { label: 'ziferblat.form.address.building' },
        $(TextField, { name: 'building' })),
      $(FormRow, { label: 'ziferblat.form.address.floor' },
        $(TextField, { name: 'floor' })),
      $(FormRow, { label: 'ziferblat.form.address.howToGet' },
        $(TextField, { name: 'howToGet' })),
      $(SaveButton)))
}

export default Address
