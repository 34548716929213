import { useLayoutContext } from "@gravity-ui/uikit"
import { GalleryQuery, useGalleryQuery } from "queries"
import { createElement as $, FC } from "react"
import { useNavigate } from "react-router-dom"
import { DataProps } from ".."
import Desktop from "./Desktop"
import Mobile from "./Mobile"
import './style.scss'

const Gallery: FC<DataProps> = ({ id }) => {
  const { data, loading } = useGalleryQuery({ 
    variables: { ziferblatId: id }, 
    fetchPolicy: 'cache-first' 
  })
  const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's'
  const component = isMobile ? Mobile : Desktop

  if (loading) return null
  return $(component, data)
}

export const ImageItemIteratee = (props: ImageProps) => 
  $(ImageItem, { key: props.id, ...props })

const ImageItem: FC<ImageProps> = ({ id, media }) => {
  const navigate = useNavigate()
  
  if (!media) return null
  
  return $('img', { 
    key: id,
    className: 'gallery-image-item', 
    src: media.url,
    onClick: () => navigate(`/settings/gallery/${id}`), 
    style: { cursor: 'pointer' }
  })
}

type ImageProps = GalleryQuery['gallery'][0]

export enum MediaLocationEnum {
	Gallery = 'gallery',
	Hero = 'hero',
}

export default Gallery