import { useLayoutContext } from '@gravity-ui/uikit'
import { GalleryQuery, ImageQuery } from 'queries'
import { createElement as $, FC } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import '../style.scss'
import Desktop from './Desktop'
import Mobile from './Mobile'

const Image: FC<GalleryQuery> = ({ gallery }) => {
  const params = useParams<'id'>()
	const id = params.id || ''
  const data: ImageQuery = { 
    galleryItem: gallery?.find(image => image.id === id) 
  }
  
	const { activeMediaQuery } = useLayoutContext()
	const component = activeMediaQuery === 's' ? Mobile : Desktop

	if (!data || !data.galleryItem) return $(Navigate, { to: `/settings/gallery` })

	return $(component, data)
}

export default Image
