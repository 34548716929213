import ChevronDown from '@gravity-ui/icons/ChevronDown'
import Dots9 from '@gravity-ui/icons/Dots9'
import { Button, Flex, Icon, Sheet, spacing } from '@gravity-ui/uikit'
import MenuContent from 'components/MobileMenu/MenuContent'
import { FabElement } from 'pages/Main/Desktop/FabContext'
import { createElement as $, FC, ReactElement, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Props } from '..'
import MenuIteratee from '../MenuIteratee'
import './style.scss'

const Menu: FC<Props & { mobileFab: FabElement }> = ({ routes, active, mobileFab }) => {
  const [sheetContent, setSheetContent] = useState<ReactElement | null>(null)
  
  useEffect(() => {
    if (active && sheetContent)
      setSheetContent(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return $('div', { className: 'mobile-menu_container' },
    $(Sheet, { visible: !!sheetContent, onClose: () => setSheetContent(null) }, sheetContent),
    $(Flex, { className: spacing({ p: 2 }) },
      $(Button, {
        size: 'l',
        view: 'outlined',
        pin: 'round-clear',
        onClick: () => setSheetContent($(MenuContent))
        },
        $(Icon, { data: Dots9 })),
      $(Button, {
          size: 'l',
          view: 'outlined',
          pin: 'clear-clear',
          width: 'max',
          onClick: () => setSheetContent($(Submenu, { routes, active }))
          },
          active && // FIXME find better way to handle active = undefined
            $(Flex, { gap: 2, alignItems: 'center' }, 
              $(FormattedMessage, { id: `settings.tabs.${active}` }),
              $(Icon, { data: ChevronDown }))),
      mobileFab,
    ))
}

const Submenu: FC<Props> = ({ routes }) =>
  $(Flex, {
    gap: 2,
    direction: 'column',
    className: spacing({ p: 2 })
    },
    routes.map(MenuIteratee))

export default Menu
