import { Flex } from '@gravity-ui/uikit'
import { createElement as $, FC } from 'react'
import { Outlet } from 'react-router-dom'
import { Props } from '.'
import MenuIteratee from './MenuIteratee'

const Desktop: FC<Props> = ({ routes }) => {

  return $(Flex, { gap: 8 },
    $(Flex, {
      direction: 'column',
      gap: 3,
      },
      routes.map(MenuIteratee)),
    $(Outlet))
}

export default Desktop