import { TrashBin } from '@gravity-ui/icons'
import { Button, ButtonProps, Icon } from '@gravity-ui/uikit'
import ConfirmDelete from 'components/ConfirmDelete'
import { CulturalEvent, useRemoveTicketMutation } from 'queries'
import { createElement as $, FC } from 'react'
import './style.scss'

const RemoveTicketButtonWithConfirmation: FC<{ id: string }> = ({ id }) => {
  const [onDelete, { loading }] = useRemoveTicketMutation({ 
    variables: { id },
    updateQueries: {
      CulturalEvent: (prev, { mutationResult }) => {
        const removedId = mutationResult.data?.removeTicket?.id
        const filtered = prev.culturalEvent.tickets.filter((ticket: CulturalEvent['tickets'][0]) => ticket.id !== removedId)
        return { culturalEvent: { ...prev.culturalEvent, tickets: filtered}}
      }
    }
  })

  return $(ConfirmDelete, {
    trigger: RemoveButton,
    onDelete,
    loading,
    title: 'delete.ticket'
  })
}

const RemoveButton: FC<ButtonProps> = ({ onClick }) => 
  $(Button, {
    view: 'flat-secondary',
    size: 'xs',
    onClick
  }, $(Icon, { data: TrashBin }))

export default RemoveTicketButtonWithConfirmation