import FabContext from 'pages/Main/Desktop/FabContext'
import { ReactElement, useContext, useEffect } from 'react'

const useFab = (fab: ReactElement, context?: typeof FabContext) => {
  const setFab = useContext(context ?? FabContext)
  useEffect(() => {
    setFab(fab)
    return () => setFab(undefined)
  }, [fab, setFab])
}

export default useFab