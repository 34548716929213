import { TextInput, TextInputProps } from '@gravity-ui/uikit';
import { createElement as $, FC } from 'react';
import { useIMask } from 'react-imask';

const PhoneInput: FC<PhoneInputProps> = ({
  onUpdate,
  ...props
}) => {
  const {
    ref,
  } = useIMask<HTMLInputElement>(
    { mask: '+{7} (000) 000-00-00' }, 
    { defaultValue: props.value,
      onAccept: (value) => onUpdate?.(value),
    })

  return $(TextInput, { controlRef: ref, ...props })
}


type PhoneInputProps = TextInputProps

export default PhoneInput