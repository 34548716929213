import { useLayoutContext } from '@gravity-ui/uikit'
import ZiferblatContext from 'components/ZiferblatContext'
import { createElement as $, useContext } from 'react'
import { Navigate, Route, Routes, useMatch } from 'react-router-dom'
import Address from './Address'
import Description from './Description'
import Desktop from './Desktop'
import Gallery from './Gallery'
import Mobile from './Mobile'

const Settings = () => {
	const { activeMediaQuery } = useLayoutContext()
	const component = activeMediaQuery === 's' ? Mobile : Desktop
  const { ziferblatId } = useContext(ZiferblatContext)
  const match = useMatch<'page', '/settings/:page'>('/settings/:page');
	const active = match?.params.page as SettingsRoute

	return $(Routes, null,
		$(Route, { path: '*', element: $(component, { routes, active }) },
			routes.map((path) =>
				$(Route, {
					key: path,
					path: `${path}/*`,
					element: $(pages[path], { id: ziferblatId })
				})),
      $(Route, { path: '*', element: $(Navigate, { to: `/settings/${routes[0]}` }) })))
}

const pages = {
  description: Description,
	address: Address,
  gallery: Gallery
}
  
const routes = Object.keys(pages) as SettingsRoute[]

export type SettingsRoute = (keyof typeof pages)
export type Props = {
	routes: SettingsRoute[]
	active: SettingsRoute
}
export type DataProps = { id: string }

export default Settings