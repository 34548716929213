import { Avatar, AvatarProps, User } from '@gravity-ui/uikit'
import ZiferList, { MeRoles } from 'components/ZIferIteratee/ZiferIteratee'
import { MeQuery } from 'queries'
import { createElement as $, FC, Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'
import './style.scss'
import { getAlias, RoleType } from 'pages/Staff/StaffListItem'

export const ROLE_KEY = 'currentRole'

const UserContent: FC<MeQuery> = (data) => {
  const me = data?.me?.user
  const avatar = me?.avatarUrl
  const name = getAlias('name', me?.alias) // FIXME getAlias should be somewhere else
  const roles = me?.roles || []
  const [currentRole, setCurrentRole] = useLocalStorageState<RoleType['id']>(ROLE_KEY)
  const [selectedRole, setSelectedRole] = useState(currentRole)
  const navigate = useNavigate()
  const role = roles.find((role) => role.role.id === currentRole) || roles[0]
  const description = roles.length > 1 
    ? `@${getAlias('telegram_username', me?.alias)}` 
    : getFormattedRole(role || roles[0])

  const handleZiferClick = (id: string) => {
    const matchingZifer = roles?.find((obj) => obj.id === id)
    setSelectedRole(id)
    setCurrentRole(matchingZifer?.id)
  }

  return $('div', { className: 'popup' },
    $('div', { className: 'popup_user' },
      $('div', { 
        className: 'popup_user_profile',
        onClick: () => navigate('/profile') 
        }, 
        $(User, { 
          avatar: $<AvatarProps>(Avatar, {
              size: 'm', 
              imgUrl: avatar || '/favicon.svg',
              fallbackImgUrl: '/favicon.svg'
            }),
          name,
          description
        }))),
    roles.length > 1 && 
      $('div', { className: 'popup_zifer-list' },
        $(ZiferList, { 
          roles,
          updatedRole: selectedRole || roles[0].id,
          handleZiferClick 
        })))
}

const getFormattedRole = ({ role, ziferblat }: MeRoles[number]) =>
  $(Fragment, null,
    $(FormattedMessage, { id: `role.${role.name as 'manager' | 'helper'}`}),
    ' | ',
    ziferblat?.i18n[0].name)

export default UserContent