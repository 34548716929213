import PlusIcon from '@gravity-ui/icons/Plus'
import { Button, Card, Flex, Icon, spacing } from '@gravity-ui/uikit'
import useFab from 'hooks/useFab'
import { createElement as $, FC, Fragment, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CalendarEventProps } from '..'
import AddEvent from '../AddEvent/Desktop'
import Attendance from '../CulturalEvent/Attendance'
import CulturalEvent from '../CulturalEvent/Desktop'
import Content from './Content'

const Desktop: FC<CalendarEventProps> = (props) => {
  useFab($(Fab))

  return $(Fragment, null,
    $(Routes, null,
      $(Route, {
        path: ':id',
        element: $(CulturalEventContainer)
      }),
      $(Route, { 
        path: '',
        element: $(CulturalEvents, props)
      })))
}

const CulturalEventContainer = () =>
  $(Flex, { gap: 2, direction: 'column' },
    $(Card, {
      view: 'filled',
      style: { maxWidth: 500 },
      children: $(CulturalEvent),
      className: spacing({ p: 4 })
      }),
    $(Attendance))

const CulturalEvents: FC<CalendarEventProps> = (props) =>
  $(Flex, {
    className: spacing({ mr: 2 }),
    direction: 'column',
    gap: 2
    },
    $(Content, props))

const Fab: FC = () => {
  const [open, setOpen] = useState(false)

  // const [mutate, { loading }] = useUpsertCulturalEventMutation({
  //   variables: { 
  //     input: {
  //       ziferblatId: ziferblatId,
  //       startsAt: dateTime().add(1, 'day').toDate()
  //     }
  //   },
  //   updateQueries: {
  //     CulturalEvents: (prev, next) => {
  //       const nextRaw = next.mutationResult.data?.upsertCulturalEvent
  //       if (nextRaw) {
  //         const nextEvent: CulturalEventsQuery['draftCulturalEvents'][number] = {
  //           __typename: 'CulturalEvent',
  //           id: nextRaw.id,
  //           title: nextRaw.title,
  //           startsAt: nextRaw.startsAt,
  //           endsAt: nextRaw.endsAt,
  //           status: nextRaw.status
  //         }
  //         return {
  //           ...prev,
  //           draftCulturalEvents: [nextEvent, ...prev.draftCulturalEvents]
  //         }
  //       }
  //       return prev
  //     }
  //   }
  // })

  return $(Fragment, null, 
    $(Button, {
      size: 'xl',
      view: 'action',
      onClick: () => setOpen(!open),
      },
      $(Icon, { data: PlusIcon })),
    $(AddEvent, { open, setOpen }))
}

export default Desktop