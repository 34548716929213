import { useApolloClient } from '@apollo/client'
import { Xmark } from '@gravity-ui/icons'
import { Button, ButtonProps, Icon } from '@gravity-ui/uikit'
import ConfirmDelete from 'components/ConfirmDelete'
import { useRemoveCulturalEventImageMutation } from 'queries'
import { createElement as $, FC } from 'react'
import './style.scss'

const RemoveImageButtonWithConfirmation: FC<{ id: string }> = ({ id }) => {
  const client = useApolloClient()
  const [mutate, { loading }] = useRemoveCulturalEventImageMutation({ variables: { id }})

  const onDelete = () => mutate().then(() => client.reFetchObservableQueries())

  return $(ConfirmDelete, {
    trigger: RemoveButton,
    onDelete,
    loading,
    title: 'delete.image'
  })
}

const RemoveButton: FC<ButtonProps> = ({ onClick }) =>
  $(Button, {
    view: 'flat',
    size: 'xs',
    onClick
    }, 
    $(Icon, { data: Xmark }))

export default RemoveImageButtonWithConfirmation