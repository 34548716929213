import { Label } from '@gravity-ui/uikit'
import useOutsideClick from 'hooks/useOutsideClick'
import { UserFragment, useRemoveRoleMutation } from 'queries'
import { createElement as $, FC, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { RoleType, Roles } from './StaffListItem'
import './style.scss'

const RoleItem: FC<RoleType> = ({ id, role }) => {
  const labelRef = useRef<HTMLElement>(null)
  const [deleteRef, setDeleteRef] = useState<typeof labelRef | null>(null)
  const [mutate, { loading }] = useRemoveRoleMutation({ variables: { id }})
  const intl = useIntl()
	const message = intl.formatMessage({ id: `role.${role.name}` as Roles })

  const handleLabelClick = (nextRef: typeof labelRef) => {
    if (nextRef === deleteRef) 
      setDeleteRef(null)
    else setDeleteRef(nextRef)
  }

  useOutsideClick(() => {
    setDeleteRef(null)
  }, labelRef)

  return $('div', {
    ref: labelRef,
    key: id,
    onClick: () => handleLabelClick(labelRef),
  }, 
    $(Label, {
      className: 'role-label',
      size: 'm',
      type: deleteRef === labelRef ? 'close' : 'default',
      theme: deleteRef === labelRef ? 'danger' : 'normal',
      disabled: loading,
      onCloseClick: () => mutate({
        update: (cache, { data }) => {
          const roles = data?.removeGrant?.grant?.subject.grants;
          const id = data?.removeGrant?.grant?.subject.id;
          !roles?.length && cache.modify({
            fields: {
              users: (prev, { readField }) => {
                return prev.filter((user: UserFragment) => readField('id', user) !== id)
              }
            }
          })
        }
      }),
      children: message
  }))
}

const RoleItemIteratee: FC<RoleType> = (props) => 
  $('div', { key: props.id }, 
    $(RoleItem, props))

export default RoleItemIteratee
