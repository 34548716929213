import { DateTime, dateTime } from "@gravity-ui/date-utils"
import ZiferblatContext from "components/ZiferblatContext"
import { FormikContext, useFormik } from "formik"
import { BookingFragment, useAddBookingMutation } from "queries"
import { createElement as $, FC, PropsWithChildren, useContext } from "react"
import { useNavigate } from "react-router-dom"

const BookingWithData: FC<PropsWithChildren<BookingFragment>> = (props) => {
  const [mutate] = useAddBookingMutation()
  const { ziferblatId } = useContext(ZiferblatContext)
  const navigate = useNavigate()
  const startsAt = props.startsAt 
    ? dateTime({ input: props.startsAt }) 
    : dateTime().add(1, 'day').startOf('hour')

  const formik = useFormik<Form>({
    initialValues: {
      name: props.name || '',
      startsAt,
      comment: props.comment || '',
      phone: props.phone || '',
      hasCheckins: !!props?.bookingCheckins?.length,
      checkins: props.bookingCheckins || []
    },
    onSubmit: ({ ...values }) => mutate({
      updateQueries: {
        Booking: (prev, { mutationResult }) => { 
          return { bookingByPk: mutationResult.data?.insertBookingOne }}
      },
      variables: {
        object: {
          id: props.id,
          ziferblatId,
          startsAt: values.startsAt?.toDate(),
          name: values.name,
          comment: values.comment,
          phone: values.phone
        },
      }}
    )
    .then(({ data }) => { 
      data?.insertBookingOne && 
        navigate(`/calendar/booking/${data?.insertBookingOne.id}`) 
      }),
    validate: ({ name }) => name ? undefined : { name: 'required' },
    validateOnBlur: true,
    enableReinitialize: true
  })

  return $(FormikContext.Provider, { value: formik }, props.children)
}

export type Form = {
  name: string | null
  startsAt: DateTime | null
  comment: string | null
  phone: string | null,
  hasCheckins: boolean
  checkins: BookingFragment['bookingCheckins'] | []
}

export default BookingWithData