import { CalendarEventsQueryResult } from "queries"

const fetchMoreCulturalEvents = (
  before: Date,
  // fetchMore: CulturalEventsQueryResult['fetchMore']
  fetchMore: CalendarEventsQueryResult['fetchMore']
) => fetchMore({
  variables: { before },
  updateQuery(prev, { fetchMoreResult }) {
    // FIXME this is required because there is no stable way
    // to avoid collision when sorting by starts at
    // because multiple events can start at the same time
    const culturalEvents = [...prev.culturalEvents]
    const booking = [...prev.booking]
    
    for (const culturalEvent of fetchMoreResult.culturalEvents)
      if (!culturalEvents.find(({ id }) => id === culturalEvent.id))
        culturalEvents.push(culturalEvent)

    return {
      ...fetchMoreResult,
      culturalEvents,
      booking
    }
  }
})

export default fetchMoreCulturalEvents