import { pathEntries } from 'pages/Main/paths'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMatch, useNavigate } from 'react-router-dom'

const useNavItems = () => {
	const navigate = useNavigate()
	const match = useMatch('/:id/*')
	const intl = useIntl()
	const [navItems, setNavItems] = useState<any>([])

	useEffect(() => {
		const updatedNavItems = pathEntries.map(([path, config]: any) => ({
			key: path,
			icon: config.icon,
			label: intl.formatMessage({ id: config.label }),
			selected: path === match?.params.id,
			onClick: () => navigate(`/${path}`),
			disabled: config.disabled,
			hidden: config.hidden,
		}))
		setNavItems(updatedNavItems)
	}, [match, navigate, intl])

	return navItems
}

export default useNavItems
