import { useLayoutContext } from '@gravity-ui/uikit'
import ZiferblatContext from 'components/ZiferblatContext'
import { addHours } from 'date-fns/fp/addHours'
import { useCheckinsQuery } from 'queries'
import { createElement as $, useContext, useMemo } from 'react'
import Desktop from './Desktop'
import Mobile from './Mobile'
import './style.scss'

const Checkins = () => {
  const { activeMediaQuery } = useLayoutContext()
  const component = activeMediaQuery === 's' ? Mobile : Desktop
  const { ziferblatId } = useContext(ZiferblatContext)
  const now = useMemo(() => new Date(), []) // FIXME needs to update (setInterval)
  const startsAt = addHours(-12, now) // FIXME get first upcoming event time (modify query)
  const endsAt = addHours(12, now)
  const { data, loading } = useCheckinsQuery({
    variables: { ziferblatId, startsAt, endsAt },
    pollInterval: 5000
  })

  // useEffect(() => {
  //   console.log('kek')
  //   if (networkStatus === 7)
  //     subscribeToMore({
  //       document: UpdateCheckinsDocument,
  //       variables: { ziferblatId },
  //       updateQuery: updateCheckins,
  //     })
  //     subscribeToMore({
  //       document: UpdateCulturalEventsDocument,
  //       variables: { ziferblatId, startsAt, endsAt },
  //       updateQuery: updateCulturalEvents
  //     })

  // }, [endsAt, networkStatus, startsAt, subscribeToMore, ziferblatId])

  return $(component, { data, loading })
}

// const updateCheckins: UpdateQueryFn<CheckinsQuery, { 
//   ziferblatId: string
//   }, UpdateCheckinsSubscription> = (prev, { subscriptionData }) => {
//   const checkins = subscriptionData.data.checkins

//   if (!subscriptionData)
//     return prev

//   return {
//     checkins, 
//     upcomingCulturalEvents: prev.upcomingCulturalEvents
//   }
// } 

// const updateCulturalEvents: UpdateQueryFn<CheckinsQuery, {
//   ziferblatId: string,
//   startsAt: Date,
//   endsAt: Date
// }, UpdateCulturalEventsSubscription> = (prev, { subscriptionData }) => {
//   const upcomingCulturalEvents = subscriptionData.data.upcomingCulturalEvents

//   if (!subscriptionData)
//     return prev

//   return {
//     checkins: prev.checkins,
//     upcomingCulturalEvents
//   }
// } 

export type CheckinsProps = Pick<ReturnType<typeof useCheckinsQuery>, 'data' | 'loading'>

export default Checkins