import { Modal, Sheet, spacing } from '@gravity-ui/uikit'
import { createElement as $, FC, PropsWithChildren } from 'react'

export const Mobile: FC<ContentProps & PropsWithChildren> = ({ open, setOpen, children }) => {
  return $(Sheet, { 
    visible: open, 
    onClose: () => setOpen(false), 
    },
    $('div', { 
      className: spacing({ px: 4 }) 
    }, children))
}

export const Desktop: FC<ContentProps & PropsWithChildren> = ({ open, setOpen, children }) => {
  return $(Modal, { 
    open,
    onEscapeKeyDown: () => setOpen(false),
    onOutsideClick: (e) => { 
      e.stopPropagation()
      setOpen(false) 
    }}, 
    $('div', { 
      className: spacing({ p: 4 }), 
      style: { 
        width: '420px'
    }}, children))
}

export type ContentProps = {
  open: boolean
  setOpen: (_arg: boolean) => void
}
