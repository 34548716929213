import { useApolloClient } from "@apollo/client"
import { ButtonProps } from "@gravity-ui/uikit"
import ZiferblatContext from "components/ZiferblatContext"
import { useAddToGalleryMutation, useImageUploadUrlMutation } from "queries"
import { createElement as $, ChangeEvent, FC, Fragment, useContext, useRef } from "react"
import { MediaLocationEnum } from "."

const UploadFab: FC<{ Fab: FC<FabProps> }> = ({ Fab }) => {
  const { ziferblatId } = useContext(ZiferblatContext)
	const ref = useRef<HTMLInputElement>(null)
  const [getUploadUrl] = useImageUploadUrlMutation()
  const [mutate, { loading }] = useAddToGalleryMutation()
  const client = useApolloClient()

	const onClick = () => {
		if (ref.current) {
			ref.current.click()
		}
	}

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const files = event.target.files || []
    
    try {
      for (const file of files) {
        const { data } = await getUploadUrl()
        
        if (!data?.getUploadUrl) 
          return console.log('no data')
        
        const { url, id } = data?.getUploadUrl
        const contentType = file.type

        if (url) {
          await fetch(url, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': 'image/png'
            }
          })
          await mutate({
            variables: {
              id,
              ziferblatId,
              location: MediaLocationEnum.Gallery, // FIXME let manually choose destination
              contentType
            }})
          await client.reFetchObservableQueries()
        }
      }
    }
    catch (error) { 
      console.log(error) 
    }
  }

  return $(Fragment, null,
    $(Fab, { onClick, props: { loading }}),
    $('input', {
      ref,
      style: { display: 'none' },
      type: 'file',
      multiple: true,
      onChange: handleUpload,
    })
  )
}

export type FabProps = {
  onClick?: () => void
  props?: ButtonProps
}

export default UploadFab