import { TextArea, TextAreaProps } from '@gravity-ui/uikit'
import { Field, FieldProps } from 'formik'
import { createElement as $, FC } from 'react'

const TextField: FC<TextAreaProps & { name: string }> = ({ name, ...props }) => 
  $(Field, {
    name,
    children: (field: FieldProps) =>
      $(GravityTextField, { field, props })
  })

const GravityTextField: FC<Props> = ({
  field,
  props
}) =>
  $(TextArea, {
    ...props,
    ...field.field,
    error: field.meta.error,
    onUpdate: (value) => field.form.setFieldValue(field.field.name, value)
  })

type Props = {
  field: FieldProps<string>,
  props: TextAreaProps
}

export default TextField;