import { Button, Tooltip } from "@gravity-ui/uikit"
import { DeviceProps } from "pages/Main"
import { MeQuery } from "queries"
import { createElement as $, FC, ReactElement } from "react"
import PopupButton from "./PopupButton"
import './style.scss'

const NavigationRail: FC<NavigationRailProps> = ({
  fab,
  navItems,
  data
}) => {
  return $('div', { className: 'navigation-rail' },
    $('div', { className: 'navigation-rail_fab' }, fab),
    $('div', { className: 'navigation-rail_nav-items' },
      navItems.map(NavItemIteratee)),
    $('div', { className: 'navigation-rail_flex-grower' }),
    $('div', null, 
      $(PopupButton, data)))
}

const NavItemIteratee = (props: NavigationRailProps['navItems'][number]) => {
  const children = $(Button, {
    size: 'l',
    view: 'flat',
    selected: props.selected,
    onClick: props.onClick,
    children: props.icon,
    disabled: props.disabled,
  })

  if (props.hidden) return null

  return $(Tooltip, {
    key: props.label,
    content: props.label,
    placement: 'right',
    children
  })
}
      
type NavigationRailProps = Pick<DeviceProps, 'navItems'> & {
  fab: ReactElement | undefined
  data: MeQuery | undefined
}
      

export default NavigationRail