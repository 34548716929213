import { DatePicker, DatePickerProps } from "@gravity-ui/date-components"
import { dateTime } from "@gravity-ui/date-utils"
import { useLayoutContext } from "@gravity-ui/uikit"
import { createElement as $, FC, Fragment, useRef } from "react"

const NativeTimePicker: FC<DatePickerProps> = (props) => {
	const { activeMediaQuery } = useLayoutContext()
  const inputRef = useRef<HTMLInputElement>(null)

  const handleInputFocus = (ref: typeof inputRef) => {
    if (ref.current && activeMediaQuery === 's') 
      try { ref.current.showPicker() }
      catch (error) { console.log(error) }
  }

  return $(Fragment, null,
    $(DatePicker, {
      ...props,
      onFocus: (e) => {
        props.onFocus && props.onFocus(e)
        handleInputFocus(inputRef)
      }
    }),
    $('input', { 
      ref: inputRef, 
      type: 'time', 
      value: props.value?.format(props.format),
      onChange: (event: any) => {
        if (!props.onUpdate) return
        const newStartTime = props.value?.format(`YYYY-MM-DDT${event.target.value}Z`)
        props.onUpdate(dateTime({ input: newStartTime }))
      },
      style: { 
        position: 'absolute',
        pointerEvents: 'none',
        left: 0,
        bottom: 0,
        opacity: 0, 
      }}))
}

export default NativeTimePicker