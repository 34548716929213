import { Button } from "@gravity-ui/uikit"
import { createElement as $, Fragment } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

const AddBooking = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  return $(Fragment, null, 
    $(Button, { 
      size: 'xl', 
      onClick: () => navigate('/calendar/booking/new')
      }, intl.formatMessage({ id: 'menu.addBooking' })))
}

export default AddBooking