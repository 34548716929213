import { Flex } from "@gravity-ui/uikit"
import Dniwe from "components/Dniwe"
import Empty from "components/Empty"
import { eventsLoadingElements } from "components/LoadingElements/LoadingElements"
import { compareAsc } from "date-fns"
import { createElement as $, FC } from "react"
import { FormattedMessage } from "react-intl"
import { CalendarEventProps } from ".."
import CulturalEventListItemIteratee from "./CalendarEventListItem"
import GroupEventsItem from "./GroupEventsItem"
import fetchMoreCulturalEvents from "./fetchMoreCalendarEvents"
import { CalendarEvent, groupCalendarEvents } from "./groupCalendarEvents"

const Content: FC<CalendarEventProps> = (props) => {
  const data = props.data
  if (!data) {
    if (props.loading)
      return eventsLoadingElements
    return $(Empty)
  }
  const bookings = props.data?.booking || []
  const culturalEvents = props.data?.culturalEvents || []
  const mergedEvents = [...bookings, ...culturalEvents].sort((a, b) => 
    compareAsc(b.startsAt, a.startsAt)) // FIXME probably wrong
  const groupedEvents = groupCalendarEvents(mergedEvents)
  const shouldShowStatus = !!data.draftCulturalEvents.length
  const before = data.culturalEvents[data?.culturalEvents.length - 1].startsAt
  const onReached = () => fetchMoreCulturalEvents(before, props.fetchMore)

  return $(Flex, { direction: 'column' },
    $(DraftCulturalEvents, { events: data.draftCulturalEvents }),
    $(GroupEventsItem, { events: groupedEvents, shouldShowStatus }),
    // FIXME this does not work for some reason
    data.culturalEvents.length < data.aggregateCulturalEvents.aggregate!.count
    // FIXME use skeleton
      && $(Dniwe, { onReached })
  )
}

const DraftCulturalEvents = ({ events }: { events: CalendarEvent[] }) => {
  if (!events.length) return null

  return $(Flex, { direction: 'column' }, 
    $('div', { className: 'event-status-container' },
      $(FormattedMessage, { id: `culturalEvent.status.Draft` })),
    $('div', { 
      className: 'events-container', 
      style: { 
        gridTemplateColumns: 'repeat(auto-fill, minmax(10rem, 1fr))',
      }}, 
      events.map(CulturalEventListItemIteratee)))
}

export default Content