import { useLayoutContext } from '@gravity-ui/uikit'
import { createElement as $, FC, useContext } from 'react'
import { StaffProps } from './Content'
import Desktop from './Desktop'
import Mobile from './Mobile'
import './style.scss'
import { useStaffQuery } from 'queries'
import ZiferblatContext from 'components/ZiferblatContext'

const Staff: FC<StaffProps> = () => {
  const { ziferblatId } = useContext(ZiferblatContext)
  const props = useStaffQuery({ variables: { ziferblatId } })
  const { activeMediaQuery } = useLayoutContext()
  const component = activeMediaQuery === 's' ? Mobile : Desktop
  
  return $(component, props)
}

export default Staff