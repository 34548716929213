import { FormRow } from '@gravity-ui/components'
import { DatePicker } from '@gravity-ui/date-components'
import { dateTimeParse } from '@gravity-ui/date-utils'
import { Xmark } from '@gravity-ui/icons'
import { Button, ButtonProps, Flex, TextInput, TextInputProps, spacing, useLayoutContext } from '@gravity-ui/uikit'
import NativeTimePicker from 'components/NativeTimePicker'
import { FormikProvider, useFormik, useFormikContext } from 'formik'
import { CulturalEventTicket, useAddTicketMutation } from 'queries'
import { createElement as $, FC } from 'react'
import { useIMask } from 'react-imask'
import { FormattedMessage, useIntl } from 'react-intl'

const TicketsForm: FC<TicketFormProps> = ({ setAdding, culturalEventId }) => {
  const [mutate] = useAddTicketMutation()
	const formik = useFormik<FormikProps>({
		initialValues: {
			activeFrom: new Date(),
			price: 0,
      maxCount: undefined,
      customUrl: '',
      customTitle: ''
		},
    validate: ({ customUrl }) => 
      !customUrl || !isValidUrl(customUrl) 
        ? { customUrl: 'invalid URL' }
        : undefined,
		onSubmit: (values) => { 
      mutate({
        variables: {
          culturalEventId,
          ...values
        },
        updateQueries: {
          CulturalEvent: (prev, { mutationResult }) => {
            const tickets = [...prev.culturalEvent.tickets, mutationResult.data?.insertCulturalEventTicketOne]
            return { culturalEvent: { ...prev.culturalEvent, tickets }}
          }
        }
      }).then(() => setAdding(false))
    },
    onReset: () => setAdding(false)
	})

	return $(FormikProvider, { value: formik }, $(Form))
}

const Form: FC = () => {
  const { 
    values,
    handleBlur, 
    handleChange,
    errors
  } = useFormikContext<Partial<CulturalEventTicket>>()
  const intl = useIntl()
  const activeFrom = dateTimeParse(values.activeFrom)
  const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's'
  const direction = isMobile ? 'column' : 'row'
  const onBlur = (name: string) => () => handleBlur({ target: { name }})
  const onChange = (name: string) => <T>(input: T) => handleChange({ target: { value: input, name }})
  const size: ButtonProps['size'] = isMobile ? 'l' : 'm'

  return $(Flex, { direction: 'column', className: spacing({ p: 4 }) }, 
    $(FormRow, { direction, label: intl.formatMessage({ id: 'culturalEvent.tickets.price' }) }, 
      $(TextInput, {
        size,
        autoFocus: true,
        value: getValue(values.price),
        rightContent: $('div', { className: spacing({ px: 2 }) }, $(FormattedMessage, { id: 'ruble' })),
        onUpdate: (value) => onChange('price')(isNaN(parseInt(value)) ? 0 : parseInt(value)),
        onBlur: onBlur('price'),
        placeholder: intl.formatMessage({ id: 'culturalEvent.tickets.price.placeholder' })
      })),
    $(FormRow, { direction, label: intl.formatMessage({ id: 'culturalEvent.tickets.maxCount' }) }, 
      $(TextInput, {
        size,
        value: getValue(values.maxCount!),
        rightContent: $('div', { className: spacing({ px: 2 }) }, 
          $(FormattedMessage, { id: 'culturalEvent.tickets.count', values: { count: '' }})),
        onUpdate: (value) => onChange('maxCount')(parseInt(value)),
        onBlur: onBlur('maxCount'),
        placeholder: intl.formatMessage({ id: 'culturalEvent.tickets.maxCount.empty' })
      })),
    $(FormRow, { direction, label: intl.formatMessage({ id: 'culturalEvent.tickets.planned' }) },
      $(Flex, { position: 'relative' },
        $(NativeTimePicker, {
          size,
          format: 'HH:mm',
          pin: 'round-clear',
          value: activeFrom,
          onUpdate(value) {
            onChange('activeFrom')(value)
          },
        }),
        $(DatePicker, {
          size,
          value: activeFrom,
          pin: 'clear-round',
          onUpdate: (date) => {
            onChange('activeFrom')(date)
          },
          format: 'DD.MM.YYYY',
          onBlur: onBlur('activeFrom'),
        }))),
    $(FormRow, { direction, label: intl.formatMessage({ id: 'culturalEvent.tickets.customUrl' }) }, 
      $(UrlInput, {
        size,
        type: 'url',
        validationState: !!errors.customUrl ? 'invalid' : undefined,
        onUpdate: (value) => onChange('customUrl')(value),
        onBlur: onBlur('customUrl'),
        placeholder: intl.formatMessage({ id: 'culturalEvent.tickets.customUrl.placeholder' }),
      })),
    $(FormRow, { direction, label: intl.formatMessage({ id: 'culturalEvent.tickets.customTitle' }) }, 
      $(TextInput, {
        size,
        type: 'url',
        validationState: !!errors.customTitle ? 'invalid' : undefined,
        onUpdate: (value) => onChange('customTitle')(value),
        onBlur: onBlur('customTitle'),
        placeholder: intl.formatMessage({ id: 'culturalEvent.tickets.customTitle.placeholder' })
      })),
    $(FormControls, { size }))
}

const FormControls: FC<{ size: ButtonProps['size'] }> = ({ size }) => {
  const { 
    values, 
    dirty, 
    isValid, 
    handleSubmit, 
    handleReset
  } = useFormikContext<Partial<CulturalEventTicket>>() 

  return $(Flex, { direction: 'column', gap: 2 },
    $(Button, { 
      view: 'action', 
      size,
      disabled: !dirty || !isValid || !values.customUrl || isNaN(values.price!), 
      onClick: () => handleSubmit()
      }, 
      $(FormattedMessage, { id: 'culturalEvent.form.save' })),
    $(Button, { 
      view: 'outlined',
      size,
      onClick: handleReset
      }, 
      $(Flex, { gap: 1, alignItems: 'center' }, 
        $(Xmark), 
        $(FormattedMessage, { id: 'culturalEvent.form.close' }))))
}

const getValue = (value: number | undefined) => {
  if (!value || isNaN(value)) return ''
  return value.toString()
}

const isValidUrl = (string: string) => {
  try {
    new URL(string)
    return true
  } catch (error) {
    return false
  }
}

const UrlInput: FC<TextInputProps> = ({
  onUpdate,
  ...props
}) => {
  const {
    ref,
  } = useIMask<HTMLInputElement>(
    { mask: 'https://{domain}',
      blocks: {
        domain: {
          mask: /^[a-zA-Z0-9.-]+(?:\/[a-zA-Z0-9._~:/?#[\]@!$&'()*+,;=%-]*)?$/
        }
      }
     }, 
    { defaultValue: props.value,
      onAccept: (value) => onUpdate?.(value),
    })

  return $(TextInput, { controlRef: ref, ...props })
}

type FormikProps = Pick<CulturalEventTicket, 'activeFrom' | 'price' | 'maxCount' | 'customUrl' | 'customTitle' >

type TicketFormProps = {
  setAdding: (arg: boolean) => void
  culturalEventId: string
}

export default TicketsForm