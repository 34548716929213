import ArrowLeft from '@gravity-ui/icons/ArrowLeft'
import Xmark from '@gravity-ui/icons/Xmark'
import { TextInput } from '@gravity-ui/uikit'
import { MessageIds } from 'components/IntlProvider'
import { createElement as $, FC, ReactElement, useState } from 'react'
import { useIntl } from 'react-intl'
import AdornmentButton from './AdornmentButton'
import Menu from './Menu'
import './style.scss'

const MobileMenu: FC<MobileMenuProps> = ({
  fab,
  label,
  search,
  setSearch
}) => {
  const { formatMessage } = useIntl()
  const [isSearchVisible, setSearchVisible] = useState(false)
  const back = $(AdornmentButton, { data: ArrowLeft, onClick: () => setSearchVisible(false) })
  const clear = $(AdornmentButton, { onClick: () => setSearch(''), data: Xmark })
  const menu = $('div', { onClick: () => setSearchVisible(false) }, $(Menu))

  return $(TextInput, {
    size: 'l',
    placeholder: label && formatMessage({ id: label }),
    className: `mobile-header_text-input ${isSearchVisible ? 'search' : 'menu'}`,
    value: search,
    onUpdate: (value) => setSearch(value),
    leftContent: isSearchVisible
      ? back
      : menu,
    rightContent: isSearchVisible
      ? search?.length && clear
      : fab,
    onFocus: () => setSearchVisible(true),
    onBlur: () => {
      setSearchVisible(false)
      setSearch('')
    },
  })
}

export type MobileMenuVisualProps = {
  fab?: ReactElement
  label?: MessageIds
}

type MobileMenuProps = MobileMenuVisualProps & {
  search: string
  setSearch: (value: string) => void
}

export default MobileMenu