import { DatePicker, DatePickerProps } from '@gravity-ui/date-components'
import { dateTime, DateTime } from '@gravity-ui/date-utils'
import { Field, FieldProps } from 'formik'
import { createElement as $, FC } from 'react'

const DateField: FC<DatePickerProps<Date> & { name: string }> = ({ name, ...props }) => 
  $(Field, {
    name,
    children: (field: FieldProps) =>
      $(GravityDateField, { field, props })
  })

const GravityDateField: FC<Props> = ({
  field,
  props
}) => {
  const datePickerProps = { ...props } as DatePickerProps<DateTime>
  return $(DatePicker, {
    format: 'DD.MM.YYYY',
    ...datePickerProps,
    ...field.field,
    value: dateTime({ input: field.field.value }),
    // TODO
    // error: meta.error,
    onUpdate: (value) => field.form.setFieldValue(field.field.name, value?.toDate())
  })
}

type Props = {
  field: FieldProps<Date>,
  props: DatePickerProps<Date>
}

export default DateField;