import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  date: { input: any; output: any; }
  interval: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  smallint: { input: number; output: number; }
  time: { input: any; output: any; }
  timestamptz: { input: Date; output: Date; }
  uuid: { input: string; output: string; }
};

/** columns and relationships of "access.access" */
export type AccessAccess = {
  __typename?: 'AccessAccess';
  objectId?: Maybe<Scalars['uuid']['output']>;
  permission?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "access.access" */
export type AccessAccessAggregate = {
  __typename?: 'AccessAccessAggregate';
  aggregate?: Maybe<AccessAccessAggregateFields>;
  nodes: Array<AccessAccess>;
};

export type AccessAccessAggregateBoolExp = {
  count?: InputMaybe<AccessAccessAggregateBoolExpCount>;
};

/** aggregate fields of "access.access" */
export type AccessAccessAggregateFields = {
  __typename?: 'AccessAccessAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AccessAccessMaxFields>;
  min?: Maybe<AccessAccessMinFields>;
};


/** aggregate fields of "access.access" */
export type AccessAccessAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccessAccessSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "access.access" */
export type AccessAccessAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccessAccessMaxOrderBy>;
  min?: InputMaybe<AccessAccessMinOrderBy>;
};

/** input type for inserting array relation for remote table "access.access" */
export type AccessAccessArrRelInsertInput = {
  data: Array<AccessAccessInsertInput>;
};

/** Boolean expression to filter rows from the table "access.access". All fields are combined with a logical 'AND'. */
export type AccessAccessBoolExp = {
  _and?: InputMaybe<Array<AccessAccessBoolExp>>;
  _not?: InputMaybe<AccessAccessBoolExp>;
  _or?: InputMaybe<Array<AccessAccessBoolExp>>;
  objectId?: InputMaybe<UuidComparisonExp>;
  permission?: InputMaybe<StringComparisonExp>;
  subjectId?: InputMaybe<UuidComparisonExp>;
};

/** input type for inserting data into table "access.access" */
export type AccessAccessInsertInput = {
  objectId?: InputMaybe<Scalars['uuid']['input']>;
  permission?: InputMaybe<Scalars['String']['input']>;
  subjectId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AccessAccessMaxFields = {
  __typename?: 'AccessAccessMaxFields';
  objectId?: Maybe<Scalars['uuid']['output']>;
  permission?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "access.access" */
export type AccessAccessMaxOrderBy = {
  objectId?: InputMaybe<OrderBy>;
  permission?: InputMaybe<OrderBy>;
  subjectId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AccessAccessMinFields = {
  __typename?: 'AccessAccessMinFields';
  objectId?: Maybe<Scalars['uuid']['output']>;
  permission?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "access.access" */
export type AccessAccessMinOrderBy = {
  objectId?: InputMaybe<OrderBy>;
  permission?: InputMaybe<OrderBy>;
  subjectId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "access.access". */
export type AccessAccessOrderBy = {
  objectId?: InputMaybe<OrderBy>;
  permission?: InputMaybe<OrderBy>;
  subjectId?: InputMaybe<OrderBy>;
};

/** select columns of table "access.access" */
export enum AccessAccessSelectColumn {
  /** column name */
  ObjectId = 'objectId',
  /** column name */
  Permission = 'permission',
  /** column name */
  SubjectId = 'subjectId'
}

/** Streaming cursor of the table "access_access" */
export type AccessAccessStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccessAccessStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessAccessStreamCursorValueInput = {
  objectId?: InputMaybe<Scalars['uuid']['input']>;
  permission?: InputMaybe<Scalars['String']['input']>;
  subjectId?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "access.expire" */
export type AccessExpire = {
  __typename?: 'AccessExpire';
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  expiredBy?: Maybe<AuthUser>;
  expiredById: Scalars['uuid']['output'];
  expiresAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  grant?: Maybe<AccessGrant>;
  grantId: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
};

/** aggregated selection of "access.expire" */
export type AccessExpireAggregate = {
  __typename?: 'AccessExpireAggregate';
  aggregate?: Maybe<AccessExpireAggregateFields>;
  nodes: Array<AccessExpire>;
};

export type AccessExpireAggregateBoolExp = {
  count?: InputMaybe<AccessExpireAggregateBoolExpCount>;
};

/** aggregate fields of "access.expire" */
export type AccessExpireAggregateFields = {
  __typename?: 'AccessExpireAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AccessExpireMaxFields>;
  min?: Maybe<AccessExpireMinFields>;
};


/** aggregate fields of "access.expire" */
export type AccessExpireAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccessExpireSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "access.expire" */
export type AccessExpireAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccessExpireMaxOrderBy>;
  min?: InputMaybe<AccessExpireMinOrderBy>;
};

/** input type for inserting array relation for remote table "access.expire" */
export type AccessExpireArrRelInsertInput = {
  data: Array<AccessExpireInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AccessExpireOnConflict>;
};

/** Boolean expression to filter rows from the table "access.expire". All fields are combined with a logical 'AND'. */
export type AccessExpireBoolExp = {
  _and?: InputMaybe<Array<AccessExpireBoolExp>>;
  _not?: InputMaybe<AccessExpireBoolExp>;
  _or?: InputMaybe<Array<AccessExpireBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  expiredBy?: InputMaybe<AuthUserBoolExp>;
  expiredById?: InputMaybe<UuidComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  grant?: InputMaybe<AccessGrantBoolExp>;
  grantId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "access.expire" */
export enum AccessExpireConstraint {
  /** unique or primary key constraint on columns "id" */
  ExpirePkey = 'expire_pkey'
}

/** input type for inserting data into table "access.expire" */
export type AccessExpireInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiredBy?: InputMaybe<AuthUserObjRelInsertInput>;
  expiredById?: InputMaybe<Scalars['uuid']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  grant?: InputMaybe<AccessGrantObjRelInsertInput>;
  grantId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AccessExpireMaxFields = {
  __typename?: 'AccessExpireMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiredById?: Maybe<Scalars['uuid']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  grantId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "access.expire" */
export type AccessExpireMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expiredById?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  grantId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AccessExpireMinFields = {
  __typename?: 'AccessExpireMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiredById?: Maybe<Scalars['uuid']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  grantId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "access.expire" */
export type AccessExpireMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expiredById?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  grantId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "access.expire" */
export type AccessExpireMutationResponse = {
  __typename?: 'AccessExpireMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessExpire>;
};

/** on_conflict condition type for table "access.expire" */
export type AccessExpireOnConflict = {
  constraint: AccessExpireConstraint;
  updateColumns?: Array<AccessExpireUpdateColumn>;
  where?: InputMaybe<AccessExpireBoolExp>;
};

/** Ordering options when selecting data from "access.expire". */
export type AccessExpireOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expiredBy?: InputMaybe<AuthUserOrderBy>;
  expiredById?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  grant?: InputMaybe<AccessGrantOrderBy>;
  grantId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: access.expire */
export type AccessExpirePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "access.expire" */
export enum AccessExpireSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiredById = 'expiredById',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  GrantId = 'grantId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "access.expire" */
export type AccessExpireSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiredById?: InputMaybe<Scalars['uuid']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  grantId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "access_expire" */
export type AccessExpireStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccessExpireStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessExpireStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiredById?: InputMaybe<Scalars['uuid']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  grantId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "access.expire" */
export enum AccessExpireUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiredById = 'expiredById',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  GrantId = 'grantId',
  /** column name */
  Id = 'id'
}

export type AccessExpireUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessExpireSetInput>;
  /** filter the rows which have to be updated */
  where: AccessExpireBoolExp;
};

/** columns and relationships of "access.grant" */
export type AccessGrant = {
  __typename?: 'AccessGrant';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  /** An array relationship */
  expires: Array<AccessExpire>;
  /** An aggregate relationship */
  expiresAggregate: AccessExpireAggregate;
  grantedById: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  objectId: Scalars['uuid']['output'];
  /** An object relationship */
  role: AccessRole;
  roleId: Scalars['uuid']['output'];
  /** An object relationship */
  subject: AuthUser;
  subjectId: Scalars['uuid']['output'];
  /** An object relationship */
  ziferblat?: Maybe<ZiferblatZiferblat>;
};


/** columns and relationships of "access.grant" */
export type AccessGrantAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "access.grant" */
export type AccessGrantAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "access.grant" */
export type AccessGrantExpiresArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


/** columns and relationships of "access.grant" */
export type AccessGrantExpiresAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};

/** aggregated selection of "access.grant" */
export type AccessGrantAggregate = {
  __typename?: 'AccessGrantAggregate';
  aggregate?: Maybe<AccessGrantAggregateFields>;
  nodes: Array<AccessGrant>;
};

export type AccessGrantAggregateBoolExp = {
  count?: InputMaybe<AccessGrantAggregateBoolExpCount>;
};

/** aggregate fields of "access.grant" */
export type AccessGrantAggregateFields = {
  __typename?: 'AccessGrantAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AccessGrantMaxFields>;
  min?: Maybe<AccessGrantMinFields>;
};


/** aggregate fields of "access.grant" */
export type AccessGrantAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccessGrantSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "access.grant" */
export type AccessGrantAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccessGrantMaxOrderBy>;
  min?: InputMaybe<AccessGrantMinOrderBy>;
};

/** input type for inserting array relation for remote table "access.grant" */
export type AccessGrantArrRelInsertInput = {
  data: Array<AccessGrantInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AccessGrantOnConflict>;
};

/** Boolean expression to filter rows from the table "access.grant". All fields are combined with a logical 'AND'. */
export type AccessGrantBoolExp = {
  _and?: InputMaybe<Array<AccessGrantBoolExp>>;
  _not?: InputMaybe<AccessGrantBoolExp>;
  _or?: InputMaybe<Array<AccessGrantBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  expires?: InputMaybe<AccessExpireBoolExp>;
  expiresAggregate?: InputMaybe<AccessExpireAggregateBoolExp>;
  grantedById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  objectId?: InputMaybe<UuidComparisonExp>;
  role?: InputMaybe<AccessRoleBoolExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
  subject?: InputMaybe<AuthUserBoolExp>;
  subjectId?: InputMaybe<UuidComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
};

/** unique or primary key constraints on table "access.grant" */
export enum AccessGrantConstraint {
  /** unique or primary key constraint on columns "id" */
  GrantPkey = 'grant_pkey'
}

/** input type for inserting data into table "access.grant" */
export type AccessGrantInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  expires?: InputMaybe<AccessExpireArrRelInsertInput>;
  grantedById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  objectId?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<AccessRoleObjRelInsertInput>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
  subject?: InputMaybe<AuthUserObjRelInsertInput>;
  subjectId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
};

/** aggregate max on columns */
export type AccessGrantMaxFields = {
  __typename?: 'AccessGrantMaxFields';
  grantedById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  objectId?: Maybe<Scalars['uuid']['output']>;
  roleId?: Maybe<Scalars['uuid']['output']>;
  subjectId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "access.grant" */
export type AccessGrantMaxOrderBy = {
  grantedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  objectId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  subjectId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AccessGrantMinFields = {
  __typename?: 'AccessGrantMinFields';
  grantedById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  objectId?: Maybe<Scalars['uuid']['output']>;
  roleId?: Maybe<Scalars['uuid']['output']>;
  subjectId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "access.grant" */
export type AccessGrantMinOrderBy = {
  grantedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  objectId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  subjectId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "access.grant" */
export type AccessGrantMutationResponse = {
  __typename?: 'AccessGrantMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessGrant>;
};

/** input type for inserting object relation for remote table "access.grant" */
export type AccessGrantObjRelInsertInput = {
  data: AccessGrantInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AccessGrantOnConflict>;
};

/** on_conflict condition type for table "access.grant" */
export type AccessGrantOnConflict = {
  constraint: AccessGrantConstraint;
  updateColumns?: Array<AccessGrantUpdateColumn>;
  where?: InputMaybe<AccessGrantBoolExp>;
};

/** Ordering options when selecting data from "access.grant". */
export type AccessGrantOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  expiresAggregate?: InputMaybe<AccessExpireAggregateOrderBy>;
  grantedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  objectId?: InputMaybe<OrderBy>;
  role?: InputMaybe<AccessRoleOrderBy>;
  roleId?: InputMaybe<OrderBy>;
  subject?: InputMaybe<AuthUserOrderBy>;
  subjectId?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
};

/** primary key columns input for table: access.grant */
export type AccessGrantPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "access.grant" */
export enum AccessGrantSelectColumn {
  /** column name */
  GrantedById = 'grantedById',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'objectId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  SubjectId = 'subjectId'
}

/** input type for updating data in table "access.grant" */
export type AccessGrantSetInput = {
  grantedById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  objectId?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
  subjectId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "access_grant" */
export type AccessGrantStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccessGrantStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessGrantStreamCursorValueInput = {
  grantedById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  objectId?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
  subjectId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "access.grant" */
export enum AccessGrantUpdateColumn {
  /** column name */
  GrantedById = 'grantedById',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'objectId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  SubjectId = 'subjectId'
}

export type AccessGrantUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessGrantSetInput>;
  /** filter the rows which have to be updated */
  where: AccessGrantBoolExp;
};

/** columns and relationships of "access.permission" */
export type AccessPermission = {
  __typename?: 'AccessPermission';
  createdAt: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  roleId: Scalars['uuid']['output'];
};

/** aggregated selection of "access.permission" */
export type AccessPermissionAggregate = {
  __typename?: 'AccessPermissionAggregate';
  aggregate?: Maybe<AccessPermissionAggregateFields>;
  nodes: Array<AccessPermission>;
};

/** aggregate fields of "access.permission" */
export type AccessPermissionAggregateFields = {
  __typename?: 'AccessPermissionAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AccessPermissionMaxFields>;
  min?: Maybe<AccessPermissionMinFields>;
};


/** aggregate fields of "access.permission" */
export type AccessPermissionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccessPermissionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "access.permission". All fields are combined with a logical 'AND'. */
export type AccessPermissionBoolExp = {
  _and?: InputMaybe<Array<AccessPermissionBoolExp>>;
  _not?: InputMaybe<AccessPermissionBoolExp>;
  _or?: InputMaybe<Array<AccessPermissionBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "access.permission" */
export enum AccessPermissionConstraint {
  /** unique or primary key constraint on columns "id" */
  PermissionPkey = 'permission_pkey'
}

/** input type for inserting data into table "access.permission" */
export type AccessPermissionInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AccessPermissionMaxFields = {
  __typename?: 'AccessPermissionMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  roleId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AccessPermissionMinFields = {
  __typename?: 'AccessPermissionMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  roleId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "access.permission" */
export type AccessPermissionMutationResponse = {
  __typename?: 'AccessPermissionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessPermission>;
};

/** on_conflict condition type for table "access.permission" */
export type AccessPermissionOnConflict = {
  constraint: AccessPermissionConstraint;
  updateColumns?: Array<AccessPermissionUpdateColumn>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};

/** Ordering options when selecting data from "access.permission". */
export type AccessPermissionOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: access.permission */
export type AccessPermissionPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "access.permission" */
export enum AccessPermissionSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId'
}

/** input type for updating data in table "access.permission" */
export type AccessPermissionSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "access_permission" */
export type AccessPermissionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccessPermissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessPermissionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "access.permission" */
export enum AccessPermissionUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId'
}

export type AccessPermissionUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessPermissionSetInput>;
  /** filter the rows which have to be updated */
  where: AccessPermissionBoolExp;
};

/** columns and relationships of "access.role" */
export type AccessRole = {
  __typename?: 'AccessRole';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "access.role" */
export type AccessRoleAggregate = {
  __typename?: 'AccessRoleAggregate';
  aggregate?: Maybe<AccessRoleAggregateFields>;
  nodes: Array<AccessRole>;
};

/** aggregate fields of "access.role" */
export type AccessRoleAggregateFields = {
  __typename?: 'AccessRoleAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AccessRoleMaxFields>;
  min?: Maybe<AccessRoleMinFields>;
};


/** aggregate fields of "access.role" */
export type AccessRoleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccessRoleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "access.role". All fields are combined with a logical 'AND'. */
export type AccessRoleBoolExp = {
  _and?: InputMaybe<Array<AccessRoleBoolExp>>;
  _not?: InputMaybe<AccessRoleBoolExp>;
  _or?: InputMaybe<Array<AccessRoleBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "access.role" */
export enum AccessRoleConstraint {
  /** unique or primary key constraint on columns "id" */
  RolePkey = 'role_pkey'
}

/** input type for inserting data into table "access.role" */
export type AccessRoleInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AccessRoleMaxFields = {
  __typename?: 'AccessRoleMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AccessRoleMinFields = {
  __typename?: 'AccessRoleMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "access.role" */
export type AccessRoleMutationResponse = {
  __typename?: 'AccessRoleMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessRole>;
};

/** input type for inserting object relation for remote table "access.role" */
export type AccessRoleObjRelInsertInput = {
  data: AccessRoleInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AccessRoleOnConflict>;
};

/** on_conflict condition type for table "access.role" */
export type AccessRoleOnConflict = {
  constraint: AccessRoleConstraint;
  updateColumns?: Array<AccessRoleUpdateColumn>;
  where?: InputMaybe<AccessRoleBoolExp>;
};

/** Ordering options when selecting data from "access.role". */
export type AccessRoleOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: access.role */
export type AccessRolePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "access.role" */
export enum AccessRoleSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "access.role" */
export type AccessRoleSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "access_role" */
export type AccessRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccessRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessRoleStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "access.role" */
export enum AccessRoleUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type AccessRoleUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessRoleSetInput>;
  /** filter the rows which have to be updated */
  where: AccessRoleBoolExp;
};

/** columns and relationships of "audit.log" */
export type AuditLog = {
  __typename?: 'AuditLog';
  createdAt: Scalars['timestamptz']['output'];
  entityIds: Array<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  operation: Scalars['String']['output'];
  payload: Scalars['jsonb']['output'];
  sessionId: Scalars['uuid']['output'];
};


/** columns and relationships of "audit.log" */
export type AuditLogPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "audit.log" */
export type AuditLogAggregate = {
  __typename?: 'AuditLogAggregate';
  aggregate?: Maybe<AuditLogAggregateFields>;
  nodes: Array<AuditLog>;
};

/** aggregate fields of "audit.log" */
export type AuditLogAggregateFields = {
  __typename?: 'AuditLogAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuditLogMaxFields>;
  min?: Maybe<AuditLogMinFields>;
};


/** aggregate fields of "audit.log" */
export type AuditLogAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuditLogSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuditLogAppendInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "audit.log". All fields are combined with a logical 'AND'. */
export type AuditLogBoolExp = {
  _and?: InputMaybe<Array<AuditLogBoolExp>>;
  _not?: InputMaybe<AuditLogBoolExp>;
  _or?: InputMaybe<Array<AuditLogBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  entityIds?: InputMaybe<UuidArrayComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  operation?: InputMaybe<StringComparisonExp>;
  payload?: InputMaybe<JsonbComparisonExp>;
  sessionId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "audit.log" */
export enum AuditLogConstraint {
  /** unique or primary key constraint on columns "id" */
  LogPkey = 'log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuditLogDeleteAtPathInput = {
  payload?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuditLogDeleteElemInput = {
  payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuditLogDeleteKeyInput = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "audit.log" */
export type AuditLogInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  entityIds?: InputMaybe<Array<Scalars['uuid']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  operation?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuditLogMaxFields = {
  __typename?: 'AuditLogMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  entityIds?: Maybe<Array<Scalars['uuid']['output']>>;
  id?: Maybe<Scalars['uuid']['output']>;
  operation?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AuditLogMinFields = {
  __typename?: 'AuditLogMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  entityIds?: Maybe<Array<Scalars['uuid']['output']>>;
  id?: Maybe<Scalars['uuid']['output']>;
  operation?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "audit.log" */
export type AuditLogMutationResponse = {
  __typename?: 'AuditLogMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuditLog>;
};

/** on_conflict condition type for table "audit.log" */
export type AuditLogOnConflict = {
  constraint: AuditLogConstraint;
  updateColumns?: Array<AuditLogUpdateColumn>;
  where?: InputMaybe<AuditLogBoolExp>;
};

/** Ordering options when selecting data from "audit.log". */
export type AuditLogOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  entityIds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  operation?: InputMaybe<OrderBy>;
  payload?: InputMaybe<OrderBy>;
  sessionId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: audit.log */
export type AuditLogPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuditLogPrependInput = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "audit.log" */
export enum AuditLogSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityIds = 'entityIds',
  /** column name */
  Id = 'id',
  /** column name */
  Operation = 'operation',
  /** column name */
  Payload = 'payload',
  /** column name */
  SessionId = 'sessionId'
}

/** input type for updating data in table "audit.log" */
export type AuditLogSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  entityIds?: InputMaybe<Array<Scalars['uuid']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  operation?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "audit_log" */
export type AuditLogStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuditLogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuditLogStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  entityIds?: InputMaybe<Array<Scalars['uuid']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  operation?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "audit.log" */
export enum AuditLogUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityIds = 'entityIds',
  /** column name */
  Id = 'id',
  /** column name */
  Operation = 'operation',
  /** column name */
  Payload = 'payload',
  /** column name */
  SessionId = 'sessionId'
}

export type AuditLogUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuditLogAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<AuditLogDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<AuditLogDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<AuditLogDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuditLogPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuditLogSetInput>;
  /** filter the rows which have to be updated */
  where: AuditLogBoolExp;
};

/** columns and relationships of "auth.alias" */
export type AuthAlias = {
  __typename?: 'AuthAlias';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  type: Scalars['String']['output'];
  /** An object relationship */
  user?: Maybe<AuthUser>;
  userId: Scalars['uuid']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "auth.alias" */
export type AuthAliasAggregate = {
  __typename?: 'AuthAliasAggregate';
  aggregate?: Maybe<AuthAliasAggregateFields>;
  nodes: Array<AuthAlias>;
};

export type AuthAliasAggregateBoolExp = {
  count?: InputMaybe<AuthAliasAggregateBoolExpCount>;
};

/** aggregate fields of "auth.alias" */
export type AuthAliasAggregateFields = {
  __typename?: 'AuthAliasAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthAliasMaxFields>;
  min?: Maybe<AuthAliasMinFields>;
};


/** aggregate fields of "auth.alias" */
export type AuthAliasAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthAliasSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.alias" */
export type AuthAliasAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AuthAliasMaxOrderBy>;
  min?: InputMaybe<AuthAliasMinOrderBy>;
};

/** input type for inserting array relation for remote table "auth.alias" */
export type AuthAliasArrRelInsertInput = {
  data: Array<AuthAliasInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AuthAliasOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.alias". All fields are combined with a logical 'AND'. */
export type AuthAliasBoolExp = {
  _and?: InputMaybe<Array<AuthAliasBoolExp>>;
  _not?: InputMaybe<AuthAliasBoolExp>;
  _or?: InputMaybe<Array<AuthAliasBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<AuthUserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "auth.alias" */
export enum AuthAliasConstraint {
  /** unique or primary key constraint on columns "id" */
  AliasPkey = 'alias_pkey',
  /** unique or primary key constraint on columns "user_id", "type" */
  ByVlalue = 'by_vlalue'
}

/** input type for inserting data into table "auth.alias" */
export type AuthAliasInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<AuthUserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthAliasMaxFields = {
  __typename?: 'AuthAliasMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "auth.alias" */
export type AuthAliasMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthAliasMinFields = {
  __typename?: 'AuthAliasMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "auth.alias" */
export type AuthAliasMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "auth.alias" */
export type AuthAliasMutationResponse = {
  __typename?: 'AuthAliasMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthAlias>;
};

/** on_conflict condition type for table "auth.alias" */
export type AuthAliasOnConflict = {
  constraint: AuthAliasConstraint;
  updateColumns?: Array<AuthAliasUpdateColumn>;
  where?: InputMaybe<AuthAliasBoolExp>;
};

/** Ordering options when selecting data from "auth.alias". */
export type AuthAliasOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  user?: InputMaybe<AuthUserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.alias */
export type AuthAliasPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.alias" */
export enum AuthAliasSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "auth.alias" */
export type AuthAliasSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_alias" */
export type AuthAliasStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthAliasStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthAliasStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.alias" */
export enum AuthAliasUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId',
  /** column name */
  Value = 'value'
}

export type AuthAliasUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthAliasSetInput>;
  /** filter the rows which have to be updated */
  where: AuthAliasBoolExp;
};

/** columns and relationships of "auth.logout" */
export type AuthLogout = {
  __typename?: 'AuthLogout';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  session?: Maybe<AuthSession>;
  sessionId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.logout" */
export type AuthLogoutAggregate = {
  __typename?: 'AuthLogoutAggregate';
  aggregate?: Maybe<AuthLogoutAggregateFields>;
  nodes: Array<AuthLogout>;
};

/** aggregate fields of "auth.logout" */
export type AuthLogoutAggregateFields = {
  __typename?: 'AuthLogoutAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthLogoutMaxFields>;
  min?: Maybe<AuthLogoutMinFields>;
};


/** aggregate fields of "auth.logout" */
export type AuthLogoutAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.logout". All fields are combined with a logical 'AND'. */
export type AuthLogoutBoolExp = {
  _and?: InputMaybe<Array<AuthLogoutBoolExp>>;
  _not?: InputMaybe<AuthLogoutBoolExp>;
  _or?: InputMaybe<Array<AuthLogoutBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  session?: InputMaybe<AuthSessionBoolExp>;
  sessionId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.logout" */
export enum AuthLogoutConstraint {
  /** unique or primary key constraint on columns "id" */
  LogoutPkey = 'logout_pkey'
}

/** input type for inserting data into table "auth.logout" */
export type AuthLogoutInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  session?: InputMaybe<AuthSessionObjRelInsertInput>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthLogoutMaxFields = {
  __typename?: 'AuthLogoutMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AuthLogoutMinFields = {
  __typename?: 'AuthLogoutMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.logout" */
export type AuthLogoutMutationResponse = {
  __typename?: 'AuthLogoutMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthLogout>;
};

/** on_conflict condition type for table "auth.logout" */
export type AuthLogoutOnConflict = {
  constraint: AuthLogoutConstraint;
  updateColumns?: Array<AuthLogoutUpdateColumn>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};

/** Ordering options when selecting data from "auth.logout". */
export type AuthLogoutOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  session?: InputMaybe<AuthSessionOrderBy>;
  sessionId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.logout */
export type AuthLogoutPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.logout" */
export enum AuthLogoutSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'sessionId'
}

/** input type for updating data in table "auth.logout" */
export type AuthLogoutSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "auth_logout" */
export type AuthLogoutStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthLogoutStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthLogoutStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.logout" */
export enum AuthLogoutUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'sessionId'
}

export type AuthLogoutUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthLogoutSetInput>;
  /** filter the rows which have to be updated */
  where: AuthLogoutBoolExp;
};

/** columns and relationships of "auth.session" */
export type AuthSession = {
  __typename?: 'AuthSession';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  token: Scalars['uuid']['output'];
  /** An object relationship */
  user?: Maybe<AuthUser>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

export type AuthSessionAggregate = {
  __typename?: 'AuthSessionAggregate';
  aggregate?: Maybe<AuthSessionAggregateFields>;
  nodes: Array<AuthSession>;
};

/** aggregate fields of "auth.session" */
export type AuthSessionAggregateFields = {
  __typename?: 'AuthSessionAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthSessionMaxFields>;
  min?: Maybe<AuthSessionMinFields>;
};


/** aggregate fields of "auth.session" */
export type AuthSessionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthSessionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.session". All fields are combined with a logical 'AND'. */
export type AuthSessionBoolExp = {
  _and?: InputMaybe<Array<AuthSessionBoolExp>>;
  _not?: InputMaybe<AuthSessionBoolExp>;
  _or?: InputMaybe<Array<AuthSessionBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  token?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<AuthUserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.session" */
export enum AuthSessionConstraint {
  /** unique or primary key constraint on columns "token" */
  ByToken = 'by_token',
  /** unique or primary key constraint on columns "id" */
  SessionPkey = 'session_pkey'
}

/** input type for inserting data into table "auth.session" */
export type AuthSessionInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  token?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<AuthUserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthSessionMaxFields = {
  __typename?: 'AuthSessionMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  token?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AuthSessionMinFields = {
  __typename?: 'AuthSessionMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  token?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.session" */
export type AuthSessionMutationResponse = {
  __typename?: 'AuthSessionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthSession>;
};

/** input type for inserting object relation for remote table "auth.session" */
export type AuthSessionObjRelInsertInput = {
  data: AuthSessionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AuthSessionOnConflict>;
};

/** on_conflict condition type for table "auth.session" */
export type AuthSessionOnConflict = {
  constraint: AuthSessionConstraint;
  updateColumns?: Array<AuthSessionUpdateColumn>;
  where?: InputMaybe<AuthSessionBoolExp>;
};

/** Ordering options when selecting data from "auth.session". */
export type AuthSessionOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
  user?: InputMaybe<AuthUserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.session */
export type AuthSessionPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.session" */
export enum AuthSessionSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.session" */
export type AuthSessionSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  token?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "auth_session" */
export type AuthSessionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthSessionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthSessionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  token?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.session" */
export enum AuthSessionUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  UserId = 'userId'
}

export type AuthSessionUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthSessionSetInput>;
  /** filter the rows which have to be updated */
  where: AuthSessionBoolExp;
};

/** columns and relationships of "auth.telegram_bot" */
export type AuthTelegramBot = {
  __typename?: 'AuthTelegramBot';
  botId: Scalars['Int']['output'];
  createdAt: Scalars['timestamptz']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "auth.telegram_bot" */
export type AuthTelegramBotAggregate = {
  __typename?: 'AuthTelegramBotAggregate';
  aggregate?: Maybe<AuthTelegramBotAggregateFields>;
  nodes: Array<AuthTelegramBot>;
};

/** aggregate fields of "auth.telegram_bot" */
export type AuthTelegramBotAggregateFields = {
  __typename?: 'AuthTelegramBotAggregateFields';
  avg?: Maybe<AuthTelegramBotAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AuthTelegramBotMaxFields>;
  min?: Maybe<AuthTelegramBotMinFields>;
  stddev?: Maybe<AuthTelegramBotStddevFields>;
  stddevPop?: Maybe<AuthTelegramBotStddevPopFields>;
  stddevSamp?: Maybe<AuthTelegramBotStddevSampFields>;
  sum?: Maybe<AuthTelegramBotSumFields>;
  varPop?: Maybe<AuthTelegramBotVarPopFields>;
  varSamp?: Maybe<AuthTelegramBotVarSampFields>;
  variance?: Maybe<AuthTelegramBotVarianceFields>;
};


/** aggregate fields of "auth.telegram_bot" */
export type AuthTelegramBotAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthTelegramBotSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type AuthTelegramBotAvgFields = {
  __typename?: 'AuthTelegramBotAvgFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "auth.telegram_bot". All fields are combined with a logical 'AND'. */
export type AuthTelegramBotBoolExp = {
  _and?: InputMaybe<Array<AuthTelegramBotBoolExp>>;
  _not?: InputMaybe<AuthTelegramBotBoolExp>;
  _or?: InputMaybe<Array<AuthTelegramBotBoolExp>>;
  botId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  domain?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "auth.telegram_bot" */
export enum AuthTelegramBotConstraint {
  /** unique or primary key constraint on columns "id" */
  TelegramBotPkey = 'telegram_bot_pkey'
}

/** input type for incrementing numeric columns in table "auth.telegram_bot" */
export type AuthTelegramBotIncInput = {
  botId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "auth.telegram_bot" */
export type AuthTelegramBotInsertInput = {
  botId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthTelegramBotMaxFields = {
  __typename?: 'AuthTelegramBotMaxFields';
  botId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthTelegramBotMinFields = {
  __typename?: 'AuthTelegramBotMinFields';
  botId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.telegram_bot" */
export type AuthTelegramBotMutationResponse = {
  __typename?: 'AuthTelegramBotMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthTelegramBot>;
};

/** on_conflict condition type for table "auth.telegram_bot" */
export type AuthTelegramBotOnConflict = {
  constraint: AuthTelegramBotConstraint;
  updateColumns?: Array<AuthTelegramBotUpdateColumn>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};

/** Ordering options when selecting data from "auth.telegram_bot". */
export type AuthTelegramBotOrderBy = {
  botId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  domain?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.telegram_bot */
export type AuthTelegramBotPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.telegram_bot" */
export enum AuthTelegramBotSelectColumn {
  /** column name */
  BotId = 'botId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "auth.telegram_bot" */
export type AuthTelegramBotSetInput = {
  botId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type AuthTelegramBotStddevFields = {
  __typename?: 'AuthTelegramBotStddevFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type AuthTelegramBotStddevPopFields = {
  __typename?: 'AuthTelegramBotStddevPopFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type AuthTelegramBotStddevSampFields = {
  __typename?: 'AuthTelegramBotStddevSampFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "auth_telegram_bot" */
export type AuthTelegramBotStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthTelegramBotStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthTelegramBotStreamCursorValueInput = {
  botId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type AuthTelegramBotSumFields = {
  __typename?: 'AuthTelegramBotSumFields';
  botId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "auth.telegram_bot" */
export enum AuthTelegramBotUpdateColumn {
  /** column name */
  BotId = 'botId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type AuthTelegramBotUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuthTelegramBotIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthTelegramBotSetInput>;
  /** filter the rows which have to be updated */
  where: AuthTelegramBotBoolExp;
};

/** aggregate varPop on columns */
export type AuthTelegramBotVarPopFields = {
  __typename?: 'AuthTelegramBotVarPopFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type AuthTelegramBotVarSampFields = {
  __typename?: 'AuthTelegramBotVarSampFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type AuthTelegramBotVarianceFields = {
  __typename?: 'AuthTelegramBotVarianceFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "auth.telegram_input" */
export type AuthTelegramInput = {
  __typename?: 'AuthTelegramInput';
  authDate: Scalars['Int']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  session: AuthSession;
  sessionId: Scalars['uuid']['output'];
  username: Scalars['String']['output'];
};

/** aggregated selection of "auth.telegram_input" */
export type AuthTelegramInputAggregate = {
  __typename?: 'AuthTelegramInputAggregate';
  aggregate?: Maybe<AuthTelegramInputAggregateFields>;
  nodes: Array<AuthTelegramInput>;
};

/** aggregate fields of "auth.telegram_input" */
export type AuthTelegramInputAggregateFields = {
  __typename?: 'AuthTelegramInputAggregateFields';
  avg?: Maybe<AuthTelegramInputAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AuthTelegramInputMaxFields>;
  min?: Maybe<AuthTelegramInputMinFields>;
  stddev?: Maybe<AuthTelegramInputStddevFields>;
  stddevPop?: Maybe<AuthTelegramInputStddevPopFields>;
  stddevSamp?: Maybe<AuthTelegramInputStddevSampFields>;
  sum?: Maybe<AuthTelegramInputSumFields>;
  varPop?: Maybe<AuthTelegramInputVarPopFields>;
  varSamp?: Maybe<AuthTelegramInputVarSampFields>;
  variance?: Maybe<AuthTelegramInputVarianceFields>;
};


/** aggregate fields of "auth.telegram_input" */
export type AuthTelegramInputAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthTelegramInputSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type AuthTelegramInputAvgFields = {
  __typename?: 'AuthTelegramInputAvgFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "auth.telegram_input". All fields are combined with a logical 'AND'. */
export type AuthTelegramInputBoolExp = {
  _and?: InputMaybe<Array<AuthTelegramInputBoolExp>>;
  _not?: InputMaybe<AuthTelegramInputBoolExp>;
  _or?: InputMaybe<Array<AuthTelegramInputBoolExp>>;
  authDate?: InputMaybe<IntComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  hash?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  photoUrl?: InputMaybe<StringComparisonExp>;
  session?: InputMaybe<AuthSessionBoolExp>;
  sessionId?: InputMaybe<UuidComparisonExp>;
  username?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "auth.telegram_input" */
export enum AuthTelegramInputConstraint {
  /** unique or primary key constraint on columns "hash" */
  TelegramInputPkey = 'telegram_input_pkey'
}

/** input type for incrementing numeric columns in table "auth.telegram_input" */
export type AuthTelegramInputIncInput = {
  authDate?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "auth.telegram_input" */
export type AuthTelegramInputInsertInput = {
  authDate?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<AuthSessionObjRelInsertInput>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthTelegramInputMaxFields = {
  __typename?: 'AuthTelegramInputMaxFields';
  authDate?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthTelegramInputMinFields = {
  __typename?: 'AuthTelegramInputMinFields';
  authDate?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.telegram_input" */
export type AuthTelegramInputMutationResponse = {
  __typename?: 'AuthTelegramInputMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthTelegramInput>;
};

/** on_conflict condition type for table "auth.telegram_input" */
export type AuthTelegramInputOnConflict = {
  constraint: AuthTelegramInputConstraint;
  updateColumns?: Array<AuthTelegramInputUpdateColumn>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};

/** Ordering options when selecting data from "auth.telegram_input". */
export type AuthTelegramInputOrderBy = {
  authDate?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  hash?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  photoUrl?: InputMaybe<OrderBy>;
  session?: InputMaybe<AuthSessionOrderBy>;
  sessionId?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.telegram_input */
export type AuthTelegramInputPkColumnsInput = {
  hash: Scalars['String']['input'];
};

/** select columns of table "auth.telegram_input" */
export enum AuthTelegramInputSelectColumn {
  /** column name */
  AuthDate = 'authDate',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "auth.telegram_input" */
export type AuthTelegramInputSetInput = {
  authDate?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type AuthTelegramInputStddevFields = {
  __typename?: 'AuthTelegramInputStddevFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type AuthTelegramInputStddevPopFields = {
  __typename?: 'AuthTelegramInputStddevPopFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type AuthTelegramInputStddevSampFields = {
  __typename?: 'AuthTelegramInputStddevSampFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "auth_telegram_input" */
export type AuthTelegramInputStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthTelegramInputStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthTelegramInputStreamCursorValueInput = {
  authDate?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type AuthTelegramInputSumFields = {
  __typename?: 'AuthTelegramInputSumFields';
  authDate?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "auth.telegram_input" */
export enum AuthTelegramInputUpdateColumn {
  /** column name */
  AuthDate = 'authDate',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  Username = 'username'
}

export type AuthTelegramInputUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuthTelegramInputIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthTelegramInputSetInput>;
  /** filter the rows which have to be updated */
  where: AuthTelegramInputBoolExp;
};

/** aggregate varPop on columns */
export type AuthTelegramInputVarPopFields = {
  __typename?: 'AuthTelegramInputVarPopFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type AuthTelegramInputVarSampFields = {
  __typename?: 'AuthTelegramInputVarSampFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type AuthTelegramInputVarianceFields = {
  __typename?: 'AuthTelegramInputVarianceFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "auth.user" */
export type AuthUser = {
  __typename?: 'AuthUser';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  /** An array relationship */
  alias: Array<AuthAlias>;
  /** An aggregate relationship */
  aliasAggregate: AuthAliasAggregate;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  checkin?: Maybe<Checkin>;
  createdAt: Scalars['timestamptz']['output'];
  createdById: Scalars['uuid']['output'];
  /** An object relationship */
  favoriteZiferblat?: Maybe<ZiferblatZiferblat>;
  favoriteZiferblatId?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  grants: Array<AccessGrant>;
  /** An aggregate relationship */
  grantsAggregate: AccessGrantAggregate;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  passes: Array<TariffPassUser>;
  /** An aggregate relationship */
  passesAggregate: TariffPassUserAggregate;
  pensioner: Scalars['Boolean']['output'];
};


/** columns and relationships of "auth.user" */
export type AuthUserAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserAliasArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserAliasAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserGrantsArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserGrantsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserPassesArgs = {
  distinctOn?: InputMaybe<Array<TariffPassUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassUserOrderBy>>;
  where?: InputMaybe<TariffPassUserBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserPassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffPassUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassUserOrderBy>>;
  where?: InputMaybe<TariffPassUserBoolExp>;
};

export type AuthUserAggregate = {
  __typename?: 'AuthUserAggregate';
  aggregate?: Maybe<AuthUserAggregateFields>;
  nodes: Array<AuthUser>;
};

/** aggregate fields of "auth.user" */
export type AuthUserAggregateFields = {
  __typename?: 'AuthUserAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserMaxFields>;
  min?: Maybe<AuthUserMinFields>;
};


/** aggregate fields of "auth.user" */
export type AuthUserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.user". All fields are combined with a logical 'AND'. */
export type AuthUserBoolExp = {
  _and?: InputMaybe<Array<AuthUserBoolExp>>;
  _not?: InputMaybe<AuthUserBoolExp>;
  _or?: InputMaybe<Array<AuthUserBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  alias?: InputMaybe<AuthAliasBoolExp>;
  aliasAggregate?: InputMaybe<AuthAliasAggregateBoolExp>;
  avatarUrl?: InputMaybe<StringComparisonExp>;
  birthday?: InputMaybe<DateComparisonExp>;
  checkin?: InputMaybe<CheckinBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  favoriteZiferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  favoriteZiferblatId?: InputMaybe<UuidComparisonExp>;
  grants?: InputMaybe<AccessGrantBoolExp>;
  grantsAggregate?: InputMaybe<AccessGrantAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  passes?: InputMaybe<TariffPassUserBoolExp>;
  passesAggregate?: InputMaybe<TariffPassUserAggregateBoolExp>;
  pensioner?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "auth.user" */
export enum AuthUserConstraint {
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "auth.user" */
export type AuthUserInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  alias?: InputMaybe<AuthAliasArrRelInsertInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['date']['input']>;
  checkin?: InputMaybe<CheckinObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  favoriteZiferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  favoriteZiferblatId?: InputMaybe<Scalars['uuid']['input']>;
  grants?: InputMaybe<AccessGrantArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  passes?: InputMaybe<TariffPassUserArrRelInsertInput>;
  pensioner?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type AuthUserMaxFields = {
  __typename?: 'AuthUserMaxFields';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['date']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdById?: Maybe<Scalars['uuid']['output']>;
  favoriteZiferblatId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AuthUserMinFields = {
  __typename?: 'AuthUserMinFields';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['date']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdById?: Maybe<Scalars['uuid']['output']>;
  favoriteZiferblatId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.user" */
export type AuthUserMutationResponse = {
  __typename?: 'AuthUserMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUser>;
};

/** input type for inserting object relation for remote table "auth.user" */
export type AuthUserObjRelInsertInput = {
  data: AuthUserInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AuthUserOnConflict>;
};

/** on_conflict condition type for table "auth.user" */
export type AuthUserOnConflict = {
  constraint: AuthUserConstraint;
  updateColumns?: Array<AuthUserUpdateColumn>;
  where?: InputMaybe<AuthUserBoolExp>;
};

/** Ordering options when selecting data from "auth.user". */
export type AuthUserOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  aliasAggregate?: InputMaybe<AuthAliasAggregateOrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  birthday?: InputMaybe<OrderBy>;
  checkin?: InputMaybe<CheckinOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  favoriteZiferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  favoriteZiferblatId?: InputMaybe<OrderBy>;
  grantsAggregate?: InputMaybe<AccessGrantAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  passesAggregate?: InputMaybe<TariffPassUserAggregateOrderBy>;
  pensioner?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.user */
export type AuthUserPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user" */
export enum AuthUserSelectColumn {
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  FavoriteZiferblatId = 'favoriteZiferblatId',
  /** column name */
  Id = 'id',
  /** column name */
  Pensioner = 'pensioner'
}

/** input type for updating data in table "auth.user" */
export type AuthUserSetInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['date']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  favoriteZiferblatId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  pensioner?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "auth_user" */
export type AuthUserStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthUserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserStreamCursorValueInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['date']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  favoriteZiferblatId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  pensioner?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "auth.user" */
export enum AuthUserUpdateColumn {
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  FavoriteZiferblatId = 'favoriteZiferblatId',
  /** column name */
  Id = 'id',
  /** column name */
  Pensioner = 'pensioner'
}

export type AuthUserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserSetInput>;
  /** filter the rows which have to be updated */
  where: AuthUserBoolExp;
};

/** columns and relationships of "booking" */
export type Booking = {
  __typename?: 'Booking';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  bookedById: Scalars['uuid']['output'];
  /** An array relationship */
  bookingCheckins: Array<BookingCheckin>;
  /** An aggregate relationship */
  bookingCheckinsAggregate: BookingCheckinAggregate;
  comment: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  startsAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "booking" */
export type BookingAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "booking" */
export type BookingAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "booking" */
export type BookingBookingCheckinsArgs = {
  distinctOn?: InputMaybe<Array<BookingCheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BookingCheckinOrderBy>>;
  where?: InputMaybe<BookingCheckinBoolExp>;
};


/** columns and relationships of "booking" */
export type BookingBookingCheckinsAggregateArgs = {
  distinctOn?: InputMaybe<Array<BookingCheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BookingCheckinOrderBy>>;
  where?: InputMaybe<BookingCheckinBoolExp>;
};

/** aggregated selection of "booking" */
export type BookingAggregate = {
  __typename?: 'BookingAggregate';
  aggregate?: Maybe<BookingAggregateFields>;
  nodes: Array<Booking>;
};

/** aggregate fields of "booking" */
export type BookingAggregateFields = {
  __typename?: 'BookingAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<BookingMaxFields>;
  min?: Maybe<BookingMinFields>;
};


/** aggregate fields of "booking" */
export type BookingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BookingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "booking". All fields are combined with a logical 'AND'. */
export type BookingBoolExp = {
  _and?: InputMaybe<Array<BookingBoolExp>>;
  _not?: InputMaybe<BookingBoolExp>;
  _or?: InputMaybe<Array<BookingBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  bookedById?: InputMaybe<UuidComparisonExp>;
  bookingCheckins?: InputMaybe<BookingCheckinBoolExp>;
  bookingCheckinsAggregate?: InputMaybe<BookingCheckinAggregateBoolExp>;
  comment?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  phone?: InputMaybe<StringComparisonExp>;
  startsAt?: InputMaybe<TimestamptzComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** columns and relationships of "booking_checkin" */
export type BookingCheckin = {
  __typename?: 'BookingCheckin';
  addedById: Scalars['uuid']['output'];
  /** An object relationship */
  booking: Booking;
  bookingId: Scalars['uuid']['output'];
  /** An object relationship */
  checkin?: Maybe<Checkin>;
  checkinId: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  visit?: Maybe<Visit>;
};

/** aggregated selection of "booking_checkin" */
export type BookingCheckinAggregate = {
  __typename?: 'BookingCheckinAggregate';
  aggregate?: Maybe<BookingCheckinAggregateFields>;
  nodes: Array<BookingCheckin>;
};

export type BookingCheckinAggregateBoolExp = {
  count?: InputMaybe<BookingCheckinAggregateBoolExpCount>;
};

/** aggregate fields of "booking_checkin" */
export type BookingCheckinAggregateFields = {
  __typename?: 'BookingCheckinAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<BookingCheckinMaxFields>;
  min?: Maybe<BookingCheckinMinFields>;
};


/** aggregate fields of "booking_checkin" */
export type BookingCheckinAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BookingCheckinSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "booking_checkin" */
export type BookingCheckinAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BookingCheckinMaxOrderBy>;
  min?: InputMaybe<BookingCheckinMinOrderBy>;
};

/** input type for inserting array relation for remote table "booking_checkin" */
export type BookingCheckinArrRelInsertInput = {
  data: Array<BookingCheckinInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<BookingCheckinOnConflict>;
};

/** Boolean expression to filter rows from the table "booking_checkin". All fields are combined with a logical 'AND'. */
export type BookingCheckinBoolExp = {
  _and?: InputMaybe<Array<BookingCheckinBoolExp>>;
  _not?: InputMaybe<BookingCheckinBoolExp>;
  _or?: InputMaybe<Array<BookingCheckinBoolExp>>;
  addedById?: InputMaybe<UuidComparisonExp>;
  booking?: InputMaybe<BookingBoolExp>;
  bookingId?: InputMaybe<UuidComparisonExp>;
  checkin?: InputMaybe<CheckinBoolExp>;
  checkinId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
};

/** unique or primary key constraints on table "booking_checkin" */
export enum BookingCheckinConstraint {
  /** unique or primary key constraint on columns "checkin_id" */
  BookingCheckinCheckinIdKey = 'booking_checkin_checkin_id_key',
  /** unique or primary key constraint on columns "id" */
  BookingCheckinPkey = 'booking_checkin_pkey'
}

/** input type for inserting data into table "booking_checkin" */
export type BookingCheckinInsertInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  booking?: InputMaybe<BookingObjRelInsertInput>;
  bookingId?: InputMaybe<Scalars['uuid']['input']>;
  checkin?: InputMaybe<CheckinObjRelInsertInput>;
  checkinId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
};

/** aggregate max on columns */
export type BookingCheckinMaxFields = {
  __typename?: 'BookingCheckinMaxFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  bookingId?: Maybe<Scalars['uuid']['output']>;
  checkinId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "booking_checkin" */
export type BookingCheckinMaxOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  bookingId?: InputMaybe<OrderBy>;
  checkinId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type BookingCheckinMinFields = {
  __typename?: 'BookingCheckinMinFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  bookingId?: Maybe<Scalars['uuid']['output']>;
  checkinId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "booking_checkin" */
export type BookingCheckinMinOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  bookingId?: InputMaybe<OrderBy>;
  checkinId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "booking_checkin" */
export type BookingCheckinMutationResponse = {
  __typename?: 'BookingCheckinMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BookingCheckin>;
};

/** input type for inserting object relation for remote table "booking_checkin" */
export type BookingCheckinObjRelInsertInput = {
  data: BookingCheckinInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<BookingCheckinOnConflict>;
};

/** on_conflict condition type for table "booking_checkin" */
export type BookingCheckinOnConflict = {
  constraint: BookingCheckinConstraint;
  updateColumns?: Array<BookingCheckinUpdateColumn>;
  where?: InputMaybe<BookingCheckinBoolExp>;
};

/** Ordering options when selecting data from "booking_checkin". */
export type BookingCheckinOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  booking?: InputMaybe<BookingOrderBy>;
  bookingId?: InputMaybe<OrderBy>;
  checkin?: InputMaybe<CheckinOrderBy>;
  checkinId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
};

/** primary key columns input for table: booking_checkin */
export type BookingCheckinPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "booking_checkin" */
export enum BookingCheckinSelectColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  CheckinId = 'checkinId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "booking_checkin" */
export type BookingCheckinSetInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  bookingId?: InputMaybe<Scalars['uuid']['input']>;
  checkinId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "booking_checkin" */
export type BookingCheckinStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BookingCheckinStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BookingCheckinStreamCursorValueInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  bookingId?: InputMaybe<Scalars['uuid']['input']>;
  checkinId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "booking_checkin" */
export enum BookingCheckinUpdateColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  BookingId = 'bookingId',
  /** column name */
  CheckinId = 'checkinId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id'
}

export type BookingCheckinUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookingCheckinSetInput>;
  /** filter the rows which have to be updated */
  where: BookingCheckinBoolExp;
};

/** unique or primary key constraints on table "booking" */
export enum BookingConstraint {
  /** unique or primary key constraint on columns "id" */
  BookingPkey = 'booking_pkey'
}

/** input type for inserting data into table "booking" */
export type BookingInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  bookedById?: InputMaybe<Scalars['uuid']['input']>;
  bookingCheckins?: InputMaybe<BookingCheckinArrRelInsertInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type BookingMaxFields = {
  __typename?: 'BookingMaxFields';
  bookedById?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['timestamptz']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type BookingMinFields = {
  __typename?: 'BookingMinFields';
  bookedById?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['timestamptz']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "booking" */
export type BookingMutationResponse = {
  __typename?: 'BookingMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking>;
};

/** input type for inserting object relation for remote table "booking" */
export type BookingObjRelInsertInput = {
  data: BookingInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<BookingOnConflict>;
};

/** on_conflict condition type for table "booking" */
export type BookingOnConflict = {
  constraint: BookingConstraint;
  updateColumns?: Array<BookingUpdateColumn>;
  where?: InputMaybe<BookingBoolExp>;
};

/** Ordering options when selecting data from "booking". */
export type BookingOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  bookedById?: InputMaybe<OrderBy>;
  bookingCheckinsAggregate?: InputMaybe<BookingCheckinAggregateOrderBy>;
  comment?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  startsAt?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: booking */
export type BookingPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "booking" */
export enum BookingSelectColumn {
  /** column name */
  BookedById = 'bookedById',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  StartsAt = 'startsAt',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "booking" */
export type BookingSetInput = {
  bookedById?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "booking" */
export type BookingStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BookingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BookingStreamCursorValueInput = {
  bookedById?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "booking" */
export enum BookingUpdateColumn {
  /** column name */
  BookedById = 'bookedById',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  StartsAt = 'startsAt',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type BookingUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookingSetInput>;
  /** filter the rows which have to be updated */
  where: BookingBoolExp;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** columns and relationships of "checkin" */
export type Checkin = {
  __typename?: 'Checkin';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  /** An array relationship */
  attendance: Array<CulturalEventAttendance>;
  /** An aggregate relationship */
  attendanceAggregate: CulturalEventAttendanceAggregate;
  checkedInAt: Scalars['timestamptz']['output'];
  checkedInById: Scalars['uuid']['output'];
  /** An object relationship */
  checkinBooking?: Maybe<BookingCheckin>;
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  pause: Array<Pause>;
  /** An aggregate relationship */
  pauseAggregate: PauseAggregate;
  /** A computed field, executes function "calculate_checkin_tariff_multirange" */
  sum?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  user?: Maybe<AuthUser>;
  userId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  ziferblat?: Maybe<ZiferblatZiferblat>;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "checkin" */
export type CheckinAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "checkin" */
export type CheckinAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "checkin" */
export type CheckinAttendanceArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


/** columns and relationships of "checkin" */
export type CheckinAttendanceAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


/** columns and relationships of "checkin" */
export type CheckinPauseArgs = {
  distinctOn?: InputMaybe<Array<PauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PauseOrderBy>>;
  where?: InputMaybe<PauseBoolExp>;
};


/** columns and relationships of "checkin" */
export type CheckinPauseAggregateArgs = {
  distinctOn?: InputMaybe<Array<PauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PauseOrderBy>>;
  where?: InputMaybe<PauseBoolExp>;
};

/** aggregated selection of "checkin" */
export type CheckinAggregate = {
  __typename?: 'CheckinAggregate';
  aggregate?: Maybe<CheckinAggregateFields>;
  nodes: Array<Checkin>;
};

/** aggregate fields of "checkin" */
export type CheckinAggregateFields = {
  __typename?: 'CheckinAggregateFields';
  avg?: Maybe<CheckinAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CheckinMaxFields>;
  min?: Maybe<CheckinMinFields>;
  stddev?: Maybe<CheckinStddevFields>;
  stddevPop?: Maybe<CheckinStddevPopFields>;
  stddevSamp?: Maybe<CheckinStddevSampFields>;
  sum?: Maybe<CheckinSumFields>;
  varPop?: Maybe<CheckinVarPopFields>;
  varSamp?: Maybe<CheckinVarSampFields>;
  variance?: Maybe<CheckinVarianceFields>;
};


/** aggregate fields of "checkin" */
export type CheckinAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CheckinSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CheckinAvgFields = {
  __typename?: 'CheckinAvgFields';
  /** A computed field, executes function "calculate_checkin_tariff_multirange" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "checkin". All fields are combined with a logical 'AND'. */
export type CheckinBoolExp = {
  _and?: InputMaybe<Array<CheckinBoolExp>>;
  _not?: InputMaybe<CheckinBoolExp>;
  _or?: InputMaybe<Array<CheckinBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  attendance?: InputMaybe<CulturalEventAttendanceBoolExp>;
  attendanceAggregate?: InputMaybe<CulturalEventAttendanceAggregateBoolExp>;
  checkedInAt?: InputMaybe<TimestamptzComparisonExp>;
  checkedInById?: InputMaybe<UuidComparisonExp>;
  checkinBooking?: InputMaybe<BookingCheckinBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  pause?: InputMaybe<PauseBoolExp>;
  pauseAggregate?: InputMaybe<PauseAggregateBoolExp>;
  sum?: InputMaybe<IntComparisonExp>;
  user?: InputMaybe<AuthUserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "checkin" */
export enum CheckinConstraint {
  /** unique or primary key constraint on columns "id" */
  CheckinPkey = 'checkin_pkey'
}

/** input type for inserting data into table "checkin" */
export type CheckinInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  attendance?: InputMaybe<CulturalEventAttendanceArrRelInsertInput>;
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  checkinBooking?: InputMaybe<BookingCheckinObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pause?: InputMaybe<PauseArrRelInsertInput>;
  user?: InputMaybe<AuthUserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type CheckinMaxFields = {
  __typename?: 'CheckinMaxFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "calculate_checkin_tariff_multirange" */
  sum?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type CheckinMinFields = {
  __typename?: 'CheckinMinFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "calculate_checkin_tariff_multirange" */
  sum?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "checkin" */
export type CheckinMutationResponse = {
  __typename?: 'CheckinMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Checkin>;
};

/** input type for inserting object relation for remote table "checkin" */
export type CheckinObjRelInsertInput = {
  data: CheckinInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CheckinOnConflict>;
};

/** on_conflict condition type for table "checkin" */
export type CheckinOnConflict = {
  constraint: CheckinConstraint;
  updateColumns?: Array<CheckinUpdateColumn>;
  where?: InputMaybe<CheckinBoolExp>;
};

/** Ordering options when selecting data from "checkin". */
export type CheckinOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  attendanceAggregate?: InputMaybe<CulturalEventAttendanceAggregateOrderBy>;
  checkedInAt?: InputMaybe<OrderBy>;
  checkedInById?: InputMaybe<OrderBy>;
  checkinBooking?: InputMaybe<BookingCheckinOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  pauseAggregate?: InputMaybe<PauseAggregateOrderBy>;
  sum?: InputMaybe<OrderBy>;
  user?: InputMaybe<AuthUserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: checkin */
export type CheckinPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "checkin" */
export enum CheckinSelectColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "checkin" */
export type CheckinSetInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type CheckinStddevFields = {
  __typename?: 'CheckinStddevFields';
  /** A computed field, executes function "calculate_checkin_tariff_multirange" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** aggregate stddevPop on columns */
export type CheckinStddevPopFields = {
  __typename?: 'CheckinStddevPopFields';
  /** A computed field, executes function "calculate_checkin_tariff_multirange" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** aggregate stddevSamp on columns */
export type CheckinStddevSampFields = {
  __typename?: 'CheckinStddevSampFields';
  /** A computed field, executes function "calculate_checkin_tariff_multirange" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** Streaming cursor of the table "checkin" */
export type CheckinStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CheckinStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CheckinStreamCursorValueInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type CheckinSumFields = {
  __typename?: 'CheckinSumFields';
  /** A computed field, executes function "calculate_checkin_tariff_multirange" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "checkin" */
export enum CheckinUpdateColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type CheckinUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CheckinSetInput>;
  /** filter the rows which have to be updated */
  where: CheckinBoolExp;
};

/** aggregate varPop on columns */
export type CheckinVarPopFields = {
  __typename?: 'CheckinVarPopFields';
  /** A computed field, executes function "calculate_checkin_tariff_multirange" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varSamp on columns */
export type CheckinVarSampFields = {
  __typename?: 'CheckinVarSampFields';
  /** A computed field, executes function "calculate_checkin_tariff_multirange" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** aggregate variance on columns */
export type CheckinVarianceFields = {
  __typename?: 'CheckinVarianceFields';
  /** A computed field, executes function "calculate_checkin_tariff_multirange" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "cultural_event.compensation_currency" */
export type CompensationCurrency = {
  __typename?: 'CompensationCurrency';
  description: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "cultural_event.compensation_currency" */
export type CompensationCurrencyAggregate = {
  __typename?: 'CompensationCurrencyAggregate';
  aggregate?: Maybe<CompensationCurrencyAggregateFields>;
  nodes: Array<CompensationCurrency>;
};

/** aggregate fields of "cultural_event.compensation_currency" */
export type CompensationCurrencyAggregateFields = {
  __typename?: 'CompensationCurrencyAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CompensationCurrencyMaxFields>;
  min?: Maybe<CompensationCurrencyMinFields>;
};


/** aggregate fields of "cultural_event.compensation_currency" */
export type CompensationCurrencyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationCurrencySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "cultural_event.compensation_currency". All fields are combined with a logical 'AND'. */
export type CompensationCurrencyBoolExp = {
  _and?: InputMaybe<Array<CompensationCurrencyBoolExp>>;
  _not?: InputMaybe<CompensationCurrencyBoolExp>;
  _or?: InputMaybe<Array<CompensationCurrencyBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.compensation_currency" */
export enum CompensationCurrencyConstraint {
  /** unique or primary key constraint on columns "value" */
  CompensationCurrencyPkey = 'compensation_currency_pkey'
}

export enum CompensationCurrencyEnum {
  /** Compensation with minutes in Ziferblat */
  Minutes = 'MINUTES',
  /** Compensation with actual money */
  Money = 'MONEY'
}

/** Boolean expression to compare columns of type "CompensationCurrencyEnum". All fields are combined with logical 'AND'. */
export type CompensationCurrencyEnumComparisonExp = {
  _eq?: InputMaybe<CompensationCurrencyEnum>;
  _in?: InputMaybe<Array<CompensationCurrencyEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<CompensationCurrencyEnum>;
  _nin?: InputMaybe<Array<CompensationCurrencyEnum>>;
};

/** input type for inserting data into table "cultural_event.compensation_currency" */
export type CompensationCurrencyInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CompensationCurrencyMaxFields = {
  __typename?: 'CompensationCurrencyMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CompensationCurrencyMinFields = {
  __typename?: 'CompensationCurrencyMinFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "cultural_event.compensation_currency" */
export type CompensationCurrencyMutationResponse = {
  __typename?: 'CompensationCurrencyMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationCurrency>;
};

/** on_conflict condition type for table "cultural_event.compensation_currency" */
export type CompensationCurrencyOnConflict = {
  constraint: CompensationCurrencyConstraint;
  updateColumns?: Array<CompensationCurrencyUpdateColumn>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.compensation_currency". */
export type CompensationCurrencyOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.compensation_currency */
export type CompensationCurrencyPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "cultural_event.compensation_currency" */
export enum CompensationCurrencySelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "cultural_event.compensation_currency" */
export type CompensationCurrencySetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "CompensationCurrency" */
export type CompensationCurrencyStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CompensationCurrencyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationCurrencyStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "cultural_event.compensation_currency" */
export enum CompensationCurrencyUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type CompensationCurrencyUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationCurrencySetInput>;
  /** filter the rows which have to be updated */
  where: CompensationCurrencyBoolExp;
};

/** columns and relationships of "cultural_event.compensation_unit" */
export type CompensationUnit = {
  __typename?: 'CompensationUnit';
  description: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "cultural_event.compensation_unit" */
export type CompensationUnitAggregate = {
  __typename?: 'CompensationUnitAggregate';
  aggregate?: Maybe<CompensationUnitAggregateFields>;
  nodes: Array<CompensationUnit>;
};

/** aggregate fields of "cultural_event.compensation_unit" */
export type CompensationUnitAggregateFields = {
  __typename?: 'CompensationUnitAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CompensationUnitMaxFields>;
  min?: Maybe<CompensationUnitMinFields>;
};


/** aggregate fields of "cultural_event.compensation_unit" */
export type CompensationUnitAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationUnitSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "cultural_event.compensation_unit". All fields are combined with a logical 'AND'. */
export type CompensationUnitBoolExp = {
  _and?: InputMaybe<Array<CompensationUnitBoolExp>>;
  _not?: InputMaybe<CompensationUnitBoolExp>;
  _or?: InputMaybe<Array<CompensationUnitBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.compensation_unit" */
export enum CompensationUnitConstraint {
  /** unique or primary key constraint on columns "value" */
  CompensationUnitPkey = 'compensation_unit_pkey'
}

export enum CompensationUnitEnum {
  /** Fixed compensation */
  Fixed = 'FIXED',
  /** Compensation by percentage from overall sum */
  Percentage = 'PERCENTAGE'
}

/** Boolean expression to compare columns of type "CompensationUnitEnum". All fields are combined with logical 'AND'. */
export type CompensationUnitEnumComparisonExp = {
  _eq?: InputMaybe<CompensationUnitEnum>;
  _in?: InputMaybe<Array<CompensationUnitEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<CompensationUnitEnum>;
  _nin?: InputMaybe<Array<CompensationUnitEnum>>;
};

/** input type for inserting data into table "cultural_event.compensation_unit" */
export type CompensationUnitInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CompensationUnitMaxFields = {
  __typename?: 'CompensationUnitMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CompensationUnitMinFields = {
  __typename?: 'CompensationUnitMinFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "cultural_event.compensation_unit" */
export type CompensationUnitMutationResponse = {
  __typename?: 'CompensationUnitMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationUnit>;
};

/** on_conflict condition type for table "cultural_event.compensation_unit" */
export type CompensationUnitOnConflict = {
  constraint: CompensationUnitConstraint;
  updateColumns?: Array<CompensationUnitUpdateColumn>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.compensation_unit". */
export type CompensationUnitOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.compensation_unit */
export type CompensationUnitPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "cultural_event.compensation_unit" */
export enum CompensationUnitSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "cultural_event.compensation_unit" */
export type CompensationUnitSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "CompensationUnit" */
export type CompensationUnitStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CompensationUnitStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationUnitStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "cultural_event.compensation_unit" */
export enum CompensationUnitUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type CompensationUnitUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationUnitSetInput>;
  /** filter the rows which have to be updated */
  where: CompensationUnitBoolExp;
};

/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEvent = {
  __typename?: 'CulturalEvent';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  /** An array relationship */
  attendees: Array<Attendees>;
  /** An aggregate relationship */
  attendeesAggregate: AttendeesAggregate;
  /** An object relationship */
  compensation?: Maybe<CulturalEventCompensation>;
  createdAt: Scalars['timestamptz']['output'];
  createdById: Scalars['uuid']['output'];
  endsAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  images: Array<CulturalEventImage>;
  /** An aggregate relationship */
  imagesAggregate: CulturalEventImageAggregate;
  internalComment: Scalars['String']['output'];
  publicDescription: Scalars['String']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  startsAt: Scalars['timestamptz']['output'];
  status: CulturalEventStatusEnum;
  /** An array relationship */
  tickets: Array<CulturalEventTicket>;
  /** An aggregate relationship */
  ticketsAggregate: CulturalEventTicketAggregate;
  title: Scalars['String']['output'];
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventAttendeesArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventAttendeesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventImagesArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventImageOrderBy>>;
  where?: InputMaybe<CulturalEventImageBoolExp>;
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventImagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventImageOrderBy>>;
  where?: InputMaybe<CulturalEventImageBoolExp>;
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventTicketsArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketOrderBy>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventTicketsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketOrderBy>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};

/** aggregated selection of "cultural_event.cultural_event" */
export type CulturalEventAggregate = {
  __typename?: 'CulturalEventAggregate';
  aggregate?: Maybe<CulturalEventAggregateFields>;
  nodes: Array<CulturalEvent>;
};

export type CulturalEventAggregateBoolExp = {
  count?: InputMaybe<CulturalEventAggregateBoolExpCount>;
};

export type CulturalEventAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CulturalEventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CulturalEventBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "cultural_event.cultural_event" */
export type CulturalEventAggregateFields = {
  __typename?: 'CulturalEventAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventMaxFields>;
  min?: Maybe<CulturalEventMinFields>;
};


/** aggregate fields of "cultural_event.cultural_event" */
export type CulturalEventAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "cultural_event.cultural_event" */
export type CulturalEventAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CulturalEventMaxOrderBy>;
  min?: InputMaybe<CulturalEventMinOrderBy>;
};

/** input type for inserting array relation for remote table "cultural_event.cultural_event" */
export type CulturalEventArrRelInsertInput = {
  data: Array<CulturalEventInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CulturalEventOnConflict>;
};

/** columns and relationships of "cultural_event.attendance" */
export type CulturalEventAttendance = {
  __typename?: 'CulturalEventAttendance';
  addedById: Scalars['uuid']['output'];
  /** An object relationship */
  checkin?: Maybe<Checkin>;
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  culturalEvent: CulturalEvent;
  culturalEventId: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  visitId: Scalars['uuid']['output'];
};

/** aggregated selection of "cultural_event.attendance" */
export type CulturalEventAttendanceAggregate = {
  __typename?: 'CulturalEventAttendanceAggregate';
  aggregate?: Maybe<CulturalEventAttendanceAggregateFields>;
  nodes: Array<CulturalEventAttendance>;
};

export type CulturalEventAttendanceAggregateBoolExp = {
  count?: InputMaybe<CulturalEventAttendanceAggregateBoolExpCount>;
};

/** aggregate fields of "cultural_event.attendance" */
export type CulturalEventAttendanceAggregateFields = {
  __typename?: 'CulturalEventAttendanceAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventAttendanceMaxFields>;
  min?: Maybe<CulturalEventAttendanceMinFields>;
};


/** aggregate fields of "cultural_event.attendance" */
export type CulturalEventAttendanceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "cultural_event.attendance" */
export type CulturalEventAttendanceAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CulturalEventAttendanceMaxOrderBy>;
  min?: InputMaybe<CulturalEventAttendanceMinOrderBy>;
};

/** input type for inserting array relation for remote table "cultural_event.attendance" */
export type CulturalEventAttendanceArrRelInsertInput = {
  data: Array<CulturalEventAttendanceInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CulturalEventAttendanceOnConflict>;
};

/** Boolean expression to filter rows from the table "cultural_event.attendance". All fields are combined with a logical 'AND'. */
export type CulturalEventAttendanceBoolExp = {
  _and?: InputMaybe<Array<CulturalEventAttendanceBoolExp>>;
  _not?: InputMaybe<CulturalEventAttendanceBoolExp>;
  _or?: InputMaybe<Array<CulturalEventAttendanceBoolExp>>;
  addedById?: InputMaybe<UuidComparisonExp>;
  checkin?: InputMaybe<CheckinBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  culturalEvent?: InputMaybe<CulturalEventBoolExp>;
  culturalEventId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  visitId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.attendance" */
export enum CulturalEventAttendanceConstraint {
  /** unique or primary key constraint on columns "visit_id", "cultural_event_id" */
  AttendanceCulturalEventIdVisitIdKey = 'attendance_cultural_event_id_visit_id_key',
  /** unique or primary key constraint on columns "id" */
  AttendancePkey = 'attendance_pkey'
}

/** input type for inserting data into table "cultural_event.attendance" */
export type CulturalEventAttendanceInsertInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  checkin?: InputMaybe<CheckinObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEvent?: InputMaybe<CulturalEventObjRelInsertInput>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type CulturalEventAttendanceMaxFields = {
  __typename?: 'CulturalEventAttendanceMaxFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "cultural_event.attendance" */
export type CulturalEventAttendanceMaxOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CulturalEventAttendanceMinFields = {
  __typename?: 'CulturalEventAttendanceMinFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "cultural_event.attendance" */
export type CulturalEventAttendanceMinOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "cultural_event.attendance" */
export type CulturalEventAttendanceMutationResponse = {
  __typename?: 'CulturalEventAttendanceMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEventAttendance>;
};

/** on_conflict condition type for table "cultural_event.attendance" */
export type CulturalEventAttendanceOnConflict = {
  constraint: CulturalEventAttendanceConstraint;
  updateColumns?: Array<CulturalEventAttendanceUpdateColumn>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.attendance". */
export type CulturalEventAttendanceOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  checkin?: InputMaybe<CheckinOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEvent?: InputMaybe<CulturalEventOrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.attendance */
export type CulturalEventAttendancePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "cultural_event.attendance" */
export enum CulturalEventAttendanceSelectColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Id = 'id',
  /** column name */
  VisitId = 'visitId'
}

/** input type for updating data in table "cultural_event.attendance" */
export type CulturalEventAttendanceSetInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "cultural_event_attendance" */
export type CulturalEventAttendanceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventAttendanceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventAttendanceStreamCursorValueInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "cultural_event.attendance" */
export enum CulturalEventAttendanceUpdateColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Id = 'id',
  /** column name */
  VisitId = 'visitId'
}

export type CulturalEventAttendanceUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventAttendanceSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventAttendanceBoolExp;
};

/** Boolean expression to filter rows from the table "cultural_event.cultural_event". All fields are combined with a logical 'AND'. */
export type CulturalEventBoolExp = {
  _and?: InputMaybe<Array<CulturalEventBoolExp>>;
  _not?: InputMaybe<CulturalEventBoolExp>;
  _or?: InputMaybe<Array<CulturalEventBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  attendees?: InputMaybe<AttendeesBoolExp>;
  attendeesAggregate?: InputMaybe<AttendeesAggregateBoolExp>;
  compensation?: InputMaybe<CulturalEventCompensationBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  endsAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  images?: InputMaybe<CulturalEventImageBoolExp>;
  imagesAggregate?: InputMaybe<CulturalEventImageAggregateBoolExp>;
  internalComment?: InputMaybe<StringComparisonExp>;
  publicDescription?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  startsAt?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<CulturalEventStatusEnumComparisonExp>;
  tickets?: InputMaybe<CulturalEventTicketBoolExp>;
  ticketsAggregate?: InputMaybe<CulturalEventTicketAggregateBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** columns and relationships of "cultural_event.compensation" */
export type CulturalEventCompensation = {
  __typename?: 'CulturalEventCompensation';
  addedById: Scalars['uuid']['output'];
  amount: Scalars['smallint']['output'];
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  culturalEvent?: Maybe<CulturalEvent>;
  culturalEventId: Scalars['uuid']['output'];
  currency: CompensationCurrencyEnum;
  id: Scalars['uuid']['output'];
  unit: CompensationUnitEnum;
};

/** aggregated selection of "cultural_event.compensation" */
export type CulturalEventCompensationAggregate = {
  __typename?: 'CulturalEventCompensationAggregate';
  aggregate?: Maybe<CulturalEventCompensationAggregateFields>;
  nodes: Array<CulturalEventCompensation>;
};

/** aggregate fields of "cultural_event.compensation" */
export type CulturalEventCompensationAggregateFields = {
  __typename?: 'CulturalEventCompensationAggregateFields';
  avg?: Maybe<CulturalEventCompensationAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventCompensationMaxFields>;
  min?: Maybe<CulturalEventCompensationMinFields>;
  stddev?: Maybe<CulturalEventCompensationStddevFields>;
  stddevPop?: Maybe<CulturalEventCompensationStddevPopFields>;
  stddevSamp?: Maybe<CulturalEventCompensationStddevSampFields>;
  sum?: Maybe<CulturalEventCompensationSumFields>;
  varPop?: Maybe<CulturalEventCompensationVarPopFields>;
  varSamp?: Maybe<CulturalEventCompensationVarSampFields>;
  variance?: Maybe<CulturalEventCompensationVarianceFields>;
};


/** aggregate fields of "cultural_event.compensation" */
export type CulturalEventCompensationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventCompensationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CulturalEventCompensationAvgFields = {
  __typename?: 'CulturalEventCompensationAvgFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "cultural_event.compensation". All fields are combined with a logical 'AND'. */
export type CulturalEventCompensationBoolExp = {
  _and?: InputMaybe<Array<CulturalEventCompensationBoolExp>>;
  _not?: InputMaybe<CulturalEventCompensationBoolExp>;
  _or?: InputMaybe<Array<CulturalEventCompensationBoolExp>>;
  addedById?: InputMaybe<UuidComparisonExp>;
  amount?: InputMaybe<SmallintComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  culturalEvent?: InputMaybe<CulturalEventBoolExp>;
  culturalEventId?: InputMaybe<UuidComparisonExp>;
  currency?: InputMaybe<CompensationCurrencyEnumComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  unit?: InputMaybe<CompensationUnitEnumComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.compensation" */
export enum CulturalEventCompensationConstraint {
  /** unique or primary key constraint on columns "id" */
  CompensationPkey = 'compensation_pkey'
}

/** input type for incrementing numeric columns in table "cultural_event.compensation" */
export type CulturalEventCompensationIncInput = {
  amount?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "cultural_event.compensation" */
export type CulturalEventCompensationInsertInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  amount?: InputMaybe<Scalars['smallint']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEvent?: InputMaybe<CulturalEventObjRelInsertInput>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  currency?: InputMaybe<CompensationCurrencyEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  unit?: InputMaybe<CompensationUnitEnum>;
};

/** aggregate max on columns */
export type CulturalEventCompensationMaxFields = {
  __typename?: 'CulturalEventCompensationMaxFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  amount?: Maybe<Scalars['smallint']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type CulturalEventCompensationMinFields = {
  __typename?: 'CulturalEventCompensationMinFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  amount?: Maybe<Scalars['smallint']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "cultural_event.compensation" */
export type CulturalEventCompensationMutationResponse = {
  __typename?: 'CulturalEventCompensationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEventCompensation>;
};

/** input type for inserting object relation for remote table "cultural_event.compensation" */
export type CulturalEventCompensationObjRelInsertInput = {
  data: CulturalEventCompensationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CulturalEventCompensationOnConflict>;
};

/** on_conflict condition type for table "cultural_event.compensation" */
export type CulturalEventCompensationOnConflict = {
  constraint: CulturalEventCompensationConstraint;
  updateColumns?: Array<CulturalEventCompensationUpdateColumn>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.compensation". */
export type CulturalEventCompensationOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEvent?: InputMaybe<CulturalEventOrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  currency?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  unit?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.compensation */
export type CulturalEventCompensationPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "cultural_event.compensation" */
export enum CulturalEventCompensationSelectColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Unit = 'unit'
}

/** input type for updating data in table "cultural_event.compensation" */
export type CulturalEventCompensationSetInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  amount?: InputMaybe<Scalars['smallint']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  currency?: InputMaybe<CompensationCurrencyEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  unit?: InputMaybe<CompensationUnitEnum>;
};

/** aggregate stddev on columns */
export type CulturalEventCompensationStddevFields = {
  __typename?: 'CulturalEventCompensationStddevFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type CulturalEventCompensationStddevPopFields = {
  __typename?: 'CulturalEventCompensationStddevPopFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type CulturalEventCompensationStddevSampFields = {
  __typename?: 'CulturalEventCompensationStddevSampFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "cultural_event_compensation" */
export type CulturalEventCompensationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventCompensationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventCompensationStreamCursorValueInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  amount?: InputMaybe<Scalars['smallint']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  currency?: InputMaybe<CompensationCurrencyEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  unit?: InputMaybe<CompensationUnitEnum>;
};

/** aggregate sum on columns */
export type CulturalEventCompensationSumFields = {
  __typename?: 'CulturalEventCompensationSumFields';
  amount?: Maybe<Scalars['smallint']['output']>;
};

/** update columns of table "cultural_event.compensation" */
export enum CulturalEventCompensationUpdateColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Unit = 'unit'
}

export type CulturalEventCompensationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CulturalEventCompensationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventCompensationSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventCompensationBoolExp;
};

/** aggregate varPop on columns */
export type CulturalEventCompensationVarPopFields = {
  __typename?: 'CulturalEventCompensationVarPopFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type CulturalEventCompensationVarSampFields = {
  __typename?: 'CulturalEventCompensationVarSampFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CulturalEventCompensationVarianceFields = {
  __typename?: 'CulturalEventCompensationVarianceFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** unique or primary key constraints on table "cultural_event.cultural_event" */
export enum CulturalEventConstraint {
  /** unique or primary key constraint on columns "id" */
  CulturalEventPkey = 'cultural_event_pkey',
  /** unique or primary key constraint on columns  */
  UniqueDaySlug = 'unique_day_slug'
}

/** input type for inserting data into table "cultural_event.cultural_event" */
export type CulturalEventInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  attendees?: InputMaybe<AttendeesArrRelInsertInput>;
  compensation?: InputMaybe<CulturalEventCompensationObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  endsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  images?: InputMaybe<CulturalEventImageArrRelInsertInput>;
  internalComment?: InputMaybe<Scalars['String']['input']>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<CulturalEventStatusEnum>;
  tickets?: InputMaybe<CulturalEventTicketArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type CulturalEventMaxFields = {
  __typename?: 'CulturalEventMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdById?: Maybe<Scalars['uuid']['output']>;
  endsAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internalComment?: Maybe<Scalars['String']['output']>;
  publicDescription?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['timestamptz']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "cultural_event.cultural_event" */
export type CulturalEventMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  endsAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  internalComment?: InputMaybe<OrderBy>;
  publicDescription?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  startsAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CulturalEventMinFields = {
  __typename?: 'CulturalEventMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdById?: Maybe<Scalars['uuid']['output']>;
  endsAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internalComment?: Maybe<Scalars['String']['output']>;
  publicDescription?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['timestamptz']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "cultural_event.cultural_event" */
export type CulturalEventMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  endsAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  internalComment?: InputMaybe<OrderBy>;
  publicDescription?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  startsAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "cultural_event.cultural_event" */
export type CulturalEventMutationResponse = {
  __typename?: 'CulturalEventMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEvent>;
};

/** input type for inserting object relation for remote table "cultural_event.cultural_event" */
export type CulturalEventObjRelInsertInput = {
  data: CulturalEventInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CulturalEventOnConflict>;
};

/** on_conflict condition type for table "cultural_event.cultural_event" */
export type CulturalEventOnConflict = {
  constraint: CulturalEventConstraint;
  updateColumns?: Array<CulturalEventUpdateColumn>;
  where?: InputMaybe<CulturalEventBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.cultural_event". */
export type CulturalEventOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  attendeesAggregate?: InputMaybe<AttendeesAggregateOrderBy>;
  compensation?: InputMaybe<CulturalEventCompensationOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  endsAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagesAggregate?: InputMaybe<CulturalEventImageAggregateOrderBy>;
  internalComment?: InputMaybe<OrderBy>;
  publicDescription?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  startsAt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  ticketsAggregate?: InputMaybe<CulturalEventTicketAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.cultural_event */
export type CulturalEventPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** This table is also practically a check for ticket QR code uniqueness */
export type CulturalEventPrepaidAttendance = {
  __typename?: 'CulturalEventPrepaidAttendance';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  transaction?: Maybe<PaymentTransaction>;
  /** Also data in QR code */
  transactionId: Scalars['uuid']['output'];
  /** An object relationship */
  visit?: Maybe<Visit>;
  visitId: Scalars['uuid']['output'];
};

/** aggregated selection of "cultural_event.prepaid_attendance" */
export type CulturalEventPrepaidAttendanceAggregate = {
  __typename?: 'CulturalEventPrepaidAttendanceAggregate';
  aggregate?: Maybe<CulturalEventPrepaidAttendanceAggregateFields>;
  nodes: Array<CulturalEventPrepaidAttendance>;
};

/** aggregate fields of "cultural_event.prepaid_attendance" */
export type CulturalEventPrepaidAttendanceAggregateFields = {
  __typename?: 'CulturalEventPrepaidAttendanceAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventPrepaidAttendanceMaxFields>;
  min?: Maybe<CulturalEventPrepaidAttendanceMinFields>;
};


/** aggregate fields of "cultural_event.prepaid_attendance" */
export type CulturalEventPrepaidAttendanceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventPrepaidAttendanceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "cultural_event.prepaid_attendance". All fields are combined with a logical 'AND'. */
export type CulturalEventPrepaidAttendanceBoolExp = {
  _and?: InputMaybe<Array<CulturalEventPrepaidAttendanceBoolExp>>;
  _not?: InputMaybe<CulturalEventPrepaidAttendanceBoolExp>;
  _or?: InputMaybe<Array<CulturalEventPrepaidAttendanceBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  transaction?: InputMaybe<PaymentTransactionBoolExp>;
  transactionId?: InputMaybe<UuidComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.prepaid_attendance" */
export enum CulturalEventPrepaidAttendanceConstraint {
  /** unique or primary key constraint on columns "transaction_id" */
  PrepaidAttendanceTransactionIdKey = 'prepaid_attendance_transaction_id_key',
  /** unique or primary key constraint on columns "id" */
  TicketVisitPkey = 'ticket_visit_pkey'
}

/** input type for inserting data into table "cultural_event.prepaid_attendance" */
export type CulturalEventPrepaidAttendanceInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  transaction?: InputMaybe<PaymentTransactionObjRelInsertInput>;
  /** Also data in QR code */
  transactionId?: InputMaybe<Scalars['uuid']['input']>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type CulturalEventPrepaidAttendanceMaxFields = {
  __typename?: 'CulturalEventPrepaidAttendanceMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** Also data in QR code */
  transactionId?: Maybe<Scalars['uuid']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type CulturalEventPrepaidAttendanceMinFields = {
  __typename?: 'CulturalEventPrepaidAttendanceMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** Also data in QR code */
  transactionId?: Maybe<Scalars['uuid']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "cultural_event.prepaid_attendance" */
export type CulturalEventPrepaidAttendanceMutationResponse = {
  __typename?: 'CulturalEventPrepaidAttendanceMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEventPrepaidAttendance>;
};

/** on_conflict condition type for table "cultural_event.prepaid_attendance" */
export type CulturalEventPrepaidAttendanceOnConflict = {
  constraint: CulturalEventPrepaidAttendanceConstraint;
  updateColumns?: Array<CulturalEventPrepaidAttendanceUpdateColumn>;
  where?: InputMaybe<CulturalEventPrepaidAttendanceBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.prepaid_attendance". */
export type CulturalEventPrepaidAttendanceOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  transaction?: InputMaybe<PaymentTransactionOrderBy>;
  transactionId?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.prepaid_attendance */
export type CulturalEventPrepaidAttendancePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "cultural_event.prepaid_attendance" */
export enum CulturalEventPrepaidAttendanceSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  VisitId = 'visitId'
}

/** input type for updating data in table "cultural_event.prepaid_attendance" */
export type CulturalEventPrepaidAttendanceSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  /** Also data in QR code */
  transactionId?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "cultural_event_prepaid_attendance" */
export type CulturalEventPrepaidAttendanceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventPrepaidAttendanceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventPrepaidAttendanceStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  /** Also data in QR code */
  transactionId?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "cultural_event.prepaid_attendance" */
export enum CulturalEventPrepaidAttendanceUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  VisitId = 'visitId'
}

export type CulturalEventPrepaidAttendanceUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventPrepaidAttendanceSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventPrepaidAttendanceBoolExp;
};

/** select columns of table "cultural_event.cultural_event" */
export enum CulturalEventSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  EndsAt = 'endsAt',
  /** column name */
  Id = 'id',
  /** column name */
  InternalComment = 'internalComment',
  /** column name */
  PublicDescription = 'publicDescription',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartsAt = 'startsAt',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "cultural_event.cultural_event" */
export type CulturalEventSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  endsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internalComment?: InputMaybe<Scalars['String']['input']>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<CulturalEventStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "cultural_event.status" */
export type CulturalEventStatus = {
  __typename?: 'CulturalEventStatus';
  description: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "cultural_event.status" */
export type CulturalEventStatusAggregate = {
  __typename?: 'CulturalEventStatusAggregate';
  aggregate?: Maybe<CulturalEventStatusAggregateFields>;
  nodes: Array<CulturalEventStatus>;
};

/** aggregate fields of "cultural_event.status" */
export type CulturalEventStatusAggregateFields = {
  __typename?: 'CulturalEventStatusAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventStatusMaxFields>;
  min?: Maybe<CulturalEventStatusMinFields>;
};


/** aggregate fields of "cultural_event.status" */
export type CulturalEventStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "cultural_event.status". All fields are combined with a logical 'AND'. */
export type CulturalEventStatusBoolExp = {
  _and?: InputMaybe<Array<CulturalEventStatusBoolExp>>;
  _not?: InputMaybe<CulturalEventStatusBoolExp>;
  _or?: InputMaybe<Array<CulturalEventStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.status" */
export enum CulturalEventStatusConstraint {
  /** unique or primary key constraint on columns "value" */
  StatusPkey = 'status_pkey'
}

export enum CulturalEventStatusEnum {
  /** Should be invisible to common users */
  Archived = 'ARCHIVED',
  /** Visibile to common users, but has informational message */
  Canceled = 'CANCELED',
  /** Should be invisible to common users */
  Draft = 'DRAFT',
  /** Visibile to common users */
  Published = 'PUBLISHED'
}

/** Boolean expression to compare columns of type "CulturalEventStatusEnum". All fields are combined with logical 'AND'. */
export type CulturalEventStatusEnumComparisonExp = {
  _eq?: InputMaybe<CulturalEventStatusEnum>;
  _in?: InputMaybe<Array<CulturalEventStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<CulturalEventStatusEnum>;
  _nin?: InputMaybe<Array<CulturalEventStatusEnum>>;
};

/** input type for inserting data into table "cultural_event.status" */
export type CulturalEventStatusInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CulturalEventStatusMaxFields = {
  __typename?: 'CulturalEventStatusMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CulturalEventStatusMinFields = {
  __typename?: 'CulturalEventStatusMinFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "cultural_event.status" */
export type CulturalEventStatusMutationResponse = {
  __typename?: 'CulturalEventStatusMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEventStatus>;
};

/** on_conflict condition type for table "cultural_event.status" */
export type CulturalEventStatusOnConflict = {
  constraint: CulturalEventStatusConstraint;
  updateColumns?: Array<CulturalEventStatusUpdateColumn>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.status". */
export type CulturalEventStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.status */
export type CulturalEventStatusPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "cultural_event.status" */
export enum CulturalEventStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "cultural_event.status" */
export type CulturalEventStatusSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "cultural_event_status" */
export type CulturalEventStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "cultural_event.status" */
export enum CulturalEventStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type CulturalEventStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventStatusSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventStatusBoolExp;
};

/** Streaming cursor of the table "CulturalEvent" */
export type CulturalEventStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  endsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internalComment?: InputMaybe<Scalars['String']['input']>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<CulturalEventStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "cultural_event.ticket" */
export type CulturalEventTicket = {
  __typename?: 'CulturalEventTicket';
  activeFrom: Scalars['timestamptz']['output'];
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  culturalEvent: CulturalEvent;
  culturalEventId: Scalars['uuid']['output'];
  customTitle?: Maybe<Scalars['String']['output']>;
  customUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  maxCount?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Int']['output'];
  /** An array relationship */
  transaction: Array<PaymentTransaction>;
  /** An aggregate relationship */
  transactionAggregate: PaymentTransactionAggregate;
};


/** columns and relationships of "cultural_event.ticket" */
export type CulturalEventTicketTransactionArgs = {
  distinctOn?: InputMaybe<Array<PaymentTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentTransactionOrderBy>>;
  where?: InputMaybe<PaymentTransactionBoolExp>;
};


/** columns and relationships of "cultural_event.ticket" */
export type CulturalEventTicketTransactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentTransactionOrderBy>>;
  where?: InputMaybe<PaymentTransactionBoolExp>;
};

/** aggregated selection of "cultural_event.ticket" */
export type CulturalEventTicketAggregate = {
  __typename?: 'CulturalEventTicketAggregate';
  aggregate?: Maybe<CulturalEventTicketAggregateFields>;
  nodes: Array<CulturalEventTicket>;
};

export type CulturalEventTicketAggregateBoolExp = {
  count?: InputMaybe<CulturalEventTicketAggregateBoolExpCount>;
};

/** aggregate fields of "cultural_event.ticket" */
export type CulturalEventTicketAggregateFields = {
  __typename?: 'CulturalEventTicketAggregateFields';
  avg?: Maybe<CulturalEventTicketAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventTicketMaxFields>;
  min?: Maybe<CulturalEventTicketMinFields>;
  stddev?: Maybe<CulturalEventTicketStddevFields>;
  stddevPop?: Maybe<CulturalEventTicketStddevPopFields>;
  stddevSamp?: Maybe<CulturalEventTicketStddevSampFields>;
  sum?: Maybe<CulturalEventTicketSumFields>;
  varPop?: Maybe<CulturalEventTicketVarPopFields>;
  varSamp?: Maybe<CulturalEventTicketVarSampFields>;
  variance?: Maybe<CulturalEventTicketVarianceFields>;
};


/** aggregate fields of "cultural_event.ticket" */
export type CulturalEventTicketAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "cultural_event.ticket" */
export type CulturalEventTicketAggregateOrderBy = {
  avg?: InputMaybe<CulturalEventTicketAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CulturalEventTicketMaxOrderBy>;
  min?: InputMaybe<CulturalEventTicketMinOrderBy>;
  stddev?: InputMaybe<CulturalEventTicketStddevOrderBy>;
  stddevPop?: InputMaybe<CulturalEventTicketStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CulturalEventTicketStddevSampOrderBy>;
  sum?: InputMaybe<CulturalEventTicketSumOrderBy>;
  varPop?: InputMaybe<CulturalEventTicketVarPopOrderBy>;
  varSamp?: InputMaybe<CulturalEventTicketVarSampOrderBy>;
  variance?: InputMaybe<CulturalEventTicketVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "cultural_event.ticket" */
export type CulturalEventTicketArrRelInsertInput = {
  data: Array<CulturalEventTicketInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CulturalEventTicketOnConflict>;
};

/** aggregate avg on columns */
export type CulturalEventTicketAvgFields = {
  __typename?: 'CulturalEventTicketAvgFields';
  maxCount?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "cultural_event.ticket" */
export type CulturalEventTicketAvgOrderBy = {
  maxCount?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "cultural_event.ticket". All fields are combined with a logical 'AND'. */
export type CulturalEventTicketBoolExp = {
  _and?: InputMaybe<Array<CulturalEventTicketBoolExp>>;
  _not?: InputMaybe<CulturalEventTicketBoolExp>;
  _or?: InputMaybe<Array<CulturalEventTicketBoolExp>>;
  activeFrom?: InputMaybe<TimestamptzComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  culturalEvent?: InputMaybe<CulturalEventBoolExp>;
  culturalEventId?: InputMaybe<UuidComparisonExp>;
  customTitle?: InputMaybe<StringComparisonExp>;
  customUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  maxCount?: InputMaybe<IntComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  transaction?: InputMaybe<PaymentTransactionBoolExp>;
  transactionAggregate?: InputMaybe<PaymentTransactionAggregateBoolExp>;
};

/** unique or primary key constraints on table "cultural_event.ticket" */
export enum CulturalEventTicketConstraint {
  /** unique or primary key constraint on columns "id" */
  TicketPkey = 'ticket_pkey'
}

/** input type for incrementing numeric columns in table "cultural_event.ticket" */
export type CulturalEventTicketIncInput = {
  maxCount?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "cultural_event.ticket" */
export type CulturalEventTicketInsertInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEvent?: InputMaybe<CulturalEventObjRelInsertInput>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  customTitle?: InputMaybe<Scalars['String']['input']>;
  customUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  maxCount?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  transaction?: InputMaybe<PaymentTransactionArrRelInsertInput>;
};

/** aggregate max on columns */
export type CulturalEventTicketMaxFields = {
  __typename?: 'CulturalEventTicketMaxFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  customTitle?: Maybe<Scalars['String']['output']>;
  customUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  maxCount?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "cultural_event.ticket" */
export type CulturalEventTicketMaxOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  customTitle?: InputMaybe<OrderBy>;
  customUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxCount?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CulturalEventTicketMinFields = {
  __typename?: 'CulturalEventTicketMinFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  customTitle?: Maybe<Scalars['String']['output']>;
  customUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  maxCount?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "cultural_event.ticket" */
export type CulturalEventTicketMinOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  customTitle?: InputMaybe<OrderBy>;
  customUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxCount?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "cultural_event.ticket" */
export type CulturalEventTicketMutationResponse = {
  __typename?: 'CulturalEventTicketMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEventTicket>;
};

/** input type for inserting object relation for remote table "cultural_event.ticket" */
export type CulturalEventTicketObjRelInsertInput = {
  data: CulturalEventTicketInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CulturalEventTicketOnConflict>;
};

/** on_conflict condition type for table "cultural_event.ticket" */
export type CulturalEventTicketOnConflict = {
  constraint: CulturalEventTicketConstraint;
  updateColumns?: Array<CulturalEventTicketUpdateColumn>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.ticket". */
export type CulturalEventTicketOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEvent?: InputMaybe<CulturalEventOrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  customTitle?: InputMaybe<OrderBy>;
  customUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxCount?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  transactionAggregate?: InputMaybe<PaymentTransactionAggregateOrderBy>;
};

/** primary key columns input for table: cultural_event.ticket */
export type CulturalEventTicketPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "cultural_event.ticket" */
export enum CulturalEventTicketSelectColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  CustomTitle = 'customTitle',
  /** column name */
  CustomUrl = 'customUrl',
  /** column name */
  Id = 'id',
  /** column name */
  MaxCount = 'maxCount',
  /** column name */
  Price = 'price'
}

/** input type for updating data in table "cultural_event.ticket" */
export type CulturalEventTicketSetInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  customTitle?: InputMaybe<Scalars['String']['input']>;
  customUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  maxCount?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type CulturalEventTicketStddevFields = {
  __typename?: 'CulturalEventTicketStddevFields';
  maxCount?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "cultural_event.ticket" */
export type CulturalEventTicketStddevOrderBy = {
  maxCount?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CulturalEventTicketStddevPopFields = {
  __typename?: 'CulturalEventTicketStddevPopFields';
  maxCount?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "cultural_event.ticket" */
export type CulturalEventTicketStddevPopOrderBy = {
  maxCount?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CulturalEventTicketStddevSampFields = {
  __typename?: 'CulturalEventTicketStddevSampFields';
  maxCount?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "cultural_event.ticket" */
export type CulturalEventTicketStddevSampOrderBy = {
  maxCount?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "cultural_event_ticket" */
export type CulturalEventTicketStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventTicketStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventTicketStreamCursorValueInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  customTitle?: InputMaybe<Scalars['String']['input']>;
  customUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  maxCount?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type CulturalEventTicketSumFields = {
  __typename?: 'CulturalEventTicketSumFields';
  maxCount?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "cultural_event.ticket" */
export type CulturalEventTicketSumOrderBy = {
  maxCount?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** update columns of table "cultural_event.ticket" */
export enum CulturalEventTicketUpdateColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  CustomTitle = 'customTitle',
  /** column name */
  CustomUrl = 'customUrl',
  /** column name */
  Id = 'id',
  /** column name */
  MaxCount = 'maxCount',
  /** column name */
  Price = 'price'
}

export type CulturalEventTicketUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CulturalEventTicketIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventTicketSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventTicketBoolExp;
};

/** aggregate varPop on columns */
export type CulturalEventTicketVarPopFields = {
  __typename?: 'CulturalEventTicketVarPopFields';
  maxCount?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "cultural_event.ticket" */
export type CulturalEventTicketVarPopOrderBy = {
  maxCount?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CulturalEventTicketVarSampFields = {
  __typename?: 'CulturalEventTicketVarSampFields';
  maxCount?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "cultural_event.ticket" */
export type CulturalEventTicketVarSampOrderBy = {
  maxCount?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CulturalEventTicketVarianceFields = {
  __typename?: 'CulturalEventTicketVarianceFields';
  maxCount?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "cultural_event.ticket" */
export type CulturalEventTicketVarianceOrderBy = {
  maxCount?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** update columns of table "cultural_event.cultural_event" */
export enum CulturalEventUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  EndsAt = 'endsAt',
  /** column name */
  Id = 'id',
  /** column name */
  InternalComment = 'internalComment',
  /** column name */
  PublicDescription = 'publicDescription',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartsAt = 'startsAt',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type CulturalEventUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventBoolExp;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntArrayComparisonExp = {
  /** is the array contained in the given array value */
  _containedIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['Int']['input']>>;
  _eq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _lte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _neq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type IntervalComparisonExp = {
  _eq?: InputMaybe<Scalars['interval']['input']>;
  _gt?: InputMaybe<Scalars['interval']['input']>;
  _gte?: InputMaybe<Scalars['interval']['input']>;
  _in?: InputMaybe<Array<Scalars['interval']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['interval']['input']>;
  _lte?: InputMaybe<Scalars['interval']['input']>;
  _neq?: InputMaybe<Scalars['interval']['input']>;
  _nin?: InputMaybe<Array<Scalars['interval']['input']>>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "media.config" */
export type MediaConfig = {
  __typename?: 'MediaConfig';
  accessKeyId: Scalars['String']['output'];
  bucket: Scalars['String']['output'];
  duration: Scalars['interval']['output'];
  endpoint: Scalars['String']['output'];
  secretAccessKey: Scalars['String']['output'];
};

/** aggregated selection of "media.config" */
export type MediaConfigAggregate = {
  __typename?: 'MediaConfigAggregate';
  aggregate?: Maybe<MediaConfigAggregateFields>;
  nodes: Array<MediaConfig>;
};

/** aggregate fields of "media.config" */
export type MediaConfigAggregateFields = {
  __typename?: 'MediaConfigAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<MediaConfigMaxFields>;
  min?: Maybe<MediaConfigMinFields>;
};


/** aggregate fields of "media.config" */
export type MediaConfigAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MediaConfigSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "media.config". All fields are combined with a logical 'AND'. */
export type MediaConfigBoolExp = {
  _and?: InputMaybe<Array<MediaConfigBoolExp>>;
  _not?: InputMaybe<MediaConfigBoolExp>;
  _or?: InputMaybe<Array<MediaConfigBoolExp>>;
  accessKeyId?: InputMaybe<StringComparisonExp>;
  bucket?: InputMaybe<StringComparisonExp>;
  duration?: InputMaybe<IntervalComparisonExp>;
  endpoint?: InputMaybe<StringComparisonExp>;
  secretAccessKey?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "media.config" */
export enum MediaConfigConstraint {
  /** unique or primary key constraint on columns "endpoint", "bucket" */
  ConfigPkey = 'config_pkey'
}

/** input type for inserting data into table "media.config" */
export type MediaConfigInsertInput = {
  accessKeyId?: InputMaybe<Scalars['String']['input']>;
  bucket?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['interval']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  secretAccessKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MediaConfigMaxFields = {
  __typename?: 'MediaConfigMaxFields';
  accessKeyId?: Maybe<Scalars['String']['output']>;
  bucket?: Maybe<Scalars['String']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  secretAccessKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MediaConfigMinFields = {
  __typename?: 'MediaConfigMinFields';
  accessKeyId?: Maybe<Scalars['String']['output']>;
  bucket?: Maybe<Scalars['String']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  secretAccessKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "media.config" */
export type MediaConfigMutationResponse = {
  __typename?: 'MediaConfigMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MediaConfig>;
};

/** on_conflict condition type for table "media.config" */
export type MediaConfigOnConflict = {
  constraint: MediaConfigConstraint;
  updateColumns?: Array<MediaConfigUpdateColumn>;
  where?: InputMaybe<MediaConfigBoolExp>;
};

/** Ordering options when selecting data from "media.config". */
export type MediaConfigOrderBy = {
  accessKeyId?: InputMaybe<OrderBy>;
  bucket?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  endpoint?: InputMaybe<OrderBy>;
  secretAccessKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: media.config */
export type MediaConfigPkColumnsInput = {
  bucket: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
};

/** select columns of table "media.config" */
export enum MediaConfigSelectColumn {
  /** column name */
  AccessKeyId = 'accessKeyId',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  Duration = 'duration',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  SecretAccessKey = 'secretAccessKey'
}

/** input type for updating data in table "media.config" */
export type MediaConfigSetInput = {
  accessKeyId?: InputMaybe<Scalars['String']['input']>;
  bucket?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['interval']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  secretAccessKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "media_config" */
export type MediaConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MediaConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MediaConfigStreamCursorValueInput = {
  accessKeyId?: InputMaybe<Scalars['String']['input']>;
  bucket?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['interval']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  secretAccessKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "media.config" */
export enum MediaConfigUpdateColumn {
  /** column name */
  AccessKeyId = 'accessKeyId',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  Duration = 'duration',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  SecretAccessKey = 'secretAccessKey'
}

export type MediaConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MediaConfigSetInput>;
  /** filter the rows which have to be updated */
  where: MediaConfigBoolExp;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST'
}

/** columns and relationships of "pause" */
export type Pause = {
  __typename?: 'Pause';
  /** An object relationship */
  checkin?: Maybe<Checkin>;
  checkinId: Scalars['uuid']['output'];
  endedAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  pausedById: Scalars['uuid']['output'];
  startedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  visit?: Maybe<Visit>;
};

/** aggregated selection of "pause" */
export type PauseAggregate = {
  __typename?: 'PauseAggregate';
  aggregate?: Maybe<PauseAggregateFields>;
  nodes: Array<Pause>;
};

export type PauseAggregateBoolExp = {
  count?: InputMaybe<PauseAggregateBoolExpCount>;
};

/** aggregate fields of "pause" */
export type PauseAggregateFields = {
  __typename?: 'PauseAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<PauseMaxFields>;
  min?: Maybe<PauseMinFields>;
};


/** aggregate fields of "pause" */
export type PauseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PauseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "pause" */
export type PauseAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PauseMaxOrderBy>;
  min?: InputMaybe<PauseMinOrderBy>;
};

/** input type for inserting array relation for remote table "pause" */
export type PauseArrRelInsertInput = {
  data: Array<PauseInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PauseOnConflict>;
};

/** Boolean expression to filter rows from the table "pause". All fields are combined with a logical 'AND'. */
export type PauseBoolExp = {
  _and?: InputMaybe<Array<PauseBoolExp>>;
  _not?: InputMaybe<PauseBoolExp>;
  _or?: InputMaybe<Array<PauseBoolExp>>;
  checkin?: InputMaybe<CheckinBoolExp>;
  checkinId?: InputMaybe<UuidComparisonExp>;
  endedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  pausedById?: InputMaybe<UuidComparisonExp>;
  startedAt?: InputMaybe<TimestamptzComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
};

/** unique or primary key constraints on table "pause" */
export enum PauseConstraint {
  /** unique or primary key constraint on columns "id" */
  PausePkey = 'pause_pkey'
}

/** input type for inserting data into table "pause" */
export type PauseInsertInput = {
  checkin?: InputMaybe<CheckinObjRelInsertInput>;
  checkinId?: InputMaybe<Scalars['uuid']['input']>;
  endedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  pausedById?: InputMaybe<Scalars['uuid']['input']>;
  startedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
};

/** aggregate max on columns */
export type PauseMaxFields = {
  __typename?: 'PauseMaxFields';
  checkinId?: Maybe<Scalars['uuid']['output']>;
  endedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  pausedById?: Maybe<Scalars['uuid']['output']>;
  startedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "pause" */
export type PauseMaxOrderBy = {
  checkinId?: InputMaybe<OrderBy>;
  endedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  pausedById?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PauseMinFields = {
  __typename?: 'PauseMinFields';
  checkinId?: Maybe<Scalars['uuid']['output']>;
  endedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  pausedById?: Maybe<Scalars['uuid']['output']>;
  startedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "pause" */
export type PauseMinOrderBy = {
  checkinId?: InputMaybe<OrderBy>;
  endedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  pausedById?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "pause" */
export type PauseMutationResponse = {
  __typename?: 'PauseMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Pause>;
};

/** on_conflict condition type for table "pause" */
export type PauseOnConflict = {
  constraint: PauseConstraint;
  updateColumns?: Array<PauseUpdateColumn>;
  where?: InputMaybe<PauseBoolExp>;
};

/** Ordering options when selecting data from "pause". */
export type PauseOrderBy = {
  checkin?: InputMaybe<CheckinOrderBy>;
  checkinId?: InputMaybe<OrderBy>;
  endedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  pausedById?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
};

/** primary key columns input for table: pause */
export type PausePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "pause" */
export enum PauseSelectColumn {
  /** column name */
  CheckinId = 'checkinId',
  /** column name */
  EndedAt = 'endedAt',
  /** column name */
  Id = 'id',
  /** column name */
  PausedById = 'pausedById',
  /** column name */
  StartedAt = 'startedAt'
}

/** input type for updating data in table "pause" */
export type PauseSetInput = {
  checkinId?: InputMaybe<Scalars['uuid']['input']>;
  endedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  pausedById?: InputMaybe<Scalars['uuid']['input']>;
  startedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "pause" */
export type PauseStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PauseStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PauseStreamCursorValueInput = {
  checkinId?: InputMaybe<Scalars['uuid']['input']>;
  endedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  pausedById?: InputMaybe<Scalars['uuid']['input']>;
  startedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "pause" */
export enum PauseUpdateColumn {
  /** column name */
  CheckinId = 'checkinId',
  /** column name */
  EndedAt = 'endedAt',
  /** column name */
  Id = 'id',
  /** column name */
  PausedById = 'pausedById',
  /** column name */
  StartedAt = 'startedAt'
}

export type PauseUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PauseSetInput>;
  /** filter the rows which have to be updated */
  where: PauseBoolExp;
};

/** columns and relationships of "payment.config" */
export type PaymentConfig = {
  __typename?: 'PaymentConfig';
  bank: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  merchantId: Scalars['String']['output'];
  secretKey: Scalars['String']['output'];
  taxSystem: Scalars['String']['output'];
  ziferblatId: Scalars['uuid']['output'];
};

/** aggregated selection of "payment.config" */
export type PaymentConfigAggregate = {
  __typename?: 'PaymentConfigAggregate';
  aggregate?: Maybe<PaymentConfigAggregateFields>;
  nodes: Array<PaymentConfig>;
};

/** aggregate fields of "payment.config" */
export type PaymentConfigAggregateFields = {
  __typename?: 'PaymentConfigAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<PaymentConfigMaxFields>;
  min?: Maybe<PaymentConfigMinFields>;
};


/** aggregate fields of "payment.config" */
export type PaymentConfigAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentConfigSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "payment.config". All fields are combined with a logical 'AND'. */
export type PaymentConfigBoolExp = {
  _and?: InputMaybe<Array<PaymentConfigBoolExp>>;
  _not?: InputMaybe<PaymentConfigBoolExp>;
  _or?: InputMaybe<Array<PaymentConfigBoolExp>>;
  bank?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  merchantId?: InputMaybe<StringComparisonExp>;
  secretKey?: InputMaybe<StringComparisonExp>;
  taxSystem?: InputMaybe<StringComparisonExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "payment.config" */
export enum PaymentConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  BankConfigPkey = 'bank_config_pkey'
}

/** input type for inserting data into table "payment.config" */
export type PaymentConfigInsertInput = {
  bank?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  merchantId?: InputMaybe<Scalars['String']['input']>;
  secretKey?: InputMaybe<Scalars['String']['input']>;
  taxSystem?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type PaymentConfigMaxFields = {
  __typename?: 'PaymentConfigMaxFields';
  bank?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  merchantId?: Maybe<Scalars['String']['output']>;
  secretKey?: Maybe<Scalars['String']['output']>;
  taxSystem?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type PaymentConfigMinFields = {
  __typename?: 'PaymentConfigMinFields';
  bank?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  merchantId?: Maybe<Scalars['String']['output']>;
  secretKey?: Maybe<Scalars['String']['output']>;
  taxSystem?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "payment.config" */
export type PaymentConfigMutationResponse = {
  __typename?: 'PaymentConfigMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PaymentConfig>;
};

/** on_conflict condition type for table "payment.config" */
export type PaymentConfigOnConflict = {
  constraint: PaymentConfigConstraint;
  updateColumns?: Array<PaymentConfigUpdateColumn>;
  where?: InputMaybe<PaymentConfigBoolExp>;
};

/** Ordering options when selecting data from "payment.config". */
export type PaymentConfigOrderBy = {
  bank?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  secretKey?: InputMaybe<OrderBy>;
  taxSystem?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: payment.config */
export type PaymentConfigPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "payment.config" */
export enum PaymentConfigSelectColumn {
  /** column name */
  Bank = 'bank',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  SecretKey = 'secretKey',
  /** column name */
  TaxSystem = 'taxSystem',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "payment.config" */
export type PaymentConfigSetInput = {
  bank?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  merchantId?: InputMaybe<Scalars['String']['input']>;
  secretKey?: InputMaybe<Scalars['String']['input']>;
  taxSystem?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "payment_config" */
export type PaymentConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentConfigStreamCursorValueInput = {
  bank?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  merchantId?: InputMaybe<Scalars['String']['input']>;
  secretKey?: InputMaybe<Scalars['String']['input']>;
  taxSystem?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "payment.config" */
export enum PaymentConfigUpdateColumn {
  /** column name */
  Bank = 'bank',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  SecretKey = 'secretKey',
  /** column name */
  TaxSystem = 'taxSystem',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type PaymentConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentConfigSetInput>;
  /** filter the rows which have to be updated */
  where: PaymentConfigBoolExp;
};

/** columns and relationships of "payment.return" */
export type PaymentReturn = {
  __typename?: 'PaymentReturn';
  amonut: Scalars['uuid']['output'];
  body: Scalars['jsonb']['output'];
  createdAt: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  returnedById: Scalars['uuid']['output'];
  transactionId: Scalars['uuid']['output'];
};


/** columns and relationships of "payment.return" */
export type PaymentReturnBodyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "payment.return" */
export type PaymentReturnAggregate = {
  __typename?: 'PaymentReturnAggregate';
  aggregate?: Maybe<PaymentReturnAggregateFields>;
  nodes: Array<PaymentReturn>;
};

/** aggregate fields of "payment.return" */
export type PaymentReturnAggregateFields = {
  __typename?: 'PaymentReturnAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<PaymentReturnMaxFields>;
  min?: Maybe<PaymentReturnMinFields>;
};


/** aggregate fields of "payment.return" */
export type PaymentReturnAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentReturnSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PaymentReturnAppendInput = {
  body?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "payment.return". All fields are combined with a logical 'AND'. */
export type PaymentReturnBoolExp = {
  _and?: InputMaybe<Array<PaymentReturnBoolExp>>;
  _not?: InputMaybe<PaymentReturnBoolExp>;
  _or?: InputMaybe<Array<PaymentReturnBoolExp>>;
  amonut?: InputMaybe<UuidComparisonExp>;
  body?: InputMaybe<JsonbComparisonExp>;
  createdAt?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  returnedById?: InputMaybe<UuidComparisonExp>;
  transactionId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "payment.return" */
export enum PaymentReturnConstraint {
  /** unique or primary key constraint on columns "id" */
  ReturnPkey = 'return_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PaymentReturnDeleteAtPathInput = {
  body?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PaymentReturnDeleteElemInput = {
  body?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PaymentReturnDeleteKeyInput = {
  body?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "payment.return" */
export type PaymentReturnInsertInput = {
  amonut?: InputMaybe<Scalars['uuid']['input']>;
  body?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  returnedById?: InputMaybe<Scalars['uuid']['input']>;
  transactionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type PaymentReturnMaxFields = {
  __typename?: 'PaymentReturnMaxFields';
  amonut?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  returnedById?: Maybe<Scalars['uuid']['output']>;
  transactionId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type PaymentReturnMinFields = {
  __typename?: 'PaymentReturnMinFields';
  amonut?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  returnedById?: Maybe<Scalars['uuid']['output']>;
  transactionId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "payment.return" */
export type PaymentReturnMutationResponse = {
  __typename?: 'PaymentReturnMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PaymentReturn>;
};

/** on_conflict condition type for table "payment.return" */
export type PaymentReturnOnConflict = {
  constraint: PaymentReturnConstraint;
  updateColumns?: Array<PaymentReturnUpdateColumn>;
  where?: InputMaybe<PaymentReturnBoolExp>;
};

/** Ordering options when selecting data from "payment.return". */
export type PaymentReturnOrderBy = {
  amonut?: InputMaybe<OrderBy>;
  body?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  returnedById?: InputMaybe<OrderBy>;
  transactionId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: payment.return */
export type PaymentReturnPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PaymentReturnPrependInput = {
  body?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "payment.return" */
export enum PaymentReturnSelectColumn {
  /** column name */
  Amonut = 'amonut',
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ReturnedById = 'returnedById',
  /** column name */
  TransactionId = 'transactionId'
}

/** input type for updating data in table "payment.return" */
export type PaymentReturnSetInput = {
  amonut?: InputMaybe<Scalars['uuid']['input']>;
  body?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  returnedById?: InputMaybe<Scalars['uuid']['input']>;
  transactionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "payment_return" */
export type PaymentReturnStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentReturnStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentReturnStreamCursorValueInput = {
  amonut?: InputMaybe<Scalars['uuid']['input']>;
  body?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  returnedById?: InputMaybe<Scalars['uuid']['input']>;
  transactionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "payment.return" */
export enum PaymentReturnUpdateColumn {
  /** column name */
  Amonut = 'amonut',
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ReturnedById = 'returnedById',
  /** column name */
  TransactionId = 'transactionId'
}

export type PaymentReturnUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PaymentReturnAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<PaymentReturnDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<PaymentReturnDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<PaymentReturnDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PaymentReturnPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentReturnSetInput>;
  /** filter the rows which have to be updated */
  where: PaymentReturnBoolExp;
};

/** columns and relationships of "payment.transaction" */
export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  /** An object relationship */
  attendance?: Maybe<Attendees>;
  body: Scalars['jsonb']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  orderId: Scalars['uuid']['output'];
  originalTransactionId: Scalars['String']['output'];
  paymentById: Scalars['uuid']['output'];
  sum: Scalars['Int']['output'];
  /** An object relationship */
  ticket?: Maybe<CulturalEventTicket>;
};


/** columns and relationships of "payment.transaction" */
export type PaymentTransactionBodyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "payment.transaction" */
export type PaymentTransactionAggregate = {
  __typename?: 'PaymentTransactionAggregate';
  aggregate?: Maybe<PaymentTransactionAggregateFields>;
  nodes: Array<PaymentTransaction>;
};

export type PaymentTransactionAggregateBoolExp = {
  count?: InputMaybe<PaymentTransactionAggregateBoolExpCount>;
};

/** aggregate fields of "payment.transaction" */
export type PaymentTransactionAggregateFields = {
  __typename?: 'PaymentTransactionAggregateFields';
  avg?: Maybe<PaymentTransactionAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PaymentTransactionMaxFields>;
  min?: Maybe<PaymentTransactionMinFields>;
  stddev?: Maybe<PaymentTransactionStddevFields>;
  stddevPop?: Maybe<PaymentTransactionStddevPopFields>;
  stddevSamp?: Maybe<PaymentTransactionStddevSampFields>;
  sum?: Maybe<PaymentTransactionSumFields>;
  varPop?: Maybe<PaymentTransactionVarPopFields>;
  varSamp?: Maybe<PaymentTransactionVarSampFields>;
  variance?: Maybe<PaymentTransactionVarianceFields>;
};


/** aggregate fields of "payment.transaction" */
export type PaymentTransactionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentTransactionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "payment.transaction" */
export type PaymentTransactionAggregateOrderBy = {
  avg?: InputMaybe<PaymentTransactionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PaymentTransactionMaxOrderBy>;
  min?: InputMaybe<PaymentTransactionMinOrderBy>;
  stddev?: InputMaybe<PaymentTransactionStddevOrderBy>;
  stddevPop?: InputMaybe<PaymentTransactionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<PaymentTransactionStddevSampOrderBy>;
  sum?: InputMaybe<PaymentTransactionSumOrderBy>;
  varPop?: InputMaybe<PaymentTransactionVarPopOrderBy>;
  varSamp?: InputMaybe<PaymentTransactionVarSampOrderBy>;
  variance?: InputMaybe<PaymentTransactionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PaymentTransactionAppendInput = {
  body?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "payment.transaction" */
export type PaymentTransactionArrRelInsertInput = {
  data: Array<PaymentTransactionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentTransactionOnConflict>;
};

/** aggregate avg on columns */
export type PaymentTransactionAvgFields = {
  __typename?: 'PaymentTransactionAvgFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "payment.transaction" */
export type PaymentTransactionAvgOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "payment.transaction". All fields are combined with a logical 'AND'. */
export type PaymentTransactionBoolExp = {
  _and?: InputMaybe<Array<PaymentTransactionBoolExp>>;
  _not?: InputMaybe<PaymentTransactionBoolExp>;
  _or?: InputMaybe<Array<PaymentTransactionBoolExp>>;
  attendance?: InputMaybe<AttendeesBoolExp>;
  body?: InputMaybe<JsonbComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  orderId?: InputMaybe<UuidComparisonExp>;
  originalTransactionId?: InputMaybe<StringComparisonExp>;
  paymentById?: InputMaybe<UuidComparisonExp>;
  sum?: InputMaybe<IntComparisonExp>;
  ticket?: InputMaybe<CulturalEventTicketBoolExp>;
};

/** unique or primary key constraints on table "payment.transaction" */
export enum PaymentTransactionConstraint {
  /** unique or primary key constraint on columns "id" */
  TransactionPkey = 'transaction_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PaymentTransactionDeleteAtPathInput = {
  body?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PaymentTransactionDeleteElemInput = {
  body?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PaymentTransactionDeleteKeyInput = {
  body?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "payment.transaction" */
export type PaymentTransactionIncInput = {
  sum?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "payment.transaction" */
export type PaymentTransactionInsertInput = {
  attendance?: InputMaybe<AttendeesObjRelInsertInput>;
  body?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  orderId?: InputMaybe<Scalars['uuid']['input']>;
  originalTransactionId?: InputMaybe<Scalars['String']['input']>;
  paymentById?: InputMaybe<Scalars['uuid']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  ticket?: InputMaybe<CulturalEventTicketObjRelInsertInput>;
};

/** aggregate max on columns */
export type PaymentTransactionMaxFields = {
  __typename?: 'PaymentTransactionMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  orderId?: Maybe<Scalars['uuid']['output']>;
  originalTransactionId?: Maybe<Scalars['String']['output']>;
  paymentById?: Maybe<Scalars['uuid']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "payment.transaction" */
export type PaymentTransactionMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  originalTransactionId?: InputMaybe<OrderBy>;
  paymentById?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PaymentTransactionMinFields = {
  __typename?: 'PaymentTransactionMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  orderId?: Maybe<Scalars['uuid']['output']>;
  originalTransactionId?: Maybe<Scalars['String']['output']>;
  paymentById?: Maybe<Scalars['uuid']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "payment.transaction" */
export type PaymentTransactionMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  originalTransactionId?: InputMaybe<OrderBy>;
  paymentById?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "payment.transaction" */
export type PaymentTransactionMutationResponse = {
  __typename?: 'PaymentTransactionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PaymentTransaction>;
};

/** input type for inserting object relation for remote table "payment.transaction" */
export type PaymentTransactionObjRelInsertInput = {
  data: PaymentTransactionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentTransactionOnConflict>;
};

/** on_conflict condition type for table "payment.transaction" */
export type PaymentTransactionOnConflict = {
  constraint: PaymentTransactionConstraint;
  updateColumns?: Array<PaymentTransactionUpdateColumn>;
  where?: InputMaybe<PaymentTransactionBoolExp>;
};

/** Ordering options when selecting data from "payment.transaction". */
export type PaymentTransactionOrderBy = {
  attendance?: InputMaybe<AttendeesOrderBy>;
  body?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  originalTransactionId?: InputMaybe<OrderBy>;
  paymentById?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  ticket?: InputMaybe<CulturalEventTicketOrderBy>;
};

/** primary key columns input for table: payment.transaction */
export type PaymentTransactionPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PaymentTransactionPrependInput = {
  body?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "payment.transaction" */
export enum PaymentTransactionSelectColumn {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  OriginalTransactionId = 'originalTransactionId',
  /** column name */
  PaymentById = 'paymentById',
  /** column name */
  Sum = 'sum'
}

/** input type for updating data in table "payment.transaction" */
export type PaymentTransactionSetInput = {
  body?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  orderId?: InputMaybe<Scalars['uuid']['input']>;
  originalTransactionId?: InputMaybe<Scalars['String']['input']>;
  paymentById?: InputMaybe<Scalars['uuid']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type PaymentTransactionStddevFields = {
  __typename?: 'PaymentTransactionStddevFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "payment.transaction" */
export type PaymentTransactionStddevOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type PaymentTransactionStddevPopFields = {
  __typename?: 'PaymentTransactionStddevPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "payment.transaction" */
export type PaymentTransactionStddevPopOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type PaymentTransactionStddevSampFields = {
  __typename?: 'PaymentTransactionStddevSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "payment.transaction" */
export type PaymentTransactionStddevSampOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "payment_transaction" */
export type PaymentTransactionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentTransactionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentTransactionStreamCursorValueInput = {
  body?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  orderId?: InputMaybe<Scalars['uuid']['input']>;
  originalTransactionId?: InputMaybe<Scalars['String']['input']>;
  paymentById?: InputMaybe<Scalars['uuid']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type PaymentTransactionSumFields = {
  __typename?: 'PaymentTransactionSumFields';
  sum?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "payment.transaction" */
export type PaymentTransactionSumOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** update columns of table "payment.transaction" */
export enum PaymentTransactionUpdateColumn {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  OriginalTransactionId = 'originalTransactionId',
  /** column name */
  PaymentById = 'paymentById',
  /** column name */
  Sum = 'sum'
}

export type PaymentTransactionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PaymentTransactionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<PaymentTransactionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<PaymentTransactionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<PaymentTransactionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PaymentTransactionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PaymentTransactionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentTransactionSetInput>;
  /** filter the rows which have to be updated */
  where: PaymentTransactionBoolExp;
};

/** aggregate varPop on columns */
export type PaymentTransactionVarPopFields = {
  __typename?: 'PaymentTransactionVarPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "payment.transaction" */
export type PaymentTransactionVarPopOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type PaymentTransactionVarSampFields = {
  __typename?: 'PaymentTransactionVarSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "payment.transaction" */
export type PaymentTransactionVarSampOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PaymentTransactionVarianceFields = {
  __typename?: 'PaymentTransactionVarianceFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "payment.transaction" */
export type PaymentTransactionVarianceOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type SmallintArrayComparisonExp = {
  /** is the array contained in the given array value */
  _containedIn?: InputMaybe<Array<Scalars['smallint']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _eq?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _gt?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _gte?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['smallint']['input']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _lte?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _neq?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['smallint']['input']>>>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type SmallintComparisonExp = {
  _eq?: InputMaybe<Scalars['smallint']['input']>;
  _gt?: InputMaybe<Scalars['smallint']['input']>;
  _gte?: InputMaybe<Scalars['smallint']['input']>;
  _in?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['smallint']['input']>;
  _lte?: InputMaybe<Scalars['smallint']['input']>;
  _neq?: InputMaybe<Scalars['smallint']['input']>;
  _nin?: InputMaybe<Array<Scalars['smallint']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "tariff.pass" */
export type TariffPass = {
  __typename?: 'TariffPass';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  activeFrom: Scalars['timestamptz']['output'];
  activeTo?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  boughtBy: Array<TariffPassUser>;
  /** An aggregate relationship */
  boughtByAggregate: TariffPassUserAggregate;
  cost: Scalars['Int']['output'];
  createdAt: Scalars['timestamptz']['output'];
  createdById: Scalars['uuid']['output'];
  days: Array<Scalars['Int']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  passI18n: Array<TariffPassI18n>;
  /** An aggregate relationship */
  passI18nAggregate: TariffPassI18nAggregate;
  period: Scalars['interval']['output'];
  timeFrom: Scalars['time']['output'];
  timeTo?: Maybe<Scalars['time']['output']>;
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "tariff.pass" */
export type TariffPassAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "tariff.pass" */
export type TariffPassAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "tariff.pass" */
export type TariffPassBoughtByArgs = {
  distinctOn?: InputMaybe<Array<TariffPassUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassUserOrderBy>>;
  where?: InputMaybe<TariffPassUserBoolExp>;
};


/** columns and relationships of "tariff.pass" */
export type TariffPassBoughtByAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffPassUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassUserOrderBy>>;
  where?: InputMaybe<TariffPassUserBoolExp>;
};


/** columns and relationships of "tariff.pass" */
export type TariffPassPassI18nArgs = {
  distinctOn?: InputMaybe<Array<TariffPassI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassI18nOrderBy>>;
  where?: InputMaybe<TariffPassI18nBoolExp>;
};


/** columns and relationships of "tariff.pass" */
export type TariffPassPassI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffPassI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassI18nOrderBy>>;
  where?: InputMaybe<TariffPassI18nBoolExp>;
};

/** aggregated selection of "tariff.pass" */
export type TariffPassAggregate = {
  __typename?: 'TariffPassAggregate';
  aggregate?: Maybe<TariffPassAggregateFields>;
  nodes: Array<TariffPass>;
};

export type TariffPassAggregateBoolExp = {
  count?: InputMaybe<TariffPassAggregateBoolExpCount>;
};

/** aggregate fields of "tariff.pass" */
export type TariffPassAggregateFields = {
  __typename?: 'TariffPassAggregateFields';
  avg?: Maybe<TariffPassAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TariffPassMaxFields>;
  min?: Maybe<TariffPassMinFields>;
  stddev?: Maybe<TariffPassStddevFields>;
  stddevPop?: Maybe<TariffPassStddevPopFields>;
  stddevSamp?: Maybe<TariffPassStddevSampFields>;
  sum?: Maybe<TariffPassSumFields>;
  varPop?: Maybe<TariffPassVarPopFields>;
  varSamp?: Maybe<TariffPassVarSampFields>;
  variance?: Maybe<TariffPassVarianceFields>;
};


/** aggregate fields of "tariff.pass" */
export type TariffPassAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TariffPassSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tariff.pass" */
export type TariffPassAggregateOrderBy = {
  avg?: InputMaybe<TariffPassAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TariffPassMaxOrderBy>;
  min?: InputMaybe<TariffPassMinOrderBy>;
  stddev?: InputMaybe<TariffPassStddevOrderBy>;
  stddevPop?: InputMaybe<TariffPassStddevPopOrderBy>;
  stddevSamp?: InputMaybe<TariffPassStddevSampOrderBy>;
  sum?: InputMaybe<TariffPassSumOrderBy>;
  varPop?: InputMaybe<TariffPassVarPopOrderBy>;
  varSamp?: InputMaybe<TariffPassVarSampOrderBy>;
  variance?: InputMaybe<TariffPassVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "tariff.pass" */
export type TariffPassArrRelInsertInput = {
  data: Array<TariffPassInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TariffPassOnConflict>;
};

/** aggregate avg on columns */
export type TariffPassAvgFields = {
  __typename?: 'TariffPassAvgFields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "tariff.pass" */
export type TariffPassAvgOrderBy = {
  cost?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "tariff.pass". All fields are combined with a logical 'AND'. */
export type TariffPassBoolExp = {
  _and?: InputMaybe<Array<TariffPassBoolExp>>;
  _not?: InputMaybe<TariffPassBoolExp>;
  _or?: InputMaybe<Array<TariffPassBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  activeFrom?: InputMaybe<TimestamptzComparisonExp>;
  activeTo?: InputMaybe<TimestamptzComparisonExp>;
  boughtBy?: InputMaybe<TariffPassUserBoolExp>;
  boughtByAggregate?: InputMaybe<TariffPassUserAggregateBoolExp>;
  cost?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  days?: InputMaybe<IntArrayComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  passI18n?: InputMaybe<TariffPassI18nBoolExp>;
  passI18nAggregate?: InputMaybe<TariffPassI18nAggregateBoolExp>;
  period?: InputMaybe<IntervalComparisonExp>;
  timeFrom?: InputMaybe<TimeComparisonExp>;
  timeTo?: InputMaybe<TimeComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "tariff.pass" */
export enum TariffPassConstraint {
  /** unique or primary key constraint on columns "id" */
  PassPkey = 'pass_pkey'
}

/** columns and relationships of "tariff.pass_i18n" */
export type TariffPassI18n = {
  __typename?: 'TariffPassI18n';
  addedById: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  lang: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  pass: TariffPass;
  passId: Scalars['uuid']['output'];
};

/** aggregated selection of "tariff.pass_i18n" */
export type TariffPassI18nAggregate = {
  __typename?: 'TariffPassI18nAggregate';
  aggregate?: Maybe<TariffPassI18nAggregateFields>;
  nodes: Array<TariffPassI18n>;
};

export type TariffPassI18nAggregateBoolExp = {
  count?: InputMaybe<TariffPassI18nAggregateBoolExpCount>;
};

/** aggregate fields of "tariff.pass_i18n" */
export type TariffPassI18nAggregateFields = {
  __typename?: 'TariffPassI18nAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<TariffPassI18nMaxFields>;
  min?: Maybe<TariffPassI18nMinFields>;
};


/** aggregate fields of "tariff.pass_i18n" */
export type TariffPassI18nAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TariffPassI18nSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tariff.pass_i18n" */
export type TariffPassI18nAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TariffPassI18nMaxOrderBy>;
  min?: InputMaybe<TariffPassI18nMinOrderBy>;
};

/** input type for inserting array relation for remote table "tariff.pass_i18n" */
export type TariffPassI18nArrRelInsertInput = {
  data: Array<TariffPassI18nInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TariffPassI18nOnConflict>;
};

/** Boolean expression to filter rows from the table "tariff.pass_i18n". All fields are combined with a logical 'AND'. */
export type TariffPassI18nBoolExp = {
  _and?: InputMaybe<Array<TariffPassI18nBoolExp>>;
  _not?: InputMaybe<TariffPassI18nBoolExp>;
  _or?: InputMaybe<Array<TariffPassI18nBoolExp>>;
  addedById?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lang?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  pass?: InputMaybe<TariffPassBoolExp>;
  passId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "tariff.pass_i18n" */
export enum TariffPassI18nConstraint {
  /** unique or primary key constraint on columns "id" */
  PassI18nPkey = 'pass_i18n_pkey'
}

/** input type for inserting data into table "tariff.pass_i18n" */
export type TariffPassI18nInsertInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pass?: InputMaybe<TariffPassObjRelInsertInput>;
  passId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type TariffPassI18nMaxFields = {
  __typename?: 'TariffPassI18nMaxFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  passId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "tariff.pass_i18n" */
export type TariffPassI18nMaxOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  passId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TariffPassI18nMinFields = {
  __typename?: 'TariffPassI18nMinFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  passId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "tariff.pass_i18n" */
export type TariffPassI18nMinOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  passId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tariff.pass_i18n" */
export type TariffPassI18nMutationResponse = {
  __typename?: 'TariffPassI18nMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TariffPassI18n>;
};

/** on_conflict condition type for table "tariff.pass_i18n" */
export type TariffPassI18nOnConflict = {
  constraint: TariffPassI18nConstraint;
  updateColumns?: Array<TariffPassI18nUpdateColumn>;
  where?: InputMaybe<TariffPassI18nBoolExp>;
};

/** Ordering options when selecting data from "tariff.pass_i18n". */
export type TariffPassI18nOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  pass?: InputMaybe<TariffPassOrderBy>;
  passId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tariff.pass_i18n */
export type TariffPassI18nPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tariff.pass_i18n" */
export enum TariffPassI18nSelectColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  PassId = 'passId'
}

/** input type for updating data in table "tariff.pass_i18n" */
export type TariffPassI18nSetInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  passId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "tariff_pass_i18n" */
export type TariffPassI18nStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TariffPassI18nStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TariffPassI18nStreamCursorValueInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  passId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "tariff.pass_i18n" */
export enum TariffPassI18nUpdateColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  PassId = 'passId'
}

export type TariffPassI18nUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TariffPassI18nSetInput>;
  /** filter the rows which have to be updated */
  where: TariffPassI18nBoolExp;
};

/** input type for incrementing numeric columns in table "tariff.pass" */
export type TariffPassIncInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tariff.pass" */
export type TariffPassInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  activeTo?: InputMaybe<Scalars['timestamptz']['input']>;
  boughtBy?: InputMaybe<TariffPassUserArrRelInsertInput>;
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  days?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  passI18n?: InputMaybe<TariffPassI18nArrRelInsertInput>;
  period?: InputMaybe<Scalars['interval']['input']>;
  timeFrom?: InputMaybe<Scalars['time']['input']>;
  timeTo?: InputMaybe<Scalars['time']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type TariffPassMaxFields = {
  __typename?: 'TariffPassMaxFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  activeTo?: Maybe<Scalars['timestamptz']['output']>;
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdById?: Maybe<Scalars['uuid']['output']>;
  days?: Maybe<Array<Scalars['Int']['output']>>;
  id?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "tariff.pass" */
export type TariffPassMaxOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  activeTo?: InputMaybe<OrderBy>;
  cost?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  days?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TariffPassMinFields = {
  __typename?: 'TariffPassMinFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  activeTo?: Maybe<Scalars['timestamptz']['output']>;
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdById?: Maybe<Scalars['uuid']['output']>;
  days?: Maybe<Array<Scalars['Int']['output']>>;
  id?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "tariff.pass" */
export type TariffPassMinOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  activeTo?: InputMaybe<OrderBy>;
  cost?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  days?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tariff.pass" */
export type TariffPassMutationResponse = {
  __typename?: 'TariffPassMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TariffPass>;
};

/** input type for inserting object relation for remote table "tariff.pass" */
export type TariffPassObjRelInsertInput = {
  data: TariffPassInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TariffPassOnConflict>;
};

/** on_conflict condition type for table "tariff.pass" */
export type TariffPassOnConflict = {
  constraint: TariffPassConstraint;
  updateColumns?: Array<TariffPassUpdateColumn>;
  where?: InputMaybe<TariffPassBoolExp>;
};

/** Ordering options when selecting data from "tariff.pass". */
export type TariffPassOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  activeFrom?: InputMaybe<OrderBy>;
  activeTo?: InputMaybe<OrderBy>;
  boughtByAggregate?: InputMaybe<TariffPassUserAggregateOrderBy>;
  cost?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  days?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  passI18nAggregate?: InputMaybe<TariffPassI18nAggregateOrderBy>;
  period?: InputMaybe<OrderBy>;
  timeFrom?: InputMaybe<OrderBy>;
  timeTo?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tariff.pass */
export type TariffPassPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tariff.pass" */
export enum TariffPassSelectColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  ActiveTo = 'activeTo',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  Period = 'period',
  /** column name */
  TimeFrom = 'timeFrom',
  /** column name */
  TimeTo = 'timeTo',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "tariff.pass" */
export type TariffPassSetInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  activeTo?: InputMaybe<Scalars['timestamptz']['input']>;
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  days?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  period?: InputMaybe<Scalars['interval']['input']>;
  timeFrom?: InputMaybe<Scalars['time']['input']>;
  timeTo?: InputMaybe<Scalars['time']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type TariffPassStddevFields = {
  __typename?: 'TariffPassStddevFields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "tariff.pass" */
export type TariffPassStddevOrderBy = {
  cost?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TariffPassStddevPopFields = {
  __typename?: 'TariffPassStddevPopFields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "tariff.pass" */
export type TariffPassStddevPopOrderBy = {
  cost?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TariffPassStddevSampFields = {
  __typename?: 'TariffPassStddevSampFields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "tariff.pass" */
export type TariffPassStddevSampOrderBy = {
  cost?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "tariff_pass" */
export type TariffPassStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TariffPassStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TariffPassStreamCursorValueInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  activeTo?: InputMaybe<Scalars['timestamptz']['input']>;
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  days?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  period?: InputMaybe<Scalars['interval']['input']>;
  timeFrom?: InputMaybe<Scalars['time']['input']>;
  timeTo?: InputMaybe<Scalars['time']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type TariffPassSumFields = {
  __typename?: 'TariffPassSumFields';
  cost?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "tariff.pass" */
export type TariffPassSumOrderBy = {
  cost?: InputMaybe<OrderBy>;
};

/** update columns of table "tariff.pass" */
export enum TariffPassUpdateColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  ActiveTo = 'activeTo',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  Period = 'period',
  /** column name */
  TimeFrom = 'timeFrom',
  /** column name */
  TimeTo = 'timeTo',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type TariffPassUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TariffPassIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TariffPassSetInput>;
  /** filter the rows which have to be updated */
  where: TariffPassBoolExp;
};

/** columns and relationships of "tariff.pass_user" */
export type TariffPassUser = {
  __typename?: 'TariffPassUser';
  activeFrom: Scalars['timestamptz']['output'];
  addedById: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  passId: Scalars['uuid']['output'];
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "tariff.pass_user" */
export type TariffPassUserAggregate = {
  __typename?: 'TariffPassUserAggregate';
  aggregate?: Maybe<TariffPassUserAggregateFields>;
  nodes: Array<TariffPassUser>;
};

export type TariffPassUserAggregateBoolExp = {
  count?: InputMaybe<TariffPassUserAggregateBoolExpCount>;
};

/** aggregate fields of "tariff.pass_user" */
export type TariffPassUserAggregateFields = {
  __typename?: 'TariffPassUserAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<TariffPassUserMaxFields>;
  min?: Maybe<TariffPassUserMinFields>;
};


/** aggregate fields of "tariff.pass_user" */
export type TariffPassUserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TariffPassUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tariff.pass_user" */
export type TariffPassUserAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TariffPassUserMaxOrderBy>;
  min?: InputMaybe<TariffPassUserMinOrderBy>;
};

/** input type for inserting array relation for remote table "tariff.pass_user" */
export type TariffPassUserArrRelInsertInput = {
  data: Array<TariffPassUserInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TariffPassUserOnConflict>;
};

/** Boolean expression to filter rows from the table "tariff.pass_user". All fields are combined with a logical 'AND'. */
export type TariffPassUserBoolExp = {
  _and?: InputMaybe<Array<TariffPassUserBoolExp>>;
  _not?: InputMaybe<TariffPassUserBoolExp>;
  _or?: InputMaybe<Array<TariffPassUserBoolExp>>;
  activeFrom?: InputMaybe<TimestamptzComparisonExp>;
  addedById?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  passId?: InputMaybe<UuidComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "tariff.pass_user" */
export enum TariffPassUserConstraint {
  /** unique or primary key constraint on columns "id" */
  PassUserPkey = 'pass_user_pkey'
}

/** input type for inserting data into table "tariff.pass_user" */
export type TariffPassUserInsertInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  passId?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type TariffPassUserMaxFields = {
  __typename?: 'TariffPassUserMaxFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  passId?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "tariff.pass_user" */
export type TariffPassUserMaxOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  passId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TariffPassUserMinFields = {
  __typename?: 'TariffPassUserMinFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  passId?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "tariff.pass_user" */
export type TariffPassUserMinOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  passId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tariff.pass_user" */
export type TariffPassUserMutationResponse = {
  __typename?: 'TariffPassUserMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TariffPassUser>;
};

/** on_conflict condition type for table "tariff.pass_user" */
export type TariffPassUserOnConflict = {
  constraint: TariffPassUserConstraint;
  updateColumns?: Array<TariffPassUserUpdateColumn>;
  where?: InputMaybe<TariffPassUserBoolExp>;
};

/** Ordering options when selecting data from "tariff.pass_user". */
export type TariffPassUserOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  passId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tariff.pass_user */
export type TariffPassUserPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tariff.pass_user" */
export enum TariffPassUserSelectColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PassId = 'passId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "tariff.pass_user" */
export type TariffPassUserSetInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  passId?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "tariff_pass_user" */
export type TariffPassUserStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TariffPassUserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TariffPassUserStreamCursorValueInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  passId?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "tariff.pass_user" */
export enum TariffPassUserUpdateColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PassId = 'passId',
  /** column name */
  UserId = 'userId'
}

export type TariffPassUserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TariffPassUserSetInput>;
  /** filter the rows which have to be updated */
  where: TariffPassUserBoolExp;
};

/** aggregate varPop on columns */
export type TariffPassVarPopFields = {
  __typename?: 'TariffPassVarPopFields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "tariff.pass" */
export type TariffPassVarPopOrderBy = {
  cost?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TariffPassVarSampFields = {
  __typename?: 'TariffPassVarSampFields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "tariff.pass" */
export type TariffPassVarSampOrderBy = {
  cost?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TariffPassVarianceFields = {
  __typename?: 'TariffPassVarianceFields';
  cost?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "tariff.pass" */
export type TariffPassVarianceOrderBy = {
  cost?: InputMaybe<OrderBy>;
};

/** columns and relationships of "tariff.tariff" */
export type TariffTariff = {
  __typename?: 'TariffTariff';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  activeFrom: Scalars['timestamptz']['output'];
  /** A computed field, executes function "tariff.get_active_to" */
  activeTo?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  addedBy: AuthUser;
  addedById: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  hourPrice: Array<Scalars['smallint']['output']>;
  id: Scalars['uuid']['output'];
  minCheck: Scalars['Int']['output'];
  stopCheck: Scalars['smallint']['output'];
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "tariff.tariff" */
export type TariffTariffAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "tariff.tariff" */
export type TariffTariffAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};

/** aggregated selection of "tariff.tariff" */
export type TariffTariffAggregate = {
  __typename?: 'TariffTariffAggregate';
  aggregate?: Maybe<TariffTariffAggregateFields>;
  nodes: Array<TariffTariff>;
};

export type TariffTariffAggregateBoolExp = {
  count?: InputMaybe<TariffTariffAggregateBoolExpCount>;
};

/** aggregate fields of "tariff.tariff" */
export type TariffTariffAggregateFields = {
  __typename?: 'TariffTariffAggregateFields';
  avg?: Maybe<TariffTariffAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TariffTariffMaxFields>;
  min?: Maybe<TariffTariffMinFields>;
  stddev?: Maybe<TariffTariffStddevFields>;
  stddevPop?: Maybe<TariffTariffStddevPopFields>;
  stddevSamp?: Maybe<TariffTariffStddevSampFields>;
  sum?: Maybe<TariffTariffSumFields>;
  varPop?: Maybe<TariffTariffVarPopFields>;
  varSamp?: Maybe<TariffTariffVarSampFields>;
  variance?: Maybe<TariffTariffVarianceFields>;
};


/** aggregate fields of "tariff.tariff" */
export type TariffTariffAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TariffTariffSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tariff.tariff" */
export type TariffTariffAggregateOrderBy = {
  avg?: InputMaybe<TariffTariffAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TariffTariffMaxOrderBy>;
  min?: InputMaybe<TariffTariffMinOrderBy>;
  stddev?: InputMaybe<TariffTariffStddevOrderBy>;
  stddevPop?: InputMaybe<TariffTariffStddevPopOrderBy>;
  stddevSamp?: InputMaybe<TariffTariffStddevSampOrderBy>;
  sum?: InputMaybe<TariffTariffSumOrderBy>;
  varPop?: InputMaybe<TariffTariffVarPopOrderBy>;
  varSamp?: InputMaybe<TariffTariffVarSampOrderBy>;
  variance?: InputMaybe<TariffTariffVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "tariff.tariff" */
export type TariffTariffArrRelInsertInput = {
  data: Array<TariffTariffInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TariffTariffOnConflict>;
};

/** aggregate avg on columns */
export type TariffTariffAvgFields = {
  __typename?: 'TariffTariffAvgFields';
  minCheck?: Maybe<Scalars['Float']['output']>;
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "tariff.tariff" */
export type TariffTariffAvgOrderBy = {
  minCheck?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "tariff.tariff". All fields are combined with a logical 'AND'. */
export type TariffTariffBoolExp = {
  _and?: InputMaybe<Array<TariffTariffBoolExp>>;
  _not?: InputMaybe<TariffTariffBoolExp>;
  _or?: InputMaybe<Array<TariffTariffBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  activeFrom?: InputMaybe<TimestamptzComparisonExp>;
  activeTo?: InputMaybe<TimestamptzComparisonExp>;
  addedBy?: InputMaybe<AuthUserBoolExp>;
  addedById?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  hourPrice?: InputMaybe<SmallintArrayComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  minCheck?: InputMaybe<IntComparisonExp>;
  stopCheck?: InputMaybe<SmallintComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "tariff.tariff" */
export enum TariffTariffConstraint {
  /** unique or primary key constraint on columns "id" */
  TariffPkey = 'tariff_pkey'
}

/** input type for incrementing numeric columns in table "tariff.tariff" */
export type TariffTariffIncInput = {
  minCheck?: InputMaybe<Scalars['Int']['input']>;
  stopCheck?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "tariff.tariff" */
export type TariffTariffInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addedBy?: InputMaybe<AuthUserObjRelInsertInput>;
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  hourPrice?: InputMaybe<Array<Scalars['smallint']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minCheck?: InputMaybe<Scalars['Int']['input']>;
  stopCheck?: InputMaybe<Scalars['smallint']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type TariffTariffMaxFields = {
  __typename?: 'TariffTariffMaxFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "tariff.get_active_to" */
  activeTo?: Maybe<Scalars['timestamptz']['output']>;
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  hourPrice?: Maybe<Array<Scalars['smallint']['output']>>;
  id?: Maybe<Scalars['uuid']['output']>;
  minCheck?: Maybe<Scalars['Int']['output']>;
  stopCheck?: Maybe<Scalars['smallint']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "tariff.tariff" */
export type TariffTariffMaxOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  hourPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minCheck?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TariffTariffMinFields = {
  __typename?: 'TariffTariffMinFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "tariff.get_active_to" */
  activeTo?: Maybe<Scalars['timestamptz']['output']>;
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  hourPrice?: Maybe<Array<Scalars['smallint']['output']>>;
  id?: Maybe<Scalars['uuid']['output']>;
  minCheck?: Maybe<Scalars['Int']['output']>;
  stopCheck?: Maybe<Scalars['smallint']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "tariff.tariff" */
export type TariffTariffMinOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  hourPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minCheck?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tariff.tariff" */
export type TariffTariffMutationResponse = {
  __typename?: 'TariffTariffMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TariffTariff>;
};

/** on_conflict condition type for table "tariff.tariff" */
export type TariffTariffOnConflict = {
  constraint: TariffTariffConstraint;
  updateColumns?: Array<TariffTariffUpdateColumn>;
  where?: InputMaybe<TariffTariffBoolExp>;
};

/** Ordering options when selecting data from "tariff.tariff". */
export type TariffTariffOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  activeFrom?: InputMaybe<OrderBy>;
  activeTo?: InputMaybe<OrderBy>;
  addedBy?: InputMaybe<AuthUserOrderBy>;
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  hourPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minCheck?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tariff.tariff */
export type TariffTariffPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tariff.tariff" */
export enum TariffTariffSelectColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HourPrice = 'hourPrice',
  /** column name */
  Id = 'id',
  /** column name */
  MinCheck = 'minCheck',
  /** column name */
  StopCheck = 'stopCheck',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "tariff.tariff" */
export type TariffTariffSetInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  hourPrice?: InputMaybe<Array<Scalars['smallint']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minCheck?: InputMaybe<Scalars['Int']['input']>;
  stopCheck?: InputMaybe<Scalars['smallint']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type TariffTariffStddevFields = {
  __typename?: 'TariffTariffStddevFields';
  minCheck?: Maybe<Scalars['Float']['output']>;
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "tariff.tariff" */
export type TariffTariffStddevOrderBy = {
  minCheck?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TariffTariffStddevPopFields = {
  __typename?: 'TariffTariffStddevPopFields';
  minCheck?: Maybe<Scalars['Float']['output']>;
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "tariff.tariff" */
export type TariffTariffStddevPopOrderBy = {
  minCheck?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TariffTariffStddevSampFields = {
  __typename?: 'TariffTariffStddevSampFields';
  minCheck?: Maybe<Scalars['Float']['output']>;
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "tariff.tariff" */
export type TariffTariffStddevSampOrderBy = {
  minCheck?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "tariff_tariff" */
export type TariffTariffStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TariffTariffStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TariffTariffStreamCursorValueInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  hourPrice?: InputMaybe<Array<Scalars['smallint']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minCheck?: InputMaybe<Scalars['Int']['input']>;
  stopCheck?: InputMaybe<Scalars['smallint']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type TariffTariffSumFields = {
  __typename?: 'TariffTariffSumFields';
  minCheck?: Maybe<Scalars['Int']['output']>;
  stopCheck?: Maybe<Scalars['smallint']['output']>;
};

/** order by sum() on columns of table "tariff.tariff" */
export type TariffTariffSumOrderBy = {
  minCheck?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
};

/** update columns of table "tariff.tariff" */
export enum TariffTariffUpdateColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HourPrice = 'hourPrice',
  /** column name */
  Id = 'id',
  /** column name */
  MinCheck = 'minCheck',
  /** column name */
  StopCheck = 'stopCheck',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type TariffTariffUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TariffTariffIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TariffTariffSetInput>;
  /** filter the rows which have to be updated */
  where: TariffTariffBoolExp;
};

/** aggregate varPop on columns */
export type TariffTariffVarPopFields = {
  __typename?: 'TariffTariffVarPopFields';
  minCheck?: Maybe<Scalars['Float']['output']>;
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "tariff.tariff" */
export type TariffTariffVarPopOrderBy = {
  minCheck?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TariffTariffVarSampFields = {
  __typename?: 'TariffTariffVarSampFields';
  minCheck?: Maybe<Scalars['Float']['output']>;
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "tariff.tariff" */
export type TariffTariffVarSampOrderBy = {
  minCheck?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TariffTariffVarianceFields = {
  __typename?: 'TariffTariffVarianceFields';
  minCheck?: Maybe<Scalars['Float']['output']>;
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "tariff.tariff" */
export type TariffTariffVarianceOrderBy = {
  minCheck?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type TimeComparisonExp = {
  _eq?: InputMaybe<Scalars['time']['input']>;
  _gt?: InputMaybe<Scalars['time']['input']>;
  _gte?: InputMaybe<Scalars['time']['input']>;
  _in?: InputMaybe<Array<Scalars['time']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['time']['input']>;
  _lte?: InputMaybe<Scalars['time']['input']>;
  _neq?: InputMaybe<Scalars['time']['input']>;
  _nin?: InputMaybe<Array<Scalars['time']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  expiresIn: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  url: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidArrayComparisonExp = {
  /** is the array contained in the given array value */
  _containedIn?: InputMaybe<Array<Scalars['uuid']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _eq?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _gt?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _gte?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['uuid']['input']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _lte?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _neq?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['uuid']['input']>>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "visit" */
export type Visit = {
  __typename?: 'Visit';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  checkedInAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  checkedInBy?: Maybe<AuthUser>;
  checkedInById: Scalars['uuid']['output'];
  checkedOutAt: Scalars['timestamptz']['output'];
  checkedOutById: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  pause: Array<Pause>;
  /** An aggregate relationship */
  pauseAggregate: PauseAggregate;
  sum: Scalars['Int']['output'];
  /** An object relationship */
  user?: Maybe<AuthUser>;
  userId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "visit" */
export type VisitAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "visit" */
export type VisitAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "visit" */
export type VisitPauseArgs = {
  distinctOn?: InputMaybe<Array<PauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PauseOrderBy>>;
  where?: InputMaybe<PauseBoolExp>;
};


/** columns and relationships of "visit" */
export type VisitPauseAggregateArgs = {
  distinctOn?: InputMaybe<Array<PauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PauseOrderBy>>;
  where?: InputMaybe<PauseBoolExp>;
};

/** aggregated selection of "visit" */
export type VisitAggregate = {
  __typename?: 'VisitAggregate';
  aggregate?: Maybe<VisitAggregateFields>;
  nodes: Array<Visit>;
};

export type VisitAggregateBoolExp = {
  count?: InputMaybe<VisitAggregateBoolExpCount>;
};

/** aggregate fields of "visit" */
export type VisitAggregateFields = {
  __typename?: 'VisitAggregateFields';
  avg?: Maybe<VisitAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<VisitMaxFields>;
  min?: Maybe<VisitMinFields>;
  stddev?: Maybe<VisitStddevFields>;
  stddevPop?: Maybe<VisitStddevPopFields>;
  stddevSamp?: Maybe<VisitStddevSampFields>;
  sum?: Maybe<VisitSumFields>;
  varPop?: Maybe<VisitVarPopFields>;
  varSamp?: Maybe<VisitVarSampFields>;
  variance?: Maybe<VisitVarianceFields>;
};


/** aggregate fields of "visit" */
export type VisitAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VisitSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "visit" */
export type VisitAggregateOrderBy = {
  avg?: InputMaybe<VisitAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<VisitMaxOrderBy>;
  min?: InputMaybe<VisitMinOrderBy>;
  stddev?: InputMaybe<VisitStddevOrderBy>;
  stddevPop?: InputMaybe<VisitStddevPopOrderBy>;
  stddevSamp?: InputMaybe<VisitStddevSampOrderBy>;
  sum?: InputMaybe<VisitSumOrderBy>;
  varPop?: InputMaybe<VisitVarPopOrderBy>;
  varSamp?: InputMaybe<VisitVarSampOrderBy>;
  variance?: InputMaybe<VisitVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visit" */
export type VisitArrRelInsertInput = {
  data: Array<VisitInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<VisitOnConflict>;
};

/** aggregate avg on columns */
export type VisitAvgFields = {
  __typename?: 'VisitAvgFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "visit" */
export type VisitAvgOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visit". All fields are combined with a logical 'AND'. */
export type VisitBoolExp = {
  _and?: InputMaybe<Array<VisitBoolExp>>;
  _not?: InputMaybe<VisitBoolExp>;
  _or?: InputMaybe<Array<VisitBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  checkedInAt?: InputMaybe<TimestamptzComparisonExp>;
  checkedInBy?: InputMaybe<AuthUserBoolExp>;
  checkedInById?: InputMaybe<UuidComparisonExp>;
  checkedOutAt?: InputMaybe<TimestamptzComparisonExp>;
  checkedOutById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  pause?: InputMaybe<PauseBoolExp>;
  pauseAggregate?: InputMaybe<PauseAggregateBoolExp>;
  sum?: InputMaybe<IntComparisonExp>;
  user?: InputMaybe<AuthUserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "visit" */
export enum VisitConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitPkey = 'visit_pkey'
}

/** input type for incrementing numeric columns in table "visit" */
export type VisitIncInput = {
  sum?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "visit" */
export type VisitInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInBy?: InputMaybe<AuthUserObjRelInsertInput>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  checkedOutAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedOutById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pause?: InputMaybe<PauseArrRelInsertInput>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<AuthUserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type VisitMaxFields = {
  __typename?: 'VisitMaxFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  checkedOutAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedOutById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "visit" */
export type VisitMaxOrderBy = {
  checkedInAt?: InputMaybe<OrderBy>;
  checkedInById?: InputMaybe<OrderBy>;
  checkedOutAt?: InputMaybe<OrderBy>;
  checkedOutById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type VisitMinFields = {
  __typename?: 'VisitMinFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  checkedOutAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedOutById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "visit" */
export type VisitMinOrderBy = {
  checkedInAt?: InputMaybe<OrderBy>;
  checkedInById?: InputMaybe<OrderBy>;
  checkedOutAt?: InputMaybe<OrderBy>;
  checkedOutById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visit" */
export type VisitMutationResponse = {
  __typename?: 'VisitMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Visit>;
};

/** input type for inserting object relation for remote table "visit" */
export type VisitObjRelInsertInput = {
  data: VisitInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<VisitOnConflict>;
};

/** on_conflict condition type for table "visit" */
export type VisitOnConflict = {
  constraint: VisitConstraint;
  updateColumns?: Array<VisitUpdateColumn>;
  where?: InputMaybe<VisitBoolExp>;
};

/** Ordering options when selecting data from "visit". */
export type VisitOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  checkedInAt?: InputMaybe<OrderBy>;
  checkedInBy?: InputMaybe<AuthUserOrderBy>;
  checkedInById?: InputMaybe<OrderBy>;
  checkedOutAt?: InputMaybe<OrderBy>;
  checkedOutById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  pauseAggregate?: InputMaybe<PauseAggregateOrderBy>;
  sum?: InputMaybe<OrderBy>;
  user?: InputMaybe<AuthUserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visit */
export type VisitPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "visit" */
export enum VisitSelectColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  CheckedOutAt = 'checkedOutAt',
  /** column name */
  CheckedOutById = 'checkedOutById',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sum = 'sum',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "visit" */
export type VisitSetInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  checkedOutAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedOutById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type VisitStddevFields = {
  __typename?: 'VisitStddevFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "visit" */
export type VisitStddevOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type VisitStddevPopFields = {
  __typename?: 'VisitStddevPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "visit" */
export type VisitStddevPopOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type VisitStddevSampFields = {
  __typename?: 'VisitStddevSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "visit" */
export type VisitStddevSampOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "visit" */
export type VisitStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitStreamCursorValueInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  checkedOutAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedOutById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type VisitSumFields = {
  __typename?: 'VisitSumFields';
  sum?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "visit" */
export type VisitSumOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** update columns of table "visit" */
export enum VisitUpdateColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  CheckedOutAt = 'checkedOutAt',
  /** column name */
  CheckedOutById = 'checkedOutById',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sum = 'sum',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type VisitUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitSetInput>;
  /** filter the rows which have to be updated */
  where: VisitBoolExp;
};

/** aggregate varPop on columns */
export type VisitVarPopFields = {
  __typename?: 'VisitVarPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "visit" */
export type VisitVarPopOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type VisitVarSampFields = {
  __typename?: 'VisitVarSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "visit" */
export type VisitVarSampOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type VisitVarianceFields = {
  __typename?: 'VisitVarianceFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "visit" */
export type VisitVarianceOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** columns and relationships of "ziferblat.address_i18n" */
export type ZiferblatAddressI18n = {
  __typename?: 'ZiferblatAddressI18n';
  building: Scalars['String']['output'];
  floor: Scalars['Int']['output'];
  howToGet?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  lang: ZiferblatLang;
  locale: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  street: Scalars['String']['output'];
  /** An object relationship */
  ziferblat?: Maybe<ZiferblatZiferblat>;
};

/** aggregated selection of "ziferblat.address_i18n" */
export type ZiferblatAddressI18nAggregate = {
  __typename?: 'ZiferblatAddressI18nAggregate';
  aggregate?: Maybe<ZiferblatAddressI18nAggregateFields>;
  nodes: Array<ZiferblatAddressI18n>;
};

export type ZiferblatAddressI18nAggregateBoolExp = {
  count?: InputMaybe<ZiferblatAddressI18nAggregateBoolExpCount>;
};

/** aggregate fields of "ziferblat.address_i18n" */
export type ZiferblatAddressI18nAggregateFields = {
  __typename?: 'ZiferblatAddressI18nAggregateFields';
  avg?: Maybe<ZiferblatAddressI18nAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ZiferblatAddressI18nMaxFields>;
  min?: Maybe<ZiferblatAddressI18nMinFields>;
  stddev?: Maybe<ZiferblatAddressI18nStddevFields>;
  stddevPop?: Maybe<ZiferblatAddressI18nStddevPopFields>;
  stddevSamp?: Maybe<ZiferblatAddressI18nStddevSampFields>;
  sum?: Maybe<ZiferblatAddressI18nSumFields>;
  varPop?: Maybe<ZiferblatAddressI18nVarPopFields>;
  varSamp?: Maybe<ZiferblatAddressI18nVarSampFields>;
  variance?: Maybe<ZiferblatAddressI18nVarianceFields>;
};


/** aggregate fields of "ziferblat.address_i18n" */
export type ZiferblatAddressI18nAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZiferblatAddressI18nSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nAggregateOrderBy = {
  avg?: InputMaybe<ZiferblatAddressI18nAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ZiferblatAddressI18nMaxOrderBy>;
  min?: InputMaybe<ZiferblatAddressI18nMinOrderBy>;
  stddev?: InputMaybe<ZiferblatAddressI18nStddevOrderBy>;
  stddevPop?: InputMaybe<ZiferblatAddressI18nStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ZiferblatAddressI18nStddevSampOrderBy>;
  sum?: InputMaybe<ZiferblatAddressI18nSumOrderBy>;
  varPop?: InputMaybe<ZiferblatAddressI18nVarPopOrderBy>;
  varSamp?: InputMaybe<ZiferblatAddressI18nVarSampOrderBy>;
  variance?: InputMaybe<ZiferblatAddressI18nVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nArrRelInsertInput = {
  data: Array<ZiferblatAddressI18nInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ZiferblatAddressI18nOnConflict>;
};

/** aggregate avg on columns */
export type ZiferblatAddressI18nAvgFields = {
  __typename?: 'ZiferblatAddressI18nAvgFields';
  floor?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nAvgOrderBy = {
  floor?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "ziferblat.address_i18n". All fields are combined with a logical 'AND'. */
export type ZiferblatAddressI18nBoolExp = {
  _and?: InputMaybe<Array<ZiferblatAddressI18nBoolExp>>;
  _not?: InputMaybe<ZiferblatAddressI18nBoolExp>;
  _or?: InputMaybe<Array<ZiferblatAddressI18nBoolExp>>;
  building?: InputMaybe<StringComparisonExp>;
  floor?: InputMaybe<IntComparisonExp>;
  howToGet?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lang?: InputMaybe<ZiferblatLangBoolExp>;
  locale?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  street?: InputMaybe<StringComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
};

/** unique or primary key constraints on table "ziferblat.address_i18n" */
export enum ZiferblatAddressI18nConstraint {
  /** unique or primary key constraint on columns "slug", "locale" */
  AddressI18nLocaleSlugKey = 'address_i18n_locale_slug_key',
  /** unique or primary key constraint on columns "id" */
  AddressI18nPkey = 'address_i18n_pkey'
}

/** input type for incrementing numeric columns in table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nIncInput = {
  floor?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nInsertInput = {
  building?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['Int']['input']>;
  howToGet?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lang?: InputMaybe<ZiferblatLangObjRelInsertInput>;
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
};

/** aggregate max on columns */
export type ZiferblatAddressI18nMaxFields = {
  __typename?: 'ZiferblatAddressI18nMaxFields';
  building?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['Int']['output']>;
  howToGet?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nMaxOrderBy = {
  building?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  howToGet?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  street?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ZiferblatAddressI18nMinFields = {
  __typename?: 'ZiferblatAddressI18nMinFields';
  building?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['Int']['output']>;
  howToGet?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nMinOrderBy = {
  building?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  howToGet?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  street?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nMutationResponse = {
  __typename?: 'ZiferblatAddressI18nMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ZiferblatAddressI18n>;
};

/** on_conflict condition type for table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nOnConflict = {
  constraint: ZiferblatAddressI18nConstraint;
  updateColumns?: Array<ZiferblatAddressI18nUpdateColumn>;
  where?: InputMaybe<ZiferblatAddressI18nBoolExp>;
};

/** Ordering options when selecting data from "ziferblat.address_i18n". */
export type ZiferblatAddressI18nOrderBy = {
  building?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  howToGet?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<ZiferblatLangOrderBy>;
  locale?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  street?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
};

/** primary key columns input for table: ziferblat.address_i18n */
export type ZiferblatAddressI18nPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "ziferblat.address_i18n" */
export enum ZiferblatAddressI18nSelectColumn {
  /** column name */
  Building = 'building',
  /** column name */
  Floor = 'floor',
  /** column name */
  HowToGet = 'howToGet',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  Slug = 'slug',
  /** column name */
  Street = 'street'
}

/** input type for updating data in table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nSetInput = {
  building?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['Int']['input']>;
  howToGet?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ZiferblatAddressI18nStddevFields = {
  __typename?: 'ZiferblatAddressI18nStddevFields';
  floor?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nStddevOrderBy = {
  floor?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ZiferblatAddressI18nStddevPopFields = {
  __typename?: 'ZiferblatAddressI18nStddevPopFields';
  floor?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nStddevPopOrderBy = {
  floor?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ZiferblatAddressI18nStddevSampFields = {
  __typename?: 'ZiferblatAddressI18nStddevSampFields';
  floor?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nStddevSampOrderBy = {
  floor?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "ziferblat_address_i18n" */
export type ZiferblatAddressI18nStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZiferblatAddressI18nStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZiferblatAddressI18nStreamCursorValueInput = {
  building?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['Int']['input']>;
  howToGet?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ZiferblatAddressI18nSumFields = {
  __typename?: 'ZiferblatAddressI18nSumFields';
  floor?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nSumOrderBy = {
  floor?: InputMaybe<OrderBy>;
};

/** update columns of table "ziferblat.address_i18n" */
export enum ZiferblatAddressI18nUpdateColumn {
  /** column name */
  Building = 'building',
  /** column name */
  Floor = 'floor',
  /** column name */
  HowToGet = 'howToGet',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  Slug = 'slug',
  /** column name */
  Street = 'street'
}

export type ZiferblatAddressI18nUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZiferblatAddressI18nIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZiferblatAddressI18nSetInput>;
  /** filter the rows which have to be updated */
  where: ZiferblatAddressI18nBoolExp;
};

/** aggregate varPop on columns */
export type ZiferblatAddressI18nVarPopFields = {
  __typename?: 'ZiferblatAddressI18nVarPopFields';
  floor?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nVarPopOrderBy = {
  floor?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ZiferblatAddressI18nVarSampFields = {
  __typename?: 'ZiferblatAddressI18nVarSampFields';
  floor?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nVarSampOrderBy = {
  floor?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ZiferblatAddressI18nVarianceFields = {
  __typename?: 'ZiferblatAddressI18nVarianceFields';
  floor?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "ziferblat.address_i18n" */
export type ZiferblatAddressI18nVarianceOrderBy = {
  floor?: InputMaybe<OrderBy>;
};

/** This is also used as a source for city slugs required for website */
export type ZiferblatCityI18n = {
  __typename?: 'ZiferblatCityI18n';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  locale: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  /** An array relationship */
  ziferblats: Array<ZiferblatZiferblat>;
  /** An aggregate relationship */
  ziferblatsAggregate: ZiferblatZiferblatAggregate;
};


/** This is also used as a source for city slugs required for website */
export type ZiferblatCityI18nZiferblatsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};


/** This is also used as a source for city slugs required for website */
export type ZiferblatCityI18nZiferblatsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};

/** aggregated selection of "ziferblat.city_i18n" */
export type ZiferblatCityI18nAggregate = {
  __typename?: 'ZiferblatCityI18nAggregate';
  aggregate?: Maybe<ZiferblatCityI18nAggregateFields>;
  nodes: Array<ZiferblatCityI18n>;
};

export type ZiferblatCityI18nAggregateBoolExp = {
  count?: InputMaybe<ZiferblatCityI18nAggregateBoolExpCount>;
};

/** aggregate fields of "ziferblat.city_i18n" */
export type ZiferblatCityI18nAggregateFields = {
  __typename?: 'ZiferblatCityI18nAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ZiferblatCityI18nMaxFields>;
  min?: Maybe<ZiferblatCityI18nMinFields>;
};


/** aggregate fields of "ziferblat.city_i18n" */
export type ZiferblatCityI18nAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZiferblatCityI18nSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ziferblat.city_i18n" */
export type ZiferblatCityI18nAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ZiferblatCityI18nMaxOrderBy>;
  min?: InputMaybe<ZiferblatCityI18nMinOrderBy>;
};

/** input type for inserting array relation for remote table "ziferblat.city_i18n" */
export type ZiferblatCityI18nArrRelInsertInput = {
  data: Array<ZiferblatCityI18nInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ZiferblatCityI18nOnConflict>;
};

/** Boolean expression to filter rows from the table "ziferblat.city_i18n". All fields are combined with a logical 'AND'. */
export type ZiferblatCityI18nBoolExp = {
  _and?: InputMaybe<Array<ZiferblatCityI18nBoolExp>>;
  _not?: InputMaybe<ZiferblatCityI18nBoolExp>;
  _or?: InputMaybe<Array<ZiferblatCityI18nBoolExp>>;
  city?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  locale?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  ziferblats?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatsAggregate?: InputMaybe<ZiferblatZiferblatAggregateBoolExp>;
};

/** unique or primary key constraints on table "ziferblat.city_i18n" */
export enum ZiferblatCityI18nConstraint {
  /** unique or primary key constraint on columns "id" */
  CityI18nPkey = 'city_i18n_pkey',
  /** unique or primary key constraint on columns "slug", "locale" */
  CityI18nSlugLocaleKey = 'city_i18n_slug_locale_key'
}

/** input type for inserting data into table "ziferblat.city_i18n" */
export type ZiferblatCityI18nInsertInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  ziferblats?: InputMaybe<ZiferblatZiferblatArrRelInsertInput>;
};

/** aggregate max on columns */
export type ZiferblatCityI18nMaxFields = {
  __typename?: 'ZiferblatCityI18nMaxFields';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "ziferblat.city_i18n" */
export type ZiferblatCityI18nMaxOrderBy = {
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ZiferblatCityI18nMinFields = {
  __typename?: 'ZiferblatCityI18nMinFields';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "ziferblat.city_i18n" */
export type ZiferblatCityI18nMinOrderBy = {
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "ziferblat.city_i18n" */
export type ZiferblatCityI18nMutationResponse = {
  __typename?: 'ZiferblatCityI18nMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ZiferblatCityI18n>;
};

/** on_conflict condition type for table "ziferblat.city_i18n" */
export type ZiferblatCityI18nOnConflict = {
  constraint: ZiferblatCityI18nConstraint;
  updateColumns?: Array<ZiferblatCityI18nUpdateColumn>;
  where?: InputMaybe<ZiferblatCityI18nBoolExp>;
};

/** Ordering options when selecting data from "ziferblat.city_i18n". */
export type ZiferblatCityI18nOrderBy = {
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  ziferblatsAggregate?: InputMaybe<ZiferblatZiferblatAggregateOrderBy>;
};

/** primary key columns input for table: ziferblat.city_i18n */
export type ZiferblatCityI18nPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "ziferblat.city_i18n" */
export enum ZiferblatCityI18nSelectColumn {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "ziferblat.city_i18n" */
export type ZiferblatCityI18nSetInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "ziferblat_city_i18n" */
export type ZiferblatCityI18nStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZiferblatCityI18nStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZiferblatCityI18nStreamCursorValueInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "ziferblat.city_i18n" */
export enum ZiferblatCityI18nUpdateColumn {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  Slug = 'slug'
}

export type ZiferblatCityI18nUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZiferblatCityI18nSetInput>;
  /** filter the rows which have to be updated */
  where: ZiferblatCityI18nBoolExp;
};

/** columns and relationships of "ziferblat.contact" */
export type ZiferblatContact = {
  __typename?: 'ZiferblatContact';
  addedById: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};

/** aggregated selection of "ziferblat.contact" */
export type ZiferblatContactAggregate = {
  __typename?: 'ZiferblatContactAggregate';
  aggregate?: Maybe<ZiferblatContactAggregateFields>;
  nodes: Array<ZiferblatContact>;
};

export type ZiferblatContactAggregateBoolExp = {
  count?: InputMaybe<ZiferblatContactAggregateBoolExpCount>;
};

/** aggregate fields of "ziferblat.contact" */
export type ZiferblatContactAggregateFields = {
  __typename?: 'ZiferblatContactAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ZiferblatContactMaxFields>;
  min?: Maybe<ZiferblatContactMinFields>;
};


/** aggregate fields of "ziferblat.contact" */
export type ZiferblatContactAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZiferblatContactSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ziferblat.contact" */
export type ZiferblatContactAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ZiferblatContactMaxOrderBy>;
  min?: InputMaybe<ZiferblatContactMinOrderBy>;
};

/** input type for inserting array relation for remote table "ziferblat.contact" */
export type ZiferblatContactArrRelInsertInput = {
  data: Array<ZiferblatContactInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ZiferblatContactOnConflict>;
};

/** Boolean expression to filter rows from the table "ziferblat.contact". All fields are combined with a logical 'AND'. */
export type ZiferblatContactBoolExp = {
  _and?: InputMaybe<Array<ZiferblatContactBoolExp>>;
  _not?: InputMaybe<ZiferblatContactBoolExp>;
  _or?: InputMaybe<Array<ZiferblatContactBoolExp>>;
  addedById?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ziferblat.contact" */
export enum ZiferblatContactConstraint {
  /** unique or primary key constraint on columns "id" */
  ContactPkey = 'contact_pkey'
}

/** input type for inserting data into table "ziferblat.contact" */
export type ZiferblatContactInsertInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ZiferblatContactMaxFields = {
  __typename?: 'ZiferblatContactMaxFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "ziferblat.contact" */
export type ZiferblatContactMaxOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ZiferblatContactMinFields = {
  __typename?: 'ZiferblatContactMinFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "ziferblat.contact" */
export type ZiferblatContactMinOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "ziferblat.contact" */
export type ZiferblatContactMutationResponse = {
  __typename?: 'ZiferblatContactMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ZiferblatContact>;
};

/** on_conflict condition type for table "ziferblat.contact" */
export type ZiferblatContactOnConflict = {
  constraint: ZiferblatContactConstraint;
  updateColumns?: Array<ZiferblatContactUpdateColumn>;
  where?: InputMaybe<ZiferblatContactBoolExp>;
};

/** Ordering options when selecting data from "ziferblat.contact". */
export type ZiferblatContactOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ziferblat.contact */
export type ZiferblatContactPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "ziferblat.contact" */
export enum ZiferblatContactSelectColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "ziferblat.contact" */
export type ZiferblatContactSetInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "ziferblat_contact" */
export type ZiferblatContactStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZiferblatContactStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZiferblatContactStreamCursorValueInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "ziferblat.contact" */
export enum ZiferblatContactUpdateColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type ZiferblatContactUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZiferblatContactSetInput>;
  /** filter the rows which have to be updated */
  where: ZiferblatContactBoolExp;
};

/** columns and relationships of "ziferblat.gallery" */
export type ZiferblatGallery = {
  __typename?: 'ZiferblatGallery';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  addedById: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  imageId: Scalars['uuid']['output'];
  location: Scalars['String']['output'];
  /** An object relationship */
  media: Media;
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "ziferblat.gallery" */
export type ZiferblatGalleryAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "ziferblat.gallery" */
export type ZiferblatGalleryAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};

/** aggregated selection of "ziferblat.gallery" */
export type ZiferblatGalleryAggregate = {
  __typename?: 'ZiferblatGalleryAggregate';
  aggregate?: Maybe<ZiferblatGalleryAggregateFields>;
  nodes: Array<ZiferblatGallery>;
};

export type ZiferblatGalleryAggregateBoolExp = {
  count?: InputMaybe<ZiferblatGalleryAggregateBoolExpCount>;
};

/** aggregate fields of "ziferblat.gallery" */
export type ZiferblatGalleryAggregateFields = {
  __typename?: 'ZiferblatGalleryAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ZiferblatGalleryMaxFields>;
  min?: Maybe<ZiferblatGalleryMinFields>;
};


/** aggregate fields of "ziferblat.gallery" */
export type ZiferblatGalleryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZiferblatGallerySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ziferblat.gallery" */
export type ZiferblatGalleryAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ZiferblatGalleryMaxOrderBy>;
  min?: InputMaybe<ZiferblatGalleryMinOrderBy>;
};

/** input type for inserting array relation for remote table "ziferblat.gallery" */
export type ZiferblatGalleryArrRelInsertInput = {
  data: Array<ZiferblatGalleryInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ZiferblatGalleryOnConflict>;
};

/** Boolean expression to filter rows from the table "ziferblat.gallery". All fields are combined with a logical 'AND'. */
export type ZiferblatGalleryBoolExp = {
  _and?: InputMaybe<Array<ZiferblatGalleryBoolExp>>;
  _not?: InputMaybe<ZiferblatGalleryBoolExp>;
  _or?: InputMaybe<Array<ZiferblatGalleryBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  addedById?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageId?: InputMaybe<UuidComparisonExp>;
  location?: InputMaybe<StringComparisonExp>;
  media?: InputMaybe<MediaBoolExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ziferblat.gallery" */
export enum ZiferblatGalleryConstraint {
  /** unique or primary key constraint on columns "id" */
  GalleryPkey = 'gallery_pkey',
  /** unique or primary key constraint on columns "ziferblat_id", "image_id" */
  GalleryZiferblatIdImageIdKey = 'gallery_ziferblat_id_image_id_key'
}

/** input type for inserting data into table "ziferblat.gallery" */
export type ZiferblatGalleryInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  imageId?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<MediaObjRelInsertInput>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ZiferblatGalleryMaxFields = {
  __typename?: 'ZiferblatGalleryMaxFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  imageId?: Maybe<Scalars['uuid']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "ziferblat.gallery" */
export type ZiferblatGalleryMaxOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageId?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ZiferblatGalleryMinFields = {
  __typename?: 'ZiferblatGalleryMinFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  imageId?: Maybe<Scalars['uuid']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "ziferblat.gallery" */
export type ZiferblatGalleryMinOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageId?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "ziferblat.gallery" */
export type ZiferblatGalleryMutationResponse = {
  __typename?: 'ZiferblatGalleryMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ZiferblatGallery>;
};

/** on_conflict condition type for table "ziferblat.gallery" */
export type ZiferblatGalleryOnConflict = {
  constraint: ZiferblatGalleryConstraint;
  updateColumns?: Array<ZiferblatGalleryUpdateColumn>;
  where?: InputMaybe<ZiferblatGalleryBoolExp>;
};

/** Ordering options when selecting data from "ziferblat.gallery". */
export type ZiferblatGalleryOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageId?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  media?: InputMaybe<MediaOrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ziferblat.gallery */
export type ZiferblatGalleryPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "ziferblat.gallery" */
export enum ZiferblatGallerySelectColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  Location = 'location',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "ziferblat.gallery" */
export type ZiferblatGallerySetInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  imageId?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "ziferblat_gallery" */
export type ZiferblatGalleryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZiferblatGalleryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZiferblatGalleryStreamCursorValueInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  imageId?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "ziferblat.gallery" */
export enum ZiferblatGalleryUpdateColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  Location = 'location',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type ZiferblatGalleryUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZiferblatGallerySetInput>;
  /** filter the rows which have to be updated */
  where: ZiferblatGalleryBoolExp;
};

/** columns and relationships of "ziferblat.juridical_i18n" */
export type ZiferblatJuridicalI18n = {
  __typename?: 'ZiferblatJuridicalI18n';
  address?: Maybe<Scalars['String']['output']>;
  confidentiality?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  lang: Scalars['String']['output'];
  name: Scalars['String']['output'];
  oferta?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Scalars['String']['output']>;
  tin: Scalars['String']['output'];
  ziferblatId: Scalars['uuid']['output'];
};

/** aggregated selection of "ziferblat.juridical_i18n" */
export type ZiferblatJuridicalI18nAggregate = {
  __typename?: 'ZiferblatJuridicalI18nAggregate';
  aggregate?: Maybe<ZiferblatJuridicalI18nAggregateFields>;
  nodes: Array<ZiferblatJuridicalI18n>;
};

/** aggregate fields of "ziferblat.juridical_i18n" */
export type ZiferblatJuridicalI18nAggregateFields = {
  __typename?: 'ZiferblatJuridicalI18nAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ZiferblatJuridicalI18nMaxFields>;
  min?: Maybe<ZiferblatJuridicalI18nMinFields>;
};


/** aggregate fields of "ziferblat.juridical_i18n" */
export type ZiferblatJuridicalI18nAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZiferblatJuridicalI18nSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "ziferblat.juridical_i18n". All fields are combined with a logical 'AND'. */
export type ZiferblatJuridicalI18nBoolExp = {
  _and?: InputMaybe<Array<ZiferblatJuridicalI18nBoolExp>>;
  _not?: InputMaybe<ZiferblatJuridicalI18nBoolExp>;
  _or?: InputMaybe<Array<ZiferblatJuridicalI18nBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  confidentiality?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lang?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  oferta?: InputMaybe<StringComparisonExp>;
  payment?: InputMaybe<StringComparisonExp>;
  tin?: InputMaybe<StringComparisonExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ziferblat.juridical_i18n" */
export enum ZiferblatJuridicalI18nConstraint {
  /** unique or primary key constraint on columns "id" */
  JuridicalI18nPkey = 'juridical_i18n_pkey'
}

/** input type for inserting data into table "ziferblat.juridical_i18n" */
export type ZiferblatJuridicalI18nInsertInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  confidentiality?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oferta?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<Scalars['String']['input']>;
  tin?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ZiferblatJuridicalI18nMaxFields = {
  __typename?: 'ZiferblatJuridicalI18nMaxFields';
  address?: Maybe<Scalars['String']['output']>;
  confidentiality?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  oferta?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Scalars['String']['output']>;
  tin?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ZiferblatJuridicalI18nMinFields = {
  __typename?: 'ZiferblatJuridicalI18nMinFields';
  address?: Maybe<Scalars['String']['output']>;
  confidentiality?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  oferta?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Scalars['String']['output']>;
  tin?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "ziferblat.juridical_i18n" */
export type ZiferblatJuridicalI18nMutationResponse = {
  __typename?: 'ZiferblatJuridicalI18nMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ZiferblatJuridicalI18n>;
};

/** on_conflict condition type for table "ziferblat.juridical_i18n" */
export type ZiferblatJuridicalI18nOnConflict = {
  constraint: ZiferblatJuridicalI18nConstraint;
  updateColumns?: Array<ZiferblatJuridicalI18nUpdateColumn>;
  where?: InputMaybe<ZiferblatJuridicalI18nBoolExp>;
};

/** Ordering options when selecting data from "ziferblat.juridical_i18n". */
export type ZiferblatJuridicalI18nOrderBy = {
  address?: InputMaybe<OrderBy>;
  confidentiality?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oferta?: InputMaybe<OrderBy>;
  payment?: InputMaybe<OrderBy>;
  tin?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ziferblat.juridical_i18n */
export type ZiferblatJuridicalI18nPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "ziferblat.juridical_i18n" */
export enum ZiferblatJuridicalI18nSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  Confidentiality = 'confidentiality',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  Oferta = 'oferta',
  /** column name */
  Payment = 'payment',
  /** column name */
  Tin = 'tin',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "ziferblat.juridical_i18n" */
export type ZiferblatJuridicalI18nSetInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  confidentiality?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oferta?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<Scalars['String']['input']>;
  tin?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "ziferblat_juridical_i18n" */
export type ZiferblatJuridicalI18nStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZiferblatJuridicalI18nStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZiferblatJuridicalI18nStreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  confidentiality?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oferta?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<Scalars['String']['input']>;
  tin?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "ziferblat.juridical_i18n" */
export enum ZiferblatJuridicalI18nUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  Confidentiality = 'confidentiality',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  Oferta = 'oferta',
  /** column name */
  Payment = 'payment',
  /** column name */
  Tin = 'tin',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type ZiferblatJuridicalI18nUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZiferblatJuridicalI18nSetInput>;
  /** filter the rows which have to be updated */
  where: ZiferblatJuridicalI18nBoolExp;
};

/** columns and relationships of "ziferblat.lang" */
export type ZiferblatLang = {
  __typename?: 'ZiferblatLang';
  comment: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "ziferblat.lang" */
export type ZiferblatLangAggregate = {
  __typename?: 'ZiferblatLangAggregate';
  aggregate?: Maybe<ZiferblatLangAggregateFields>;
  nodes: Array<ZiferblatLang>;
};

/** aggregate fields of "ziferblat.lang" */
export type ZiferblatLangAggregateFields = {
  __typename?: 'ZiferblatLangAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ZiferblatLangMaxFields>;
  min?: Maybe<ZiferblatLangMinFields>;
};


/** aggregate fields of "ziferblat.lang" */
export type ZiferblatLangAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZiferblatLangSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "ziferblat.lang". All fields are combined with a logical 'AND'. */
export type ZiferblatLangBoolExp = {
  _and?: InputMaybe<Array<ZiferblatLangBoolExp>>;
  _not?: InputMaybe<ZiferblatLangBoolExp>;
  _or?: InputMaybe<Array<ZiferblatLangBoolExp>>;
  comment?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "ziferblat.lang" */
export enum ZiferblatLangConstraint {
  /** unique or primary key constraint on columns "value" */
  LangPkey = 'lang_pkey'
}

/** input type for inserting data into table "ziferblat.lang" */
export type ZiferblatLangInsertInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ZiferblatLangMaxFields = {
  __typename?: 'ZiferblatLangMaxFields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ZiferblatLangMinFields = {
  __typename?: 'ZiferblatLangMinFields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ziferblat.lang" */
export type ZiferblatLangMutationResponse = {
  __typename?: 'ZiferblatLangMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ZiferblatLang>;
};

/** input type for inserting object relation for remote table "ziferblat.lang" */
export type ZiferblatLangObjRelInsertInput = {
  data: ZiferblatLangInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ZiferblatLangOnConflict>;
};

/** on_conflict condition type for table "ziferblat.lang" */
export type ZiferblatLangOnConflict = {
  constraint: ZiferblatLangConstraint;
  updateColumns?: Array<ZiferblatLangUpdateColumn>;
  where?: InputMaybe<ZiferblatLangBoolExp>;
};

/** Ordering options when selecting data from "ziferblat.lang". */
export type ZiferblatLangOrderBy = {
  comment?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ziferblat.lang */
export type ZiferblatLangPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "ziferblat.lang" */
export enum ZiferblatLangSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ziferblat.lang" */
export type ZiferblatLangSetInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "ziferblat_lang" */
export type ZiferblatLangStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZiferblatLangStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZiferblatLangStreamCursorValueInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "ziferblat.lang" */
export enum ZiferblatLangUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type ZiferblatLangUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZiferblatLangSetInput>;
  /** filter the rows which have to be updated */
  where: ZiferblatLangBoolExp;
};

/** columns and relationships of "ziferblat.room" */
export type ZiferblatRoom = {
  __typename?: 'ZiferblatRoom';
  area: Scalars['Int']['output'];
  capacity: Scalars['Int']['output'];
  cost: Scalars['Int']['output'];
  createdAt: Scalars['timestamptz']['output'];
  createdById: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  ziferblatId: Scalars['uuid']['output'];
};

/** aggregated selection of "ziferblat.room" */
export type ZiferblatRoomAggregate = {
  __typename?: 'ZiferblatRoomAggregate';
  aggregate?: Maybe<ZiferblatRoomAggregateFields>;
  nodes: Array<ZiferblatRoom>;
};

/** aggregate fields of "ziferblat.room" */
export type ZiferblatRoomAggregateFields = {
  __typename?: 'ZiferblatRoomAggregateFields';
  avg?: Maybe<ZiferblatRoomAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ZiferblatRoomMaxFields>;
  min?: Maybe<ZiferblatRoomMinFields>;
  stddev?: Maybe<ZiferblatRoomStddevFields>;
  stddevPop?: Maybe<ZiferblatRoomStddevPopFields>;
  stddevSamp?: Maybe<ZiferblatRoomStddevSampFields>;
  sum?: Maybe<ZiferblatRoomSumFields>;
  varPop?: Maybe<ZiferblatRoomVarPopFields>;
  varSamp?: Maybe<ZiferblatRoomVarSampFields>;
  variance?: Maybe<ZiferblatRoomVarianceFields>;
};


/** aggregate fields of "ziferblat.room" */
export type ZiferblatRoomAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZiferblatRoomSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ZiferblatRoomAvgFields = {
  __typename?: 'ZiferblatRoomAvgFields';
  area?: Maybe<Scalars['Float']['output']>;
  capacity?: Maybe<Scalars['Float']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ziferblat.room". All fields are combined with a logical 'AND'. */
export type ZiferblatRoomBoolExp = {
  _and?: InputMaybe<Array<ZiferblatRoomBoolExp>>;
  _not?: InputMaybe<ZiferblatRoomBoolExp>;
  _or?: InputMaybe<Array<ZiferblatRoomBoolExp>>;
  area?: InputMaybe<IntComparisonExp>;
  capacity?: InputMaybe<IntComparisonExp>;
  cost?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ziferblat.room" */
export enum ZiferblatRoomConstraint {
  /** unique or primary key constraint on columns "id" */
  RoomPkey = 'room_pkey'
}

/** columns and relationships of "ziferblat.room_i18n" */
export type ZiferblatRoomI18n = {
  __typename?: 'ZiferblatRoomI18n';
  createdAt: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  inputById: Scalars['uuid']['output'];
  lang: Scalars['String']['output'];
  name: Scalars['String']['output'];
  roomId: Scalars['uuid']['output'];
};

/** aggregated selection of "ziferblat.room_i18n" */
export type ZiferblatRoomI18nAggregate = {
  __typename?: 'ZiferblatRoomI18nAggregate';
  aggregate?: Maybe<ZiferblatRoomI18nAggregateFields>;
  nodes: Array<ZiferblatRoomI18n>;
};

/** aggregate fields of "ziferblat.room_i18n" */
export type ZiferblatRoomI18nAggregateFields = {
  __typename?: 'ZiferblatRoomI18nAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ZiferblatRoomI18nMaxFields>;
  min?: Maybe<ZiferblatRoomI18nMinFields>;
};


/** aggregate fields of "ziferblat.room_i18n" */
export type ZiferblatRoomI18nAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZiferblatRoomI18nSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "ziferblat.room_i18n". All fields are combined with a logical 'AND'. */
export type ZiferblatRoomI18nBoolExp = {
  _and?: InputMaybe<Array<ZiferblatRoomI18nBoolExp>>;
  _not?: InputMaybe<ZiferblatRoomI18nBoolExp>;
  _or?: InputMaybe<Array<ZiferblatRoomI18nBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inputById?: InputMaybe<UuidComparisonExp>;
  lang?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  roomId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ziferblat.room_i18n" */
export enum ZiferblatRoomI18nConstraint {
  /** unique or primary key constraint on columns "id" */
  RoomI18nPkey = 'room_i18n_pkey',
  /** unique or primary key constraint on columns "lang", "room_id" */
  RoomI18nRoomIdLangKey = 'room_i18n_room_id_lang_key'
}

/** input type for inserting data into table "ziferblat.room_i18n" */
export type ZiferblatRoomI18nInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputById?: InputMaybe<Scalars['uuid']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ZiferblatRoomI18nMaxFields = {
  __typename?: 'ZiferblatRoomI18nMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  inputById?: Maybe<Scalars['uuid']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ZiferblatRoomI18nMinFields = {
  __typename?: 'ZiferblatRoomI18nMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  inputById?: Maybe<Scalars['uuid']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "ziferblat.room_i18n" */
export type ZiferblatRoomI18nMutationResponse = {
  __typename?: 'ZiferblatRoomI18nMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ZiferblatRoomI18n>;
};

/** on_conflict condition type for table "ziferblat.room_i18n" */
export type ZiferblatRoomI18nOnConflict = {
  constraint: ZiferblatRoomI18nConstraint;
  updateColumns?: Array<ZiferblatRoomI18nUpdateColumn>;
  where?: InputMaybe<ZiferblatRoomI18nBoolExp>;
};

/** Ordering options when selecting data from "ziferblat.room_i18n". */
export type ZiferblatRoomI18nOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inputById?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  roomId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ziferblat.room_i18n */
export type ZiferblatRoomI18nPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "ziferblat.room_i18n" */
export enum ZiferblatRoomI18nSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InputById = 'inputById',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  RoomId = 'roomId'
}

/** input type for updating data in table "ziferblat.room_i18n" */
export type ZiferblatRoomI18nSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputById?: InputMaybe<Scalars['uuid']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "ziferblat_room_i18n" */
export type ZiferblatRoomI18nStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZiferblatRoomI18nStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZiferblatRoomI18nStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputById?: InputMaybe<Scalars['uuid']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "ziferblat.room_i18n" */
export enum ZiferblatRoomI18nUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InputById = 'inputById',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  RoomId = 'roomId'
}

export type ZiferblatRoomI18nUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZiferblatRoomI18nSetInput>;
  /** filter the rows which have to be updated */
  where: ZiferblatRoomI18nBoolExp;
};

/** input type for incrementing numeric columns in table "ziferblat.room" */
export type ZiferblatRoomIncInput = {
  area?: InputMaybe<Scalars['Int']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  cost?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "ziferblat.room" */
export type ZiferblatRoomInsertInput = {
  area?: InputMaybe<Scalars['Int']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ZiferblatRoomMaxFields = {
  __typename?: 'ZiferblatRoomMaxFields';
  area?: Maybe<Scalars['Int']['output']>;
  capacity?: Maybe<Scalars['Int']['output']>;
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ZiferblatRoomMinFields = {
  __typename?: 'ZiferblatRoomMinFields';
  area?: Maybe<Scalars['Int']['output']>;
  capacity?: Maybe<Scalars['Int']['output']>;
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "ziferblat.room" */
export type ZiferblatRoomMutationResponse = {
  __typename?: 'ZiferblatRoomMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ZiferblatRoom>;
};

/** on_conflict condition type for table "ziferblat.room" */
export type ZiferblatRoomOnConflict = {
  constraint: ZiferblatRoomConstraint;
  updateColumns?: Array<ZiferblatRoomUpdateColumn>;
  where?: InputMaybe<ZiferblatRoomBoolExp>;
};

/** Ordering options when selecting data from "ziferblat.room". */
export type ZiferblatRoomOrderBy = {
  area?: InputMaybe<OrderBy>;
  capacity?: InputMaybe<OrderBy>;
  cost?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ziferblat.room */
export type ZiferblatRoomPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "ziferblat.room" */
export enum ZiferblatRoomSelectColumn {
  /** column name */
  Area = 'area',
  /** column name */
  Capacity = 'capacity',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  Id = 'id',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "ziferblat.room" */
export type ZiferblatRoomSetInput = {
  area?: InputMaybe<Scalars['Int']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type ZiferblatRoomStddevFields = {
  __typename?: 'ZiferblatRoomStddevFields';
  area?: Maybe<Scalars['Float']['output']>;
  capacity?: Maybe<Scalars['Float']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type ZiferblatRoomStddevPopFields = {
  __typename?: 'ZiferblatRoomStddevPopFields';
  area?: Maybe<Scalars['Float']['output']>;
  capacity?: Maybe<Scalars['Float']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type ZiferblatRoomStddevSampFields = {
  __typename?: 'ZiferblatRoomStddevSampFields';
  area?: Maybe<Scalars['Float']['output']>;
  capacity?: Maybe<Scalars['Float']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ziferblat_room" */
export type ZiferblatRoomStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZiferblatRoomStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZiferblatRoomStreamCursorValueInput = {
  area?: InputMaybe<Scalars['Int']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type ZiferblatRoomSumFields = {
  __typename?: 'ZiferblatRoomSumFields';
  area?: Maybe<Scalars['Int']['output']>;
  capacity?: Maybe<Scalars['Int']['output']>;
  cost?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "ziferblat.room" */
export enum ZiferblatRoomUpdateColumn {
  /** column name */
  Area = 'area',
  /** column name */
  Capacity = 'capacity',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  Id = 'id',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type ZiferblatRoomUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZiferblatRoomIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZiferblatRoomSetInput>;
  /** filter the rows which have to be updated */
  where: ZiferblatRoomBoolExp;
};

/** aggregate varPop on columns */
export type ZiferblatRoomVarPopFields = {
  __typename?: 'ZiferblatRoomVarPopFields';
  area?: Maybe<Scalars['Float']['output']>;
  capacity?: Maybe<Scalars['Float']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type ZiferblatRoomVarSampFields = {
  __typename?: 'ZiferblatRoomVarSampFields';
  area?: Maybe<Scalars['Float']['output']>;
  capacity?: Maybe<Scalars['Float']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ZiferblatRoomVarianceFields = {
  __typename?: 'ZiferblatRoomVarianceFields';
  area?: Maybe<Scalars['Float']['output']>;
  capacity?: Maybe<Scalars['Float']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblat = {
  __typename?: 'ZiferblatZiferblat';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  activeFrom: Scalars['timestamptz']['output'];
  addressSlug: Scalars['String']['output'];
  avatarUrl: Scalars['String']['output'];
  /** An array relationship */
  city: Array<ZiferblatCityI18n>;
  /** An aggregate relationship */
  cityAggregate: ZiferblatCityI18nAggregate;
  citySlug: Scalars['String']['output'];
  /** An array relationship */
  contacts: Array<ZiferblatContact>;
  /** An aggregate relationship */
  contactsAggregate: ZiferblatContactAggregate;
  /** An array relationship */
  culturalEvents: Array<CulturalEvent>;
  /** An aggregate relationship */
  culturalEventsAggregate: CulturalEventAggregate;
  /** An array relationship */
  gallery: Array<ZiferblatGallery>;
  /** An aggregate relationship */
  galleryAggregate: ZiferblatGalleryAggregate;
  /** An array relationship */
  i18n: Array<ZiferblatZiferblatI18n>;
  /** An aggregate relationship */
  i18nAggregate: ZiferblatZiferblatI18nAggregate;
  id: Scalars['uuid']['output'];
  oldId?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  passes: Array<TariffPass>;
  /** An aggregate relationship */
  passesAggregate: TariffPassAggregate;
  /** An array relationship */
  streetAddress: Array<ZiferblatAddressI18n>;
  /** An aggregate relationship */
  streetAddressAggregate: ZiferblatAddressI18nAggregate;
  /** An array relationship */
  tariffs: Array<TariffTariff>;
  /** An aggregate relationship */
  tariffsAggregate: TariffTariffAggregate;
  /** An array relationship */
  visits: Array<Visit>;
  /** An aggregate relationship */
  visitsAggregate: VisitAggregate;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatCityArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatCityI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatCityI18nOrderBy>>;
  where?: InputMaybe<ZiferblatCityI18nBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatCityAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatCityI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatCityI18nOrderBy>>;
  where?: InputMaybe<ZiferblatCityI18nBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatContactsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatContactOrderBy>>;
  where?: InputMaybe<ZiferblatContactBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatContactsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatContactOrderBy>>;
  where?: InputMaybe<ZiferblatContactBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatCulturalEventsArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventOrderBy>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatCulturalEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventOrderBy>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatGalleryArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatGallerySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatGalleryOrderBy>>;
  where?: InputMaybe<ZiferblatGalleryBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatGalleryAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatGallerySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatGalleryOrderBy>>;
  where?: InputMaybe<ZiferblatGalleryBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatI18nArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatI18nOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatI18nBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatI18nOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatI18nBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatPassesArgs = {
  distinctOn?: InputMaybe<Array<TariffPassSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassOrderBy>>;
  where?: InputMaybe<TariffPassBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatPassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffPassSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassOrderBy>>;
  where?: InputMaybe<TariffPassBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatStreetAddressArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatAddressI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatAddressI18nOrderBy>>;
  where?: InputMaybe<ZiferblatAddressI18nBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatStreetAddressAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatAddressI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatAddressI18nOrderBy>>;
  where?: InputMaybe<ZiferblatAddressI18nBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatTariffsArgs = {
  distinctOn?: InputMaybe<Array<TariffTariffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffTariffOrderBy>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatTariffsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffTariffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffTariffOrderBy>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};


/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblatVisitsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** aggregated selection of "ziferblat.ziferblat" */
export type ZiferblatZiferblatAggregate = {
  __typename?: 'ZiferblatZiferblatAggregate';
  aggregate?: Maybe<ZiferblatZiferblatAggregateFields>;
  nodes: Array<ZiferblatZiferblat>;
};

export type ZiferblatZiferblatAggregateBoolExp = {
  count?: InputMaybe<ZiferblatZiferblatAggregateBoolExpCount>;
};

/** aggregate fields of "ziferblat.ziferblat" */
export type ZiferblatZiferblatAggregateFields = {
  __typename?: 'ZiferblatZiferblatAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ZiferblatZiferblatMaxFields>;
  min?: Maybe<ZiferblatZiferblatMinFields>;
};


/** aggregate fields of "ziferblat.ziferblat" */
export type ZiferblatZiferblatAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ziferblat.ziferblat" */
export type ZiferblatZiferblatAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ZiferblatZiferblatMaxOrderBy>;
  min?: InputMaybe<ZiferblatZiferblatMinOrderBy>;
};

/** input type for inserting array relation for remote table "ziferblat.ziferblat" */
export type ZiferblatZiferblatArrRelInsertInput = {
  data: Array<ZiferblatZiferblatInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ZiferblatZiferblatOnConflict>;
};

/** Boolean expression to filter rows from the table "ziferblat.ziferblat". All fields are combined with a logical 'AND'. */
export type ZiferblatZiferblatBoolExp = {
  _and?: InputMaybe<Array<ZiferblatZiferblatBoolExp>>;
  _not?: InputMaybe<ZiferblatZiferblatBoolExp>;
  _or?: InputMaybe<Array<ZiferblatZiferblatBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  activeFrom?: InputMaybe<TimestamptzComparisonExp>;
  addressSlug?: InputMaybe<StringComparisonExp>;
  avatarUrl?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<ZiferblatCityI18nBoolExp>;
  cityAggregate?: InputMaybe<ZiferblatCityI18nAggregateBoolExp>;
  citySlug?: InputMaybe<StringComparisonExp>;
  contacts?: InputMaybe<ZiferblatContactBoolExp>;
  contactsAggregate?: InputMaybe<ZiferblatContactAggregateBoolExp>;
  culturalEvents?: InputMaybe<CulturalEventBoolExp>;
  culturalEventsAggregate?: InputMaybe<CulturalEventAggregateBoolExp>;
  gallery?: InputMaybe<ZiferblatGalleryBoolExp>;
  galleryAggregate?: InputMaybe<ZiferblatGalleryAggregateBoolExp>;
  i18n?: InputMaybe<ZiferblatZiferblatI18nBoolExp>;
  i18nAggregate?: InputMaybe<ZiferblatZiferblatI18nAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  oldId?: InputMaybe<StringComparisonExp>;
  passes?: InputMaybe<TariffPassBoolExp>;
  passesAggregate?: InputMaybe<TariffPassAggregateBoolExp>;
  streetAddress?: InputMaybe<ZiferblatAddressI18nBoolExp>;
  streetAddressAggregate?: InputMaybe<ZiferblatAddressI18nAggregateBoolExp>;
  tariffs?: InputMaybe<TariffTariffBoolExp>;
  tariffsAggregate?: InputMaybe<TariffTariffAggregateBoolExp>;
  visits?: InputMaybe<VisitBoolExp>;
  visitsAggregate?: InputMaybe<VisitAggregateBoolExp>;
};

/** unique or primary key constraints on table "ziferblat.ziferblat" */
export enum ZiferblatZiferblatConstraint {
  /** unique or primary key constraint on columns "id" */
  ZiferblatPkey = 'ziferblat_pkey'
}

/** columns and relationships of "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18n = {
  __typename?: 'ZiferblatZiferblatI18n';
  createdAt: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  inputById: Scalars['uuid']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rules: Scalars['String']['output'];
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};

/** aggregated selection of "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18nAggregate = {
  __typename?: 'ZiferblatZiferblatI18nAggregate';
  aggregate?: Maybe<ZiferblatZiferblatI18nAggregateFields>;
  nodes: Array<ZiferblatZiferblatI18n>;
};

export type ZiferblatZiferblatI18nAggregateBoolExp = {
  count?: InputMaybe<ZiferblatZiferblatI18nAggregateBoolExpCount>;
};

/** aggregate fields of "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18nAggregateFields = {
  __typename?: 'ZiferblatZiferblatI18nAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ZiferblatZiferblatI18nMaxFields>;
  min?: Maybe<ZiferblatZiferblatI18nMinFields>;
};


/** aggregate fields of "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18nAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZiferblatZiferblatI18nSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18nAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ZiferblatZiferblatI18nMaxOrderBy>;
  min?: InputMaybe<ZiferblatZiferblatI18nMinOrderBy>;
};

/** input type for inserting array relation for remote table "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18nArrRelInsertInput = {
  data: Array<ZiferblatZiferblatI18nInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ZiferblatZiferblatI18nOnConflict>;
};

/** Boolean expression to filter rows from the table "ziferblat.ziferblat_i18n". All fields are combined with a logical 'AND'. */
export type ZiferblatZiferblatI18nBoolExp = {
  _and?: InputMaybe<Array<ZiferblatZiferblatI18nBoolExp>>;
  _not?: InputMaybe<ZiferblatZiferblatI18nBoolExp>;
  _or?: InputMaybe<Array<ZiferblatZiferblatI18nBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inputById?: InputMaybe<UuidComparisonExp>;
  locale?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  rules?: InputMaybe<StringComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ziferblat.ziferblat_i18n" */
export enum ZiferblatZiferblatI18nConstraint {
  /** unique or primary key constraint on columns "id" */
  ZiferblatI18nPkey = 'ziferblat_i18n_pkey',
  /** unique or primary key constraint on columns "ziferblat_id", "locale" */
  ZiferblatI18nZiferblatIdLangKey = 'ziferblat_i18n_ziferblat_id_lang_key'
}

/** input type for inserting data into table "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18nInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputById?: InputMaybe<Scalars['uuid']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rules?: InputMaybe<Scalars['String']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ZiferblatZiferblatI18nMaxFields = {
  __typename?: 'ZiferblatZiferblatI18nMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  inputById?: Maybe<Scalars['uuid']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rules?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18nMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inputById?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  rules?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ZiferblatZiferblatI18nMinFields = {
  __typename?: 'ZiferblatZiferblatI18nMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  inputById?: Maybe<Scalars['uuid']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rules?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18nMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inputById?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  rules?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18nMutationResponse = {
  __typename?: 'ZiferblatZiferblatI18nMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ZiferblatZiferblatI18n>;
};

/** on_conflict condition type for table "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18nOnConflict = {
  constraint: ZiferblatZiferblatI18nConstraint;
  updateColumns?: Array<ZiferblatZiferblatI18nUpdateColumn>;
  where?: InputMaybe<ZiferblatZiferblatI18nBoolExp>;
};

/** Ordering options when selecting data from "ziferblat.ziferblat_i18n". */
export type ZiferblatZiferblatI18nOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inputById?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  rules?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ziferblat.ziferblat_i18n */
export type ZiferblatZiferblatI18nPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "ziferblat.ziferblat_i18n" */
export enum ZiferblatZiferblatI18nSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InputById = 'inputById',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name',
  /** column name */
  Rules = 'rules',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "ziferblat.ziferblat_i18n" */
export type ZiferblatZiferblatI18nSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputById?: InputMaybe<Scalars['uuid']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rules?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "ziferblat_ziferblat_i18n" */
export type ZiferblatZiferblatI18nStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZiferblatZiferblatI18nStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZiferblatZiferblatI18nStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputById?: InputMaybe<Scalars['uuid']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rules?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "ziferblat.ziferblat_i18n" */
export enum ZiferblatZiferblatI18nUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InputById = 'inputById',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name',
  /** column name */
  Rules = 'rules',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type ZiferblatZiferblatI18nUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZiferblatZiferblatI18nSetInput>;
  /** filter the rows which have to be updated */
  where: ZiferblatZiferblatI18nBoolExp;
};

/** input type for inserting data into table "ziferblat.ziferblat" */
export type ZiferblatZiferblatInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addressSlug?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<ZiferblatCityI18nArrRelInsertInput>;
  citySlug?: InputMaybe<Scalars['String']['input']>;
  contacts?: InputMaybe<ZiferblatContactArrRelInsertInput>;
  culturalEvents?: InputMaybe<CulturalEventArrRelInsertInput>;
  gallery?: InputMaybe<ZiferblatGalleryArrRelInsertInput>;
  i18n?: InputMaybe<ZiferblatZiferblatI18nArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oldId?: InputMaybe<Scalars['String']['input']>;
  passes?: InputMaybe<TariffPassArrRelInsertInput>;
  streetAddress?: InputMaybe<ZiferblatAddressI18nArrRelInsertInput>;
  tariffs?: InputMaybe<TariffTariffArrRelInsertInput>;
  visits?: InputMaybe<VisitArrRelInsertInput>;
};

/** aggregate max on columns */
export type ZiferblatZiferblatMaxFields = {
  __typename?: 'ZiferblatZiferblatMaxFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  addressSlug?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  citySlug?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  oldId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "ziferblat.ziferblat" */
export type ZiferblatZiferblatMaxOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  addressSlug?: InputMaybe<OrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  citySlug?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  oldId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ZiferblatZiferblatMinFields = {
  __typename?: 'ZiferblatZiferblatMinFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  addressSlug?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  citySlug?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  oldId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "ziferblat.ziferblat" */
export type ZiferblatZiferblatMinOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  addressSlug?: InputMaybe<OrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  citySlug?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  oldId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "ziferblat.ziferblat" */
export type ZiferblatZiferblatMutationResponse = {
  __typename?: 'ZiferblatZiferblatMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ZiferblatZiferblat>;
};

/** input type for inserting object relation for remote table "ziferblat.ziferblat" */
export type ZiferblatZiferblatObjRelInsertInput = {
  data: ZiferblatZiferblatInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ZiferblatZiferblatOnConflict>;
};

/** on_conflict condition type for table "ziferblat.ziferblat" */
export type ZiferblatZiferblatOnConflict = {
  constraint: ZiferblatZiferblatConstraint;
  updateColumns?: Array<ZiferblatZiferblatUpdateColumn>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};

/** Ordering options when selecting data from "ziferblat.ziferblat". */
export type ZiferblatZiferblatOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  activeFrom?: InputMaybe<OrderBy>;
  addressSlug?: InputMaybe<OrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  cityAggregate?: InputMaybe<ZiferblatCityI18nAggregateOrderBy>;
  citySlug?: InputMaybe<OrderBy>;
  contactsAggregate?: InputMaybe<ZiferblatContactAggregateOrderBy>;
  culturalEventsAggregate?: InputMaybe<CulturalEventAggregateOrderBy>;
  galleryAggregate?: InputMaybe<ZiferblatGalleryAggregateOrderBy>;
  i18nAggregate?: InputMaybe<ZiferblatZiferblatI18nAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  oldId?: InputMaybe<OrderBy>;
  passesAggregate?: InputMaybe<TariffPassAggregateOrderBy>;
  streetAddressAggregate?: InputMaybe<ZiferblatAddressI18nAggregateOrderBy>;
  tariffsAggregate?: InputMaybe<TariffTariffAggregateOrderBy>;
  visitsAggregate?: InputMaybe<VisitAggregateOrderBy>;
};

/** primary key columns input for table: ziferblat.ziferblat */
export type ZiferblatZiferblatPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "ziferblat.ziferblat" */
export enum ZiferblatZiferblatSelectColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  AddressSlug = 'addressSlug',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CitySlug = 'citySlug',
  /** column name */
  Id = 'id',
  /** column name */
  OldId = 'oldId'
}

/** input type for updating data in table "ziferblat.ziferblat" */
export type ZiferblatZiferblatSetInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addressSlug?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  citySlug?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oldId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "ziferblat_ziferblat" */
export type ZiferblatZiferblatStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZiferblatZiferblatStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZiferblatZiferblatStreamCursorValueInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addressSlug?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  citySlug?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oldId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "ziferblat.ziferblat" */
export enum ZiferblatZiferblatUpdateColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  AddressSlug = 'addressSlug',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CitySlug = 'citySlug',
  /** column name */
  Id = 'id',
  /** column name */
  OldId = 'oldId'
}

export type ZiferblatZiferblatUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZiferblatZiferblatSetInput>;
  /** filter the rows which have to be updated */
  where: ZiferblatZiferblatBoolExp;
};

export type AccessAccessAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AccessAccessSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AccessAccessBoolExp>;
  predicate: IntComparisonExp;
};

export type AccessExpireAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AccessExpireSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AccessExpireBoolExp>;
  predicate: IntComparisonExp;
};

export type AccessGrantAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AccessGrantSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AccessGrantBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "cultural_event.attendees" */
export type Attendees = {
  __typename?: 'attendees';
  /** An object relationship */
  checkin?: Maybe<Checkin>;
  /** An object relationship */
  culturalEvent?: Maybe<CulturalEvent>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  endedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  startedAt?: Maybe<Scalars['timestamptz']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  transaction?: Maybe<PaymentTransaction>;
  transactionId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  visit?: Maybe<Visit>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "cultural_event.attendees" */
export type AttendeesAggregate = {
  __typename?: 'attendeesAggregate';
  aggregate?: Maybe<AttendeesAggregateFields>;
  nodes: Array<Attendees>;
};

export type AttendeesAggregateBoolExp = {
  count?: InputMaybe<AttendeesAggregateBoolExpCount>;
};

export type AttendeesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AttendeesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AttendeesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "cultural_event.attendees" */
export type AttendeesAggregateFields = {
  __typename?: 'attendeesAggregateFields';
  avg?: Maybe<AttendeesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AttendeesMaxFields>;
  min?: Maybe<AttendeesMinFields>;
  stddev?: Maybe<AttendeesStddevFields>;
  stddevPop?: Maybe<AttendeesStddevPopFields>;
  stddevSamp?: Maybe<AttendeesStddevSampFields>;
  sum?: Maybe<AttendeesSumFields>;
  varPop?: Maybe<AttendeesVarPopFields>;
  varSamp?: Maybe<AttendeesVarSampFields>;
  variance?: Maybe<AttendeesVarianceFields>;
};


/** aggregate fields of "cultural_event.attendees" */
export type AttendeesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AttendeesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "cultural_event.attendees" */
export type AttendeesAggregateOrderBy = {
  avg?: InputMaybe<AttendeesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AttendeesMaxOrderBy>;
  min?: InputMaybe<AttendeesMinOrderBy>;
  stddev?: InputMaybe<AttendeesStddevOrderBy>;
  stddevPop?: InputMaybe<AttendeesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<AttendeesStddevSampOrderBy>;
  sum?: InputMaybe<AttendeesSumOrderBy>;
  varPop?: InputMaybe<AttendeesVarPopOrderBy>;
  varSamp?: InputMaybe<AttendeesVarSampOrderBy>;
  variance?: InputMaybe<AttendeesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "cultural_event.attendees" */
export type AttendeesArrRelInsertInput = {
  data: Array<AttendeesInsertInput>;
};

/** aggregate avg on columns */
export type AttendeesAvgFields = {
  __typename?: 'attendeesAvgFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "cultural_event.attendees" */
export type AttendeesAvgOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "cultural_event.attendees". All fields are combined with a logical 'AND'. */
export type AttendeesBoolExp = {
  _and?: InputMaybe<Array<AttendeesBoolExp>>;
  _not?: InputMaybe<AttendeesBoolExp>;
  _or?: InputMaybe<Array<AttendeesBoolExp>>;
  checkin?: InputMaybe<CheckinBoolExp>;
  culturalEvent?: InputMaybe<CulturalEventBoolExp>;
  culturalEventId?: InputMaybe<UuidComparisonExp>;
  endedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  startedAt?: InputMaybe<TimestamptzComparisonExp>;
  sum?: InputMaybe<IntComparisonExp>;
  transaction?: InputMaybe<PaymentTransactionBoolExp>;
  transactionId?: InputMaybe<UuidComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<UuidComparisonExp>;
};

/** input type for inserting data into table "cultural_event.attendees" */
export type AttendeesInsertInput = {
  checkin?: InputMaybe<CheckinObjRelInsertInput>;
  culturalEvent?: InputMaybe<CulturalEventObjRelInsertInput>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  endedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  startedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  transaction?: InputMaybe<PaymentTransactionObjRelInsertInput>;
  transactionId?: InputMaybe<Scalars['uuid']['input']>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AttendeesMaxFields = {
  __typename?: 'attendeesMaxFields';
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  endedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  startedAt?: Maybe<Scalars['timestamptz']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  transactionId?: Maybe<Scalars['uuid']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "cultural_event.attendees" */
export type AttendeesMaxOrderBy = {
  culturalEventId?: InputMaybe<OrderBy>;
  endedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  transactionId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AttendeesMinFields = {
  __typename?: 'attendeesMinFields';
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  endedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  startedAt?: Maybe<Scalars['timestamptz']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  transactionId?: Maybe<Scalars['uuid']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "cultural_event.attendees" */
export type AttendeesMinOrderBy = {
  culturalEventId?: InputMaybe<OrderBy>;
  endedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  transactionId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** input type for inserting object relation for remote table "cultural_event.attendees" */
export type AttendeesObjRelInsertInput = {
  data: AttendeesInsertInput;
};

/** Ordering options when selecting data from "cultural_event.attendees". */
export type AttendeesOrderBy = {
  checkin?: InputMaybe<CheckinOrderBy>;
  culturalEvent?: InputMaybe<CulturalEventOrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  endedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  transaction?: InputMaybe<PaymentTransactionOrderBy>;
  transactionId?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** select columns of table "cultural_event.attendees" */
export enum AttendeesSelectColumn {
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  EndedAt = 'endedAt',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  Sum = 'sum',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  VisitId = 'visitId'
}

/** aggregate stddev on columns */
export type AttendeesStddevFields = {
  __typename?: 'attendeesStddevFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "cultural_event.attendees" */
export type AttendeesStddevOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type AttendeesStddevPopFields = {
  __typename?: 'attendeesStddevPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "cultural_event.attendees" */
export type AttendeesStddevPopOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type AttendeesStddevSampFields = {
  __typename?: 'attendeesStddevSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "cultural_event.attendees" */
export type AttendeesStddevSampOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "attendees" */
export type AttendeesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AttendeesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AttendeesStreamCursorValueInput = {
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  endedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  startedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type AttendeesSumFields = {
  __typename?: 'attendeesSumFields';
  sum?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "cultural_event.attendees" */
export type AttendeesSumOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type AttendeesVarPopFields = {
  __typename?: 'attendeesVarPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "cultural_event.attendees" */
export type AttendeesVarPopOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type AttendeesVarSampFields = {
  __typename?: 'attendeesVarSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "cultural_event.attendees" */
export type AttendeesVarSampOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AttendeesVarianceFields = {
  __typename?: 'attendeesVarianceFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "cultural_event.attendees" */
export type AttendeesVarianceOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

export type AuthAliasAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AuthAliasSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthAliasBoolExp>;
  predicate: IntComparisonExp;
};

export type BookingCheckinAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<BookingCheckinSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookingCheckinBoolExp>;
  predicate: IntComparisonExp;
};

export type CulturalEventAttendanceAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CulturalEventAttendanceBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "cultural_event.cultural_event_image" */
export type CulturalEventImage = {
  __typename?: 'culturalEventImage';
  addedById: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  culturalEvent: CulturalEvent;
  culturalEventId: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  image: Media;
  imageId: Scalars['uuid']['output'];
};

/** aggregated selection of "cultural_event.cultural_event_image" */
export type CulturalEventImageAggregate = {
  __typename?: 'culturalEventImageAggregate';
  aggregate?: Maybe<CulturalEventImageAggregateFields>;
  nodes: Array<CulturalEventImage>;
};

export type CulturalEventImageAggregateBoolExp = {
  count?: InputMaybe<CulturalEventImageAggregateBoolExpCount>;
};

export type CulturalEventImageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CulturalEventImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CulturalEventImageBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "cultural_event.cultural_event_image" */
export type CulturalEventImageAggregateFields = {
  __typename?: 'culturalEventImageAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventImageMaxFields>;
  min?: Maybe<CulturalEventImageMinFields>;
};


/** aggregate fields of "cultural_event.cultural_event_image" */
export type CulturalEventImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "cultural_event.cultural_event_image" */
export type CulturalEventImageAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CulturalEventImageMaxOrderBy>;
  min?: InputMaybe<CulturalEventImageMinOrderBy>;
};

/** input type for inserting array relation for remote table "cultural_event.cultural_event_image" */
export type CulturalEventImageArrRelInsertInput = {
  data: Array<CulturalEventImageInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CulturalEventImageOnConflict>;
};

/** Boolean expression to filter rows from the table "cultural_event.cultural_event_image". All fields are combined with a logical 'AND'. */
export type CulturalEventImageBoolExp = {
  _and?: InputMaybe<Array<CulturalEventImageBoolExp>>;
  _not?: InputMaybe<CulturalEventImageBoolExp>;
  _or?: InputMaybe<Array<CulturalEventImageBoolExp>>;
  addedById?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  culturalEvent?: InputMaybe<CulturalEventBoolExp>;
  culturalEventId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  image?: InputMaybe<MediaBoolExp>;
  imageId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.cultural_event_image" */
export enum CulturalEventImageConstraint {
  /** unique or primary key constraint on columns "id" */
  CulturalEventImagePkey = 'cultural_event_image_pkey'
}

/** input type for inserting data into table "cultural_event.cultural_event_image" */
export type CulturalEventImageInsertInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEvent?: InputMaybe<CulturalEventObjRelInsertInput>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<MediaObjRelInsertInput>;
  imageId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type CulturalEventImageMaxFields = {
  __typename?: 'culturalEventImageMaxFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  imageId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "cultural_event.cultural_event_image" */
export type CulturalEventImageMaxOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CulturalEventImageMinFields = {
  __typename?: 'culturalEventImageMinFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  imageId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "cultural_event.cultural_event_image" */
export type CulturalEventImageMinOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "cultural_event.cultural_event_image" */
export type CulturalEventImageMutationResponse = {
  __typename?: 'culturalEventImageMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEventImage>;
};

/** on_conflict condition type for table "cultural_event.cultural_event_image" */
export type CulturalEventImageOnConflict = {
  constraint: CulturalEventImageConstraint;
  updateColumns?: Array<CulturalEventImageUpdateColumn>;
  where?: InputMaybe<CulturalEventImageBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.cultural_event_image". */
export type CulturalEventImageOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEvent?: InputMaybe<CulturalEventOrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<MediaOrderBy>;
  imageId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.cultural_event_image */
export type CulturalEventImagePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "cultural_event.cultural_event_image" */
export enum CulturalEventImageSelectColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId'
}

/** input type for updating data in table "cultural_event.cultural_event_image" */
export type CulturalEventImageSetInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  imageId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "culturalEventImage" */
export type CulturalEventImageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventImageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventImageStreamCursorValueInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  imageId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "cultural_event.cultural_event_image" */
export enum CulturalEventImageUpdateColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId'
}

export type CulturalEventImageUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventImageSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventImageBoolExp;
};

export type CulturalEventTicketAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CulturalEventTicketBoolExp>;
  predicate: IntComparisonExp;
};

export type CulturalEventToggleAttendanceArgs = {
  checkin_id?: InputMaybe<Scalars['uuid']['input']>;
  cultural_event_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "media.media" */
export type Media = {
  __typename?: 'media';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  uploadedById: Scalars['uuid']['output'];
  /** A computed field, executes function "media.get_image_url" */
  url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "media.media" */
export type MediaAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "media.media" */
export type MediaAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "media.media" */
export type MediaUrlArgs = {
  args?: InputMaybe<Url_Media_Args>;
};

/** aggregated selection of "media.media" */
export type MediaAggregate = {
  __typename?: 'mediaAggregate';
  aggregate?: Maybe<MediaAggregateFields>;
  nodes: Array<Media>;
};

/** aggregate fields of "media.media" */
export type MediaAggregateFields = {
  __typename?: 'mediaAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<MediaMaxFields>;
  min?: Maybe<MediaMinFields>;
};


/** aggregate fields of "media.media" */
export type MediaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MediaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "media.media". All fields are combined with a logical 'AND'. */
export type MediaBoolExp = {
  _and?: InputMaybe<Array<MediaBoolExp>>;
  _not?: InputMaybe<MediaBoolExp>;
  _or?: InputMaybe<Array<MediaBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  contentType?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  uploadedById?: InputMaybe<UuidComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "media.media" */
export enum MediaConstraint {
  /** unique or primary key constraint on columns "id" */
  ImagePkey = 'image_pkey'
}

/** input type for inserting data into table "media.media" */
export type MediaInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  uploadedById?: InputMaybe<Scalars['uuid']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type MediaMaxFields = {
  __typename?: 'mediaMaxFields';
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  uploadedById?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "media.get_image_url" */
  url?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};


/** aggregate max on columns */
export type MediaMaxFieldsUrlArgs = {
  args?: InputMaybe<Url_Media_Args>;
};

/** aggregate min on columns */
export type MediaMinFields = {
  __typename?: 'mediaMinFields';
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  uploadedById?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "media.get_image_url" */
  url?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};


/** aggregate min on columns */
export type MediaMinFieldsUrlArgs = {
  args?: InputMaybe<Url_Media_Args>;
};

/** response of any mutation on the table "media.media" */
export type MediaMutationResponse = {
  __typename?: 'mediaMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Media>;
};

/** input type for inserting object relation for remote table "media.media" */
export type MediaObjRelInsertInput = {
  data: MediaInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MediaOnConflict>;
};

/** on_conflict condition type for table "media.media" */
export type MediaOnConflict = {
  constraint: MediaConstraint;
  updateColumns?: Array<MediaUpdateColumn>;
  where?: InputMaybe<MediaBoolExp>;
};

/** Ordering options when selecting data from "media.media". */
export type MediaOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  contentType?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: media.media */
export type MediaPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "media.media" */
export enum MediaSelectColumn {
  /** column name */
  ContentType = 'contentType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UploadedById = 'uploadedById',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "media.media" */
export type MediaSetInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  uploadedById?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "media" */
export type MediaStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MediaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MediaStreamCursorValueInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  uploadedById?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "media.media" */
export enum MediaUpdateColumn {
  /** column name */
  ContentType = 'contentType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UploadedById = 'uploadedById',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type MediaUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MediaSetInput>;
  /** filter the rows which have to be updated */
  where: MediaBoolExp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** insert a single row into the table: "cultural_event.attendance" */
  addAttendance?: Maybe<CulturalEventAttendance>;
  /** insert a single row into the table: "checkin" */
  addCheckin?: Maybe<Checkin>;
  /** insert data into the table: "checkin" */
  addCheckins?: Maybe<CheckinMutationResponse>;
  /** insert a single row into the table: "ziferblat.contact" */
  addContact?: Maybe<ZiferblatContact>;
  /** insert data into the table: "ziferblat.contact" */
  addContacts?: Maybe<ZiferblatContactMutationResponse>;
  /** insert a single row into the table: "cultural_event.cultural_event" */
  addCulturalEvent?: Maybe<CulturalEvent>;
  /** insert a single row into the table: "cultural_event.cultural_event_image" */
  addCulturalEventImage?: Maybe<CulturalEventImage>;
  /** insert data into the table: "cultural_event.cultural_event_image" */
  addCulturalEventImages?: Maybe<CulturalEventImageMutationResponse>;
  /** insert data into the table: "cultural_event.cultural_event" */
  addCulturalEvents?: Maybe<CulturalEventMutationResponse>;
  /** insert a single row into the table: "media.media" */
  addMedia?: Maybe<Media>;
  /** insert data into the table: "media.media" */
  addMedias?: Maybe<MediaMutationResponse>;
  /** insert a single row into the table: "ziferblat.gallery" */
  addToGallery?: Maybe<ZiferblatGallery>;
  /** insert a single row into the table: "auth.user" */
  addUser?: Maybe<AuthUser>;
  /** insert data into the table: "auth.user" */
  addUsers?: Maybe<AuthUserMutationResponse>;
  /** insert a single row into the table: "ziferblat.ziferblat" */
  addZiferblat?: Maybe<ZiferblatZiferblat>;
  /** insert data into the table: "ziferblat.ziferblat" */
  addZiferblats?: Maybe<ZiferblatZiferblatMutationResponse>;
  /** insert a single row into the table: "visit" */
  checkOut?: Maybe<Visit>;
  /** insert data into the table: "visit" */
  checkOutMultiple?: Maybe<VisitMutationResponse>;
  /** execute VOLATILE function "cultural_event.toggle_attendance" which returns "cultural_event.attendance" */
  culturalEventToggleAttendance?: Maybe<CulturalEventAttendance>;
  /** delete data from the table: "access.expire" */
  deleteAccessExpire?: Maybe<AccessExpireMutationResponse>;
  /** delete single row from the table: "access.expire" */
  deleteAccessExpireByPk?: Maybe<AccessExpire>;
  /** delete data from the table: "access.grant" */
  deleteAccessGrant?: Maybe<AccessGrantMutationResponse>;
  /** delete single row from the table: "access.grant" */
  deleteAccessGrantByPk?: Maybe<AccessGrant>;
  /** delete data from the table: "access.permission" */
  deleteAccessPermission?: Maybe<AccessPermissionMutationResponse>;
  /** delete single row from the table: "access.permission" */
  deleteAccessPermissionByPk?: Maybe<AccessPermission>;
  /** delete data from the table: "access.role" */
  deleteAccessRole?: Maybe<AccessRoleMutationResponse>;
  /** delete single row from the table: "access.role" */
  deleteAccessRoleByPk?: Maybe<AccessRole>;
  /** delete data from the table: "audit.log" */
  deleteAuditLog?: Maybe<AuditLogMutationResponse>;
  /** delete single row from the table: "audit.log" */
  deleteAuditLogByPk?: Maybe<AuditLog>;
  /** delete data from the table: "auth.alias" */
  deleteAuthAlias?: Maybe<AuthAliasMutationResponse>;
  /** delete single row from the table: "auth.alias" */
  deleteAuthAliasByPk?: Maybe<AuthAlias>;
  /** delete data from the table: "auth.logout" */
  deleteAuthLogout?: Maybe<AuthLogoutMutationResponse>;
  /** delete single row from the table: "auth.logout" */
  deleteAuthLogoutByPk?: Maybe<AuthLogout>;
  /** delete data from the table: "auth.session" */
  deleteAuthSession?: Maybe<AuthSessionMutationResponse>;
  /** delete single row from the table: "auth.session" */
  deleteAuthSessionByPk?: Maybe<AuthSession>;
  /** delete data from the table: "auth.telegram_bot" */
  deleteAuthTelegramBot?: Maybe<AuthTelegramBotMutationResponse>;
  /** delete single row from the table: "auth.telegram_bot" */
  deleteAuthTelegramBotByPk?: Maybe<AuthTelegramBot>;
  /** delete data from the table: "auth.telegram_input" */
  deleteAuthTelegramInput?: Maybe<AuthTelegramInputMutationResponse>;
  /** delete single row from the table: "auth.telegram_input" */
  deleteAuthTelegramInputByPk?: Maybe<AuthTelegramInput>;
  /** delete data from the table: "auth.user" */
  deleteAuthUser?: Maybe<AuthUserMutationResponse>;
  /** delete single row from the table: "auth.user" */
  deleteAuthUserByPk?: Maybe<AuthUser>;
  /** delete data from the table: "booking" */
  deleteBooking?: Maybe<BookingMutationResponse>;
  /** delete single row from the table: "booking" */
  deleteBookingByPk?: Maybe<Booking>;
  /** delete data from the table: "booking_checkin" */
  deleteBookingCheckin?: Maybe<BookingCheckinMutationResponse>;
  /** delete single row from the table: "booking_checkin" */
  deleteBookingCheckinByPk?: Maybe<BookingCheckin>;
  /** delete data from the table: "checkin" */
  deleteCheckin?: Maybe<CheckinMutationResponse>;
  /** delete single row from the table: "checkin" */
  deleteCheckinByPk?: Maybe<Checkin>;
  /** delete data from the table: "cultural_event.compensation_currency" */
  deleteCompensationCurrency?: Maybe<CompensationCurrencyMutationResponse>;
  /** delete single row from the table: "cultural_event.compensation_currency" */
  deleteCompensationCurrencyByPk?: Maybe<CompensationCurrency>;
  /** delete data from the table: "cultural_event.compensation_unit" */
  deleteCompensationUnit?: Maybe<CompensationUnitMutationResponse>;
  /** delete single row from the table: "cultural_event.compensation_unit" */
  deleteCompensationUnitByPk?: Maybe<CompensationUnit>;
  /** delete data from the table: "cultural_event.cultural_event" */
  deleteCulturalEvent?: Maybe<CulturalEventMutationResponse>;
  /** delete single row from the table: "cultural_event.attendance" */
  deleteCulturalEventAttendanceByPk?: Maybe<CulturalEventAttendance>;
  /** delete single row from the table: "cultural_event.cultural_event" */
  deleteCulturalEventByPk?: Maybe<CulturalEvent>;
  /** delete data from the table: "cultural_event.compensation" */
  deleteCulturalEventCompensation?: Maybe<CulturalEventCompensationMutationResponse>;
  /** delete single row from the table: "cultural_event.compensation" */
  deleteCulturalEventCompensationByPk?: Maybe<CulturalEventCompensation>;
  /** delete data from the table: "cultural_event.prepaid_attendance" */
  deleteCulturalEventPrepaidAttendance?: Maybe<CulturalEventPrepaidAttendanceMutationResponse>;
  /** delete single row from the table: "cultural_event.prepaid_attendance" */
  deleteCulturalEventPrepaidAttendanceByPk?: Maybe<CulturalEventPrepaidAttendance>;
  /** delete data from the table: "cultural_event.status" */
  deleteCulturalEventStatus?: Maybe<CulturalEventStatusMutationResponse>;
  /** delete single row from the table: "cultural_event.status" */
  deleteCulturalEventStatusByPk?: Maybe<CulturalEventStatus>;
  /** delete data from the table: "media.media" */
  deleteMedia?: Maybe<MediaMutationResponse>;
  /** delete single row from the table: "media.media" */
  deleteMediaByPk?: Maybe<Media>;
  /** delete data from the table: "media.config" */
  deleteMediaConfig?: Maybe<MediaConfigMutationResponse>;
  /** delete single row from the table: "media.config" */
  deleteMediaConfigByPk?: Maybe<MediaConfig>;
  /** delete data from the table: "pause" */
  deletePause?: Maybe<PauseMutationResponse>;
  /** delete single row from the table: "pause" */
  deletePauseByPk?: Maybe<Pause>;
  /** delete data from the table: "payment.config" */
  deletePaymentConfig?: Maybe<PaymentConfigMutationResponse>;
  /** delete single row from the table: "payment.config" */
  deletePaymentConfigByPk?: Maybe<PaymentConfig>;
  /** delete data from the table: "payment.return" */
  deletePaymentReturn?: Maybe<PaymentReturnMutationResponse>;
  /** delete single row from the table: "payment.return" */
  deletePaymentReturnByPk?: Maybe<PaymentReturn>;
  /** delete data from the table: "payment.transaction" */
  deletePaymentTransaction?: Maybe<PaymentTransactionMutationResponse>;
  /** delete single row from the table: "payment.transaction" */
  deletePaymentTransactionByPk?: Maybe<PaymentTransaction>;
  /** delete data from the table: "tariff.pass" */
  deleteTariffPass?: Maybe<TariffPassMutationResponse>;
  /** delete single row from the table: "tariff.pass" */
  deleteTariffPassByPk?: Maybe<TariffPass>;
  /** delete data from the table: "tariff.pass_i18n" */
  deleteTariffPassI18n?: Maybe<TariffPassI18nMutationResponse>;
  /** delete single row from the table: "tariff.pass_i18n" */
  deleteTariffPassI18nByPk?: Maybe<TariffPassI18n>;
  /** delete data from the table: "tariff.pass_user" */
  deleteTariffPassUser?: Maybe<TariffPassUserMutationResponse>;
  /** delete single row from the table: "tariff.pass_user" */
  deleteTariffPassUserByPk?: Maybe<TariffPassUser>;
  /** delete data from the table: "tariff.tariff" */
  deleteTariffTariff?: Maybe<TariffTariffMutationResponse>;
  /** delete single row from the table: "tariff.tariff" */
  deleteTariffTariffByPk?: Maybe<TariffTariff>;
  /** delete data from the table: "visit" */
  deleteVisit?: Maybe<VisitMutationResponse>;
  /** delete single row from the table: "visit" */
  deleteVisitByPk?: Maybe<Visit>;
  /** delete data from the table: "ziferblat.address_i18n" */
  deleteZiferblatAddressI18n?: Maybe<ZiferblatAddressI18nMutationResponse>;
  /** delete single row from the table: "ziferblat.address_i18n" */
  deleteZiferblatAddressI18nByPk?: Maybe<ZiferblatAddressI18n>;
  /** delete data from the table: "ziferblat.city_i18n" */
  deleteZiferblatCityI18n?: Maybe<ZiferblatCityI18nMutationResponse>;
  /** delete single row from the table: "ziferblat.city_i18n" */
  deleteZiferblatCityI18nByPk?: Maybe<ZiferblatCityI18n>;
  /** delete data from the table: "ziferblat.gallery" */
  deleteZiferblatGallery?: Maybe<ZiferblatGalleryMutationResponse>;
  /** delete single row from the table: "ziferblat.gallery" */
  deleteZiferblatGalleryByPk?: Maybe<ZiferblatGallery>;
  /** delete data from the table: "ziferblat.juridical_i18n" */
  deleteZiferblatJuridicalI18n?: Maybe<ZiferblatJuridicalI18nMutationResponse>;
  /** delete single row from the table: "ziferblat.juridical_i18n" */
  deleteZiferblatJuridicalI18nByPk?: Maybe<ZiferblatJuridicalI18n>;
  /** delete data from the table: "ziferblat.lang" */
  deleteZiferblatLang?: Maybe<ZiferblatLangMutationResponse>;
  /** delete single row from the table: "ziferblat.lang" */
  deleteZiferblatLangByPk?: Maybe<ZiferblatLang>;
  /** delete data from the table: "ziferblat.room" */
  deleteZiferblatRoom?: Maybe<ZiferblatRoomMutationResponse>;
  /** delete single row from the table: "ziferblat.room" */
  deleteZiferblatRoomByPk?: Maybe<ZiferblatRoom>;
  /** delete data from the table: "ziferblat.room_i18n" */
  deleteZiferblatRoomI18n?: Maybe<ZiferblatRoomI18nMutationResponse>;
  /** delete single row from the table: "ziferblat.room_i18n" */
  deleteZiferblatRoomI18nByPk?: Maybe<ZiferblatRoomI18n>;
  /** delete data from the table: "ziferblat.ziferblat" */
  deleteZiferblatZiferblat?: Maybe<ZiferblatZiferblatMutationResponse>;
  /** delete single row from the table: "ziferblat.ziferblat" */
  deleteZiferblatZiferblatByPk?: Maybe<ZiferblatZiferblat>;
  /** delete data from the table: "ziferblat.ziferblat_i18n" */
  deleteZiferblatZiferblatI18n?: Maybe<ZiferblatZiferblatI18nMutationResponse>;
  /** delete single row from the table: "ziferblat.ziferblat_i18n" */
  deleteZiferblatZiferblatI18nByPk?: Maybe<ZiferblatZiferblatI18n>;
  getUploadUrl?: Maybe<UploadUrl>;
  /** insert data into the table: "access.expire" */
  insertAccessExpire?: Maybe<AccessExpireMutationResponse>;
  /** insert data into the table: "access.grant" */
  insertAccessGrant?: Maybe<AccessGrantMutationResponse>;
  /** insert a single row into the table: "access.grant" */
  insertAccessGrantOne?: Maybe<AccessGrant>;
  /** insert data into the table: "access.permission" */
  insertAccessPermission?: Maybe<AccessPermissionMutationResponse>;
  /** insert a single row into the table: "access.permission" */
  insertAccessPermissionOne?: Maybe<AccessPermission>;
  /** insert data into the table: "access.role" */
  insertAccessRole?: Maybe<AccessRoleMutationResponse>;
  /** insert a single row into the table: "access.role" */
  insertAccessRoleOne?: Maybe<AccessRole>;
  /** insert data into the table: "audit.log" */
  insertAuditLog?: Maybe<AuditLogMutationResponse>;
  /** insert a single row into the table: "audit.log" */
  insertAuditLogOne?: Maybe<AuditLog>;
  /** insert data into the table: "auth.alias" */
  insertAuthAlias?: Maybe<AuthAliasMutationResponse>;
  /** insert a single row into the table: "auth.alias" */
  insertAuthAliasOne?: Maybe<AuthAlias>;
  /** insert data into the table: "auth.logout" */
  insertAuthLogout?: Maybe<AuthLogoutMutationResponse>;
  /** insert data into the table: "auth.session" */
  insertAuthSession?: Maybe<AuthSessionMutationResponse>;
  /** insert a single row into the table: "auth.session" */
  insertAuthSessionOne?: Maybe<AuthSession>;
  /** insert data into the table: "auth.telegram_bot" */
  insertAuthTelegramBot?: Maybe<AuthTelegramBotMutationResponse>;
  /** insert a single row into the table: "auth.telegram_bot" */
  insertAuthTelegramBotOne?: Maybe<AuthTelegramBot>;
  /** insert data into the table: "auth.telegram_input" */
  insertAuthTelegramInput?: Maybe<AuthTelegramInputMutationResponse>;
  /** insert data into the table: "booking" */
  insertBooking?: Maybe<BookingMutationResponse>;
  /** insert data into the table: "booking_checkin" */
  insertBookingCheckin?: Maybe<BookingCheckinMutationResponse>;
  /** insert a single row into the table: "booking_checkin" */
  insertBookingCheckinOne?: Maybe<BookingCheckin>;
  /** insert a single row into the table: "booking" */
  insertBookingOne?: Maybe<Booking>;
  /** insert data into the table: "cultural_event.compensation_currency" */
  insertCompensationCurrency?: Maybe<CompensationCurrencyMutationResponse>;
  /** insert a single row into the table: "cultural_event.compensation_currency" */
  insertCompensationCurrencyOne?: Maybe<CompensationCurrency>;
  /** insert data into the table: "cultural_event.compensation_unit" */
  insertCompensationUnit?: Maybe<CompensationUnitMutationResponse>;
  /** insert a single row into the table: "cultural_event.compensation_unit" */
  insertCompensationUnitOne?: Maybe<CompensationUnit>;
  /** insert data into the table: "cultural_event.attendance" */
  insertCulturalEventAttendance?: Maybe<CulturalEventAttendanceMutationResponse>;
  /** insert data into the table: "cultural_event.compensation" */
  insertCulturalEventCompensation?: Maybe<CulturalEventCompensationMutationResponse>;
  /** insert a single row into the table: "cultural_event.compensation" */
  insertCulturalEventCompensationOne?: Maybe<CulturalEventCompensation>;
  /** insert data into the table: "cultural_event.prepaid_attendance" */
  insertCulturalEventPrepaidAttendance?: Maybe<CulturalEventPrepaidAttendanceMutationResponse>;
  /** insert a single row into the table: "cultural_event.prepaid_attendance" */
  insertCulturalEventPrepaidAttendanceOne?: Maybe<CulturalEventPrepaidAttendance>;
  /** insert data into the table: "cultural_event.status" */
  insertCulturalEventStatus?: Maybe<CulturalEventStatusMutationResponse>;
  /** insert a single row into the table: "cultural_event.status" */
  insertCulturalEventStatusOne?: Maybe<CulturalEventStatus>;
  /** insert data into the table: "cultural_event.ticket" */
  insertCulturalEventTicket?: Maybe<CulturalEventTicketMutationResponse>;
  /** insert a single row into the table: "cultural_event.ticket" */
  insertCulturalEventTicketOne?: Maybe<CulturalEventTicket>;
  /** insert data into the table: "media.config" */
  insertMediaConfig?: Maybe<MediaConfigMutationResponse>;
  /** insert a single row into the table: "media.config" */
  insertMediaConfigOne?: Maybe<MediaConfig>;
  /** insert data into the table: "pause" */
  insertPause?: Maybe<PauseMutationResponse>;
  /** insert a single row into the table: "pause" */
  insertPauseOne?: Maybe<Pause>;
  /** insert data into the table: "payment.config" */
  insertPaymentConfig?: Maybe<PaymentConfigMutationResponse>;
  /** insert a single row into the table: "payment.config" */
  insertPaymentConfigOne?: Maybe<PaymentConfig>;
  /** insert data into the table: "payment.return" */
  insertPaymentReturn?: Maybe<PaymentReturnMutationResponse>;
  /** insert a single row into the table: "payment.return" */
  insertPaymentReturnOne?: Maybe<PaymentReturn>;
  /** insert data into the table: "payment.transaction" */
  insertPaymentTransaction?: Maybe<PaymentTransactionMutationResponse>;
  /** insert a single row into the table: "payment.transaction" */
  insertPaymentTransactionOne?: Maybe<PaymentTransaction>;
  /** insert data into the table: "tariff.pass" */
  insertTariffPass?: Maybe<TariffPassMutationResponse>;
  /** insert data into the table: "tariff.pass_i18n" */
  insertTariffPassI18n?: Maybe<TariffPassI18nMutationResponse>;
  /** insert a single row into the table: "tariff.pass_i18n" */
  insertTariffPassI18nOne?: Maybe<TariffPassI18n>;
  /** insert a single row into the table: "tariff.pass" */
  insertTariffPassOne?: Maybe<TariffPass>;
  /** insert data into the table: "tariff.pass_user" */
  insertTariffPassUser?: Maybe<TariffPassUserMutationResponse>;
  /** insert a single row into the table: "tariff.pass_user" */
  insertTariffPassUserOne?: Maybe<TariffPassUser>;
  /** insert data into the table: "tariff.tariff" */
  insertTariffTariff?: Maybe<TariffTariffMutationResponse>;
  /** insert a single row into the table: "tariff.tariff" */
  insertTariffTariffOne?: Maybe<TariffTariff>;
  /** insert a single row into the table: "ziferblat.address_i18n" */
  insertZiferblatAddressI18n?: Maybe<ZiferblatAddressI18n>;
  /** insert data into the table: "ziferblat.address_i18n" */
  insertZiferblatAddressI18ns?: Maybe<ZiferblatAddressI18nMutationResponse>;
  /** insert data into the table: "ziferblat.city_i18n" */
  insertZiferblatCityI18n?: Maybe<ZiferblatCityI18nMutationResponse>;
  /** insert a single row into the table: "ziferblat.city_i18n" */
  insertZiferblatCityI18nOne?: Maybe<ZiferblatCityI18n>;
  /** insert data into the table: "ziferblat.gallery" */
  insertZiferblatGallery?: Maybe<ZiferblatGalleryMutationResponse>;
  /** insert a single row into the table: "ziferblat.ziferblat_i18n" */
  insertZiferblatI18n?: Maybe<ZiferblatZiferblatI18n>;
  /** insert data into the table: "ziferblat.ziferblat_i18n" */
  insertZiferblatI18ns?: Maybe<ZiferblatZiferblatI18nMutationResponse>;
  /** insert data into the table: "ziferblat.juridical_i18n" */
  insertZiferblatJuridicalI18n?: Maybe<ZiferblatJuridicalI18nMutationResponse>;
  /** insert a single row into the table: "ziferblat.juridical_i18n" */
  insertZiferblatJuridicalI18nOne?: Maybe<ZiferblatJuridicalI18n>;
  /** insert data into the table: "ziferblat.lang" */
  insertZiferblatLang?: Maybe<ZiferblatLangMutationResponse>;
  /** insert a single row into the table: "ziferblat.lang" */
  insertZiferblatLangOne?: Maybe<ZiferblatLang>;
  /** insert data into the table: "ziferblat.room" */
  insertZiferblatRoom?: Maybe<ZiferblatRoomMutationResponse>;
  /** insert data into the table: "ziferblat.room_i18n" */
  insertZiferblatRoomI18n?: Maybe<ZiferblatRoomI18nMutationResponse>;
  /** insert a single row into the table: "ziferblat.room_i18n" */
  insertZiferblatRoomI18nOne?: Maybe<ZiferblatRoomI18n>;
  /** insert a single row into the table: "ziferblat.room" */
  insertZiferblatRoomOne?: Maybe<ZiferblatRoom>;
  /** insert a single row into the table: "auth.logout" */
  logout?: Maybe<AuthLogout>;
  /** delete data from the table: "cultural_event.attendance" */
  removeAttendance?: Maybe<CulturalEventAttendanceMutationResponse>;
  /** delete single row from the table: "ziferblat.contact" */
  removeContact?: Maybe<ZiferblatContact>;
  /** delete data from the table: "ziferblat.contact" */
  removeContacts?: Maybe<ZiferblatContactMutationResponse>;
  /** delete single row from the table: "cultural_event.cultural_event_image" */
  removeCulturalEventImage?: Maybe<CulturalEventImage>;
  /** delete data from the table: "cultural_event.cultural_event_image" */
  removeCulturalEventImages?: Maybe<CulturalEventImageMutationResponse>;
  /** insert a single row into the table: "access.expire" */
  removeGrant?: Maybe<AccessExpire>;
  /** delete single row from the table: "cultural_event.ticket" */
  removeTicket?: Maybe<CulturalEventTicket>;
  /** delete data from the table: "cultural_event.ticket" */
  removeTickets?: Maybe<CulturalEventTicketMutationResponse>;
  /** insert a single row into the table: "auth.telegram_input" */
  telegramLogin?: Maybe<AuthTelegramInput>;
  /** update data of the table: "access.expire" */
  updateAccessExpire?: Maybe<AccessExpireMutationResponse>;
  /** update single row of the table: "access.expire" */
  updateAccessExpireByPk?: Maybe<AccessExpire>;
  /** update multiples rows of table: "access.expire" */
  updateAccessExpireMany?: Maybe<Array<Maybe<AccessExpireMutationResponse>>>;
  /** update data of the table: "access.grant" */
  updateAccessGrant?: Maybe<AccessGrantMutationResponse>;
  /** update single row of the table: "access.grant" */
  updateAccessGrantByPk?: Maybe<AccessGrant>;
  /** update multiples rows of table: "access.grant" */
  updateAccessGrantMany?: Maybe<Array<Maybe<AccessGrantMutationResponse>>>;
  /** update data of the table: "access.permission" */
  updateAccessPermission?: Maybe<AccessPermissionMutationResponse>;
  /** update single row of the table: "access.permission" */
  updateAccessPermissionByPk?: Maybe<AccessPermission>;
  /** update multiples rows of table: "access.permission" */
  updateAccessPermissionMany?: Maybe<Array<Maybe<AccessPermissionMutationResponse>>>;
  /** update data of the table: "access.role" */
  updateAccessRole?: Maybe<AccessRoleMutationResponse>;
  /** update single row of the table: "access.role" */
  updateAccessRoleByPk?: Maybe<AccessRole>;
  /** update multiples rows of table: "access.role" */
  updateAccessRoleMany?: Maybe<Array<Maybe<AccessRoleMutationResponse>>>;
  /** update data of the table: "audit.log" */
  updateAuditLog?: Maybe<AuditLogMutationResponse>;
  /** update single row of the table: "audit.log" */
  updateAuditLogByPk?: Maybe<AuditLog>;
  /** update multiples rows of table: "audit.log" */
  updateAuditLogMany?: Maybe<Array<Maybe<AuditLogMutationResponse>>>;
  /** update data of the table: "auth.alias" */
  updateAuthAlias?: Maybe<AuthAliasMutationResponse>;
  /** update single row of the table: "auth.alias" */
  updateAuthAliasByPk?: Maybe<AuthAlias>;
  /** update multiples rows of table: "auth.alias" */
  updateAuthAliasMany?: Maybe<Array<Maybe<AuthAliasMutationResponse>>>;
  /** update data of the table: "auth.logout" */
  updateAuthLogout?: Maybe<AuthLogoutMutationResponse>;
  /** update single row of the table: "auth.logout" */
  updateAuthLogoutByPk?: Maybe<AuthLogout>;
  /** update multiples rows of table: "auth.logout" */
  updateAuthLogoutMany?: Maybe<Array<Maybe<AuthLogoutMutationResponse>>>;
  /** update data of the table: "auth.session" */
  updateAuthSession?: Maybe<AuthSessionMutationResponse>;
  /** update single row of the table: "auth.session" */
  updateAuthSessionByPk?: Maybe<AuthSession>;
  /** update multiples rows of table: "auth.session" */
  updateAuthSessionMany?: Maybe<Array<Maybe<AuthSessionMutationResponse>>>;
  /** update data of the table: "auth.telegram_bot" */
  updateAuthTelegramBot?: Maybe<AuthTelegramBotMutationResponse>;
  /** update single row of the table: "auth.telegram_bot" */
  updateAuthTelegramBotByPk?: Maybe<AuthTelegramBot>;
  /** update multiples rows of table: "auth.telegram_bot" */
  updateAuthTelegramBotMany?: Maybe<Array<Maybe<AuthTelegramBotMutationResponse>>>;
  /** update data of the table: "auth.telegram_input" */
  updateAuthTelegramInput?: Maybe<AuthTelegramInputMutationResponse>;
  /** update single row of the table: "auth.telegram_input" */
  updateAuthTelegramInputByPk?: Maybe<AuthTelegramInput>;
  /** update multiples rows of table: "auth.telegram_input" */
  updateAuthTelegramInputMany?: Maybe<Array<Maybe<AuthTelegramInputMutationResponse>>>;
  /** update data of the table: "auth.user" */
  updateAuthUser?: Maybe<AuthUserMutationResponse>;
  /** update single row of the table: "auth.user" */
  updateAuthUserByPk?: Maybe<AuthUser>;
  /** update multiples rows of table: "auth.user" */
  updateAuthUserMany?: Maybe<Array<Maybe<AuthUserMutationResponse>>>;
  /** update data of the table: "booking" */
  updateBooking?: Maybe<BookingMutationResponse>;
  /** update single row of the table: "booking" */
  updateBookingByPk?: Maybe<Booking>;
  /** update data of the table: "booking_checkin" */
  updateBookingCheckin?: Maybe<BookingCheckinMutationResponse>;
  /** update single row of the table: "booking_checkin" */
  updateBookingCheckinByPk?: Maybe<BookingCheckin>;
  /** update multiples rows of table: "booking_checkin" */
  updateBookingCheckinMany?: Maybe<Array<Maybe<BookingCheckinMutationResponse>>>;
  /** update multiples rows of table: "booking" */
  updateBookingMany?: Maybe<Array<Maybe<BookingMutationResponse>>>;
  /** update single row of the table: "checkin" */
  updateCheckin?: Maybe<Checkin>;
  /** update multiples rows of table: "checkin" */
  updateCheckinMany?: Maybe<Array<Maybe<CheckinMutationResponse>>>;
  /** update data of the table: "checkin" */
  updateCheckins?: Maybe<CheckinMutationResponse>;
  /** update data of the table: "cultural_event.compensation_currency" */
  updateCompensationCurrency?: Maybe<CompensationCurrencyMutationResponse>;
  /** update single row of the table: "cultural_event.compensation_currency" */
  updateCompensationCurrencyByPk?: Maybe<CompensationCurrency>;
  /** update multiples rows of table: "cultural_event.compensation_currency" */
  updateCompensationCurrencyMany?: Maybe<Array<Maybe<CompensationCurrencyMutationResponse>>>;
  /** update data of the table: "cultural_event.compensation_unit" */
  updateCompensationUnit?: Maybe<CompensationUnitMutationResponse>;
  /** update single row of the table: "cultural_event.compensation_unit" */
  updateCompensationUnitByPk?: Maybe<CompensationUnit>;
  /** update multiples rows of table: "cultural_event.compensation_unit" */
  updateCompensationUnitMany?: Maybe<Array<Maybe<CompensationUnitMutationResponse>>>;
  /** update single row of the table: "ziferblat.contact" */
  updateContact?: Maybe<ZiferblatContact>;
  /** update data of the table: "ziferblat.contact" */
  updateContacts?: Maybe<ZiferblatContactMutationResponse>;
  /** update single row of the table: "cultural_event.cultural_event" */
  updateCulturalEvent?: Maybe<CulturalEvent>;
  /** update data of the table: "cultural_event.attendance" */
  updateCulturalEventAttendance?: Maybe<CulturalEventAttendanceMutationResponse>;
  /** update single row of the table: "cultural_event.attendance" */
  updateCulturalEventAttendanceByPk?: Maybe<CulturalEventAttendance>;
  /** update multiples rows of table: "cultural_event.attendance" */
  updateCulturalEventAttendanceMany?: Maybe<Array<Maybe<CulturalEventAttendanceMutationResponse>>>;
  /** update data of the table: "cultural_event.compensation" */
  updateCulturalEventCompensation?: Maybe<CulturalEventCompensationMutationResponse>;
  /** update single row of the table: "cultural_event.compensation" */
  updateCulturalEventCompensationByPk?: Maybe<CulturalEventCompensation>;
  /** update multiples rows of table: "cultural_event.compensation" */
  updateCulturalEventCompensationMany?: Maybe<Array<Maybe<CulturalEventCompensationMutationResponse>>>;
  /** update data of the table: "cultural_event.cultural_event_image" */
  updateCulturalEventImage?: Maybe<CulturalEventImageMutationResponse>;
  /** update single row of the table: "cultural_event.cultural_event_image" */
  updateCulturalEventImageByPk?: Maybe<CulturalEventImage>;
  /** update multiples rows of table: "cultural_event.cultural_event_image" */
  updateCulturalEventImageMany?: Maybe<Array<Maybe<CulturalEventImageMutationResponse>>>;
  /** update data of the table: "cultural_event.prepaid_attendance" */
  updateCulturalEventPrepaidAttendance?: Maybe<CulturalEventPrepaidAttendanceMutationResponse>;
  /** update single row of the table: "cultural_event.prepaid_attendance" */
  updateCulturalEventPrepaidAttendanceByPk?: Maybe<CulturalEventPrepaidAttendance>;
  /** update multiples rows of table: "cultural_event.prepaid_attendance" */
  updateCulturalEventPrepaidAttendanceMany?: Maybe<Array<Maybe<CulturalEventPrepaidAttendanceMutationResponse>>>;
  /** update data of the table: "cultural_event.status" */
  updateCulturalEventStatus?: Maybe<CulturalEventStatusMutationResponse>;
  /** update single row of the table: "cultural_event.status" */
  updateCulturalEventStatusByPk?: Maybe<CulturalEventStatus>;
  /** update multiples rows of table: "cultural_event.status" */
  updateCulturalEventStatusMany?: Maybe<Array<Maybe<CulturalEventStatusMutationResponse>>>;
  /** update data of the table: "cultural_event.ticket" */
  updateCulturalEventTicket?: Maybe<CulturalEventTicketMutationResponse>;
  /** update single row of the table: "cultural_event.ticket" */
  updateCulturalEventTicketByPk?: Maybe<CulturalEventTicket>;
  /** update multiples rows of table: "cultural_event.ticket" */
  updateCulturalEventTicketMany?: Maybe<Array<Maybe<CulturalEventTicketMutationResponse>>>;
  /** update data of the table: "cultural_event.cultural_event" */
  updateCulturalEvents?: Maybe<CulturalEventMutationResponse>;
  /** update data of the table: "media.media" */
  updateMedia?: Maybe<MediaMutationResponse>;
  /** update single row of the table: "media.media" */
  updateMediaByPk?: Maybe<Media>;
  /** update data of the table: "media.config" */
  updateMediaConfig?: Maybe<MediaConfigMutationResponse>;
  /** update single row of the table: "media.config" */
  updateMediaConfigByPk?: Maybe<MediaConfig>;
  /** update multiples rows of table: "media.config" */
  updateMediaConfigMany?: Maybe<Array<Maybe<MediaConfigMutationResponse>>>;
  /** update multiples rows of table: "media.media" */
  updateMediaMany?: Maybe<Array<Maybe<MediaMutationResponse>>>;
  /** update multiples rows of table: "cultural_event.cultural_event" */
  updateMultipleCulturalEvents?: Maybe<Array<Maybe<CulturalEventMutationResponse>>>;
  /** update data of the table: "pause" */
  updatePause?: Maybe<PauseMutationResponse>;
  /** update single row of the table: "pause" */
  updatePauseByPk?: Maybe<Pause>;
  /** update multiples rows of table: "pause" */
  updatePauseMany?: Maybe<Array<Maybe<PauseMutationResponse>>>;
  /** update data of the table: "payment.config" */
  updatePaymentConfig?: Maybe<PaymentConfigMutationResponse>;
  /** update single row of the table: "payment.config" */
  updatePaymentConfigByPk?: Maybe<PaymentConfig>;
  /** update multiples rows of table: "payment.config" */
  updatePaymentConfigMany?: Maybe<Array<Maybe<PaymentConfigMutationResponse>>>;
  /** update data of the table: "payment.return" */
  updatePaymentReturn?: Maybe<PaymentReturnMutationResponse>;
  /** update single row of the table: "payment.return" */
  updatePaymentReturnByPk?: Maybe<PaymentReturn>;
  /** update multiples rows of table: "payment.return" */
  updatePaymentReturnMany?: Maybe<Array<Maybe<PaymentReturnMutationResponse>>>;
  /** update data of the table: "payment.transaction" */
  updatePaymentTransaction?: Maybe<PaymentTransactionMutationResponse>;
  /** update single row of the table: "payment.transaction" */
  updatePaymentTransactionByPk?: Maybe<PaymentTransaction>;
  /** update multiples rows of table: "payment.transaction" */
  updatePaymentTransactionMany?: Maybe<Array<Maybe<PaymentTransactionMutationResponse>>>;
  /** update data of the table: "tariff.pass" */
  updateTariffPass?: Maybe<TariffPassMutationResponse>;
  /** update single row of the table: "tariff.pass" */
  updateTariffPassByPk?: Maybe<TariffPass>;
  /** update data of the table: "tariff.pass_i18n" */
  updateTariffPassI18n?: Maybe<TariffPassI18nMutationResponse>;
  /** update single row of the table: "tariff.pass_i18n" */
  updateTariffPassI18nByPk?: Maybe<TariffPassI18n>;
  /** update multiples rows of table: "tariff.pass_i18n" */
  updateTariffPassI18nMany?: Maybe<Array<Maybe<TariffPassI18nMutationResponse>>>;
  /** update multiples rows of table: "tariff.pass" */
  updateTariffPassMany?: Maybe<Array<Maybe<TariffPassMutationResponse>>>;
  /** update data of the table: "tariff.pass_user" */
  updateTariffPassUser?: Maybe<TariffPassUserMutationResponse>;
  /** update single row of the table: "tariff.pass_user" */
  updateTariffPassUserByPk?: Maybe<TariffPassUser>;
  /** update multiples rows of table: "tariff.pass_user" */
  updateTariffPassUserMany?: Maybe<Array<Maybe<TariffPassUserMutationResponse>>>;
  /** update data of the table: "tariff.tariff" */
  updateTariffTariff?: Maybe<TariffTariffMutationResponse>;
  /** update single row of the table: "tariff.tariff" */
  updateTariffTariffByPk?: Maybe<TariffTariff>;
  /** update multiples rows of table: "tariff.tariff" */
  updateTariffTariffMany?: Maybe<Array<Maybe<TariffTariffMutationResponse>>>;
  /** update data of the table: "visit" */
  updateVisit?: Maybe<VisitMutationResponse>;
  /** update single row of the table: "visit" */
  updateVisitByPk?: Maybe<Visit>;
  /** update multiples rows of table: "visit" */
  updateVisitMany?: Maybe<Array<Maybe<VisitMutationResponse>>>;
  /** update single row of the table: "ziferblat.ziferblat" */
  updateZiferblat?: Maybe<ZiferblatZiferblat>;
  /** update single row of the table: "ziferblat.address_i18n" */
  updateZiferblatAddressI18n?: Maybe<ZiferblatAddressI18n>;
  /** update multiples rows of table: "ziferblat.address_i18n" */
  updateZiferblatAddressI18nMany?: Maybe<Array<Maybe<ZiferblatAddressI18nMutationResponse>>>;
  /** update data of the table: "ziferblat.address_i18n" */
  updateZiferblatAddressI18ns?: Maybe<ZiferblatAddressI18nMutationResponse>;
  /** update data of the table: "ziferblat.city_i18n" */
  updateZiferblatCityI18n?: Maybe<ZiferblatCityI18nMutationResponse>;
  /** update single row of the table: "ziferblat.city_i18n" */
  updateZiferblatCityI18nByPk?: Maybe<ZiferblatCityI18n>;
  /** update multiples rows of table: "ziferblat.city_i18n" */
  updateZiferblatCityI18nMany?: Maybe<Array<Maybe<ZiferblatCityI18nMutationResponse>>>;
  /** update multiples rows of table: "ziferblat.contact" */
  updateZiferblatContactMany?: Maybe<Array<Maybe<ZiferblatContactMutationResponse>>>;
  /** update data of the table: "ziferblat.gallery" */
  updateZiferblatGallery?: Maybe<ZiferblatGalleryMutationResponse>;
  /** update single row of the table: "ziferblat.gallery" */
  updateZiferblatGalleryByPk?: Maybe<ZiferblatGallery>;
  /** update multiples rows of table: "ziferblat.gallery" */
  updateZiferblatGalleryMany?: Maybe<Array<Maybe<ZiferblatGalleryMutationResponse>>>;
  /** update single row of the table: "ziferblat.ziferblat_i18n" */
  updateZiferblatI18n?: Maybe<ZiferblatZiferblatI18n>;
  /** update data of the table: "ziferblat.ziferblat_i18n" */
  updateZiferblatI18ns?: Maybe<ZiferblatZiferblatI18nMutationResponse>;
  /** update data of the table: "ziferblat.juridical_i18n" */
  updateZiferblatJuridicalI18n?: Maybe<ZiferblatJuridicalI18nMutationResponse>;
  /** update single row of the table: "ziferblat.juridical_i18n" */
  updateZiferblatJuridicalI18nByPk?: Maybe<ZiferblatJuridicalI18n>;
  /** update multiples rows of table: "ziferblat.juridical_i18n" */
  updateZiferblatJuridicalI18nMany?: Maybe<Array<Maybe<ZiferblatJuridicalI18nMutationResponse>>>;
  /** update data of the table: "ziferblat.lang" */
  updateZiferblatLang?: Maybe<ZiferblatLangMutationResponse>;
  /** update single row of the table: "ziferblat.lang" */
  updateZiferblatLangByPk?: Maybe<ZiferblatLang>;
  /** update multiples rows of table: "ziferblat.lang" */
  updateZiferblatLangMany?: Maybe<Array<Maybe<ZiferblatLangMutationResponse>>>;
  /** update data of the table: "ziferblat.room" */
  updateZiferblatRoom?: Maybe<ZiferblatRoomMutationResponse>;
  /** update single row of the table: "ziferblat.room" */
  updateZiferblatRoomByPk?: Maybe<ZiferblatRoom>;
  /** update data of the table: "ziferblat.room_i18n" */
  updateZiferblatRoomI18n?: Maybe<ZiferblatRoomI18nMutationResponse>;
  /** update single row of the table: "ziferblat.room_i18n" */
  updateZiferblatRoomI18nByPk?: Maybe<ZiferblatRoomI18n>;
  /** update multiples rows of table: "ziferblat.room_i18n" */
  updateZiferblatRoomI18nMany?: Maybe<Array<Maybe<ZiferblatRoomI18nMutationResponse>>>;
  /** update multiples rows of table: "ziferblat.room" */
  updateZiferblatRoomMany?: Maybe<Array<Maybe<ZiferblatRoomMutationResponse>>>;
  /** update multiples rows of table: "ziferblat.ziferblat_i18n" */
  updateZiferblatZiferblatI18nMany?: Maybe<Array<Maybe<ZiferblatZiferblatI18nMutationResponse>>>;
  /** update multiples rows of table: "ziferblat.ziferblat" */
  updateZiferblatZiferblatMany?: Maybe<Array<Maybe<ZiferblatZiferblatMutationResponse>>>;
  /** update data of the table: "ziferblat.ziferblat" */
  updateZiferblats?: Maybe<ZiferblatZiferblatMutationResponse>;
};


/** mutation root */
export type Mutation_RootAddAttendanceArgs = {
  object: CulturalEventAttendanceInsertInput;
  onConflict?: InputMaybe<CulturalEventAttendanceOnConflict>;
};


/** mutation root */
export type Mutation_RootAddCheckinArgs = {
  object: CheckinInsertInput;
  onConflict?: InputMaybe<CheckinOnConflict>;
};


/** mutation root */
export type Mutation_RootAddCheckinsArgs = {
  objects: Array<CheckinInsertInput>;
  onConflict?: InputMaybe<CheckinOnConflict>;
};


/** mutation root */
export type Mutation_RootAddContactArgs = {
  object: ZiferblatContactInsertInput;
  onConflict?: InputMaybe<ZiferblatContactOnConflict>;
};


/** mutation root */
export type Mutation_RootAddContactsArgs = {
  objects: Array<ZiferblatContactInsertInput>;
  onConflict?: InputMaybe<ZiferblatContactOnConflict>;
};


/** mutation root */
export type Mutation_RootAddCulturalEventArgs = {
  object: CulturalEventInsertInput;
  onConflict?: InputMaybe<CulturalEventOnConflict>;
};


/** mutation root */
export type Mutation_RootAddCulturalEventImageArgs = {
  object: CulturalEventImageInsertInput;
  onConflict?: InputMaybe<CulturalEventImageOnConflict>;
};


/** mutation root */
export type Mutation_RootAddCulturalEventImagesArgs = {
  objects: Array<CulturalEventImageInsertInput>;
  onConflict?: InputMaybe<CulturalEventImageOnConflict>;
};


/** mutation root */
export type Mutation_RootAddCulturalEventsArgs = {
  objects: Array<CulturalEventInsertInput>;
  onConflict?: InputMaybe<CulturalEventOnConflict>;
};


/** mutation root */
export type Mutation_RootAddMediaArgs = {
  object: MediaInsertInput;
  onConflict?: InputMaybe<MediaOnConflict>;
};


/** mutation root */
export type Mutation_RootAddMediasArgs = {
  objects: Array<MediaInsertInput>;
  onConflict?: InputMaybe<MediaOnConflict>;
};


/** mutation root */
export type Mutation_RootAddToGalleryArgs = {
  object: ZiferblatGalleryInsertInput;
  onConflict?: InputMaybe<ZiferblatGalleryOnConflict>;
};


/** mutation root */
export type Mutation_RootAddUserArgs = {
  object: AuthUserInsertInput;
  onConflict?: InputMaybe<AuthUserOnConflict>;
};


/** mutation root */
export type Mutation_RootAddUsersArgs = {
  objects: Array<AuthUserInsertInput>;
  onConflict?: InputMaybe<AuthUserOnConflict>;
};


/** mutation root */
export type Mutation_RootAddZiferblatArgs = {
  object: ZiferblatZiferblatInsertInput;
  onConflict?: InputMaybe<ZiferblatZiferblatOnConflict>;
};


/** mutation root */
export type Mutation_RootAddZiferblatsArgs = {
  objects: Array<ZiferblatZiferblatInsertInput>;
  onConflict?: InputMaybe<ZiferblatZiferblatOnConflict>;
};


/** mutation root */
export type Mutation_RootCheckOutArgs = {
  object: VisitInsertInput;
  onConflict?: InputMaybe<VisitOnConflict>;
};


/** mutation root */
export type Mutation_RootCheckOutMultipleArgs = {
  objects: Array<VisitInsertInput>;
  onConflict?: InputMaybe<VisitOnConflict>;
};


/** mutation root */
export type Mutation_RootCulturalEventToggleAttendanceArgs = {
  args: CulturalEventToggleAttendanceArgs;
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


/** mutation root */
export type Mutation_RootDeleteAccessExpireArgs = {
  where: AccessExpireBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAccessExpireByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAccessGrantArgs = {
  where: AccessGrantBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAccessGrantByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAccessPermissionArgs = {
  where: AccessPermissionBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAccessPermissionByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAccessRoleArgs = {
  where: AccessRoleBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAccessRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuditLogArgs = {
  where: AuditLogBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuditLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthAliasArgs = {
  where: AuthAliasBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthAliasByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthLogoutArgs = {
  where: AuthLogoutBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthLogoutByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthSessionArgs = {
  where: AuthSessionBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthSessionByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthTelegramBotArgs = {
  where: AuthTelegramBotBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthTelegramBotByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthTelegramInputArgs = {
  where: AuthTelegramInputBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthTelegramInputByPkArgs = {
  hash: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthUserArgs = {
  where: AuthUserBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthUserByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteBookingArgs = {
  where: BookingBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteBookingByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteBookingCheckinArgs = {
  where: BookingCheckinBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteBookingCheckinByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCheckinArgs = {
  where: CheckinBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCheckinByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCompensationCurrencyArgs = {
  where: CompensationCurrencyBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCompensationCurrencyByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCompensationUnitArgs = {
  where: CompensationUnitBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCompensationUnitByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventArgs = {
  where: CulturalEventBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventAttendanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventCompensationArgs = {
  where: CulturalEventCompensationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventCompensationByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventPrepaidAttendanceArgs = {
  where: CulturalEventPrepaidAttendanceBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventPrepaidAttendanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventStatusArgs = {
  where: CulturalEventStatusBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventStatusByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteMediaArgs = {
  where: MediaBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteMediaByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteMediaConfigArgs = {
  where: MediaConfigBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteMediaConfigByPkArgs = {
  bucket: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeletePauseArgs = {
  where: PauseBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePauseByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeletePaymentConfigArgs = {
  where: PaymentConfigBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePaymentConfigByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeletePaymentReturnArgs = {
  where: PaymentReturnBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePaymentReturnByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeletePaymentTransactionArgs = {
  where: PaymentTransactionBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePaymentTransactionByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteTariffPassArgs = {
  where: TariffPassBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTariffPassByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteTariffPassI18nArgs = {
  where: TariffPassI18nBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTariffPassI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteTariffPassUserArgs = {
  where: TariffPassUserBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTariffPassUserByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteTariffTariffArgs = {
  where: TariffTariffBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTariffTariffByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteVisitArgs = {
  where: VisitBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteVisitByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteZiferblatAddressI18nArgs = {
  where: ZiferblatAddressI18nBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteZiferblatAddressI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteZiferblatCityI18nArgs = {
  where: ZiferblatCityI18nBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteZiferblatCityI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteZiferblatGalleryArgs = {
  where: ZiferblatGalleryBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteZiferblatGalleryByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteZiferblatJuridicalI18nArgs = {
  where: ZiferblatJuridicalI18nBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteZiferblatJuridicalI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteZiferblatLangArgs = {
  where: ZiferblatLangBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteZiferblatLangByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteZiferblatRoomArgs = {
  where: ZiferblatRoomBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteZiferblatRoomByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteZiferblatRoomI18nArgs = {
  where: ZiferblatRoomI18nBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteZiferblatRoomI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteZiferblatZiferblatArgs = {
  where: ZiferblatZiferblatBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteZiferblatZiferblatByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteZiferblatZiferblatI18nArgs = {
  where: ZiferblatZiferblatI18nBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteZiferblatZiferblatI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootGetUploadUrlArgs = {
  contentType?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootInsertAccessExpireArgs = {
  objects: Array<AccessExpireInsertInput>;
  onConflict?: InputMaybe<AccessExpireOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessGrantArgs = {
  objects: Array<AccessGrantInsertInput>;
  onConflict?: InputMaybe<AccessGrantOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessGrantOneArgs = {
  object: AccessGrantInsertInput;
  onConflict?: InputMaybe<AccessGrantOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessPermissionArgs = {
  objects: Array<AccessPermissionInsertInput>;
  onConflict?: InputMaybe<AccessPermissionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessPermissionOneArgs = {
  object: AccessPermissionInsertInput;
  onConflict?: InputMaybe<AccessPermissionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessRoleArgs = {
  objects: Array<AccessRoleInsertInput>;
  onConflict?: InputMaybe<AccessRoleOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessRoleOneArgs = {
  object: AccessRoleInsertInput;
  onConflict?: InputMaybe<AccessRoleOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuditLogArgs = {
  objects: Array<AuditLogInsertInput>;
  onConflict?: InputMaybe<AuditLogOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuditLogOneArgs = {
  object: AuditLogInsertInput;
  onConflict?: InputMaybe<AuditLogOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthAliasArgs = {
  objects: Array<AuthAliasInsertInput>;
  onConflict?: InputMaybe<AuthAliasOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthAliasOneArgs = {
  object: AuthAliasInsertInput;
  onConflict?: InputMaybe<AuthAliasOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthLogoutArgs = {
  objects: Array<AuthLogoutInsertInput>;
  onConflict?: InputMaybe<AuthLogoutOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthSessionArgs = {
  objects: Array<AuthSessionInsertInput>;
  onConflict?: InputMaybe<AuthSessionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthSessionOneArgs = {
  object: AuthSessionInsertInput;
  onConflict?: InputMaybe<AuthSessionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthTelegramBotArgs = {
  objects: Array<AuthTelegramBotInsertInput>;
  onConflict?: InputMaybe<AuthTelegramBotOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthTelegramBotOneArgs = {
  object: AuthTelegramBotInsertInput;
  onConflict?: InputMaybe<AuthTelegramBotOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthTelegramInputArgs = {
  objects: Array<AuthTelegramInputInsertInput>;
  onConflict?: InputMaybe<AuthTelegramInputOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertBookingArgs = {
  objects: Array<BookingInsertInput>;
  onConflict?: InputMaybe<BookingOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertBookingCheckinArgs = {
  objects: Array<BookingCheckinInsertInput>;
  onConflict?: InputMaybe<BookingCheckinOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertBookingCheckinOneArgs = {
  object: BookingCheckinInsertInput;
  onConflict?: InputMaybe<BookingCheckinOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertBookingOneArgs = {
  object: BookingInsertInput;
  onConflict?: InputMaybe<BookingOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCompensationCurrencyArgs = {
  objects: Array<CompensationCurrencyInsertInput>;
  onConflict?: InputMaybe<CompensationCurrencyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCompensationCurrencyOneArgs = {
  object: CompensationCurrencyInsertInput;
  onConflict?: InputMaybe<CompensationCurrencyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCompensationUnitArgs = {
  objects: Array<CompensationUnitInsertInput>;
  onConflict?: InputMaybe<CompensationUnitOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCompensationUnitOneArgs = {
  object: CompensationUnitInsertInput;
  onConflict?: InputMaybe<CompensationUnitOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventAttendanceArgs = {
  objects: Array<CulturalEventAttendanceInsertInput>;
  onConflict?: InputMaybe<CulturalEventAttendanceOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventCompensationArgs = {
  objects: Array<CulturalEventCompensationInsertInput>;
  onConflict?: InputMaybe<CulturalEventCompensationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventCompensationOneArgs = {
  object: CulturalEventCompensationInsertInput;
  onConflict?: InputMaybe<CulturalEventCompensationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventPrepaidAttendanceArgs = {
  objects: Array<CulturalEventPrepaidAttendanceInsertInput>;
  onConflict?: InputMaybe<CulturalEventPrepaidAttendanceOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventPrepaidAttendanceOneArgs = {
  object: CulturalEventPrepaidAttendanceInsertInput;
  onConflict?: InputMaybe<CulturalEventPrepaidAttendanceOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventStatusArgs = {
  objects: Array<CulturalEventStatusInsertInput>;
  onConflict?: InputMaybe<CulturalEventStatusOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventStatusOneArgs = {
  object: CulturalEventStatusInsertInput;
  onConflict?: InputMaybe<CulturalEventStatusOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventTicketArgs = {
  objects: Array<CulturalEventTicketInsertInput>;
  onConflict?: InputMaybe<CulturalEventTicketOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventTicketOneArgs = {
  object: CulturalEventTicketInsertInput;
  onConflict?: InputMaybe<CulturalEventTicketOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMediaConfigArgs = {
  objects: Array<MediaConfigInsertInput>;
  onConflict?: InputMaybe<MediaConfigOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMediaConfigOneArgs = {
  object: MediaConfigInsertInput;
  onConflict?: InputMaybe<MediaConfigOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPauseArgs = {
  objects: Array<PauseInsertInput>;
  onConflict?: InputMaybe<PauseOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPauseOneArgs = {
  object: PauseInsertInput;
  onConflict?: InputMaybe<PauseOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPaymentConfigArgs = {
  objects: Array<PaymentConfigInsertInput>;
  onConflict?: InputMaybe<PaymentConfigOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPaymentConfigOneArgs = {
  object: PaymentConfigInsertInput;
  onConflict?: InputMaybe<PaymentConfigOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPaymentReturnArgs = {
  objects: Array<PaymentReturnInsertInput>;
  onConflict?: InputMaybe<PaymentReturnOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPaymentReturnOneArgs = {
  object: PaymentReturnInsertInput;
  onConflict?: InputMaybe<PaymentReturnOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPaymentTransactionArgs = {
  objects: Array<PaymentTransactionInsertInput>;
  onConflict?: InputMaybe<PaymentTransactionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPaymentTransactionOneArgs = {
  object: PaymentTransactionInsertInput;
  onConflict?: InputMaybe<PaymentTransactionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTariffPassArgs = {
  objects: Array<TariffPassInsertInput>;
  onConflict?: InputMaybe<TariffPassOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTariffPassI18nArgs = {
  objects: Array<TariffPassI18nInsertInput>;
  onConflict?: InputMaybe<TariffPassI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTariffPassI18nOneArgs = {
  object: TariffPassI18nInsertInput;
  onConflict?: InputMaybe<TariffPassI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTariffPassOneArgs = {
  object: TariffPassInsertInput;
  onConflict?: InputMaybe<TariffPassOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTariffPassUserArgs = {
  objects: Array<TariffPassUserInsertInput>;
  onConflict?: InputMaybe<TariffPassUserOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTariffPassUserOneArgs = {
  object: TariffPassUserInsertInput;
  onConflict?: InputMaybe<TariffPassUserOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTariffTariffArgs = {
  objects: Array<TariffTariffInsertInput>;
  onConflict?: InputMaybe<TariffTariffOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTariffTariffOneArgs = {
  object: TariffTariffInsertInput;
  onConflict?: InputMaybe<TariffTariffOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatAddressI18nArgs = {
  object: ZiferblatAddressI18nInsertInput;
  onConflict?: InputMaybe<ZiferblatAddressI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatAddressI18nsArgs = {
  objects: Array<ZiferblatAddressI18nInsertInput>;
  onConflict?: InputMaybe<ZiferblatAddressI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatCityI18nArgs = {
  objects: Array<ZiferblatCityI18nInsertInput>;
  onConflict?: InputMaybe<ZiferblatCityI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatCityI18nOneArgs = {
  object: ZiferblatCityI18nInsertInput;
  onConflict?: InputMaybe<ZiferblatCityI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatGalleryArgs = {
  objects: Array<ZiferblatGalleryInsertInput>;
  onConflict?: InputMaybe<ZiferblatGalleryOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatI18nArgs = {
  object: ZiferblatZiferblatI18nInsertInput;
  onConflict?: InputMaybe<ZiferblatZiferblatI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatI18nsArgs = {
  objects: Array<ZiferblatZiferblatI18nInsertInput>;
  onConflict?: InputMaybe<ZiferblatZiferblatI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatJuridicalI18nArgs = {
  objects: Array<ZiferblatJuridicalI18nInsertInput>;
  onConflict?: InputMaybe<ZiferblatJuridicalI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatJuridicalI18nOneArgs = {
  object: ZiferblatJuridicalI18nInsertInput;
  onConflict?: InputMaybe<ZiferblatJuridicalI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatLangArgs = {
  objects: Array<ZiferblatLangInsertInput>;
  onConflict?: InputMaybe<ZiferblatLangOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatLangOneArgs = {
  object: ZiferblatLangInsertInput;
  onConflict?: InputMaybe<ZiferblatLangOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatRoomArgs = {
  objects: Array<ZiferblatRoomInsertInput>;
  onConflict?: InputMaybe<ZiferblatRoomOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatRoomI18nArgs = {
  objects: Array<ZiferblatRoomI18nInsertInput>;
  onConflict?: InputMaybe<ZiferblatRoomI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatRoomI18nOneArgs = {
  object: ZiferblatRoomI18nInsertInput;
  onConflict?: InputMaybe<ZiferblatRoomI18nOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatRoomOneArgs = {
  object: ZiferblatRoomInsertInput;
  onConflict?: InputMaybe<ZiferblatRoomOnConflict>;
};


/** mutation root */
export type Mutation_RootLogoutArgs = {
  object: AuthLogoutInsertInput;
  onConflict?: InputMaybe<AuthLogoutOnConflict>;
};


/** mutation root */
export type Mutation_RootRemoveAttendanceArgs = {
  where: CulturalEventAttendanceBoolExp;
};


/** mutation root */
export type Mutation_RootRemoveContactArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootRemoveContactsArgs = {
  where: ZiferblatContactBoolExp;
};


/** mutation root */
export type Mutation_RootRemoveCulturalEventImageArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootRemoveCulturalEventImagesArgs = {
  where: CulturalEventImageBoolExp;
};


/** mutation root */
export type Mutation_RootRemoveGrantArgs = {
  object: AccessExpireInsertInput;
  onConflict?: InputMaybe<AccessExpireOnConflict>;
};


/** mutation root */
export type Mutation_RootRemoveTicketArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootRemoveTicketsArgs = {
  where: CulturalEventTicketBoolExp;
};


/** mutation root */
export type Mutation_RootTelegramLoginArgs = {
  object: AuthTelegramInputInsertInput;
  onConflict?: InputMaybe<AuthTelegramInputOnConflict>;
};


/** mutation root */
export type Mutation_RootUpdateAccessExpireArgs = {
  _set?: InputMaybe<AccessExpireSetInput>;
  where: AccessExpireBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAccessExpireByPkArgs = {
  _set?: InputMaybe<AccessExpireSetInput>;
  pkColumns: AccessExpirePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAccessExpireManyArgs = {
  updates: Array<AccessExpireUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAccessGrantArgs = {
  _set?: InputMaybe<AccessGrantSetInput>;
  where: AccessGrantBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAccessGrantByPkArgs = {
  _set?: InputMaybe<AccessGrantSetInput>;
  pkColumns: AccessGrantPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAccessGrantManyArgs = {
  updates: Array<AccessGrantUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAccessPermissionArgs = {
  _set?: InputMaybe<AccessPermissionSetInput>;
  where: AccessPermissionBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAccessPermissionByPkArgs = {
  _set?: InputMaybe<AccessPermissionSetInput>;
  pkColumns: AccessPermissionPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAccessPermissionManyArgs = {
  updates: Array<AccessPermissionUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAccessRoleArgs = {
  _set?: InputMaybe<AccessRoleSetInput>;
  where: AccessRoleBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAccessRoleByPkArgs = {
  _set?: InputMaybe<AccessRoleSetInput>;
  pkColumns: AccessRolePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAccessRoleManyArgs = {
  updates: Array<AccessRoleUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuditLogArgs = {
  _append?: InputMaybe<AuditLogAppendInput>;
  _deleteAtPath?: InputMaybe<AuditLogDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AuditLogDeleteElemInput>;
  _deleteKey?: InputMaybe<AuditLogDeleteKeyInput>;
  _prepend?: InputMaybe<AuditLogPrependInput>;
  _set?: InputMaybe<AuditLogSetInput>;
  where: AuditLogBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuditLogByPkArgs = {
  _append?: InputMaybe<AuditLogAppendInput>;
  _deleteAtPath?: InputMaybe<AuditLogDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AuditLogDeleteElemInput>;
  _deleteKey?: InputMaybe<AuditLogDeleteKeyInput>;
  _prepend?: InputMaybe<AuditLogPrependInput>;
  _set?: InputMaybe<AuditLogSetInput>;
  pkColumns: AuditLogPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuditLogManyArgs = {
  updates: Array<AuditLogUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthAliasArgs = {
  _set?: InputMaybe<AuthAliasSetInput>;
  where: AuthAliasBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthAliasByPkArgs = {
  _set?: InputMaybe<AuthAliasSetInput>;
  pkColumns: AuthAliasPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthAliasManyArgs = {
  updates: Array<AuthAliasUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthLogoutArgs = {
  _set?: InputMaybe<AuthLogoutSetInput>;
  where: AuthLogoutBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthLogoutByPkArgs = {
  _set?: InputMaybe<AuthLogoutSetInput>;
  pkColumns: AuthLogoutPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthLogoutManyArgs = {
  updates: Array<AuthLogoutUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthSessionArgs = {
  _set?: InputMaybe<AuthSessionSetInput>;
  where: AuthSessionBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthSessionByPkArgs = {
  _set?: InputMaybe<AuthSessionSetInput>;
  pkColumns: AuthSessionPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthSessionManyArgs = {
  updates: Array<AuthSessionUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramBotArgs = {
  _inc?: InputMaybe<AuthTelegramBotIncInput>;
  _set?: InputMaybe<AuthTelegramBotSetInput>;
  where: AuthTelegramBotBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramBotByPkArgs = {
  _inc?: InputMaybe<AuthTelegramBotIncInput>;
  _set?: InputMaybe<AuthTelegramBotSetInput>;
  pkColumns: AuthTelegramBotPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramBotManyArgs = {
  updates: Array<AuthTelegramBotUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramInputArgs = {
  _inc?: InputMaybe<AuthTelegramInputIncInput>;
  _set?: InputMaybe<AuthTelegramInputSetInput>;
  where: AuthTelegramInputBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramInputByPkArgs = {
  _inc?: InputMaybe<AuthTelegramInputIncInput>;
  _set?: InputMaybe<AuthTelegramInputSetInput>;
  pkColumns: AuthTelegramInputPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramInputManyArgs = {
  updates: Array<AuthTelegramInputUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserArgs = {
  _set?: InputMaybe<AuthUserSetInput>;
  where: AuthUserBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserByPkArgs = {
  _set?: InputMaybe<AuthUserSetInput>;
  pkColumns: AuthUserPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserManyArgs = {
  updates: Array<AuthUserUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateBookingArgs = {
  _set?: InputMaybe<BookingSetInput>;
  where: BookingBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateBookingByPkArgs = {
  _set?: InputMaybe<BookingSetInput>;
  pkColumns: BookingPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateBookingCheckinArgs = {
  _set?: InputMaybe<BookingCheckinSetInput>;
  where: BookingCheckinBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateBookingCheckinByPkArgs = {
  _set?: InputMaybe<BookingCheckinSetInput>;
  pkColumns: BookingCheckinPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateBookingCheckinManyArgs = {
  updates: Array<BookingCheckinUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateBookingManyArgs = {
  updates: Array<BookingUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCheckinArgs = {
  _set?: InputMaybe<CheckinSetInput>;
  pkColumns: CheckinPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCheckinManyArgs = {
  updates: Array<CheckinUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCheckinsArgs = {
  _set?: InputMaybe<CheckinSetInput>;
  where: CheckinBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCompensationCurrencyArgs = {
  _set?: InputMaybe<CompensationCurrencySetInput>;
  where: CompensationCurrencyBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCompensationCurrencyByPkArgs = {
  _set?: InputMaybe<CompensationCurrencySetInput>;
  pkColumns: CompensationCurrencyPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCompensationCurrencyManyArgs = {
  updates: Array<CompensationCurrencyUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCompensationUnitArgs = {
  _set?: InputMaybe<CompensationUnitSetInput>;
  where: CompensationUnitBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCompensationUnitByPkArgs = {
  _set?: InputMaybe<CompensationUnitSetInput>;
  pkColumns: CompensationUnitPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCompensationUnitManyArgs = {
  updates: Array<CompensationUnitUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateContactArgs = {
  _set?: InputMaybe<ZiferblatContactSetInput>;
  pkColumns: ZiferblatContactPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateContactsArgs = {
  _set?: InputMaybe<ZiferblatContactSetInput>;
  where: ZiferblatContactBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventArgs = {
  _set?: InputMaybe<CulturalEventSetInput>;
  pkColumns: CulturalEventPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventAttendanceArgs = {
  _set?: InputMaybe<CulturalEventAttendanceSetInput>;
  where: CulturalEventAttendanceBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventAttendanceByPkArgs = {
  _set?: InputMaybe<CulturalEventAttendanceSetInput>;
  pkColumns: CulturalEventAttendancePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventAttendanceManyArgs = {
  updates: Array<CulturalEventAttendanceUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventCompensationArgs = {
  _inc?: InputMaybe<CulturalEventCompensationIncInput>;
  _set?: InputMaybe<CulturalEventCompensationSetInput>;
  where: CulturalEventCompensationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventCompensationByPkArgs = {
  _inc?: InputMaybe<CulturalEventCompensationIncInput>;
  _set?: InputMaybe<CulturalEventCompensationSetInput>;
  pkColumns: CulturalEventCompensationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventCompensationManyArgs = {
  updates: Array<CulturalEventCompensationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventImageArgs = {
  _set?: InputMaybe<CulturalEventImageSetInput>;
  where: CulturalEventImageBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventImageByPkArgs = {
  _set?: InputMaybe<CulturalEventImageSetInput>;
  pkColumns: CulturalEventImagePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventImageManyArgs = {
  updates: Array<CulturalEventImageUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventPrepaidAttendanceArgs = {
  _set?: InputMaybe<CulturalEventPrepaidAttendanceSetInput>;
  where: CulturalEventPrepaidAttendanceBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventPrepaidAttendanceByPkArgs = {
  _set?: InputMaybe<CulturalEventPrepaidAttendanceSetInput>;
  pkColumns: CulturalEventPrepaidAttendancePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventPrepaidAttendanceManyArgs = {
  updates: Array<CulturalEventPrepaidAttendanceUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventStatusArgs = {
  _set?: InputMaybe<CulturalEventStatusSetInput>;
  where: CulturalEventStatusBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventStatusByPkArgs = {
  _set?: InputMaybe<CulturalEventStatusSetInput>;
  pkColumns: CulturalEventStatusPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventStatusManyArgs = {
  updates: Array<CulturalEventStatusUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventTicketArgs = {
  _inc?: InputMaybe<CulturalEventTicketIncInput>;
  _set?: InputMaybe<CulturalEventTicketSetInput>;
  where: CulturalEventTicketBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventTicketByPkArgs = {
  _inc?: InputMaybe<CulturalEventTicketIncInput>;
  _set?: InputMaybe<CulturalEventTicketSetInput>;
  pkColumns: CulturalEventTicketPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventTicketManyArgs = {
  updates: Array<CulturalEventTicketUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventsArgs = {
  _set?: InputMaybe<CulturalEventSetInput>;
  where: CulturalEventBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateMediaArgs = {
  _set?: InputMaybe<MediaSetInput>;
  where: MediaBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateMediaByPkArgs = {
  _set?: InputMaybe<MediaSetInput>;
  pkColumns: MediaPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateMediaConfigArgs = {
  _set?: InputMaybe<MediaConfigSetInput>;
  where: MediaConfigBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateMediaConfigByPkArgs = {
  _set?: InputMaybe<MediaConfigSetInput>;
  pkColumns: MediaConfigPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateMediaConfigManyArgs = {
  updates: Array<MediaConfigUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateMediaManyArgs = {
  updates: Array<MediaUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateMultipleCulturalEventsArgs = {
  updates: Array<CulturalEventUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePauseArgs = {
  _set?: InputMaybe<PauseSetInput>;
  where: PauseBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePauseByPkArgs = {
  _set?: InputMaybe<PauseSetInput>;
  pkColumns: PausePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePauseManyArgs = {
  updates: Array<PauseUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePaymentConfigArgs = {
  _set?: InputMaybe<PaymentConfigSetInput>;
  where: PaymentConfigBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePaymentConfigByPkArgs = {
  _set?: InputMaybe<PaymentConfigSetInput>;
  pkColumns: PaymentConfigPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePaymentConfigManyArgs = {
  updates: Array<PaymentConfigUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePaymentReturnArgs = {
  _append?: InputMaybe<PaymentReturnAppendInput>;
  _deleteAtPath?: InputMaybe<PaymentReturnDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PaymentReturnDeleteElemInput>;
  _deleteKey?: InputMaybe<PaymentReturnDeleteKeyInput>;
  _prepend?: InputMaybe<PaymentReturnPrependInput>;
  _set?: InputMaybe<PaymentReturnSetInput>;
  where: PaymentReturnBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePaymentReturnByPkArgs = {
  _append?: InputMaybe<PaymentReturnAppendInput>;
  _deleteAtPath?: InputMaybe<PaymentReturnDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PaymentReturnDeleteElemInput>;
  _deleteKey?: InputMaybe<PaymentReturnDeleteKeyInput>;
  _prepend?: InputMaybe<PaymentReturnPrependInput>;
  _set?: InputMaybe<PaymentReturnSetInput>;
  pkColumns: PaymentReturnPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePaymentReturnManyArgs = {
  updates: Array<PaymentReturnUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePaymentTransactionArgs = {
  _append?: InputMaybe<PaymentTransactionAppendInput>;
  _deleteAtPath?: InputMaybe<PaymentTransactionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PaymentTransactionDeleteElemInput>;
  _deleteKey?: InputMaybe<PaymentTransactionDeleteKeyInput>;
  _inc?: InputMaybe<PaymentTransactionIncInput>;
  _prepend?: InputMaybe<PaymentTransactionPrependInput>;
  _set?: InputMaybe<PaymentTransactionSetInput>;
  where: PaymentTransactionBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePaymentTransactionByPkArgs = {
  _append?: InputMaybe<PaymentTransactionAppendInput>;
  _deleteAtPath?: InputMaybe<PaymentTransactionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PaymentTransactionDeleteElemInput>;
  _deleteKey?: InputMaybe<PaymentTransactionDeleteKeyInput>;
  _inc?: InputMaybe<PaymentTransactionIncInput>;
  _prepend?: InputMaybe<PaymentTransactionPrependInput>;
  _set?: InputMaybe<PaymentTransactionSetInput>;
  pkColumns: PaymentTransactionPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePaymentTransactionManyArgs = {
  updates: Array<PaymentTransactionUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTariffPassArgs = {
  _inc?: InputMaybe<TariffPassIncInput>;
  _set?: InputMaybe<TariffPassSetInput>;
  where: TariffPassBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTariffPassByPkArgs = {
  _inc?: InputMaybe<TariffPassIncInput>;
  _set?: InputMaybe<TariffPassSetInput>;
  pkColumns: TariffPassPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTariffPassI18nArgs = {
  _set?: InputMaybe<TariffPassI18nSetInput>;
  where: TariffPassI18nBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTariffPassI18nByPkArgs = {
  _set?: InputMaybe<TariffPassI18nSetInput>;
  pkColumns: TariffPassI18nPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTariffPassI18nManyArgs = {
  updates: Array<TariffPassI18nUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTariffPassManyArgs = {
  updates: Array<TariffPassUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTariffPassUserArgs = {
  _set?: InputMaybe<TariffPassUserSetInput>;
  where: TariffPassUserBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTariffPassUserByPkArgs = {
  _set?: InputMaybe<TariffPassUserSetInput>;
  pkColumns: TariffPassUserPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTariffPassUserManyArgs = {
  updates: Array<TariffPassUserUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTariffTariffArgs = {
  _inc?: InputMaybe<TariffTariffIncInput>;
  _set?: InputMaybe<TariffTariffSetInput>;
  where: TariffTariffBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTariffTariffByPkArgs = {
  _inc?: InputMaybe<TariffTariffIncInput>;
  _set?: InputMaybe<TariffTariffSetInput>;
  pkColumns: TariffTariffPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTariffTariffManyArgs = {
  updates: Array<TariffTariffUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateVisitArgs = {
  _inc?: InputMaybe<VisitIncInput>;
  _set?: InputMaybe<VisitSetInput>;
  where: VisitBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateVisitByPkArgs = {
  _inc?: InputMaybe<VisitIncInput>;
  _set?: InputMaybe<VisitSetInput>;
  pkColumns: VisitPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateVisitManyArgs = {
  updates: Array<VisitUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatArgs = {
  _set?: InputMaybe<ZiferblatZiferblatSetInput>;
  pkColumns: ZiferblatZiferblatPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatAddressI18nArgs = {
  _inc?: InputMaybe<ZiferblatAddressI18nIncInput>;
  _set?: InputMaybe<ZiferblatAddressI18nSetInput>;
  pkColumns: ZiferblatAddressI18nPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatAddressI18nManyArgs = {
  updates: Array<ZiferblatAddressI18nUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatAddressI18nsArgs = {
  _inc?: InputMaybe<ZiferblatAddressI18nIncInput>;
  _set?: InputMaybe<ZiferblatAddressI18nSetInput>;
  where: ZiferblatAddressI18nBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatCityI18nArgs = {
  _set?: InputMaybe<ZiferblatCityI18nSetInput>;
  where: ZiferblatCityI18nBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatCityI18nByPkArgs = {
  _set?: InputMaybe<ZiferblatCityI18nSetInput>;
  pkColumns: ZiferblatCityI18nPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatCityI18nManyArgs = {
  updates: Array<ZiferblatCityI18nUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatContactManyArgs = {
  updates: Array<ZiferblatContactUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatGalleryArgs = {
  _set?: InputMaybe<ZiferblatGallerySetInput>;
  where: ZiferblatGalleryBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatGalleryByPkArgs = {
  _set?: InputMaybe<ZiferblatGallerySetInput>;
  pkColumns: ZiferblatGalleryPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatGalleryManyArgs = {
  updates: Array<ZiferblatGalleryUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatI18nArgs = {
  _set?: InputMaybe<ZiferblatZiferblatI18nSetInput>;
  pkColumns: ZiferblatZiferblatI18nPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatI18nsArgs = {
  _set?: InputMaybe<ZiferblatZiferblatI18nSetInput>;
  where: ZiferblatZiferblatI18nBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatJuridicalI18nArgs = {
  _set?: InputMaybe<ZiferblatJuridicalI18nSetInput>;
  where: ZiferblatJuridicalI18nBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatJuridicalI18nByPkArgs = {
  _set?: InputMaybe<ZiferblatJuridicalI18nSetInput>;
  pkColumns: ZiferblatJuridicalI18nPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatJuridicalI18nManyArgs = {
  updates: Array<ZiferblatJuridicalI18nUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatLangArgs = {
  _set?: InputMaybe<ZiferblatLangSetInput>;
  where: ZiferblatLangBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatLangByPkArgs = {
  _set?: InputMaybe<ZiferblatLangSetInput>;
  pkColumns: ZiferblatLangPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatLangManyArgs = {
  updates: Array<ZiferblatLangUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatRoomArgs = {
  _inc?: InputMaybe<ZiferblatRoomIncInput>;
  _set?: InputMaybe<ZiferblatRoomSetInput>;
  where: ZiferblatRoomBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatRoomByPkArgs = {
  _inc?: InputMaybe<ZiferblatRoomIncInput>;
  _set?: InputMaybe<ZiferblatRoomSetInput>;
  pkColumns: ZiferblatRoomPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatRoomI18nArgs = {
  _set?: InputMaybe<ZiferblatRoomI18nSetInput>;
  where: ZiferblatRoomI18nBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatRoomI18nByPkArgs = {
  _set?: InputMaybe<ZiferblatRoomI18nSetInput>;
  pkColumns: ZiferblatRoomI18nPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatRoomI18nManyArgs = {
  updates: Array<ZiferblatRoomI18nUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatRoomManyArgs = {
  updates: Array<ZiferblatRoomUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatZiferblatI18nManyArgs = {
  updates: Array<ZiferblatZiferblatI18nUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatZiferblatManyArgs = {
  updates: Array<ZiferblatZiferblatUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatsArgs = {
  _set?: InputMaybe<ZiferblatZiferblatSetInput>;
  where: ZiferblatZiferblatBoolExp;
};

export type PauseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PauseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PauseBoolExp>;
  predicate: IntComparisonExp;
};

export type PaymentTransactionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PaymentTransactionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentTransactionBoolExp>;
  predicate: IntComparisonExp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "cultural_event.compensation_currency" */
  CompensationCurrency: Array<CompensationCurrency>;
  /** fetch aggregated fields from the table: "cultural_event.compensation_currency" */
  CompensationCurrencyAggregate: CompensationCurrencyAggregate;
  /** fetch data from the table: "cultural_event.compensation_currency" using primary key columns */
  CompensationCurrencyByPk?: Maybe<CompensationCurrency>;
  /** fetch data from the table: "cultural_event.compensation_unit" */
  CompensationUnit: Array<CompensationUnit>;
  /** fetch aggregated fields from the table: "cultural_event.compensation_unit" */
  CompensationUnitAggregate: CompensationUnitAggregate;
  /** fetch data from the table: "cultural_event.compensation_unit" using primary key columns */
  CompensationUnitByPk?: Maybe<CompensationUnit>;
  /** fetch data from the table: "access.access" */
  accessAccess: Array<AccessAccess>;
  /** fetch aggregated fields from the table: "access.access" */
  accessAccessAggregate: AccessAccessAggregate;
  /** fetch data from the table: "access.expire" */
  accessExpire: Array<AccessExpire>;
  /** fetch aggregated fields from the table: "access.expire" */
  accessExpireAggregate: AccessExpireAggregate;
  /** fetch data from the table: "access.expire" using primary key columns */
  accessExpireByPk?: Maybe<AccessExpire>;
  /** fetch data from the table: "access.grant" */
  accessGrant: Array<AccessGrant>;
  /** fetch aggregated fields from the table: "access.grant" */
  accessGrantAggregate: AccessGrantAggregate;
  /** fetch data from the table: "access.grant" using primary key columns */
  accessGrantByPk?: Maybe<AccessGrant>;
  /** fetch data from the table: "access.permission" */
  accessPermission: Array<AccessPermission>;
  /** fetch aggregated fields from the table: "access.permission" */
  accessPermissionAggregate: AccessPermissionAggregate;
  /** fetch data from the table: "access.permission" using primary key columns */
  accessPermissionByPk?: Maybe<AccessPermission>;
  /** fetch data from the table: "access.role" */
  accessRole: Array<AccessRole>;
  /** fetch aggregated fields from the table: "access.role" */
  accessRoleAggregate: AccessRoleAggregate;
  /** fetch data from the table: "access.role" using primary key columns */
  accessRoleByPk?: Maybe<AccessRole>;
  /** fetch aggregated fields from the table: "checkin" */
  aggregateCheckins: CheckinAggregate;
  /** fetch aggregated fields from the table: "ziferblat.contact" */
  aggregateContacts: ZiferblatContactAggregate;
  /** fetch aggregated fields from the table: "cultural_event.cultural_event" */
  aggregateCulturalEvents: CulturalEventAggregate;
  /** fetch aggregated fields from the table: "visit" */
  aggregateVisits: VisitAggregate;
  /** fetch aggregated fields from the table: "ziferblat.ziferblat_i18n" */
  aggregateZiferblatI18n: ZiferblatZiferblatI18nAggregate;
  /** An array relationship */
  attendees: Array<Attendees>;
  /** An aggregate relationship */
  attendeesAggregate: AttendeesAggregate;
  /** fetch data from the table: "audit.log" */
  auditLog: Array<AuditLog>;
  /** fetch aggregated fields from the table: "audit.log" */
  auditLogAggregate: AuditLogAggregate;
  /** fetch data from the table: "audit.log" using primary key columns */
  auditLogByPk?: Maybe<AuditLog>;
  /** fetch data from the table: "auth.alias" */
  authAlias: Array<AuthAlias>;
  /** fetch aggregated fields from the table: "auth.alias" */
  authAliasAggregate: AuthAliasAggregate;
  /** fetch data from the table: "auth.alias" using primary key columns */
  authAliasByPk?: Maybe<AuthAlias>;
  /** execute function "auth.current_session" which returns "auth.session" */
  authCurrentSession?: Maybe<AuthSession>;
  /** execute function "auth.current_session" and query aggregates on result of table type "auth.session" */
  authCurrentSessionAggregate: AuthSessionAggregate;
  /** fetch data from the table: "auth.logout" */
  authLogout: Array<AuthLogout>;
  /** fetch aggregated fields from the table: "auth.logout" */
  authLogoutAggregate: AuthLogoutAggregate;
  /** fetch data from the table: "auth.logout" using primary key columns */
  authLogoutByPk?: Maybe<AuthLogout>;
  /** execute function "auth.me" which returns "auth.user" */
  authMe?: Maybe<AuthUser>;
  /** execute function "auth.me" and query aggregates on result of table type "auth.user" */
  authMeAggregate: AuthUserAggregate;
  /** fetch data from the table: "auth.session" */
  authSession: Array<AuthSession>;
  /** fetch aggregated fields from the table: "auth.session" */
  authSessionAggregate: AuthSessionAggregate;
  /** fetch data from the table: "auth.session" using primary key columns */
  authSessionByPk?: Maybe<AuthSession>;
  /** fetch data from the table: "auth.telegram_bot" */
  authTelegramBot: Array<AuthTelegramBot>;
  /** fetch aggregated fields from the table: "auth.telegram_bot" */
  authTelegramBotAggregate: AuthTelegramBotAggregate;
  /** fetch data from the table: "auth.telegram_bot" using primary key columns */
  authTelegramBotByPk?: Maybe<AuthTelegramBot>;
  /** fetch data from the table: "auth.telegram_input" */
  authTelegramInput: Array<AuthTelegramInput>;
  /** fetch aggregated fields from the table: "auth.telegram_input" */
  authTelegramInputAggregate: AuthTelegramInputAggregate;
  /** fetch data from the table: "auth.telegram_input" using primary key columns */
  authTelegramInputByPk?: Maybe<AuthTelegramInput>;
  /** fetch aggregated fields from the table: "auth.user" */
  authUserAggregate: AuthUserAggregate;
  /** fetch data from the table: "booking" */
  booking: Array<Booking>;
  /** fetch aggregated fields from the table: "booking" */
  bookingAggregate: BookingAggregate;
  /** fetch data from the table: "booking" using primary key columns */
  bookingByPk?: Maybe<Booking>;
  /** fetch data from the table: "booking_checkin" */
  bookingCheckin: Array<BookingCheckin>;
  /** fetch aggregated fields from the table: "booking_checkin" */
  bookingCheckinAggregate: BookingCheckinAggregate;
  /** fetch data from the table: "booking_checkin" using primary key columns */
  bookingCheckinByPk?: Maybe<BookingCheckin>;
  /** fetch data from the table: "checkin" using primary key columns */
  checkin?: Maybe<Checkin>;
  /** fetch data from the table: "checkin" */
  checkins: Array<Checkin>;
  /** fetch data from the table: "ziferblat.city_i18n" */
  cities: Array<ZiferblatCityI18n>;
  /** fetch data from the table: "ziferblat.city_i18n" using primary key columns */
  city?: Maybe<ZiferblatCityI18n>;
  /** fetch data from the table: "ziferblat.contact" using primary key columns */
  contact?: Maybe<ZiferblatContact>;
  /** An array relationship */
  contacts: Array<ZiferblatContact>;
  /** fetch data from the table: "cultural_event.cultural_event" using primary key columns */
  culturalEvent?: Maybe<CulturalEvent>;
  /** fetch data from the table: "cultural_event.attendance" */
  culturalEventAttendance: Array<CulturalEventAttendance>;
  /** fetch aggregated fields from the table: "cultural_event.attendance" */
  culturalEventAttendanceAggregate: CulturalEventAttendanceAggregate;
  /** fetch data from the table: "cultural_event.attendance" using primary key columns */
  culturalEventAttendanceByPk?: Maybe<CulturalEventAttendance>;
  /** fetch data from the table: "cultural_event.compensation" */
  culturalEventCompensation: Array<CulturalEventCompensation>;
  /** fetch aggregated fields from the table: "cultural_event.compensation" */
  culturalEventCompensationAggregate: CulturalEventCompensationAggregate;
  /** fetch data from the table: "cultural_event.compensation" using primary key columns */
  culturalEventCompensationByPk?: Maybe<CulturalEventCompensation>;
  /** fetch data from the table: "cultural_event.cultural_event_image" using primary key columns */
  culturalEventImage?: Maybe<CulturalEventImage>;
  /** fetch aggregated fields from the table: "cultural_event.cultural_event_image" */
  culturalEventImageAggregate: CulturalEventImageAggregate;
  /** fetch data from the table: "cultural_event.cultural_event_image" */
  culturalEventImages: Array<CulturalEventImage>;
  /** fetch data from the table: "cultural_event.prepaid_attendance" */
  culturalEventPrepaidAttendance: Array<CulturalEventPrepaidAttendance>;
  /** fetch aggregated fields from the table: "cultural_event.prepaid_attendance" */
  culturalEventPrepaidAttendanceAggregate: CulturalEventPrepaidAttendanceAggregate;
  /** fetch data from the table: "cultural_event.prepaid_attendance" using primary key columns */
  culturalEventPrepaidAttendanceByPk?: Maybe<CulturalEventPrepaidAttendance>;
  /** fetch data from the table: "cultural_event.status" */
  culturalEventStatus: Array<CulturalEventStatus>;
  /** fetch aggregated fields from the table: "cultural_event.status" */
  culturalEventStatusAggregate: CulturalEventStatusAggregate;
  /** fetch data from the table: "cultural_event.status" using primary key columns */
  culturalEventStatusByPk?: Maybe<CulturalEventStatus>;
  /** fetch data from the table: "cultural_event.ticket" */
  culturalEventTicket: Array<CulturalEventTicket>;
  /** fetch aggregated fields from the table: "cultural_event.ticket" */
  culturalEventTicketAggregate: CulturalEventTicketAggregate;
  /** fetch data from the table: "cultural_event.ticket" using primary key columns */
  culturalEventTicketByPk?: Maybe<CulturalEventTicket>;
  /** An array relationship */
  culturalEvents: Array<CulturalEvent>;
  /** An array relationship */
  gallery: Array<ZiferblatGallery>;
  /** fetch data from the table: "ziferblat.gallery" using primary key columns */
  galleryItem?: Maybe<ZiferblatGallery>;
  /** fetch data from the table: "media.media" using primary key columns */
  media?: Maybe<Media>;
  /** fetch aggregated fields from the table: "media.media" */
  mediaAggregate: MediaAggregate;
  /** fetch data from the table: "media.config" */
  mediaConfig: Array<MediaConfig>;
  /** fetch aggregated fields from the table: "media.config" */
  mediaConfigAggregate: MediaConfigAggregate;
  /** fetch data from the table: "media.config" using primary key columns */
  mediaConfigByPk?: Maybe<MediaConfig>;
  /** fetch data from the table: "media.media" */
  medias: Array<Media>;
  /** An array relationship */
  pause: Array<Pause>;
  /** An aggregate relationship */
  pauseAggregate: PauseAggregate;
  /** fetch data from the table: "pause" using primary key columns */
  pauseByPk?: Maybe<Pause>;
  /** fetch data from the table: "payment.config" */
  paymentConfig: Array<PaymentConfig>;
  /** fetch aggregated fields from the table: "payment.config" */
  paymentConfigAggregate: PaymentConfigAggregate;
  /** fetch data from the table: "payment.config" using primary key columns */
  paymentConfigByPk?: Maybe<PaymentConfig>;
  /** fetch data from the table: "payment.return" */
  paymentReturn: Array<PaymentReturn>;
  /** fetch aggregated fields from the table: "payment.return" */
  paymentReturnAggregate: PaymentReturnAggregate;
  /** fetch data from the table: "payment.return" using primary key columns */
  paymentReturnByPk?: Maybe<PaymentReturn>;
  /** fetch data from the table: "payment.transaction" */
  paymentTransaction: Array<PaymentTransaction>;
  /** fetch aggregated fields from the table: "payment.transaction" */
  paymentTransactionAggregate: PaymentTransactionAggregate;
  /** fetch data from the table: "payment.transaction" using primary key columns */
  paymentTransactionByPk?: Maybe<PaymentTransaction>;
  /** fetch data from the table: "tariff.pass_user" using primary key columns */
  soldPass?: Maybe<TariffPassUser>;
  /** fetch data from the table: "tariff.pass_user" */
  soldPasses: Array<TariffPassUser>;
  /** fetch data from the table: "tariff.tariff" using primary key columns */
  tariff?: Maybe<TariffTariff>;
  /** fetch data from the table: "tariff.pass" */
  tariffPass: Array<TariffPass>;
  /** fetch aggregated fields from the table: "tariff.pass" */
  tariffPassAggregate: TariffPassAggregate;
  /** fetch data from the table: "tariff.pass" using primary key columns */
  tariffPassByPk?: Maybe<TariffPass>;
  /** fetch data from the table: "tariff.pass_i18n" */
  tariffPassI18n: Array<TariffPassI18n>;
  /** fetch aggregated fields from the table: "tariff.pass_i18n" */
  tariffPassI18nAggregate: TariffPassI18nAggregate;
  /** fetch data from the table: "tariff.pass_i18n" using primary key columns */
  tariffPassI18nByPk?: Maybe<TariffPassI18n>;
  /** fetch aggregated fields from the table: "tariff.pass_user" */
  tariffPassUserAggregate: TariffPassUserAggregate;
  /** fetch aggregated fields from the table: "tariff.tariff" */
  tariffTariffAggregate: TariffTariffAggregate;
  /** An array relationship */
  tariffs: Array<TariffTariff>;
  /** fetch data from the table: "auth.user" using primary key columns */
  user?: Maybe<AuthUser>;
  /** fetch data from the table: "auth.user" */
  users: Array<AuthUser>;
  /** fetch data from the table: "visit" using primary key columns */
  visit?: Maybe<Visit>;
  /** An array relationship */
  visits: Array<Visit>;
  /** fetch data from the table: "ziferblat.ziferblat" using primary key columns */
  ziferblat?: Maybe<ZiferblatZiferblat>;
  /** fetch data from the table: "ziferblat.address_i18n" */
  ziferblatAddressI18n: Array<ZiferblatAddressI18n>;
  /** fetch aggregated fields from the table: "ziferblat.address_i18n" */
  ziferblatAddressI18nAggregate: ZiferblatAddressI18nAggregate;
  /** fetch data from the table: "ziferblat.address_i18n" using primary key columns */
  ziferblatAddressI18ns?: Maybe<ZiferblatAddressI18n>;
  /** fetch aggregated fields from the table: "ziferblat.city_i18n" */
  ziferblatCityI18nAggregate: ZiferblatCityI18nAggregate;
  /** fetch aggregated fields from the table: "ziferblat.gallery" */
  ziferblatGalleryAggregate: ZiferblatGalleryAggregate;
  /** fetch data from the table: "ziferblat.ziferblat_i18n" using primary key columns */
  ziferblatI18n?: Maybe<ZiferblatZiferblatI18n>;
  /** fetch data from the table: "ziferblat.ziferblat_i18n" */
  ziferblatI18ns: Array<ZiferblatZiferblatI18n>;
  /** fetch data from the table: "ziferblat.juridical_i18n" */
  ziferblatJuridicalI18n: Array<ZiferblatJuridicalI18n>;
  /** fetch aggregated fields from the table: "ziferblat.juridical_i18n" */
  ziferblatJuridicalI18nAggregate: ZiferblatJuridicalI18nAggregate;
  /** fetch data from the table: "ziferblat.juridical_i18n" using primary key columns */
  ziferblatJuridicalI18nByPk?: Maybe<ZiferblatJuridicalI18n>;
  /** fetch data from the table: "ziferblat.lang" */
  ziferblatLang: Array<ZiferblatLang>;
  /** fetch aggregated fields from the table: "ziferblat.lang" */
  ziferblatLangAggregate: ZiferblatLangAggregate;
  /** fetch data from the table: "ziferblat.lang" using primary key columns */
  ziferblatLangByPk?: Maybe<ZiferblatLang>;
  /** fetch data from the table: "ziferblat.room" */
  ziferblatRoom: Array<ZiferblatRoom>;
  /** fetch aggregated fields from the table: "ziferblat.room" */
  ziferblatRoomAggregate: ZiferblatRoomAggregate;
  /** fetch data from the table: "ziferblat.room" using primary key columns */
  ziferblatRoomByPk?: Maybe<ZiferblatRoom>;
  /** fetch data from the table: "ziferblat.room_i18n" */
  ziferblatRoomI18n: Array<ZiferblatRoomI18n>;
  /** fetch aggregated fields from the table: "ziferblat.room_i18n" */
  ziferblatRoomI18nAggregate: ZiferblatRoomI18nAggregate;
  /** fetch data from the table: "ziferblat.room_i18n" using primary key columns */
  ziferblatRoomI18nByPk?: Maybe<ZiferblatRoomI18n>;
  /** An array relationship */
  ziferblats: Array<ZiferblatZiferblat>;
  /** An aggregate relationship */
  ziferblatsAggregate: ZiferblatZiferblatAggregate;
};


export type Query_RootCompensationCurrencyArgs = {
  distinctOn?: InputMaybe<Array<CompensationCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationCurrencyOrderBy>>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};


export type Query_RootCompensationCurrencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompensationCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationCurrencyOrderBy>>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};


export type Query_RootCompensationCurrencyByPkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootCompensationUnitArgs = {
  distinctOn?: InputMaybe<Array<CompensationUnitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationUnitOrderBy>>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};


export type Query_RootCompensationUnitAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompensationUnitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationUnitOrderBy>>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};


export type Query_RootCompensationUnitByPkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootAccessAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


export type Query_RootAccessAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


export type Query_RootAccessExpireArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


export type Query_RootAccessExpireAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


export type Query_RootAccessExpireByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAccessGrantArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


export type Query_RootAccessGrantAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


export type Query_RootAccessGrantByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAccessPermissionArgs = {
  distinctOn?: InputMaybe<Array<AccessPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessPermissionOrderBy>>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};


export type Query_RootAccessPermissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessPermissionOrderBy>>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};


export type Query_RootAccessPermissionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAccessRoleArgs = {
  distinctOn?: InputMaybe<Array<AccessRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessRoleOrderBy>>;
  where?: InputMaybe<AccessRoleBoolExp>;
};


export type Query_RootAccessRoleAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessRoleOrderBy>>;
  where?: InputMaybe<AccessRoleBoolExp>;
};


export type Query_RootAccessRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAggregateCheckinsArgs = {
  distinctOn?: InputMaybe<Array<CheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CheckinOrderBy>>;
  where?: InputMaybe<CheckinBoolExp>;
};


export type Query_RootAggregateContactsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatContactOrderBy>>;
  where?: InputMaybe<ZiferblatContactBoolExp>;
};


export type Query_RootAggregateCulturalEventsArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventOrderBy>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


export type Query_RootAggregateVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};


export type Query_RootAggregateZiferblatI18nArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatI18nOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatI18nBoolExp>;
};


export type Query_RootAttendeesArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


export type Query_RootAttendeesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


export type Query_RootAuditLogArgs = {
  distinctOn?: InputMaybe<Array<AuditLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuditLogOrderBy>>;
  where?: InputMaybe<AuditLogBoolExp>;
};


export type Query_RootAuditLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuditLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuditLogOrderBy>>;
  where?: InputMaybe<AuditLogBoolExp>;
};


export type Query_RootAuditLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthAliasArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


export type Query_RootAuthAliasAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


export type Query_RootAuthAliasByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthCurrentSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Query_RootAuthCurrentSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Query_RootAuthLogoutArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Query_RootAuthLogoutAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Query_RootAuthLogoutByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthMeArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Query_RootAuthMeAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Query_RootAuthSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Query_RootAuthSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Query_RootAuthSessionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthTelegramBotArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramBotSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramBotOrderBy>>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};


export type Query_RootAuthTelegramBotAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramBotSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramBotOrderBy>>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};


export type Query_RootAuthTelegramBotByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthTelegramInputArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramInputSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramInputOrderBy>>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};


export type Query_RootAuthTelegramInputAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramInputSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramInputOrderBy>>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};


export type Query_RootAuthTelegramInputByPkArgs = {
  hash: Scalars['String']['input'];
};


export type Query_RootAuthUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Query_RootBookingArgs = {
  distinctOn?: InputMaybe<Array<BookingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BookingOrderBy>>;
  where?: InputMaybe<BookingBoolExp>;
};


export type Query_RootBookingAggregateArgs = {
  distinctOn?: InputMaybe<Array<BookingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BookingOrderBy>>;
  where?: InputMaybe<BookingBoolExp>;
};


export type Query_RootBookingByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBookingCheckinArgs = {
  distinctOn?: InputMaybe<Array<BookingCheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BookingCheckinOrderBy>>;
  where?: InputMaybe<BookingCheckinBoolExp>;
};


export type Query_RootBookingCheckinAggregateArgs = {
  distinctOn?: InputMaybe<Array<BookingCheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BookingCheckinOrderBy>>;
  where?: InputMaybe<BookingCheckinBoolExp>;
};


export type Query_RootBookingCheckinByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCheckinArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCheckinsArgs = {
  distinctOn?: InputMaybe<Array<CheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CheckinOrderBy>>;
  where?: InputMaybe<CheckinBoolExp>;
};


export type Query_RootCitiesArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatCityI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatCityI18nOrderBy>>;
  where?: InputMaybe<ZiferblatCityI18nBoolExp>;
};


export type Query_RootCityArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootContactArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootContactsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatContactOrderBy>>;
  where?: InputMaybe<ZiferblatContactBoolExp>;
};


export type Query_RootCulturalEventArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCulturalEventAttendanceArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


export type Query_RootCulturalEventAttendanceAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


export type Query_RootCulturalEventAttendanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCulturalEventCompensationArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventCompensationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventCompensationOrderBy>>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};


export type Query_RootCulturalEventCompensationAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventCompensationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventCompensationOrderBy>>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};


export type Query_RootCulturalEventCompensationByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCulturalEventImageArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCulturalEventImageAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventImageOrderBy>>;
  where?: InputMaybe<CulturalEventImageBoolExp>;
};


export type Query_RootCulturalEventImagesArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventImageOrderBy>>;
  where?: InputMaybe<CulturalEventImageBoolExp>;
};


export type Query_RootCulturalEventPrepaidAttendanceArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventPrepaidAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventPrepaidAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventPrepaidAttendanceBoolExp>;
};


export type Query_RootCulturalEventPrepaidAttendanceAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventPrepaidAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventPrepaidAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventPrepaidAttendanceBoolExp>;
};


export type Query_RootCulturalEventPrepaidAttendanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCulturalEventStatusArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventStatusOrderBy>>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};


export type Query_RootCulturalEventStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventStatusOrderBy>>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};


export type Query_RootCulturalEventStatusByPkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootCulturalEventTicketArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketOrderBy>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};


export type Query_RootCulturalEventTicketAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketOrderBy>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};


export type Query_RootCulturalEventTicketByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCulturalEventsArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventOrderBy>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


export type Query_RootGalleryArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatGallerySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatGalleryOrderBy>>;
  where?: InputMaybe<ZiferblatGalleryBoolExp>;
};


export type Query_RootGalleryItemArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootMediaArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootMediaAggregateArgs = {
  distinctOn?: InputMaybe<Array<MediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
  where?: InputMaybe<MediaBoolExp>;
};


export type Query_RootMediaConfigArgs = {
  distinctOn?: InputMaybe<Array<MediaConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaConfigOrderBy>>;
  where?: InputMaybe<MediaConfigBoolExp>;
};


export type Query_RootMediaConfigAggregateArgs = {
  distinctOn?: InputMaybe<Array<MediaConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaConfigOrderBy>>;
  where?: InputMaybe<MediaConfigBoolExp>;
};


export type Query_RootMediaConfigByPkArgs = {
  bucket: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
};


export type Query_RootMediasArgs = {
  distinctOn?: InputMaybe<Array<MediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
  where?: InputMaybe<MediaBoolExp>;
};


export type Query_RootPauseArgs = {
  distinctOn?: InputMaybe<Array<PauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PauseOrderBy>>;
  where?: InputMaybe<PauseBoolExp>;
};


export type Query_RootPauseAggregateArgs = {
  distinctOn?: InputMaybe<Array<PauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PauseOrderBy>>;
  where?: InputMaybe<PauseBoolExp>;
};


export type Query_RootPauseByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPaymentConfigArgs = {
  distinctOn?: InputMaybe<Array<PaymentConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentConfigOrderBy>>;
  where?: InputMaybe<PaymentConfigBoolExp>;
};


export type Query_RootPaymentConfigAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentConfigOrderBy>>;
  where?: InputMaybe<PaymentConfigBoolExp>;
};


export type Query_RootPaymentConfigByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPaymentReturnArgs = {
  distinctOn?: InputMaybe<Array<PaymentReturnSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentReturnOrderBy>>;
  where?: InputMaybe<PaymentReturnBoolExp>;
};


export type Query_RootPaymentReturnAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentReturnSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentReturnOrderBy>>;
  where?: InputMaybe<PaymentReturnBoolExp>;
};


export type Query_RootPaymentReturnByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPaymentTransactionArgs = {
  distinctOn?: InputMaybe<Array<PaymentTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentTransactionOrderBy>>;
  where?: InputMaybe<PaymentTransactionBoolExp>;
};


export type Query_RootPaymentTransactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentTransactionOrderBy>>;
  where?: InputMaybe<PaymentTransactionBoolExp>;
};


export type Query_RootPaymentTransactionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSoldPassArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSoldPassesArgs = {
  distinctOn?: InputMaybe<Array<TariffPassUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassUserOrderBy>>;
  where?: InputMaybe<TariffPassUserBoolExp>;
};


export type Query_RootTariffArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTariffPassArgs = {
  distinctOn?: InputMaybe<Array<TariffPassSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassOrderBy>>;
  where?: InputMaybe<TariffPassBoolExp>;
};


export type Query_RootTariffPassAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffPassSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassOrderBy>>;
  where?: InputMaybe<TariffPassBoolExp>;
};


export type Query_RootTariffPassByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTariffPassI18nArgs = {
  distinctOn?: InputMaybe<Array<TariffPassI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassI18nOrderBy>>;
  where?: InputMaybe<TariffPassI18nBoolExp>;
};


export type Query_RootTariffPassI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffPassI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassI18nOrderBy>>;
  where?: InputMaybe<TariffPassI18nBoolExp>;
};


export type Query_RootTariffPassI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTariffPassUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffPassUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassUserOrderBy>>;
  where?: InputMaybe<TariffPassUserBoolExp>;
};


export type Query_RootTariffTariffAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffTariffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffTariffOrderBy>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


export type Query_RootTariffsArgs = {
  distinctOn?: InputMaybe<Array<TariffTariffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffTariffOrderBy>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


export type Query_RootUserArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Query_RootVisitArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};


export type Query_RootZiferblatArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootZiferblatAddressI18nArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatAddressI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatAddressI18nOrderBy>>;
  where?: InputMaybe<ZiferblatAddressI18nBoolExp>;
};


export type Query_RootZiferblatAddressI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatAddressI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatAddressI18nOrderBy>>;
  where?: InputMaybe<ZiferblatAddressI18nBoolExp>;
};


export type Query_RootZiferblatAddressI18nsArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootZiferblatCityI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatCityI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatCityI18nOrderBy>>;
  where?: InputMaybe<ZiferblatCityI18nBoolExp>;
};


export type Query_RootZiferblatGalleryAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatGallerySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatGalleryOrderBy>>;
  where?: InputMaybe<ZiferblatGalleryBoolExp>;
};


export type Query_RootZiferblatI18nArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootZiferblatI18nsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatI18nOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatI18nBoolExp>;
};


export type Query_RootZiferblatJuridicalI18nArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatJuridicalI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatJuridicalI18nOrderBy>>;
  where?: InputMaybe<ZiferblatJuridicalI18nBoolExp>;
};


export type Query_RootZiferblatJuridicalI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatJuridicalI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatJuridicalI18nOrderBy>>;
  where?: InputMaybe<ZiferblatJuridicalI18nBoolExp>;
};


export type Query_RootZiferblatJuridicalI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootZiferblatLangArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatLangSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatLangOrderBy>>;
  where?: InputMaybe<ZiferblatLangBoolExp>;
};


export type Query_RootZiferblatLangAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatLangSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatLangOrderBy>>;
  where?: InputMaybe<ZiferblatLangBoolExp>;
};


export type Query_RootZiferblatLangByPkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootZiferblatRoomArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatRoomSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatRoomOrderBy>>;
  where?: InputMaybe<ZiferblatRoomBoolExp>;
};


export type Query_RootZiferblatRoomAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatRoomSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatRoomOrderBy>>;
  where?: InputMaybe<ZiferblatRoomBoolExp>;
};


export type Query_RootZiferblatRoomByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootZiferblatRoomI18nArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatRoomI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatRoomI18nOrderBy>>;
  where?: InputMaybe<ZiferblatRoomI18nBoolExp>;
};


export type Query_RootZiferblatRoomI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatRoomI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatRoomI18nOrderBy>>;
  where?: InputMaybe<ZiferblatRoomI18nBoolExp>;
};


export type Query_RootZiferblatRoomI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootZiferblatsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};


export type Query_RootZiferblatsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "cultural_event.compensation_currency" */
  CompensationCurrency: Array<CompensationCurrency>;
  /** fetch aggregated fields from the table: "cultural_event.compensation_currency" */
  CompensationCurrencyAggregate: CompensationCurrencyAggregate;
  /** fetch data from the table: "cultural_event.compensation_currency" using primary key columns */
  CompensationCurrencyByPk?: Maybe<CompensationCurrency>;
  /** fetch data from the table in a streaming manner: "cultural_event.compensation_currency" */
  CompensationCurrencyStream: Array<CompensationCurrency>;
  /** fetch data from the table: "cultural_event.compensation_unit" */
  CompensationUnit: Array<CompensationUnit>;
  /** fetch aggregated fields from the table: "cultural_event.compensation_unit" */
  CompensationUnitAggregate: CompensationUnitAggregate;
  /** fetch data from the table: "cultural_event.compensation_unit" using primary key columns */
  CompensationUnitByPk?: Maybe<CompensationUnit>;
  /** fetch data from the table in a streaming manner: "cultural_event.compensation_unit" */
  CompensationUnitStream: Array<CompensationUnit>;
  /** fetch data from the table: "access.access" */
  accessAccess: Array<AccessAccess>;
  /** fetch aggregated fields from the table: "access.access" */
  accessAccessAggregate: AccessAccessAggregate;
  /** fetch data from the table in a streaming manner: "access.access" */
  accessAccessStream: Array<AccessAccess>;
  /** fetch data from the table: "access.expire" */
  accessExpire: Array<AccessExpire>;
  /** fetch aggregated fields from the table: "access.expire" */
  accessExpireAggregate: AccessExpireAggregate;
  /** fetch data from the table: "access.expire" using primary key columns */
  accessExpireByPk?: Maybe<AccessExpire>;
  /** fetch data from the table in a streaming manner: "access.expire" */
  accessExpireStream: Array<AccessExpire>;
  /** fetch data from the table: "access.grant" */
  accessGrant: Array<AccessGrant>;
  /** fetch aggregated fields from the table: "access.grant" */
  accessGrantAggregate: AccessGrantAggregate;
  /** fetch data from the table: "access.grant" using primary key columns */
  accessGrantByPk?: Maybe<AccessGrant>;
  /** fetch data from the table in a streaming manner: "access.grant" */
  accessGrantStream: Array<AccessGrant>;
  /** fetch data from the table: "access.permission" */
  accessPermission: Array<AccessPermission>;
  /** fetch aggregated fields from the table: "access.permission" */
  accessPermissionAggregate: AccessPermissionAggregate;
  /** fetch data from the table: "access.permission" using primary key columns */
  accessPermissionByPk?: Maybe<AccessPermission>;
  /** fetch data from the table in a streaming manner: "access.permission" */
  accessPermissionStream: Array<AccessPermission>;
  /** fetch data from the table: "access.role" */
  accessRole: Array<AccessRole>;
  /** fetch aggregated fields from the table: "access.role" */
  accessRoleAggregate: AccessRoleAggregate;
  /** fetch data from the table: "access.role" using primary key columns */
  accessRoleByPk?: Maybe<AccessRole>;
  /** fetch data from the table in a streaming manner: "access.role" */
  accessRoleStream: Array<AccessRole>;
  /** fetch aggregated fields from the table: "checkin" */
  aggregateCheckins: CheckinAggregate;
  /** fetch aggregated fields from the table: "ziferblat.contact" */
  aggregateContacts: ZiferblatContactAggregate;
  /** fetch aggregated fields from the table: "cultural_event.cultural_event" */
  aggregateCulturalEvents: CulturalEventAggregate;
  /** fetch aggregated fields from the table: "visit" */
  aggregateVisits: VisitAggregate;
  /** fetch aggregated fields from the table: "ziferblat.ziferblat_i18n" */
  aggregateZiferblatI18n: ZiferblatZiferblatI18nAggregate;
  /** An array relationship */
  attendees: Array<Attendees>;
  /** An aggregate relationship */
  attendeesAggregate: AttendeesAggregate;
  /** fetch data from the table in a streaming manner: "cultural_event.attendees" */
  attendeesStream: Array<Attendees>;
  /** fetch data from the table: "audit.log" */
  auditLog: Array<AuditLog>;
  /** fetch aggregated fields from the table: "audit.log" */
  auditLogAggregate: AuditLogAggregate;
  /** fetch data from the table: "audit.log" using primary key columns */
  auditLogByPk?: Maybe<AuditLog>;
  /** fetch data from the table in a streaming manner: "audit.log" */
  auditLogStream: Array<AuditLog>;
  /** fetch data from the table: "auth.alias" */
  authAlias: Array<AuthAlias>;
  /** fetch aggregated fields from the table: "auth.alias" */
  authAliasAggregate: AuthAliasAggregate;
  /** fetch data from the table: "auth.alias" using primary key columns */
  authAliasByPk?: Maybe<AuthAlias>;
  /** fetch data from the table in a streaming manner: "auth.alias" */
  authAliasStream: Array<AuthAlias>;
  /** execute function "auth.current_session" which returns "auth.session" */
  authCurrentSession?: Maybe<AuthSession>;
  /** execute function "auth.current_session" and query aggregates on result of table type "auth.session" */
  authCurrentSessionAggregate: AuthSessionAggregate;
  /** fetch data from the table: "auth.logout" */
  authLogout: Array<AuthLogout>;
  /** fetch aggregated fields from the table: "auth.logout" */
  authLogoutAggregate: AuthLogoutAggregate;
  /** fetch data from the table: "auth.logout" using primary key columns */
  authLogoutByPk?: Maybe<AuthLogout>;
  /** fetch data from the table in a streaming manner: "auth.logout" */
  authLogoutStream: Array<AuthLogout>;
  /** execute function "auth.me" which returns "auth.user" */
  authMe?: Maybe<AuthUser>;
  /** execute function "auth.me" and query aggregates on result of table type "auth.user" */
  authMeAggregate: AuthUserAggregate;
  /** fetch data from the table: "auth.session" */
  authSession: Array<AuthSession>;
  /** fetch aggregated fields from the table: "auth.session" */
  authSessionAggregate: AuthSessionAggregate;
  /** fetch data from the table: "auth.session" using primary key columns */
  authSessionByPk?: Maybe<AuthSession>;
  /** fetch data from the table in a streaming manner: "auth.session" */
  authSessionStream: Array<AuthSession>;
  /** fetch data from the table: "auth.telegram_bot" */
  authTelegramBot: Array<AuthTelegramBot>;
  /** fetch aggregated fields from the table: "auth.telegram_bot" */
  authTelegramBotAggregate: AuthTelegramBotAggregate;
  /** fetch data from the table: "auth.telegram_bot" using primary key columns */
  authTelegramBotByPk?: Maybe<AuthTelegramBot>;
  /** fetch data from the table in a streaming manner: "auth.telegram_bot" */
  authTelegramBotStream: Array<AuthTelegramBot>;
  /** fetch data from the table: "auth.telegram_input" */
  authTelegramInput: Array<AuthTelegramInput>;
  /** fetch aggregated fields from the table: "auth.telegram_input" */
  authTelegramInputAggregate: AuthTelegramInputAggregate;
  /** fetch data from the table: "auth.telegram_input" using primary key columns */
  authTelegramInputByPk?: Maybe<AuthTelegramInput>;
  /** fetch data from the table in a streaming manner: "auth.telegram_input" */
  authTelegramInputStream: Array<AuthTelegramInput>;
  /** fetch aggregated fields from the table: "auth.user" */
  authUserAggregate: AuthUserAggregate;
  /** fetch data from the table in a streaming manner: "auth.user" */
  authUserStream: Array<AuthUser>;
  /** fetch data from the table: "booking" */
  booking: Array<Booking>;
  /** fetch aggregated fields from the table: "booking" */
  bookingAggregate: BookingAggregate;
  /** fetch data from the table: "booking" using primary key columns */
  bookingByPk?: Maybe<Booking>;
  /** fetch data from the table: "booking_checkin" */
  bookingCheckin: Array<BookingCheckin>;
  /** fetch aggregated fields from the table: "booking_checkin" */
  bookingCheckinAggregate: BookingCheckinAggregate;
  /** fetch data from the table: "booking_checkin" using primary key columns */
  bookingCheckinByPk?: Maybe<BookingCheckin>;
  /** fetch data from the table in a streaming manner: "booking_checkin" */
  bookingCheckinStream: Array<BookingCheckin>;
  /** fetch data from the table in a streaming manner: "booking" */
  bookingStream: Array<Booking>;
  /** fetch data from the table: "checkin" using primary key columns */
  checkin?: Maybe<Checkin>;
  /** fetch data from the table: "checkin" */
  checkins: Array<Checkin>;
  /** fetch data from the table in a streaming manner: "checkin" */
  checkinsStream: Array<Checkin>;
  /** fetch data from the table: "ziferblat.city_i18n" */
  cities: Array<ZiferblatCityI18n>;
  /** fetch data from the table: "ziferblat.city_i18n" using primary key columns */
  city?: Maybe<ZiferblatCityI18n>;
  /** fetch data from the table: "ziferblat.contact" using primary key columns */
  contact?: Maybe<ZiferblatContact>;
  /** An array relationship */
  contacts: Array<ZiferblatContact>;
  /** fetch data from the table: "cultural_event.cultural_event" using primary key columns */
  culturalEvent?: Maybe<CulturalEvent>;
  /** fetch data from the table: "cultural_event.attendance" */
  culturalEventAttendance: Array<CulturalEventAttendance>;
  /** fetch aggregated fields from the table: "cultural_event.attendance" */
  culturalEventAttendanceAggregate: CulturalEventAttendanceAggregate;
  /** fetch data from the table: "cultural_event.attendance" using primary key columns */
  culturalEventAttendanceByPk?: Maybe<CulturalEventAttendance>;
  /** fetch data from the table in a streaming manner: "cultural_event.attendance" */
  culturalEventAttendanceStream: Array<CulturalEventAttendance>;
  /** fetch data from the table: "cultural_event.compensation" */
  culturalEventCompensation: Array<CulturalEventCompensation>;
  /** fetch aggregated fields from the table: "cultural_event.compensation" */
  culturalEventCompensationAggregate: CulturalEventCompensationAggregate;
  /** fetch data from the table: "cultural_event.compensation" using primary key columns */
  culturalEventCompensationByPk?: Maybe<CulturalEventCompensation>;
  /** fetch data from the table in a streaming manner: "cultural_event.compensation" */
  culturalEventCompensationStream: Array<CulturalEventCompensation>;
  /** fetch data from the table: "cultural_event.cultural_event_image" using primary key columns */
  culturalEventImage?: Maybe<CulturalEventImage>;
  /** fetch aggregated fields from the table: "cultural_event.cultural_event_image" */
  culturalEventImageAggregate: CulturalEventImageAggregate;
  /** fetch data from the table in a streaming manner: "cultural_event.cultural_event_image" */
  culturalEventImageStream: Array<CulturalEventImage>;
  /** fetch data from the table: "cultural_event.cultural_event_image" */
  culturalEventImages: Array<CulturalEventImage>;
  /** fetch data from the table: "cultural_event.prepaid_attendance" */
  culturalEventPrepaidAttendance: Array<CulturalEventPrepaidAttendance>;
  /** fetch aggregated fields from the table: "cultural_event.prepaid_attendance" */
  culturalEventPrepaidAttendanceAggregate: CulturalEventPrepaidAttendanceAggregate;
  /** fetch data from the table: "cultural_event.prepaid_attendance" using primary key columns */
  culturalEventPrepaidAttendanceByPk?: Maybe<CulturalEventPrepaidAttendance>;
  /** fetch data from the table in a streaming manner: "cultural_event.prepaid_attendance" */
  culturalEventPrepaidAttendanceStream: Array<CulturalEventPrepaidAttendance>;
  /** fetch data from the table: "cultural_event.status" */
  culturalEventStatus: Array<CulturalEventStatus>;
  /** fetch aggregated fields from the table: "cultural_event.status" */
  culturalEventStatusAggregate: CulturalEventStatusAggregate;
  /** fetch data from the table: "cultural_event.status" using primary key columns */
  culturalEventStatusByPk?: Maybe<CulturalEventStatus>;
  /** fetch data from the table in a streaming manner: "cultural_event.status" */
  culturalEventStatusStream: Array<CulturalEventStatus>;
  /** fetch data from the table: "cultural_event.ticket" */
  culturalEventTicket: Array<CulturalEventTicket>;
  /** fetch aggregated fields from the table: "cultural_event.ticket" */
  culturalEventTicketAggregate: CulturalEventTicketAggregate;
  /** fetch data from the table: "cultural_event.ticket" using primary key columns */
  culturalEventTicketByPk?: Maybe<CulturalEventTicket>;
  /** fetch data from the table in a streaming manner: "cultural_event.ticket" */
  culturalEventTicketStream: Array<CulturalEventTicket>;
  /** An array relationship */
  culturalEvents: Array<CulturalEvent>;
  /** fetch data from the table in a streaming manner: "cultural_event.cultural_event" */
  culturalEventsStream: Array<CulturalEvent>;
  /** An array relationship */
  gallery: Array<ZiferblatGallery>;
  /** fetch data from the table: "ziferblat.gallery" using primary key columns */
  galleryItem?: Maybe<ZiferblatGallery>;
  /** fetch data from the table: "media.media" using primary key columns */
  media?: Maybe<Media>;
  /** fetch aggregated fields from the table: "media.media" */
  mediaAggregate: MediaAggregate;
  /** fetch data from the table: "media.config" */
  mediaConfig: Array<MediaConfig>;
  /** fetch aggregated fields from the table: "media.config" */
  mediaConfigAggregate: MediaConfigAggregate;
  /** fetch data from the table: "media.config" using primary key columns */
  mediaConfigByPk?: Maybe<MediaConfig>;
  /** fetch data from the table in a streaming manner: "media.config" */
  mediaConfigStream: Array<MediaConfig>;
  /** fetch data from the table in a streaming manner: "media.media" */
  mediaStream: Array<Media>;
  /** fetch data from the table: "media.media" */
  medias: Array<Media>;
  /** An array relationship */
  pause: Array<Pause>;
  /** An aggregate relationship */
  pauseAggregate: PauseAggregate;
  /** fetch data from the table: "pause" using primary key columns */
  pauseByPk?: Maybe<Pause>;
  /** fetch data from the table in a streaming manner: "pause" */
  pauseStream: Array<Pause>;
  /** fetch data from the table: "payment.config" */
  paymentConfig: Array<PaymentConfig>;
  /** fetch aggregated fields from the table: "payment.config" */
  paymentConfigAggregate: PaymentConfigAggregate;
  /** fetch data from the table: "payment.config" using primary key columns */
  paymentConfigByPk?: Maybe<PaymentConfig>;
  /** fetch data from the table in a streaming manner: "payment.config" */
  paymentConfigStream: Array<PaymentConfig>;
  /** fetch data from the table: "payment.return" */
  paymentReturn: Array<PaymentReturn>;
  /** fetch aggregated fields from the table: "payment.return" */
  paymentReturnAggregate: PaymentReturnAggregate;
  /** fetch data from the table: "payment.return" using primary key columns */
  paymentReturnByPk?: Maybe<PaymentReturn>;
  /** fetch data from the table in a streaming manner: "payment.return" */
  paymentReturnStream: Array<PaymentReturn>;
  /** fetch data from the table: "payment.transaction" */
  paymentTransaction: Array<PaymentTransaction>;
  /** fetch aggregated fields from the table: "payment.transaction" */
  paymentTransactionAggregate: PaymentTransactionAggregate;
  /** fetch data from the table: "payment.transaction" using primary key columns */
  paymentTransactionByPk?: Maybe<PaymentTransaction>;
  /** fetch data from the table in a streaming manner: "payment.transaction" */
  paymentTransactionStream: Array<PaymentTransaction>;
  /** fetch data from the table: "tariff.pass_user" using primary key columns */
  soldPass?: Maybe<TariffPassUser>;
  /** fetch data from the table: "tariff.pass_user" */
  soldPasses: Array<TariffPassUser>;
  /** fetch data from the table: "tariff.tariff" using primary key columns */
  tariff?: Maybe<TariffTariff>;
  /** fetch data from the table: "tariff.pass" */
  tariffPass: Array<TariffPass>;
  /** fetch aggregated fields from the table: "tariff.pass" */
  tariffPassAggregate: TariffPassAggregate;
  /** fetch data from the table: "tariff.pass" using primary key columns */
  tariffPassByPk?: Maybe<TariffPass>;
  /** fetch data from the table: "tariff.pass_i18n" */
  tariffPassI18n: Array<TariffPassI18n>;
  /** fetch aggregated fields from the table: "tariff.pass_i18n" */
  tariffPassI18nAggregate: TariffPassI18nAggregate;
  /** fetch data from the table: "tariff.pass_i18n" using primary key columns */
  tariffPassI18nByPk?: Maybe<TariffPassI18n>;
  /** fetch data from the table in a streaming manner: "tariff.pass_i18n" */
  tariffPassI18nStream: Array<TariffPassI18n>;
  /** fetch data from the table in a streaming manner: "tariff.pass" */
  tariffPassStream: Array<TariffPass>;
  /** fetch aggregated fields from the table: "tariff.pass_user" */
  tariffPassUserAggregate: TariffPassUserAggregate;
  /** fetch data from the table in a streaming manner: "tariff.pass_user" */
  tariffPassUserStream: Array<TariffPassUser>;
  /** fetch aggregated fields from the table: "tariff.tariff" */
  tariffTariffAggregate: TariffTariffAggregate;
  /** fetch data from the table in a streaming manner: "tariff.tariff" */
  tariffTariffStream: Array<TariffTariff>;
  /** An array relationship */
  tariffs: Array<TariffTariff>;
  /** fetch data from the table: "auth.user" using primary key columns */
  user?: Maybe<AuthUser>;
  /** fetch data from the table: "auth.user" */
  users: Array<AuthUser>;
  /** fetch data from the table: "visit" using primary key columns */
  visit?: Maybe<Visit>;
  /** An array relationship */
  visits: Array<Visit>;
  /** fetch data from the table in a streaming manner: "visit" */
  visitsStream: Array<Visit>;
  /** fetch data from the table: "ziferblat.ziferblat" using primary key columns */
  ziferblat?: Maybe<ZiferblatZiferblat>;
  /** fetch data from the table: "ziferblat.address_i18n" */
  ziferblatAddressI18n: Array<ZiferblatAddressI18n>;
  /** fetch aggregated fields from the table: "ziferblat.address_i18n" */
  ziferblatAddressI18nAggregate: ZiferblatAddressI18nAggregate;
  /** fetch data from the table in a streaming manner: "ziferblat.address_i18n" */
  ziferblatAddressI18nStream: Array<ZiferblatAddressI18n>;
  /** fetch data from the table: "ziferblat.address_i18n" using primary key columns */
  ziferblatAddressI18ns?: Maybe<ZiferblatAddressI18n>;
  /** fetch aggregated fields from the table: "ziferblat.city_i18n" */
  ziferblatCityI18nAggregate: ZiferblatCityI18nAggregate;
  /** fetch data from the table in a streaming manner: "ziferblat.city_i18n" */
  ziferblatCityI18nStream: Array<ZiferblatCityI18n>;
  /** fetch data from the table in a streaming manner: "ziferblat.contact" */
  ziferblatContactStream: Array<ZiferblatContact>;
  /** fetch aggregated fields from the table: "ziferblat.gallery" */
  ziferblatGalleryAggregate: ZiferblatGalleryAggregate;
  /** fetch data from the table in a streaming manner: "ziferblat.gallery" */
  ziferblatGalleryStream: Array<ZiferblatGallery>;
  /** fetch data from the table: "ziferblat.ziferblat_i18n" using primary key columns */
  ziferblatI18n?: Maybe<ZiferblatZiferblatI18n>;
  /** fetch data from the table in a streaming manner: "ziferblat.ziferblat_i18n" */
  ziferblatI18nStream: Array<ZiferblatZiferblatI18n>;
  /** fetch data from the table: "ziferblat.ziferblat_i18n" */
  ziferblatI18ns: Array<ZiferblatZiferblatI18n>;
  /** fetch data from the table: "ziferblat.juridical_i18n" */
  ziferblatJuridicalI18n: Array<ZiferblatJuridicalI18n>;
  /** fetch aggregated fields from the table: "ziferblat.juridical_i18n" */
  ziferblatJuridicalI18nAggregate: ZiferblatJuridicalI18nAggregate;
  /** fetch data from the table: "ziferblat.juridical_i18n" using primary key columns */
  ziferblatJuridicalI18nByPk?: Maybe<ZiferblatJuridicalI18n>;
  /** fetch data from the table in a streaming manner: "ziferblat.juridical_i18n" */
  ziferblatJuridicalI18nStream: Array<ZiferblatJuridicalI18n>;
  /** fetch data from the table: "ziferblat.lang" */
  ziferblatLang: Array<ZiferblatLang>;
  /** fetch aggregated fields from the table: "ziferblat.lang" */
  ziferblatLangAggregate: ZiferblatLangAggregate;
  /** fetch data from the table: "ziferblat.lang" using primary key columns */
  ziferblatLangByPk?: Maybe<ZiferblatLang>;
  /** fetch data from the table in a streaming manner: "ziferblat.lang" */
  ziferblatLangStream: Array<ZiferblatLang>;
  /** fetch data from the table: "ziferblat.room" */
  ziferblatRoom: Array<ZiferblatRoom>;
  /** fetch aggregated fields from the table: "ziferblat.room" */
  ziferblatRoomAggregate: ZiferblatRoomAggregate;
  /** fetch data from the table: "ziferblat.room" using primary key columns */
  ziferblatRoomByPk?: Maybe<ZiferblatRoom>;
  /** fetch data from the table: "ziferblat.room_i18n" */
  ziferblatRoomI18n: Array<ZiferblatRoomI18n>;
  /** fetch aggregated fields from the table: "ziferblat.room_i18n" */
  ziferblatRoomI18nAggregate: ZiferblatRoomI18nAggregate;
  /** fetch data from the table: "ziferblat.room_i18n" using primary key columns */
  ziferblatRoomI18nByPk?: Maybe<ZiferblatRoomI18n>;
  /** fetch data from the table in a streaming manner: "ziferblat.room_i18n" */
  ziferblatRoomI18nStream: Array<ZiferblatRoomI18n>;
  /** fetch data from the table in a streaming manner: "ziferblat.room" */
  ziferblatRoomStream: Array<ZiferblatRoom>;
  /** fetch data from the table in a streaming manner: "ziferblat.ziferblat" */
  ziferblatStream: Array<ZiferblatZiferblat>;
  /** An array relationship */
  ziferblats: Array<ZiferblatZiferblat>;
  /** An aggregate relationship */
  ziferblatsAggregate: ZiferblatZiferblatAggregate;
};


export type Subscription_RootCompensationCurrencyArgs = {
  distinctOn?: InputMaybe<Array<CompensationCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationCurrencyOrderBy>>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};


export type Subscription_RootCompensationCurrencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompensationCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationCurrencyOrderBy>>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};


export type Subscription_RootCompensationCurrencyByPkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootCompensationCurrencyStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CompensationCurrencyStreamCursorInput>>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};


export type Subscription_RootCompensationUnitArgs = {
  distinctOn?: InputMaybe<Array<CompensationUnitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationUnitOrderBy>>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};


export type Subscription_RootCompensationUnitAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompensationUnitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationUnitOrderBy>>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};


export type Subscription_RootCompensationUnitByPkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootCompensationUnitStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CompensationUnitStreamCursorInput>>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};


export type Subscription_RootAccessAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


export type Subscription_RootAccessAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


export type Subscription_RootAccessAccessStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccessAccessStreamCursorInput>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


export type Subscription_RootAccessExpireArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


export type Subscription_RootAccessExpireAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


export type Subscription_RootAccessExpireByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAccessExpireStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccessExpireStreamCursorInput>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


export type Subscription_RootAccessGrantArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


export type Subscription_RootAccessGrantAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


export type Subscription_RootAccessGrantByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAccessGrantStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccessGrantStreamCursorInput>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


export type Subscription_RootAccessPermissionArgs = {
  distinctOn?: InputMaybe<Array<AccessPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessPermissionOrderBy>>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};


export type Subscription_RootAccessPermissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessPermissionOrderBy>>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};


export type Subscription_RootAccessPermissionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAccessPermissionStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccessPermissionStreamCursorInput>>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};


export type Subscription_RootAccessRoleArgs = {
  distinctOn?: InputMaybe<Array<AccessRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessRoleOrderBy>>;
  where?: InputMaybe<AccessRoleBoolExp>;
};


export type Subscription_RootAccessRoleAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessRoleOrderBy>>;
  where?: InputMaybe<AccessRoleBoolExp>;
};


export type Subscription_RootAccessRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAccessRoleStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccessRoleStreamCursorInput>>;
  where?: InputMaybe<AccessRoleBoolExp>;
};


export type Subscription_RootAggregateCheckinsArgs = {
  distinctOn?: InputMaybe<Array<CheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CheckinOrderBy>>;
  where?: InputMaybe<CheckinBoolExp>;
};


export type Subscription_RootAggregateContactsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatContactOrderBy>>;
  where?: InputMaybe<ZiferblatContactBoolExp>;
};


export type Subscription_RootAggregateCulturalEventsArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventOrderBy>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


export type Subscription_RootAggregateVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};


export type Subscription_RootAggregateZiferblatI18nArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatI18nOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatI18nBoolExp>;
};


export type Subscription_RootAttendeesArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


export type Subscription_RootAttendeesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


export type Subscription_RootAttendeesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AttendeesStreamCursorInput>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


export type Subscription_RootAuditLogArgs = {
  distinctOn?: InputMaybe<Array<AuditLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuditLogOrderBy>>;
  where?: InputMaybe<AuditLogBoolExp>;
};


export type Subscription_RootAuditLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuditLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuditLogOrderBy>>;
  where?: InputMaybe<AuditLogBoolExp>;
};


export type Subscription_RootAuditLogByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuditLogStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuditLogStreamCursorInput>>;
  where?: InputMaybe<AuditLogBoolExp>;
};


export type Subscription_RootAuthAliasArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


export type Subscription_RootAuthAliasAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


export type Subscription_RootAuthAliasByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthAliasStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthAliasStreamCursorInput>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


export type Subscription_RootAuthCurrentSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Subscription_RootAuthCurrentSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Subscription_RootAuthLogoutArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Subscription_RootAuthLogoutAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Subscription_RootAuthLogoutByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthLogoutStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthLogoutStreamCursorInput>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Subscription_RootAuthMeArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Subscription_RootAuthMeAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Subscription_RootAuthSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Subscription_RootAuthSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Subscription_RootAuthSessionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthSessionStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthSessionStreamCursorInput>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Subscription_RootAuthTelegramBotArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramBotSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramBotOrderBy>>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};


export type Subscription_RootAuthTelegramBotAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramBotSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramBotOrderBy>>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};


export type Subscription_RootAuthTelegramBotByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthTelegramBotStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthTelegramBotStreamCursorInput>>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};


export type Subscription_RootAuthTelegramInputArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramInputSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramInputOrderBy>>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};


export type Subscription_RootAuthTelegramInputAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramInputSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramInputOrderBy>>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};


export type Subscription_RootAuthTelegramInputByPkArgs = {
  hash: Scalars['String']['input'];
};


export type Subscription_RootAuthTelegramInputStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthTelegramInputStreamCursorInput>>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};


export type Subscription_RootAuthUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Subscription_RootAuthUserStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserStreamCursorInput>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Subscription_RootBookingArgs = {
  distinctOn?: InputMaybe<Array<BookingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BookingOrderBy>>;
  where?: InputMaybe<BookingBoolExp>;
};


export type Subscription_RootBookingAggregateArgs = {
  distinctOn?: InputMaybe<Array<BookingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BookingOrderBy>>;
  where?: InputMaybe<BookingBoolExp>;
};


export type Subscription_RootBookingByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBookingCheckinArgs = {
  distinctOn?: InputMaybe<Array<BookingCheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BookingCheckinOrderBy>>;
  where?: InputMaybe<BookingCheckinBoolExp>;
};


export type Subscription_RootBookingCheckinAggregateArgs = {
  distinctOn?: InputMaybe<Array<BookingCheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BookingCheckinOrderBy>>;
  where?: InputMaybe<BookingCheckinBoolExp>;
};


export type Subscription_RootBookingCheckinByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBookingCheckinStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BookingCheckinStreamCursorInput>>;
  where?: InputMaybe<BookingCheckinBoolExp>;
};


export type Subscription_RootBookingStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BookingStreamCursorInput>>;
  where?: InputMaybe<BookingBoolExp>;
};


export type Subscription_RootCheckinArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCheckinsArgs = {
  distinctOn?: InputMaybe<Array<CheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CheckinOrderBy>>;
  where?: InputMaybe<CheckinBoolExp>;
};


export type Subscription_RootCheckinsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CheckinStreamCursorInput>>;
  where?: InputMaybe<CheckinBoolExp>;
};


export type Subscription_RootCitiesArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatCityI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatCityI18nOrderBy>>;
  where?: InputMaybe<ZiferblatCityI18nBoolExp>;
};


export type Subscription_RootCityArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootContactArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootContactsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatContactOrderBy>>;
  where?: InputMaybe<ZiferblatContactBoolExp>;
};


export type Subscription_RootCulturalEventArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCulturalEventAttendanceArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


export type Subscription_RootCulturalEventAttendanceAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


export type Subscription_RootCulturalEventAttendanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCulturalEventAttendanceStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventAttendanceStreamCursorInput>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


export type Subscription_RootCulturalEventCompensationArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventCompensationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventCompensationOrderBy>>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};


export type Subscription_RootCulturalEventCompensationAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventCompensationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventCompensationOrderBy>>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};


export type Subscription_RootCulturalEventCompensationByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCulturalEventCompensationStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventCompensationStreamCursorInput>>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};


export type Subscription_RootCulturalEventImageArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCulturalEventImageAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventImageOrderBy>>;
  where?: InputMaybe<CulturalEventImageBoolExp>;
};


export type Subscription_RootCulturalEventImageStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventImageStreamCursorInput>>;
  where?: InputMaybe<CulturalEventImageBoolExp>;
};


export type Subscription_RootCulturalEventImagesArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventImageOrderBy>>;
  where?: InputMaybe<CulturalEventImageBoolExp>;
};


export type Subscription_RootCulturalEventPrepaidAttendanceArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventPrepaidAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventPrepaidAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventPrepaidAttendanceBoolExp>;
};


export type Subscription_RootCulturalEventPrepaidAttendanceAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventPrepaidAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventPrepaidAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventPrepaidAttendanceBoolExp>;
};


export type Subscription_RootCulturalEventPrepaidAttendanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCulturalEventPrepaidAttendanceStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventPrepaidAttendanceStreamCursorInput>>;
  where?: InputMaybe<CulturalEventPrepaidAttendanceBoolExp>;
};


export type Subscription_RootCulturalEventStatusArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventStatusOrderBy>>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};


export type Subscription_RootCulturalEventStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventStatusOrderBy>>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};


export type Subscription_RootCulturalEventStatusByPkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootCulturalEventStatusStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventStatusStreamCursorInput>>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};


export type Subscription_RootCulturalEventTicketArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketOrderBy>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};


export type Subscription_RootCulturalEventTicketAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketOrderBy>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};


export type Subscription_RootCulturalEventTicketByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCulturalEventTicketStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventTicketStreamCursorInput>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};


export type Subscription_RootCulturalEventsArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventOrderBy>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


export type Subscription_RootCulturalEventsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventStreamCursorInput>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


export type Subscription_RootGalleryArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatGallerySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatGalleryOrderBy>>;
  where?: InputMaybe<ZiferblatGalleryBoolExp>;
};


export type Subscription_RootGalleryItemArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootMediaArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootMediaAggregateArgs = {
  distinctOn?: InputMaybe<Array<MediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
  where?: InputMaybe<MediaBoolExp>;
};


export type Subscription_RootMediaConfigArgs = {
  distinctOn?: InputMaybe<Array<MediaConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaConfigOrderBy>>;
  where?: InputMaybe<MediaConfigBoolExp>;
};


export type Subscription_RootMediaConfigAggregateArgs = {
  distinctOn?: InputMaybe<Array<MediaConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaConfigOrderBy>>;
  where?: InputMaybe<MediaConfigBoolExp>;
};


export type Subscription_RootMediaConfigByPkArgs = {
  bucket: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
};


export type Subscription_RootMediaConfigStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MediaConfigStreamCursorInput>>;
  where?: InputMaybe<MediaConfigBoolExp>;
};


export type Subscription_RootMediaStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MediaStreamCursorInput>>;
  where?: InputMaybe<MediaBoolExp>;
};


export type Subscription_RootMediasArgs = {
  distinctOn?: InputMaybe<Array<MediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
  where?: InputMaybe<MediaBoolExp>;
};


export type Subscription_RootPauseArgs = {
  distinctOn?: InputMaybe<Array<PauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PauseOrderBy>>;
  where?: InputMaybe<PauseBoolExp>;
};


export type Subscription_RootPauseAggregateArgs = {
  distinctOn?: InputMaybe<Array<PauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PauseOrderBy>>;
  where?: InputMaybe<PauseBoolExp>;
};


export type Subscription_RootPauseByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPauseStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PauseStreamCursorInput>>;
  where?: InputMaybe<PauseBoolExp>;
};


export type Subscription_RootPaymentConfigArgs = {
  distinctOn?: InputMaybe<Array<PaymentConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentConfigOrderBy>>;
  where?: InputMaybe<PaymentConfigBoolExp>;
};


export type Subscription_RootPaymentConfigAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentConfigOrderBy>>;
  where?: InputMaybe<PaymentConfigBoolExp>;
};


export type Subscription_RootPaymentConfigByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPaymentConfigStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PaymentConfigStreamCursorInput>>;
  where?: InputMaybe<PaymentConfigBoolExp>;
};


export type Subscription_RootPaymentReturnArgs = {
  distinctOn?: InputMaybe<Array<PaymentReturnSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentReturnOrderBy>>;
  where?: InputMaybe<PaymentReturnBoolExp>;
};


export type Subscription_RootPaymentReturnAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentReturnSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentReturnOrderBy>>;
  where?: InputMaybe<PaymentReturnBoolExp>;
};


export type Subscription_RootPaymentReturnByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPaymentReturnStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PaymentReturnStreamCursorInput>>;
  where?: InputMaybe<PaymentReturnBoolExp>;
};


export type Subscription_RootPaymentTransactionArgs = {
  distinctOn?: InputMaybe<Array<PaymentTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentTransactionOrderBy>>;
  where?: InputMaybe<PaymentTransactionBoolExp>;
};


export type Subscription_RootPaymentTransactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentTransactionOrderBy>>;
  where?: InputMaybe<PaymentTransactionBoolExp>;
};


export type Subscription_RootPaymentTransactionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPaymentTransactionStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PaymentTransactionStreamCursorInput>>;
  where?: InputMaybe<PaymentTransactionBoolExp>;
};


export type Subscription_RootSoldPassArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootSoldPassesArgs = {
  distinctOn?: InputMaybe<Array<TariffPassUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassUserOrderBy>>;
  where?: InputMaybe<TariffPassUserBoolExp>;
};


export type Subscription_RootTariffArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTariffPassArgs = {
  distinctOn?: InputMaybe<Array<TariffPassSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassOrderBy>>;
  where?: InputMaybe<TariffPassBoolExp>;
};


export type Subscription_RootTariffPassAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffPassSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassOrderBy>>;
  where?: InputMaybe<TariffPassBoolExp>;
};


export type Subscription_RootTariffPassByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTariffPassI18nArgs = {
  distinctOn?: InputMaybe<Array<TariffPassI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassI18nOrderBy>>;
  where?: InputMaybe<TariffPassI18nBoolExp>;
};


export type Subscription_RootTariffPassI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffPassI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassI18nOrderBy>>;
  where?: InputMaybe<TariffPassI18nBoolExp>;
};


export type Subscription_RootTariffPassI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTariffPassI18nStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TariffPassI18nStreamCursorInput>>;
  where?: InputMaybe<TariffPassI18nBoolExp>;
};


export type Subscription_RootTariffPassStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TariffPassStreamCursorInput>>;
  where?: InputMaybe<TariffPassBoolExp>;
};


export type Subscription_RootTariffPassUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffPassUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffPassUserOrderBy>>;
  where?: InputMaybe<TariffPassUserBoolExp>;
};


export type Subscription_RootTariffPassUserStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TariffPassUserStreamCursorInput>>;
  where?: InputMaybe<TariffPassUserBoolExp>;
};


export type Subscription_RootTariffTariffAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffTariffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffTariffOrderBy>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


export type Subscription_RootTariffTariffStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TariffTariffStreamCursorInput>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


export type Subscription_RootTariffsArgs = {
  distinctOn?: InputMaybe<Array<TariffTariffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffTariffOrderBy>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


export type Subscription_RootUserArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Subscription_RootVisitArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};


export type Subscription_RootVisitsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VisitStreamCursorInput>>;
  where?: InputMaybe<VisitBoolExp>;
};


export type Subscription_RootZiferblatArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootZiferblatAddressI18nArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatAddressI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatAddressI18nOrderBy>>;
  where?: InputMaybe<ZiferblatAddressI18nBoolExp>;
};


export type Subscription_RootZiferblatAddressI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatAddressI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatAddressI18nOrderBy>>;
  where?: InputMaybe<ZiferblatAddressI18nBoolExp>;
};


export type Subscription_RootZiferblatAddressI18nStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZiferblatAddressI18nStreamCursorInput>>;
  where?: InputMaybe<ZiferblatAddressI18nBoolExp>;
};


export type Subscription_RootZiferblatAddressI18nsArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootZiferblatCityI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatCityI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatCityI18nOrderBy>>;
  where?: InputMaybe<ZiferblatCityI18nBoolExp>;
};


export type Subscription_RootZiferblatCityI18nStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZiferblatCityI18nStreamCursorInput>>;
  where?: InputMaybe<ZiferblatCityI18nBoolExp>;
};


export type Subscription_RootZiferblatContactStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZiferblatContactStreamCursorInput>>;
  where?: InputMaybe<ZiferblatContactBoolExp>;
};


export type Subscription_RootZiferblatGalleryAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatGallerySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatGalleryOrderBy>>;
  where?: InputMaybe<ZiferblatGalleryBoolExp>;
};


export type Subscription_RootZiferblatGalleryStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZiferblatGalleryStreamCursorInput>>;
  where?: InputMaybe<ZiferblatGalleryBoolExp>;
};


export type Subscription_RootZiferblatI18nArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootZiferblatI18nStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZiferblatZiferblatI18nStreamCursorInput>>;
  where?: InputMaybe<ZiferblatZiferblatI18nBoolExp>;
};


export type Subscription_RootZiferblatI18nsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatI18nOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatI18nBoolExp>;
};


export type Subscription_RootZiferblatJuridicalI18nArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatJuridicalI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatJuridicalI18nOrderBy>>;
  where?: InputMaybe<ZiferblatJuridicalI18nBoolExp>;
};


export type Subscription_RootZiferblatJuridicalI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatJuridicalI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatJuridicalI18nOrderBy>>;
  where?: InputMaybe<ZiferblatJuridicalI18nBoolExp>;
};


export type Subscription_RootZiferblatJuridicalI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootZiferblatJuridicalI18nStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZiferblatJuridicalI18nStreamCursorInput>>;
  where?: InputMaybe<ZiferblatJuridicalI18nBoolExp>;
};


export type Subscription_RootZiferblatLangArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatLangSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatLangOrderBy>>;
  where?: InputMaybe<ZiferblatLangBoolExp>;
};


export type Subscription_RootZiferblatLangAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatLangSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatLangOrderBy>>;
  where?: InputMaybe<ZiferblatLangBoolExp>;
};


export type Subscription_RootZiferblatLangByPkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootZiferblatLangStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZiferblatLangStreamCursorInput>>;
  where?: InputMaybe<ZiferblatLangBoolExp>;
};


export type Subscription_RootZiferblatRoomArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatRoomSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatRoomOrderBy>>;
  where?: InputMaybe<ZiferblatRoomBoolExp>;
};


export type Subscription_RootZiferblatRoomAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatRoomSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatRoomOrderBy>>;
  where?: InputMaybe<ZiferblatRoomBoolExp>;
};


export type Subscription_RootZiferblatRoomByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootZiferblatRoomI18nArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatRoomI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatRoomI18nOrderBy>>;
  where?: InputMaybe<ZiferblatRoomI18nBoolExp>;
};


export type Subscription_RootZiferblatRoomI18nAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatRoomI18nSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatRoomI18nOrderBy>>;
  where?: InputMaybe<ZiferblatRoomI18nBoolExp>;
};


export type Subscription_RootZiferblatRoomI18nByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootZiferblatRoomI18nStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZiferblatRoomI18nStreamCursorInput>>;
  where?: InputMaybe<ZiferblatRoomI18nBoolExp>;
};


export type Subscription_RootZiferblatRoomStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZiferblatRoomStreamCursorInput>>;
  where?: InputMaybe<ZiferblatRoomBoolExp>;
};


export type Subscription_RootZiferblatStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZiferblatZiferblatStreamCursorInput>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};


export type Subscription_RootZiferblatsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};


export type Subscription_RootZiferblatsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};

export type TariffPassAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TariffPassSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TariffPassBoolExp>;
  predicate: IntComparisonExp;
};

export type TariffPassI18nAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TariffPassI18nSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TariffPassI18nBoolExp>;
  predicate: IntComparisonExp;
};

export type TariffPassUserAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TariffPassUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TariffPassUserBoolExp>;
  predicate: IntComparisonExp;
};

export type TariffTariffAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TariffTariffSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TariffTariffBoolExp>;
  predicate: IntComparisonExp;
};

export type Url_Media_Args = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type VisitAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<VisitSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<VisitBoolExp>;
  predicate: IntComparisonExp;
};

export type ZiferblatAddressI18nAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ZiferblatAddressI18nSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ZiferblatAddressI18nBoolExp>;
  predicate: IntComparisonExp;
};

export type ZiferblatCityI18nAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ZiferblatCityI18nSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ZiferblatCityI18nBoolExp>;
  predicate: IntComparisonExp;
};

export type ZiferblatContactAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ZiferblatContactSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ZiferblatContactBoolExp>;
  predicate: IntComparisonExp;
};

export type ZiferblatGalleryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ZiferblatGallerySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ZiferblatGalleryBoolExp>;
  predicate: IntComparisonExp;
};

export type ZiferblatZiferblatAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ZiferblatZiferblatBoolExp>;
  predicate: IntComparisonExp;
};

export type ZiferblatZiferblatI18nAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ZiferblatZiferblatI18nSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ZiferblatZiferblatI18nBoolExp>;
  predicate: IntComparisonExp;
};

export type AddBookingMutationVariables = Exact<{
  object: BookingInsertInput;
}>;


export type AddBookingMutation = { __typename?: 'mutation_root', insertBookingOne?: { __typename?: 'Booking', id: string, name: string, phone: string, comment: string, startsAt: Date, bookingCheckins: Array<{ __typename?: 'BookingCheckin', id: string, checkin?: { __typename?: 'Checkin', id: string, name?: string | null, checkedInAt: Date, sum?: number | null } | null, visit?: { __typename?: 'Visit', id: string, name?: string | null, checkedInAt: Date, checkedOutAt: Date, sum: number } | null }> } | null };

export type AddCulturalEventImageMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  ziferblatId: Scalars['uuid']['input'];
  culturalEventId: Scalars['uuid']['input'];
  contentType: Scalars['String']['input'];
}>;


export type AddCulturalEventImageMutation = { __typename?: 'mutation_root', addCulturalEventImage?: { __typename?: 'culturalEventImage', id: string } | null };

export type AddRoleMutationVariables = Exact<{
  roleId: Scalars['uuid']['input'];
  userId: Scalars['uuid']['input'];
  ziferblatId: Scalars['uuid']['input'];
}>;


export type AddRoleMutation = { __typename?: 'mutation_root', addRole?: { __typename?: 'AccessGrant', id: string, subject: { __typename?: 'AuthUser', id: string, alias: Array<{ __typename?: 'AuthAlias', type: string, value: string }>, grants: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string } }> } } | null };

export type AddTariffMutationVariables = Exact<{
  activeFrom: Scalars['timestamptz']['input'];
  stopCheck: Scalars['smallint']['input'];
  hourPrice: Array<Scalars['smallint']['input']> | Scalars['smallint']['input'];
  ziferblatId: Scalars['uuid']['input'];
}>;


export type AddTariffMutation = { __typename?: 'mutation_root', addTariff?: { __typename?: 'TariffTariff', id: string, hourPrice: Array<number>, stopCheck: number, activeFrom: Date, activeTo?: Date | null } | null };

export type AddTicketMutationVariables = Exact<{
  culturalEventId: Scalars['uuid']['input'];
  activeFrom: Scalars['timestamptz']['input'];
  maxCount?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  customUrl?: InputMaybe<Scalars['String']['input']>;
  customTitle?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddTicketMutation = { __typename?: 'mutation_root', insertCulturalEventTicketOne?: { __typename?: 'CulturalEventTicket', id: string, activeFrom: Date, maxCount?: number | null, price: number, customUrl?: string | null, customTitle?: string | null, transaction: Array<{ __typename?: 'PaymentTransaction', id: string }> } | null };

export type AddToGalleryMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  ziferblatId: Scalars['uuid']['input'];
  contentType?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddToGalleryMutation = { __typename?: 'mutation_root', addToGallery?: { __typename?: 'ZiferblatGallery', id: string } | null };

export type AttendanceQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type AttendanceQuery = { __typename?: 'query_root', attendees: Array<{ __typename?: 'attendees', id?: string | null, startedAt?: Date | null, endedAt?: Date | null, sum?: number | null, transactionId?: string | null, checkin?: { __typename?: 'Checkin', id: string, name?: string | null, sum?: number | null } | null, visit?: { __typename?: 'Visit', id: string, name?: string | null, sum: number } | null }> };

export type BankTransactionQueryVariables = Exact<{
  originalTransactionId: Scalars['String']['input'];
}>;


export type BankTransactionQuery = { __typename?: 'query_root', paymentTransaction: Array<{ __typename?: 'PaymentTransaction', id: string }> };

export type BookingFragment = { __typename?: 'Booking', id: string, name: string, phone: string, comment: string, startsAt: Date, bookingCheckins: Array<{ __typename?: 'BookingCheckin', id: string, checkin?: { __typename?: 'Checkin', id: string, name?: string | null, checkedInAt: Date, sum?: number | null } | null, visit?: { __typename?: 'Visit', id: string, name?: string | null, checkedInAt: Date, checkedOutAt: Date, sum: number } | null }> };

export type BookingQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type BookingQuery = { __typename?: 'query_root', bookingByPk?: { __typename?: 'Booking', id: string, name: string, phone: string, comment: string, startsAt: Date, bookingCheckins: Array<{ __typename?: 'BookingCheckin', id: string, checkin?: { __typename?: 'Checkin', id: string, name?: string | null, checkedInAt: Date, sum?: number | null } | null, visit?: { __typename?: 'Visit', id: string, name?: string | null, checkedInAt: Date, checkedOutAt: Date, sum: number } | null }> } | null };

export type BookingsQueryVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
  before: Scalars['timestamptz']['input'];
}>;


export type BookingsQuery = { __typename?: 'query_root', booking: Array<{ __typename?: 'Booking', id: string, comment: string, name: string, phone: string, startsAt: Date }> };

export type CalendarEventsQueryVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
  before: Scalars['timestamptz']['input'];
}>;


export type CalendarEventsQuery = { __typename?: 'query_root', draftCulturalEvents: Array<{ __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, status: CulturalEventStatusEnum, internalComment: string }>, aggregateCulturalEvents: { __typename?: 'CulturalEventAggregate', aggregate?: { __typename?: 'CulturalEventAggregateFields', count: number } | null }, culturalEvents: Array<{ __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, status: CulturalEventStatusEnum, internalComment: string }>, booking: Array<{ __typename?: 'Booking', id: string, comment: string, name: string, phone: string, startsAt: Date }> };

export type ChangeVisitNameMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  name: Scalars['String']['input'];
}>;


export type ChangeVisitNameMutation = { __typename?: 'mutation_root', updateCheckin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null } | null };

export type CheckInMutationVariables = Exact<{
  data: CheckinInsertInput;
}>;


export type CheckInMutation = { __typename?: 'mutation_root', addCheckin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null } | null };

export type CheckOutMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  sum: Scalars['Int']['input'];
}>;


export type CheckOutMutation = { __typename?: 'mutation_root', checkOut?: { __typename?: 'Visit', id: string } | null };

export type CheckOutMultipleMutationVariables = Exact<{
  objects: Array<VisitInsertInput> | VisitInsertInput;
}>;


export type CheckOutMultipleMutation = { __typename?: 'mutation_root', checkOutMultiple?: { __typename?: 'VisitMutationResponse', returning: Array<{ __typename?: 'Visit', id: string, name?: string | null }> } | null };

export type CheckinFragment = { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null };

export type CheckinQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type CheckinQuery = { __typename?: 'query_root', checkin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null } | null };

export type CheckinPauseMutationVariables = Exact<{
  checkinId: Scalars['uuid']['input'];
}>;


export type CheckinPauseMutation = { __typename?: 'mutation_root', insertPauseOne?: { __typename?: 'Pause', checkin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null } | null } | null };

export type CheckinResumeMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  endedAt: Scalars['timestamptz']['input'];
}>;


export type CheckinResumeMutation = { __typename?: 'mutation_root', updatePauseByPk?: { __typename?: 'Pause', checkin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null } | null } | null };

export type CheckinsQueryVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
  startsAt: Scalars['timestamptz']['input'];
  endsAt: Scalars['timestamptz']['input'];
}>;


export type CheckinsQuery = { __typename?: 'query_root', checkins: Array<{ __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null }>, upcomingCulturalEvents: Array<{ __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, status: CulturalEventStatusEnum, tickets: Array<{ __typename?: 'CulturalEventTicket', id: string }> }>, upcomingBookings: Array<{ __typename?: 'Booking', id: string, name: string, phone: string, comment: string, startsAt: Date, bookingCheckins: Array<{ __typename?: 'BookingCheckin', id: string, checkin?: { __typename?: 'Checkin', id: string, name?: string | null, checkedInAt: Date, sum?: number | null } | null, visit?: { __typename?: 'Visit', id: string, name?: string | null, checkedInAt: Date, checkedOutAt: Date, sum: number } | null }> }> };

export type UpdateCheckinsSubscriptionVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
}>;


export type UpdateCheckinsSubscription = { __typename?: 'subscription_root', checkins: Array<{ __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null }> };

export type UpdateCulturalEventsSubscriptionVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
  startsAt: Scalars['timestamptz']['input'];
  endsAt: Scalars['timestamptz']['input'];
}>;


export type UpdateCulturalEventsSubscription = { __typename?: 'subscription_root', upcomingCulturalEvents: Array<{ __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, status: CulturalEventStatusEnum, tickets: Array<{ __typename?: 'CulturalEventTicket', id: string }> }> };

export type CulturalEventFragment = { __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, publicDescription: string, internalComment: string, status: CulturalEventStatusEnum, ziferblatId: string, slug?: string | null, images: Array<{ __typename?: 'culturalEventImage', imageId: string, image: { __typename?: 'media', id: string } }>, tickets: Array<{ __typename?: 'CulturalEventTicket', id: string, price: number, maxCount?: number | null, activeFrom: Date, customUrl?: string | null, transaction: Array<{ __typename?: 'PaymentTransaction', id: string, body: any, createdAt: Date, sum: number, attendance?: { __typename?: 'attendees', id?: string | null } | null }> }>, compensation?: { __typename?: 'CulturalEventCompensation', id: string, currency: CompensationCurrencyEnum, amount: number, unit: CompensationUnitEnum } | null };

export type CulturalEventQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type CulturalEventQuery = { __typename?: 'query_root', culturalEvent?: { __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, publicDescription: string, internalComment: string, status: CulturalEventStatusEnum, ziferblatId: string, slug?: string | null, images: Array<{ __typename?: 'culturalEventImage', imageId: string, image: { __typename?: 'media', id: string } }>, tickets: Array<{ __typename?: 'CulturalEventTicket', id: string, price: number, maxCount?: number | null, activeFrom: Date, customUrl?: string | null, transaction: Array<{ __typename?: 'PaymentTransaction', id: string, body: any, createdAt: Date, sum: number, attendance?: { __typename?: 'attendees', id?: string | null } | null }> }>, compensation?: { __typename?: 'CulturalEventCompensation', id: string, currency: CompensationCurrencyEnum, amount: number, unit: CompensationUnitEnum } | null } | null };

export type CulturalEventImagesQueryVariables = Exact<{
  culturalEventId: Scalars['uuid']['input'];
}>;


export type CulturalEventImagesQuery = { __typename?: 'query_root', culturalEventImages: Array<{ __typename?: 'culturalEventImage', id: string, image: { __typename?: 'media', id: string, url?: string | null } }> };

export type CulturalEventListItemFragment = { __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, status: CulturalEventStatusEnum, internalComment: string };

export type CulturalEventsQueryVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
  before: Scalars['timestamptz']['input'];
}>;


export type CulturalEventsQuery = { __typename?: 'query_root', draftCulturalEvents: Array<{ __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, status: CulturalEventStatusEnum, internalComment: string }>, aggregateCulturalEvents: { __typename?: 'CulturalEventAggregate', aggregate?: { __typename?: 'CulturalEventAggregateFields', count: number } | null }, culturalEvents: Array<{ __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, status: CulturalEventStatusEnum, internalComment: string }> };

export type GalleryQueryVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
}>;


export type GalleryQuery = { __typename?: 'query_root', gallery: Array<{ __typename?: 'ZiferblatGallery', id: string, ziferblatId: string, location: string, media: { __typename?: 'media', id: string, contentType: string, url?: string | null } }> };

export type ImageQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type ImageQuery = { __typename?: 'query_root', galleryItem?: { __typename?: 'ZiferblatGallery', id: string, location: string, media: { __typename?: 'media', id: string, contentType: string, url?: string | null } } | null };

export type ImageUploadUrlMutationVariables = Exact<{ [key: string]: never; }>;


export type ImageUploadUrlMutation = { __typename?: 'mutation_root', getUploadUrl?: { __typename?: 'UploadUrl', id: string, url: string, expiresIn: number } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'mutation_root', logout?: { __typename?: 'AuthLogout', id: string } | null };

export type MeQueryVariables = Exact<{
  domain: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}>;


export type MeQuery = { __typename?: 'query_root', telegramBot: Array<{ __typename?: 'AuthTelegramBot', domain: string, name: string, id: number }>, me?: { __typename?: 'AuthSession', id: string, token: string, user?: { __typename?: 'AuthUser', id: string, avatarUrl?: string | null, alias: Array<{ __typename?: 'AuthAlias', id: string, type: string, value: string }>, roles: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string }, ziferblat?: { __typename?: 'ZiferblatZiferblat', id: string, avatarUrl: string, i18n: Array<{ __typename?: 'ZiferblatZiferblatI18n', name: string }> } | null }> } | null } | null };

export type RemoveBookingMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveBookingMutation = { __typename?: 'mutation_root', deleteBookingByPk?: { __typename?: 'Booking', id: string } | null };

export type RemoveCulturalEventImageMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveCulturalEventImageMutation = { __typename?: 'mutation_root', removeCulturalEventImage?: { __typename?: 'culturalEventImage', id: string, culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, publicDescription: string, internalComment: string, status: CulturalEventStatusEnum, ziferblatId: string, slug?: string | null, images: Array<{ __typename?: 'culturalEventImage', imageId: string, image: { __typename?: 'media', id: string } }>, tickets: Array<{ __typename?: 'CulturalEventTicket', id: string, price: number, maxCount?: number | null, activeFrom: Date, customUrl?: string | null, transaction: Array<{ __typename?: 'PaymentTransaction', id: string, body: any, createdAt: Date, sum: number, attendance?: { __typename?: 'attendees', id?: string | null } | null }> }>, compensation?: { __typename?: 'CulturalEventCompensation', id: string, currency: CompensationCurrencyEnum, amount: number, unit: CompensationUnitEnum } | null } } | null };

export type RemoveFromGalleryMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveFromGalleryMutation = { __typename?: 'mutation_root', deleteZiferblatGalleryByPk?: { __typename?: 'ZiferblatGallery', id: string } | null };

export type RemoveRoleMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveRoleMutation = { __typename?: 'mutation_root', removeGrant?: { __typename?: 'AccessExpire', grant?: { __typename?: 'AccessGrant', subject: { __typename?: 'AuthUser', id: string, alias: Array<{ __typename?: 'AuthAlias', type: string, value: string }>, grants: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string } }> } } | null } | null };

export type RemoveTicketMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveTicketMutation = { __typename?: 'mutation_root', removeTicket?: { __typename?: 'CulturalEventTicket', id: string, culturalEvent: { __typename?: 'CulturalEvent', tickets: Array<{ __typename?: 'CulturalEventTicket', id: string, customUrl?: string | null, price: number }> } } | null };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'query_root', accessRole: Array<{ __typename?: 'AccessRole', id: string, name: string }> };

export type SearchAliasQueryVariables = Exact<{
  term: Scalars['String']['input'];
  ziferblatId: Scalars['uuid']['input'];
}>;


export type SearchAliasQuery = { __typename?: 'query_root', authAlias: Array<{ __typename?: 'AuthAlias', id: string, user?: { __typename?: 'AuthUser', id: string, alias: Array<{ __typename?: 'AuthAlias', id: string, type: string, value: string }> } | null }> };

export type SessionFragment = { __typename?: 'AuthSession', id: string, token: string, user?: { __typename?: 'AuthUser', id: string, avatarUrl?: string | null, alias: Array<{ __typename?: 'AuthAlias', id: string, type: string, value: string }>, roles: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string }, ziferblat?: { __typename?: 'ZiferblatZiferblat', id: string, avatarUrl: string, i18n: Array<{ __typename?: 'ZiferblatZiferblatI18n', name: string }> } | null }> } | null };

export type ZiferblatAddressQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type ZiferblatAddressQuery = { __typename?: 'query_root', ziferblat?: { __typename?: 'ZiferblatZiferblat', id: string, addressSlug: string, city: Array<{ __typename?: 'ZiferblatCityI18n', city: string, country: string }>, streetAddress: Array<{ __typename?: 'ZiferblatAddressI18n', building: string, floor: number, street: string, locale: string, howToGet?: string | null }> } | null };

export type ZiferblatDescriptionQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type ZiferblatDescriptionQuery = { __typename?: 'query_root', ziferblat?: { __typename?: 'ZiferblatZiferblat', id: string, activeFrom: Date, i18n: Array<{ __typename?: 'ZiferblatZiferblatI18n', id: string, name: string, rules: string, description: string }> } | null };

export type ZiferblatI18nFragment = { __typename?: 'ZiferblatZiferblatI18n', id: string, name: string, rules: string, description: string };

export type StaffUserFragment = { __typename?: 'AuthUser', id: string, alias: Array<{ __typename?: 'AuthAlias', type: string, value: string }>, grants: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string } }> };

export type StaffQueryVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
}>;


export type StaffQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'AuthUser', id: string, alias: Array<{ __typename?: 'AuthAlias', type: string, value: string }>, grants: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string } }> }> };

export type TariffFragment = { __typename?: 'TariffTariff', id: string, hourPrice: Array<number>, stopCheck: number, activeFrom: Date, activeTo?: Date | null };

export type TariffsQueryVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
}>;


export type TariffsQuery = { __typename?: 'query_root', tariffs: Array<{ __typename?: 'TariffTariff', id: string, hourPrice: Array<number>, stopCheck: number, activeFrom: Date, activeTo?: Date | null }> };

export type TelegramLoginMutationVariables = Exact<{
  input: AuthTelegramInputInsertInput;
}>;


export type TelegramLoginMutation = { __typename?: 'mutation_root', telegramLogin?: { __typename?: 'AuthTelegramInput', id: number } | null };

export type TicketCheckinMutationVariables = Exact<{
  object: CulturalEventPrepaidAttendanceInsertInput;
}>;


export type TicketCheckinMutation = { __typename?: 'mutation_root', insertCulturalEventPrepaidAttendanceOne?: { __typename?: 'CulturalEventPrepaidAttendance', id: string, visit?: { __typename?: 'Visit', id: string, name?: string | null } | null, transaction?: { __typename?: 'PaymentTransaction', ticket?: { __typename?: 'CulturalEventTicket', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date } } | null } | null } | null };

export type AddAttendanceMutationVariables = Exact<{
  checkinId: Scalars['uuid']['input'];
  culturalEventId: Scalars['uuid']['input'];
}>;


export type AddAttendanceMutation = { __typename?: 'mutation_root', toggleAttendance?: { __typename?: 'CulturalEventAttendance', checkin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null } | null } | null };

export type RemoveAttendanceMutationVariables = Exact<{
  checkinId: Scalars['uuid']['input'];
  culturalEventId: Scalars['uuid']['input'];
}>;


export type RemoveAttendanceMutation = { __typename?: 'mutation_root', toggleAttendance?: { __typename?: 'CulturalEventAttendanceMutationResponse', returning: Array<{ __typename?: 'CulturalEventAttendance', checkin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null } | null }> } | null };

export type AddBookingCheckinMutationVariables = Exact<{
  checkinId: Scalars['uuid']['input'];
  bookingId: Scalars['uuid']['input'];
}>;


export type AddBookingCheckinMutation = { __typename?: 'mutation_root', insertBookingCheckinOne?: { __typename?: 'BookingCheckin', checkin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null } | null } | null };

export type RemoveBookingCheckinMutationVariables = Exact<{
  checkinId: Scalars['uuid']['input'];
  bookingId: Scalars['uuid']['input'];
}>;


export type RemoveBookingCheckinMutation = { __typename?: 'mutation_root', deleteBookingCheckin?: { __typename?: 'BookingCheckinMutationResponse', returning: Array<{ __typename?: 'BookingCheckin', checkin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string, internalComment: string } }>, user?: { __typename?: 'AuthUser', id: string } | null, pause: Array<{ __typename?: 'Pause', id: string, startedAt: Date, endedAt?: Date | null }>, checkinBooking?: { __typename?: 'BookingCheckin', id: string, checkinId: string, bookingId: string, booking: { __typename?: 'Booking', id: string, name: string, phone: string, startsAt: Date, comment: string } } | null } | null }> } | null };

export type UpcomingCulturalEventFragment = { __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, status: CulturalEventStatusEnum, tickets: Array<{ __typename?: 'CulturalEventTicket', id: string }> };

export type UpdateMediaLocationMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  location: Scalars['String']['input'];
}>;


export type UpdateMediaLocationMutation = { __typename?: 'mutation_root', updateZiferblatGalleryByPk?: { __typename?: 'ZiferblatGallery', id: string, location: string } | null };

export type UpdateZiferblatMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
}>;


export type UpdateZiferblatMutation = { __typename?: 'mutation_root', updateZiferblat?: { __typename?: 'ZiferblatZiferblat', id: string } | null };

export type UpsertCulturalEventMutationVariables = Exact<{
  input: CulturalEventInsertInput;
  compensationIds?: InputMaybe<Array<Scalars['uuid']['input']> | Scalars['uuid']['input']>;
}>;


export type UpsertCulturalEventMutation = { __typename?: 'mutation_root', deleteCulturalEventCompensation?: { __typename?: 'CulturalEventCompensationMutationResponse', affectedRows: number } | null, upsertCulturalEvent?: { __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, publicDescription: string, internalComment: string, status: CulturalEventStatusEnum, ziferblatId: string, slug?: string | null, images: Array<{ __typename?: 'culturalEventImage', imageId: string, image: { __typename?: 'media', id: string } }>, tickets: Array<{ __typename?: 'CulturalEventTicket', id: string, price: number, maxCount?: number | null, activeFrom: Date, customUrl?: string | null, transaction: Array<{ __typename?: 'PaymentTransaction', id: string, body: any, createdAt: Date, sum: number, attendance?: { __typename?: 'attendees', id?: string | null } | null }> }>, compensation?: { __typename?: 'CulturalEventCompensation', id: string, currency: CompensationCurrencyEnum, amount: number, unit: CompensationUnitEnum } | null } | null };

export type UpsertZiferblatI18nMutationVariables = Exact<{
  data: ZiferblatZiferblatI18nInsertInput;
}>;


export type UpsertZiferblatI18nMutation = { __typename?: 'mutation_root', insertZiferblatI18n?: { __typename?: 'ZiferblatZiferblatI18n', id: string, name: string, rules: string, description: string } | null };

export type UpsertZiferblatI18nAddressMutationVariables = Exact<{
  data: ZiferblatAddressI18nInsertInput;
}>;


export type UpsertZiferblatI18nAddressMutation = { __typename?: 'mutation_root', insertZiferblatAddressI18n?: { __typename?: 'ZiferblatAddressI18n', id: string, building: string, street: string, floor: number, howToGet?: string | null } | null };

export type UserFragment = { __typename?: 'AuthUser', id: string, avatarUrl?: string | null, alias: Array<{ __typename?: 'AuthAlias', id: string, type: string, value: string }>, roles: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string }, ziferblat?: { __typename?: 'ZiferblatZiferblat', id: string, avatarUrl: string, i18n: Array<{ __typename?: 'ZiferblatZiferblatI18n', name: string }> } | null }> };

export type ZiferblatInfoQueryVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
}>;


export type ZiferblatInfoQuery = { __typename?: 'query_root', ziferblat?: { __typename?: 'ZiferblatZiferblat', citySlug: string, addressSlug: string, activeFrom: Date, city: Array<{ __typename?: 'ZiferblatCityI18n', city: string, country: string }>, streetAddress: Array<{ __typename?: 'ZiferblatAddressI18n', building: string, floor: number, street: string }>, gallery: Array<{ __typename?: 'ZiferblatGallery', location: string, media: { __typename?: 'media', id: string, contentType: string, url?: string | null } }>, i18n: Array<{ __typename?: 'ZiferblatZiferblatI18n', createdAt: Date, description: string, id: string, name: string, rules: string, locale: string }> } | null };

export const BookingFragmentDoc = gql`
    fragment Booking on Booking {
  id
  name
  phone
  comment
  startsAt
  bookingCheckins {
    id
    checkin {
      id
      name
      checkedInAt
      sum
    }
    visit {
      id
      name
      checkedInAt
      checkedOutAt
      sum
    }
  }
}
    `;
export const CheckinFragmentDoc = gql`
    fragment Checkin on Checkin {
  id
  checkedInAt
  name
  sum
  attendance {
    culturalEvent {
      id
      title
      internalComment
    }
  }
  user {
    id
  }
  pause(orderBy: {endedAt: DESC_NULLS_FIRST}) {
    id
    startedAt
    endedAt
  }
  checkinBooking {
    id
    checkinId
    bookingId
    booking {
      id
      name
      phone
      startsAt
      comment
    }
  }
}
    `;
export const CulturalEventFragmentDoc = gql`
    fragment CulturalEvent on CulturalEvent {
  id
  title
  startsAt
  endsAt
  publicDescription
  internalComment
  status
  ziferblatId
  slug
  images {
    image {
      id
    }
    imageId
  }
  tickets {
    id
    price
    maxCount
    activeFrom
    customUrl
    transaction {
      id
      body
      createdAt
      sum
      attendance {
        id
      }
    }
  }
  compensation {
    id
    currency
    amount
    unit
  }
}
    `;
export const CulturalEventListItemFragmentDoc = gql`
    fragment CulturalEventListItem on CulturalEvent {
  id
  title
  startsAt
  endsAt
  status
  internalComment
}
    `;
export const UserFragmentDoc = gql`
    fragment User on AuthUser {
  id
  avatarUrl
  alias {
    id
    type
    value
  }
  roles: grants(
    where: {_or: [{_not: {expires: {}}}, {expires: {expiresAt: {_gt: "now()"}}}]}
  ) {
    id
    role {
      id
      name
    }
    ziferblat {
      id
      avatarUrl
      i18n(where: {locale: {_eq: $locale}}) {
        name
      }
    }
  }
}
    `;
export const SessionFragmentDoc = gql`
    fragment Session on AuthSession {
  id
  token
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const ZiferblatI18nFragmentDoc = gql`
    fragment ZiferblatI18n on ZiferblatZiferblatI18n {
  id
  name
  rules
  description
}
    `;
export const StaffUserFragmentDoc = gql`
    fragment StaffUser on AuthUser {
  id
  alias {
    type
    value
  }
  grants(
    where: {_or: [{_not: {expires: {}}}, {expires: {expiresAt: {_gt: "now()"}}}]}
  ) {
    id
    role {
      id
      name
    }
  }
}
    `;
export const TariffFragmentDoc = gql`
    fragment Tariff on TariffTariff {
  id
  hourPrice
  stopCheck
  activeFrom
  activeTo
}
    `;
export const UpcomingCulturalEventFragmentDoc = gql`
    fragment UpcomingCulturalEvent on CulturalEvent {
  id
  title
  startsAt
  endsAt
  status
  tickets {
    id
  }
}
    `;
export const AddBookingDocument = gql`
    mutation AddBooking($object: BookingInsertInput!) {
  insertBookingOne(
    object: $object
    onConflict: {constraint: booking_pkey, updateColumns: [comment, name, phone, startsAt]}
  ) {
    ...Booking
  }
}
    ${BookingFragmentDoc}`;
export type AddBookingMutationFn = Apollo.MutationFunction<AddBookingMutation, AddBookingMutationVariables>;

/**
 * __useAddBookingMutation__
 *
 * To run a mutation, you first call `useAddBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBookingMutation, { data, loading, error }] = useAddBookingMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddBookingMutation(baseOptions?: Apollo.MutationHookOptions<AddBookingMutation, AddBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBookingMutation, AddBookingMutationVariables>(AddBookingDocument, options);
      }
export type AddBookingMutationHookResult = ReturnType<typeof useAddBookingMutation>;
export type AddBookingMutationResult = Apollo.MutationResult<AddBookingMutation>;
export type AddBookingMutationOptions = Apollo.BaseMutationOptions<AddBookingMutation, AddBookingMutationVariables>;
export const AddCulturalEventImageDocument = gql`
    mutation AddCulturalEventImage($id: uuid!, $ziferblatId: uuid!, $culturalEventId: uuid!, $contentType: String!) {
  addCulturalEventImage(
    object: {culturalEventId: $culturalEventId, image: {data: {id: $id, ziferblatId: $ziferblatId, contentType: $contentType}}}
  ) {
    id
  }
}
    `;
export type AddCulturalEventImageMutationFn = Apollo.MutationFunction<AddCulturalEventImageMutation, AddCulturalEventImageMutationVariables>;

/**
 * __useAddCulturalEventImageMutation__
 *
 * To run a mutation, you first call `useAddCulturalEventImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCulturalEventImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCulturalEventImageMutation, { data, loading, error }] = useAddCulturalEventImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ziferblatId: // value for 'ziferblatId'
 *      culturalEventId: // value for 'culturalEventId'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useAddCulturalEventImageMutation(baseOptions?: Apollo.MutationHookOptions<AddCulturalEventImageMutation, AddCulturalEventImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCulturalEventImageMutation, AddCulturalEventImageMutationVariables>(AddCulturalEventImageDocument, options);
      }
export type AddCulturalEventImageMutationHookResult = ReturnType<typeof useAddCulturalEventImageMutation>;
export type AddCulturalEventImageMutationResult = Apollo.MutationResult<AddCulturalEventImageMutation>;
export type AddCulturalEventImageMutationOptions = Apollo.BaseMutationOptions<AddCulturalEventImageMutation, AddCulturalEventImageMutationVariables>;
export const AddRoleDocument = gql`
    mutation AddRole($roleId: uuid!, $userId: uuid!, $ziferblatId: uuid!) {
  addRole: insertAccessGrantOne(
    object: {roleId: $roleId, subjectId: $userId, objectId: $ziferblatId}
  ) {
    id
    subject {
      ...StaffUser
    }
  }
}
    ${StaffUserFragmentDoc}`;
export type AddRoleMutationFn = Apollo.MutationFunction<AddRoleMutation, AddRoleMutationVariables>;

/**
 * __useAddRoleMutation__
 *
 * To run a mutation, you first call `useAddRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleMutation, { data, loading, error }] = useAddRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      userId: // value for 'userId'
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useAddRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddRoleMutation, AddRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRoleMutation, AddRoleMutationVariables>(AddRoleDocument, options);
      }
export type AddRoleMutationHookResult = ReturnType<typeof useAddRoleMutation>;
export type AddRoleMutationResult = Apollo.MutationResult<AddRoleMutation>;
export type AddRoleMutationOptions = Apollo.BaseMutationOptions<AddRoleMutation, AddRoleMutationVariables>;
export const AddTariffDocument = gql`
    mutation AddTariff($activeFrom: timestamptz!, $stopCheck: smallint!, $hourPrice: [smallint!]!, $ziferblatId: uuid!) {
  addTariff: insertTariffTariffOne(
    object: {activeFrom: $activeFrom, stopCheck: $stopCheck, hourPrice: $hourPrice, ziferblatId: $ziferblatId}
  ) {
    ...Tariff
  }
}
    ${TariffFragmentDoc}`;
export type AddTariffMutationFn = Apollo.MutationFunction<AddTariffMutation, AddTariffMutationVariables>;

/**
 * __useAddTariffMutation__
 *
 * To run a mutation, you first call `useAddTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTariffMutation, { data, loading, error }] = useAddTariffMutation({
 *   variables: {
 *      activeFrom: // value for 'activeFrom'
 *      stopCheck: // value for 'stopCheck'
 *      hourPrice: // value for 'hourPrice'
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useAddTariffMutation(baseOptions?: Apollo.MutationHookOptions<AddTariffMutation, AddTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTariffMutation, AddTariffMutationVariables>(AddTariffDocument, options);
      }
export type AddTariffMutationHookResult = ReturnType<typeof useAddTariffMutation>;
export type AddTariffMutationResult = Apollo.MutationResult<AddTariffMutation>;
export type AddTariffMutationOptions = Apollo.BaseMutationOptions<AddTariffMutation, AddTariffMutationVariables>;
export const AddTicketDocument = gql`
    mutation AddTicket($culturalEventId: uuid!, $activeFrom: timestamptz!, $maxCount: Int, $price: Int, $customUrl: String, $customTitle: String) {
  insertCulturalEventTicketOne(
    object: {culturalEventId: $culturalEventId, activeFrom: $activeFrom, maxCount: $maxCount, price: $price, customUrl: $customUrl, customTitle: $customTitle}
  ) {
    id
    activeFrom
    maxCount
    price
    customUrl
    customTitle
    transaction {
      id
    }
  }
}
    `;
export type AddTicketMutationFn = Apollo.MutationFunction<AddTicketMutation, AddTicketMutationVariables>;

/**
 * __useAddTicketMutation__
 *
 * To run a mutation, you first call `useAddTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTicketMutation, { data, loading, error }] = useAddTicketMutation({
 *   variables: {
 *      culturalEventId: // value for 'culturalEventId'
 *      activeFrom: // value for 'activeFrom'
 *      maxCount: // value for 'maxCount'
 *      price: // value for 'price'
 *      customUrl: // value for 'customUrl'
 *      customTitle: // value for 'customTitle'
 *   },
 * });
 */
export function useAddTicketMutation(baseOptions?: Apollo.MutationHookOptions<AddTicketMutation, AddTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTicketMutation, AddTicketMutationVariables>(AddTicketDocument, options);
      }
export type AddTicketMutationHookResult = ReturnType<typeof useAddTicketMutation>;
export type AddTicketMutationResult = Apollo.MutationResult<AddTicketMutation>;
export type AddTicketMutationOptions = Apollo.BaseMutationOptions<AddTicketMutation, AddTicketMutationVariables>;
export const AddToGalleryDocument = gql`
    mutation AddToGallery($id: uuid!, $ziferblatId: uuid!, $contentType: String, $url: String, $location: String) {
  addToGallery(
    object: {ziferblatId: $ziferblatId, location: $location, media: {data: {id: $id, contentType: $contentType, ziferblatId: $ziferblatId}}}
  ) {
    id
  }
}
    `;
export type AddToGalleryMutationFn = Apollo.MutationFunction<AddToGalleryMutation, AddToGalleryMutationVariables>;

/**
 * __useAddToGalleryMutation__
 *
 * To run a mutation, you first call `useAddToGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToGalleryMutation, { data, loading, error }] = useAddToGalleryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ziferblatId: // value for 'ziferblatId'
 *      contentType: // value for 'contentType'
 *      url: // value for 'url'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useAddToGalleryMutation(baseOptions?: Apollo.MutationHookOptions<AddToGalleryMutation, AddToGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToGalleryMutation, AddToGalleryMutationVariables>(AddToGalleryDocument, options);
      }
export type AddToGalleryMutationHookResult = ReturnType<typeof useAddToGalleryMutation>;
export type AddToGalleryMutationResult = Apollo.MutationResult<AddToGalleryMutation>;
export type AddToGalleryMutationOptions = Apollo.BaseMutationOptions<AddToGalleryMutation, AddToGalleryMutationVariables>;
export const AttendanceDocument = gql`
    query Attendance($id: uuid!) {
  attendees(where: {culturalEventId: {_eq: $id}, transactionId: {_isNull: true}}) {
    id
    startedAt
    endedAt
    sum
    checkin {
      id
      name
      sum
    }
    visit {
      id
      name
      sum
    }
    transactionId
  }
}
    `;

/**
 * __useAttendanceQuery__
 *
 * To run a query within a React component, call `useAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttendanceQuery(baseOptions: Apollo.QueryHookOptions<AttendanceQuery, AttendanceQueryVariables> & ({ variables: AttendanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttendanceQuery, AttendanceQueryVariables>(AttendanceDocument, options);
      }
export function useAttendanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttendanceQuery, AttendanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttendanceQuery, AttendanceQueryVariables>(AttendanceDocument, options);
        }
export function useAttendanceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AttendanceQuery, AttendanceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AttendanceQuery, AttendanceQueryVariables>(AttendanceDocument, options);
        }
export type AttendanceQueryHookResult = ReturnType<typeof useAttendanceQuery>;
export type AttendanceLazyQueryHookResult = ReturnType<typeof useAttendanceLazyQuery>;
export type AttendanceSuspenseQueryHookResult = ReturnType<typeof useAttendanceSuspenseQuery>;
export type AttendanceQueryResult = Apollo.QueryResult<AttendanceQuery, AttendanceQueryVariables>;
export const BankTransactionDocument = gql`
    query BankTransaction($originalTransactionId: String!) {
  paymentTransaction(
    where: {originalTransactionId: {_eq: $originalTransactionId}}
  ) {
    id
  }
}
    `;

/**
 * __useBankTransactionQuery__
 *
 * To run a query within a React component, call `useBankTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankTransactionQuery({
 *   variables: {
 *      originalTransactionId: // value for 'originalTransactionId'
 *   },
 * });
 */
export function useBankTransactionQuery(baseOptions: Apollo.QueryHookOptions<BankTransactionQuery, BankTransactionQueryVariables> & ({ variables: BankTransactionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BankTransactionQuery, BankTransactionQueryVariables>(BankTransactionDocument, options);
      }
export function useBankTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BankTransactionQuery, BankTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BankTransactionQuery, BankTransactionQueryVariables>(BankTransactionDocument, options);
        }
export function useBankTransactionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BankTransactionQuery, BankTransactionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BankTransactionQuery, BankTransactionQueryVariables>(BankTransactionDocument, options);
        }
export type BankTransactionQueryHookResult = ReturnType<typeof useBankTransactionQuery>;
export type BankTransactionLazyQueryHookResult = ReturnType<typeof useBankTransactionLazyQuery>;
export type BankTransactionSuspenseQueryHookResult = ReturnType<typeof useBankTransactionSuspenseQuery>;
export type BankTransactionQueryResult = Apollo.QueryResult<BankTransactionQuery, BankTransactionQueryVariables>;
export const BookingDocument = gql`
    query Booking($id: uuid!) {
  bookingByPk(id: $id) {
    ...Booking
  }
}
    ${BookingFragmentDoc}`;

/**
 * __useBookingQuery__
 *
 * To run a query within a React component, call `useBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingQuery(baseOptions: Apollo.QueryHookOptions<BookingQuery, BookingQueryVariables> & ({ variables: BookingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
      }
export function useBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingQuery, BookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
        }
export function useBookingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BookingQuery, BookingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
        }
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export type BookingSuspenseQueryHookResult = ReturnType<typeof useBookingSuspenseQuery>;
export type BookingQueryResult = Apollo.QueryResult<BookingQuery, BookingQueryVariables>;
export const BookingsDocument = gql`
    query Bookings($ziferblatId: uuid!, $before: timestamptz!) {
  booking(
    where: {ziferblatId: {_eq: $ziferblatId}, startsAt: {_lte: $before}}
    orderBy: {startsAt: DESC}
  ) {
    id
    comment
    name
    phone
    startsAt
  }
}
    `;

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useBookingsQuery(baseOptions: Apollo.QueryHookOptions<BookingsQuery, BookingsQueryVariables> & ({ variables: BookingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
      }
export function useBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingsQuery, BookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
        }
export function useBookingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BookingsQuery, BookingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
        }
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<typeof useBookingsLazyQuery>;
export type BookingsSuspenseQueryHookResult = ReturnType<typeof useBookingsSuspenseQuery>;
export type BookingsQueryResult = Apollo.QueryResult<BookingsQuery, BookingsQueryVariables>;
export const CalendarEventsDocument = gql`
    query CalendarEvents($ziferblatId: uuid!, $before: timestamptz!) {
  draftCulturalEvents: culturalEvents(
    where: {status: {_eq: DRAFT}, ziferblatId: {_eq: $ziferblatId}}
  ) {
    ...CulturalEventListItem
  }
  aggregateCulturalEvents(
    where: {status: {_neq: DRAFT}, ziferblatId: {_eq: $ziferblatId}}
  ) {
    aggregate {
      count
    }
  }
  culturalEvents(
    where: {status: {_neq: DRAFT}, ziferblatId: {_eq: $ziferblatId}, startsAt: {_lte: $before}}
    orderBy: {startsAt: DESC}
    limit: 50
  ) {
    ...CulturalEventListItem
  }
  booking(
    where: {ziferblatId: {_eq: $ziferblatId}, startsAt: {_lte: $before}}
    orderBy: {startsAt: DESC}
  ) {
    id
    comment
    name
    phone
    startsAt
  }
}
    ${CulturalEventListItemFragmentDoc}`;

/**
 * __useCalendarEventsQuery__
 *
 * To run a query within a React component, call `useCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventsQuery({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useCalendarEventsQuery(baseOptions: Apollo.QueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables> & ({ variables: CalendarEventsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, options);
      }
export function useCalendarEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, options);
        }
export function useCalendarEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, options);
        }
export type CalendarEventsQueryHookResult = ReturnType<typeof useCalendarEventsQuery>;
export type CalendarEventsLazyQueryHookResult = ReturnType<typeof useCalendarEventsLazyQuery>;
export type CalendarEventsSuspenseQueryHookResult = ReturnType<typeof useCalendarEventsSuspenseQuery>;
export type CalendarEventsQueryResult = Apollo.QueryResult<CalendarEventsQuery, CalendarEventsQueryVariables>;
export const ChangeVisitNameDocument = gql`
    mutation ChangeVisitName($id: uuid!, $name: String!) {
  updateCheckin(pkColumns: {id: $id}, _set: {name: $name}) {
    ...Checkin
  }
}
    ${CheckinFragmentDoc}`;
export type ChangeVisitNameMutationFn = Apollo.MutationFunction<ChangeVisitNameMutation, ChangeVisitNameMutationVariables>;

/**
 * __useChangeVisitNameMutation__
 *
 * To run a mutation, you first call `useChangeVisitNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVisitNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVisitNameMutation, { data, loading, error }] = useChangeVisitNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useChangeVisitNameMutation(baseOptions?: Apollo.MutationHookOptions<ChangeVisitNameMutation, ChangeVisitNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeVisitNameMutation, ChangeVisitNameMutationVariables>(ChangeVisitNameDocument, options);
      }
export type ChangeVisitNameMutationHookResult = ReturnType<typeof useChangeVisitNameMutation>;
export type ChangeVisitNameMutationResult = Apollo.MutationResult<ChangeVisitNameMutation>;
export type ChangeVisitNameMutationOptions = Apollo.BaseMutationOptions<ChangeVisitNameMutation, ChangeVisitNameMutationVariables>;
export const CheckInDocument = gql`
    mutation CheckIn($data: CheckinInsertInput!) {
  addCheckin(object: $data) {
    ...Checkin
  }
}
    ${CheckinFragmentDoc}`;
export type CheckInMutationFn = Apollo.MutationFunction<CheckInMutation, CheckInMutationVariables>;

/**
 * __useCheckInMutation__
 *
 * To run a mutation, you first call `useCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInMutation, { data, loading, error }] = useCheckInMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckInMutation(baseOptions?: Apollo.MutationHookOptions<CheckInMutation, CheckInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckInMutation, CheckInMutationVariables>(CheckInDocument, options);
      }
export type CheckInMutationHookResult = ReturnType<typeof useCheckInMutation>;
export type CheckInMutationResult = Apollo.MutationResult<CheckInMutation>;
export type CheckInMutationOptions = Apollo.BaseMutationOptions<CheckInMutation, CheckInMutationVariables>;
export const CheckOutDocument = gql`
    mutation CheckOut($id: uuid!, $sum: Int!) {
  checkOut(object: {id: $id, sum: $sum}) {
    id
  }
}
    `;
export type CheckOutMutationFn = Apollo.MutationFunction<CheckOutMutation, CheckOutMutationVariables>;

/**
 * __useCheckOutMutation__
 *
 * To run a mutation, you first call `useCheckOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOutMutation, { data, loading, error }] = useCheckOutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sum: // value for 'sum'
 *   },
 * });
 */
export function useCheckOutMutation(baseOptions?: Apollo.MutationHookOptions<CheckOutMutation, CheckOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckOutMutation, CheckOutMutationVariables>(CheckOutDocument, options);
      }
export type CheckOutMutationHookResult = ReturnType<typeof useCheckOutMutation>;
export type CheckOutMutationResult = Apollo.MutationResult<CheckOutMutation>;
export type CheckOutMutationOptions = Apollo.BaseMutationOptions<CheckOutMutation, CheckOutMutationVariables>;
export const CheckOutMultipleDocument = gql`
    mutation CheckOutMultiple($objects: [VisitInsertInput!]!) {
  checkOutMultiple(objects: $objects) {
    returning {
      id
      name
    }
  }
}
    `;
export type CheckOutMultipleMutationFn = Apollo.MutationFunction<CheckOutMultipleMutation, CheckOutMultipleMutationVariables>;

/**
 * __useCheckOutMultipleMutation__
 *
 * To run a mutation, you first call `useCheckOutMultipleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOutMultipleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOutMultipleMutation, { data, loading, error }] = useCheckOutMultipleMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useCheckOutMultipleMutation(baseOptions?: Apollo.MutationHookOptions<CheckOutMultipleMutation, CheckOutMultipleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckOutMultipleMutation, CheckOutMultipleMutationVariables>(CheckOutMultipleDocument, options);
      }
export type CheckOutMultipleMutationHookResult = ReturnType<typeof useCheckOutMultipleMutation>;
export type CheckOutMultipleMutationResult = Apollo.MutationResult<CheckOutMultipleMutation>;
export type CheckOutMultipleMutationOptions = Apollo.BaseMutationOptions<CheckOutMultipleMutation, CheckOutMultipleMutationVariables>;
export const CheckinDocument = gql`
    query Checkin($id: uuid!) {
  checkin(id: $id) {
    ...Checkin
  }
}
    ${CheckinFragmentDoc}`;

/**
 * __useCheckinQuery__
 *
 * To run a query within a React component, call `useCheckinQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckinQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckinQuery(baseOptions: Apollo.QueryHookOptions<CheckinQuery, CheckinQueryVariables> & ({ variables: CheckinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckinQuery, CheckinQueryVariables>(CheckinDocument, options);
      }
export function useCheckinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckinQuery, CheckinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckinQuery, CheckinQueryVariables>(CheckinDocument, options);
        }
export function useCheckinSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckinQuery, CheckinQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckinQuery, CheckinQueryVariables>(CheckinDocument, options);
        }
export type CheckinQueryHookResult = ReturnType<typeof useCheckinQuery>;
export type CheckinLazyQueryHookResult = ReturnType<typeof useCheckinLazyQuery>;
export type CheckinSuspenseQueryHookResult = ReturnType<typeof useCheckinSuspenseQuery>;
export type CheckinQueryResult = Apollo.QueryResult<CheckinQuery, CheckinQueryVariables>;
export const CheckinPauseDocument = gql`
    mutation CheckinPause($checkinId: uuid!) {
  insertPauseOne(object: {checkinId: $checkinId}) {
    checkin {
      ...Checkin
    }
  }
}
    ${CheckinFragmentDoc}`;
export type CheckinPauseMutationFn = Apollo.MutationFunction<CheckinPauseMutation, CheckinPauseMutationVariables>;

/**
 * __useCheckinPauseMutation__
 *
 * To run a mutation, you first call `useCheckinPauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckinPauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkinPauseMutation, { data, loading, error }] = useCheckinPauseMutation({
 *   variables: {
 *      checkinId: // value for 'checkinId'
 *   },
 * });
 */
export function useCheckinPauseMutation(baseOptions?: Apollo.MutationHookOptions<CheckinPauseMutation, CheckinPauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckinPauseMutation, CheckinPauseMutationVariables>(CheckinPauseDocument, options);
      }
export type CheckinPauseMutationHookResult = ReturnType<typeof useCheckinPauseMutation>;
export type CheckinPauseMutationResult = Apollo.MutationResult<CheckinPauseMutation>;
export type CheckinPauseMutationOptions = Apollo.BaseMutationOptions<CheckinPauseMutation, CheckinPauseMutationVariables>;
export const CheckinResumeDocument = gql`
    mutation CheckinResume($id: uuid!, $endedAt: timestamptz!) {
  updatePauseByPk(pkColumns: {id: $id}, _set: {endedAt: $endedAt}) {
    checkin {
      ...Checkin
    }
  }
}
    ${CheckinFragmentDoc}`;
export type CheckinResumeMutationFn = Apollo.MutationFunction<CheckinResumeMutation, CheckinResumeMutationVariables>;

/**
 * __useCheckinResumeMutation__
 *
 * To run a mutation, you first call `useCheckinResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckinResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkinResumeMutation, { data, loading, error }] = useCheckinResumeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      endedAt: // value for 'endedAt'
 *   },
 * });
 */
export function useCheckinResumeMutation(baseOptions?: Apollo.MutationHookOptions<CheckinResumeMutation, CheckinResumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckinResumeMutation, CheckinResumeMutationVariables>(CheckinResumeDocument, options);
      }
export type CheckinResumeMutationHookResult = ReturnType<typeof useCheckinResumeMutation>;
export type CheckinResumeMutationResult = Apollo.MutationResult<CheckinResumeMutation>;
export type CheckinResumeMutationOptions = Apollo.BaseMutationOptions<CheckinResumeMutation, CheckinResumeMutationVariables>;
export const CheckinsDocument = gql`
    query Checkins($ziferblatId: uuid!, $startsAt: timestamptz!, $endsAt: timestamptz!) {
  checkins(where: {ziferblatId: {_eq: $ziferblatId}}) {
    ...Checkin
  }
  upcomingCulturalEvents: culturalEvents(
    where: {ziferblatId: {_eq: $ziferblatId}, status: {_in: [PUBLISHED]}, _and: [{startsAt: {_gte: $startsAt}}, {startsAt: {_lte: $endsAt}}]}
  ) {
    id
    title
    startsAt
    endsAt
    status
    tickets {
      id
    }
  }
  upcomingBookings: booking(
    where: {ziferblatId: {_eq: $ziferblatId}, _and: [{startsAt: {_gte: $startsAt}}, {startsAt: {_lte: $endsAt}}]}
  ) {
    ...Booking
  }
}
    ${CheckinFragmentDoc}
${BookingFragmentDoc}`;

/**
 * __useCheckinsQuery__
 *
 * To run a query within a React component, call `useCheckinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckinsQuery({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useCheckinsQuery(baseOptions: Apollo.QueryHookOptions<CheckinsQuery, CheckinsQueryVariables> & ({ variables: CheckinsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckinsQuery, CheckinsQueryVariables>(CheckinsDocument, options);
      }
export function useCheckinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckinsQuery, CheckinsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckinsQuery, CheckinsQueryVariables>(CheckinsDocument, options);
        }
export function useCheckinsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckinsQuery, CheckinsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckinsQuery, CheckinsQueryVariables>(CheckinsDocument, options);
        }
export type CheckinsQueryHookResult = ReturnType<typeof useCheckinsQuery>;
export type CheckinsLazyQueryHookResult = ReturnType<typeof useCheckinsLazyQuery>;
export type CheckinsSuspenseQueryHookResult = ReturnType<typeof useCheckinsSuspenseQuery>;
export type CheckinsQueryResult = Apollo.QueryResult<CheckinsQuery, CheckinsQueryVariables>;
export const UpdateCheckinsDocument = gql`
    subscription UpdateCheckins($ziferblatId: uuid!) {
  checkins(where: {ziferblatId: {_eq: $ziferblatId}}) {
    ...Checkin
  }
}
    ${CheckinFragmentDoc}`;

/**
 * __useUpdateCheckinsSubscription__
 *
 * To run a query within a React component, call `useUpdateCheckinsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckinsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateCheckinsSubscription({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useUpdateCheckinsSubscription(baseOptions: Apollo.SubscriptionHookOptions<UpdateCheckinsSubscription, UpdateCheckinsSubscriptionVariables> & ({ variables: UpdateCheckinsSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdateCheckinsSubscription, UpdateCheckinsSubscriptionVariables>(UpdateCheckinsDocument, options);
      }
export type UpdateCheckinsSubscriptionHookResult = ReturnType<typeof useUpdateCheckinsSubscription>;
export type UpdateCheckinsSubscriptionResult = Apollo.SubscriptionResult<UpdateCheckinsSubscription>;
export const UpdateCulturalEventsDocument = gql`
    subscription UpdateCulturalEvents($ziferblatId: uuid!, $startsAt: timestamptz!, $endsAt: timestamptz!) {
  upcomingCulturalEvents: culturalEvents(
    where: {ziferblatId: {_eq: $ziferblatId}, startsAt: {_gte: $startsAt}, endsAt: {_lte: $endsAt}, status: {_eq: PUBLISHED}}
  ) {
    ...UpcomingCulturalEvent
  }
}
    ${UpcomingCulturalEventFragmentDoc}`;

/**
 * __useUpdateCulturalEventsSubscription__
 *
 * To run a query within a React component, call `useUpdateCulturalEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdateCulturalEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateCulturalEventsSubscription({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useUpdateCulturalEventsSubscription(baseOptions: Apollo.SubscriptionHookOptions<UpdateCulturalEventsSubscription, UpdateCulturalEventsSubscriptionVariables> & ({ variables: UpdateCulturalEventsSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdateCulturalEventsSubscription, UpdateCulturalEventsSubscriptionVariables>(UpdateCulturalEventsDocument, options);
      }
export type UpdateCulturalEventsSubscriptionHookResult = ReturnType<typeof useUpdateCulturalEventsSubscription>;
export type UpdateCulturalEventsSubscriptionResult = Apollo.SubscriptionResult<UpdateCulturalEventsSubscription>;
export const CulturalEventDocument = gql`
    query CulturalEvent($id: uuid!) {
  culturalEvent(id: $id) {
    ...CulturalEvent
  }
}
    ${CulturalEventFragmentDoc}`;

/**
 * __useCulturalEventQuery__
 *
 * To run a query within a React component, call `useCulturalEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useCulturalEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCulturalEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCulturalEventQuery(baseOptions: Apollo.QueryHookOptions<CulturalEventQuery, CulturalEventQueryVariables> & ({ variables: CulturalEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CulturalEventQuery, CulturalEventQueryVariables>(CulturalEventDocument, options);
      }
export function useCulturalEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CulturalEventQuery, CulturalEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CulturalEventQuery, CulturalEventQueryVariables>(CulturalEventDocument, options);
        }
export function useCulturalEventSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CulturalEventQuery, CulturalEventQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CulturalEventQuery, CulturalEventQueryVariables>(CulturalEventDocument, options);
        }
export type CulturalEventQueryHookResult = ReturnType<typeof useCulturalEventQuery>;
export type CulturalEventLazyQueryHookResult = ReturnType<typeof useCulturalEventLazyQuery>;
export type CulturalEventSuspenseQueryHookResult = ReturnType<typeof useCulturalEventSuspenseQuery>;
export type CulturalEventQueryResult = Apollo.QueryResult<CulturalEventQuery, CulturalEventQueryVariables>;
export const CulturalEventImagesDocument = gql`
    query CulturalEventImages($culturalEventId: uuid!) {
  culturalEventImages(where: {culturalEventId: {_eq: $culturalEventId}}) {
    id
    image {
      id
      url
    }
  }
}
    `;

/**
 * __useCulturalEventImagesQuery__
 *
 * To run a query within a React component, call `useCulturalEventImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCulturalEventImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCulturalEventImagesQuery({
 *   variables: {
 *      culturalEventId: // value for 'culturalEventId'
 *   },
 * });
 */
export function useCulturalEventImagesQuery(baseOptions: Apollo.QueryHookOptions<CulturalEventImagesQuery, CulturalEventImagesQueryVariables> & ({ variables: CulturalEventImagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CulturalEventImagesQuery, CulturalEventImagesQueryVariables>(CulturalEventImagesDocument, options);
      }
export function useCulturalEventImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CulturalEventImagesQuery, CulturalEventImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CulturalEventImagesQuery, CulturalEventImagesQueryVariables>(CulturalEventImagesDocument, options);
        }
export function useCulturalEventImagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CulturalEventImagesQuery, CulturalEventImagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CulturalEventImagesQuery, CulturalEventImagesQueryVariables>(CulturalEventImagesDocument, options);
        }
export type CulturalEventImagesQueryHookResult = ReturnType<typeof useCulturalEventImagesQuery>;
export type CulturalEventImagesLazyQueryHookResult = ReturnType<typeof useCulturalEventImagesLazyQuery>;
export type CulturalEventImagesSuspenseQueryHookResult = ReturnType<typeof useCulturalEventImagesSuspenseQuery>;
export type CulturalEventImagesQueryResult = Apollo.QueryResult<CulturalEventImagesQuery, CulturalEventImagesQueryVariables>;
export const CulturalEventsDocument = gql`
    query CulturalEvents($ziferblatId: uuid!, $before: timestamptz!) {
  draftCulturalEvents: culturalEvents(
    where: {status: {_eq: DRAFT}, ziferblatId: {_eq: $ziferblatId}}
  ) {
    ...CulturalEventListItem
  }
  aggregateCulturalEvents(
    where: {status: {_neq: DRAFT}, ziferblatId: {_eq: $ziferblatId}}
  ) {
    aggregate {
      count
    }
  }
  culturalEvents(
    where: {status: {_neq: DRAFT}, ziferblatId: {_eq: $ziferblatId}, startsAt: {_lte: $before}}
    orderBy: {startsAt: DESC}
    limit: 50
  ) {
    ...CulturalEventListItem
  }
}
    ${CulturalEventListItemFragmentDoc}`;

/**
 * __useCulturalEventsQuery__
 *
 * To run a query within a React component, call `useCulturalEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCulturalEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCulturalEventsQuery({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useCulturalEventsQuery(baseOptions: Apollo.QueryHookOptions<CulturalEventsQuery, CulturalEventsQueryVariables> & ({ variables: CulturalEventsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CulturalEventsQuery, CulturalEventsQueryVariables>(CulturalEventsDocument, options);
      }
export function useCulturalEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CulturalEventsQuery, CulturalEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CulturalEventsQuery, CulturalEventsQueryVariables>(CulturalEventsDocument, options);
        }
export function useCulturalEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CulturalEventsQuery, CulturalEventsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CulturalEventsQuery, CulturalEventsQueryVariables>(CulturalEventsDocument, options);
        }
export type CulturalEventsQueryHookResult = ReturnType<typeof useCulturalEventsQuery>;
export type CulturalEventsLazyQueryHookResult = ReturnType<typeof useCulturalEventsLazyQuery>;
export type CulturalEventsSuspenseQueryHookResult = ReturnType<typeof useCulturalEventsSuspenseQuery>;
export type CulturalEventsQueryResult = Apollo.QueryResult<CulturalEventsQuery, CulturalEventsQueryVariables>;
export const GalleryDocument = gql`
    query Gallery($ziferblatId: uuid!) {
  gallery(where: {ziferblatId: {_eq: $ziferblatId}}) {
    id
    ziferblatId
    location
    media {
      id
      contentType
      url
    }
  }
}
    `;

/**
 * __useGalleryQuery__
 *
 * To run a query within a React component, call `useGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryQuery({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useGalleryQuery(baseOptions: Apollo.QueryHookOptions<GalleryQuery, GalleryQueryVariables> & ({ variables: GalleryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GalleryQuery, GalleryQueryVariables>(GalleryDocument, options);
      }
export function useGalleryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GalleryQuery, GalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GalleryQuery, GalleryQueryVariables>(GalleryDocument, options);
        }
export function useGallerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GalleryQuery, GalleryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GalleryQuery, GalleryQueryVariables>(GalleryDocument, options);
        }
export type GalleryQueryHookResult = ReturnType<typeof useGalleryQuery>;
export type GalleryLazyQueryHookResult = ReturnType<typeof useGalleryLazyQuery>;
export type GallerySuspenseQueryHookResult = ReturnType<typeof useGallerySuspenseQuery>;
export type GalleryQueryResult = Apollo.QueryResult<GalleryQuery, GalleryQueryVariables>;
export const ImageDocument = gql`
    query Image($id: uuid!) {
  galleryItem(id: $id) {
    id
    location
    media {
      id
      contentType
      url
    }
  }
}
    `;

/**
 * __useImageQuery__
 *
 * To run a query within a React component, call `useImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useImageQuery(baseOptions: Apollo.QueryHookOptions<ImageQuery, ImageQueryVariables> & ({ variables: ImageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImageQuery, ImageQueryVariables>(ImageDocument, options);
      }
export function useImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImageQuery, ImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImageQuery, ImageQueryVariables>(ImageDocument, options);
        }
export function useImageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ImageQuery, ImageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ImageQuery, ImageQueryVariables>(ImageDocument, options);
        }
export type ImageQueryHookResult = ReturnType<typeof useImageQuery>;
export type ImageLazyQueryHookResult = ReturnType<typeof useImageLazyQuery>;
export type ImageSuspenseQueryHookResult = ReturnType<typeof useImageSuspenseQuery>;
export type ImageQueryResult = Apollo.QueryResult<ImageQuery, ImageQueryVariables>;
export const ImageUploadUrlDocument = gql`
    mutation ImageUploadURL {
  getUploadUrl {
    id
    url
    expiresIn
  }
}
    `;
export type ImageUploadUrlMutationFn = Apollo.MutationFunction<ImageUploadUrlMutation, ImageUploadUrlMutationVariables>;

/**
 * __useImageUploadUrlMutation__
 *
 * To run a mutation, you first call `useImageUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageUploadUrlMutation, { data, loading, error }] = useImageUploadUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useImageUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<ImageUploadUrlMutation, ImageUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImageUploadUrlMutation, ImageUploadUrlMutationVariables>(ImageUploadUrlDocument, options);
      }
export type ImageUploadUrlMutationHookResult = ReturnType<typeof useImageUploadUrlMutation>;
export type ImageUploadUrlMutationResult = Apollo.MutationResult<ImageUploadUrlMutation>;
export type ImageUploadUrlMutationOptions = Apollo.BaseMutationOptions<ImageUploadUrlMutation, ImageUploadUrlMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout(object: {}) {
    id
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query Me($domain: String!, $locale: String!) {
  telegramBot: authTelegramBot(where: {domain: {_eq: $domain}}) {
    id: botId
    domain
    name
  }
  me: authCurrentSession {
    ...Session
  }
}
    ${SessionFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useMeQuery(baseOptions: Apollo.QueryHookOptions<MeQuery, MeQueryVariables> & ({ variables: MeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const RemoveBookingDocument = gql`
    mutation RemoveBooking($id: uuid!) {
  deleteBookingByPk(id: $id) {
    id
  }
}
    `;
export type RemoveBookingMutationFn = Apollo.MutationFunction<RemoveBookingMutation, RemoveBookingMutationVariables>;

/**
 * __useRemoveBookingMutation__
 *
 * To run a mutation, you first call `useRemoveBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBookingMutation, { data, loading, error }] = useRemoveBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBookingMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBookingMutation, RemoveBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBookingMutation, RemoveBookingMutationVariables>(RemoveBookingDocument, options);
      }
export type RemoveBookingMutationHookResult = ReturnType<typeof useRemoveBookingMutation>;
export type RemoveBookingMutationResult = Apollo.MutationResult<RemoveBookingMutation>;
export type RemoveBookingMutationOptions = Apollo.BaseMutationOptions<RemoveBookingMutation, RemoveBookingMutationVariables>;
export const RemoveCulturalEventImageDocument = gql`
    mutation RemoveCulturalEventImage($id: uuid!) {
  removeCulturalEventImage(id: $id) {
    id
    culturalEvent {
      ...CulturalEvent
    }
  }
}
    ${CulturalEventFragmentDoc}`;
export type RemoveCulturalEventImageMutationFn = Apollo.MutationFunction<RemoveCulturalEventImageMutation, RemoveCulturalEventImageMutationVariables>;

/**
 * __useRemoveCulturalEventImageMutation__
 *
 * To run a mutation, you first call `useRemoveCulturalEventImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCulturalEventImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCulturalEventImageMutation, { data, loading, error }] = useRemoveCulturalEventImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCulturalEventImageMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCulturalEventImageMutation, RemoveCulturalEventImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCulturalEventImageMutation, RemoveCulturalEventImageMutationVariables>(RemoveCulturalEventImageDocument, options);
      }
export type RemoveCulturalEventImageMutationHookResult = ReturnType<typeof useRemoveCulturalEventImageMutation>;
export type RemoveCulturalEventImageMutationResult = Apollo.MutationResult<RemoveCulturalEventImageMutation>;
export type RemoveCulturalEventImageMutationOptions = Apollo.BaseMutationOptions<RemoveCulturalEventImageMutation, RemoveCulturalEventImageMutationVariables>;
export const RemoveFromGalleryDocument = gql`
    mutation RemoveFromGallery($id: uuid!) {
  deleteZiferblatGalleryByPk(id: $id) {
    id
  }
}
    `;
export type RemoveFromGalleryMutationFn = Apollo.MutationFunction<RemoveFromGalleryMutation, RemoveFromGalleryMutationVariables>;

/**
 * __useRemoveFromGalleryMutation__
 *
 * To run a mutation, you first call `useRemoveFromGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromGalleryMutation, { data, loading, error }] = useRemoveFromGalleryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFromGalleryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromGalleryMutation, RemoveFromGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFromGalleryMutation, RemoveFromGalleryMutationVariables>(RemoveFromGalleryDocument, options);
      }
export type RemoveFromGalleryMutationHookResult = ReturnType<typeof useRemoveFromGalleryMutation>;
export type RemoveFromGalleryMutationResult = Apollo.MutationResult<RemoveFromGalleryMutation>;
export type RemoveFromGalleryMutationOptions = Apollo.BaseMutationOptions<RemoveFromGalleryMutation, RemoveFromGalleryMutationVariables>;
export const RemoveRoleDocument = gql`
    mutation RemoveRole($id: uuid!) {
  removeGrant(object: {grantId: $id}) {
    grant {
      subject {
        ...StaffUser
      }
    }
  }
}
    ${StaffUserFragmentDoc}`;
export type RemoveRoleMutationFn = Apollo.MutationFunction<RemoveRoleMutation, RemoveRoleMutationVariables>;

/**
 * __useRemoveRoleMutation__
 *
 * To run a mutation, you first call `useRemoveRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRoleMutation, { data, loading, error }] = useRemoveRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRoleMutation, RemoveRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRoleMutation, RemoveRoleMutationVariables>(RemoveRoleDocument, options);
      }
export type RemoveRoleMutationHookResult = ReturnType<typeof useRemoveRoleMutation>;
export type RemoveRoleMutationResult = Apollo.MutationResult<RemoveRoleMutation>;
export type RemoveRoleMutationOptions = Apollo.BaseMutationOptions<RemoveRoleMutation, RemoveRoleMutationVariables>;
export const RemoveTicketDocument = gql`
    mutation RemoveTicket($id: uuid!) {
  removeTicket(id: $id) {
    id
    culturalEvent {
      tickets {
        id
        customUrl
        price
      }
    }
  }
}
    `;
export type RemoveTicketMutationFn = Apollo.MutationFunction<RemoveTicketMutation, RemoveTicketMutationVariables>;

/**
 * __useRemoveTicketMutation__
 *
 * To run a mutation, you first call `useRemoveTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTicketMutation, { data, loading, error }] = useRemoveTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTicketMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTicketMutation, RemoveTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTicketMutation, RemoveTicketMutationVariables>(RemoveTicketDocument, options);
      }
export type RemoveTicketMutationHookResult = ReturnType<typeof useRemoveTicketMutation>;
export type RemoveTicketMutationResult = Apollo.MutationResult<RemoveTicketMutation>;
export type RemoveTicketMutationOptions = Apollo.BaseMutationOptions<RemoveTicketMutation, RemoveTicketMutationVariables>;
export const RolesDocument = gql`
    query Roles {
  accessRole {
    id
    name
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export function useRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesSuspenseQueryHookResult = ReturnType<typeof useRolesSuspenseQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const SearchAliasDocument = gql`
    query SearchAlias($term: String!, $ziferblatId: uuid!) {
  authAlias(
    limit: 5
    distinctOn: userId
    where: {_not: {user: {access: {objectId: {_eq: $ziferblatId}}}}, value: {_ilike: $term}}
  ) {
    id
    user {
      id
      alias {
        id
        type
        value
      }
    }
  }
}
    `;

/**
 * __useSearchAliasQuery__
 *
 * To run a query within a React component, call `useSearchAliasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAliasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAliasQuery({
 *   variables: {
 *      term: // value for 'term'
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useSearchAliasQuery(baseOptions: Apollo.QueryHookOptions<SearchAliasQuery, SearchAliasQueryVariables> & ({ variables: SearchAliasQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAliasQuery, SearchAliasQueryVariables>(SearchAliasDocument, options);
      }
export function useSearchAliasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAliasQuery, SearchAliasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAliasQuery, SearchAliasQueryVariables>(SearchAliasDocument, options);
        }
export function useSearchAliasSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchAliasQuery, SearchAliasQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchAliasQuery, SearchAliasQueryVariables>(SearchAliasDocument, options);
        }
export type SearchAliasQueryHookResult = ReturnType<typeof useSearchAliasQuery>;
export type SearchAliasLazyQueryHookResult = ReturnType<typeof useSearchAliasLazyQuery>;
export type SearchAliasSuspenseQueryHookResult = ReturnType<typeof useSearchAliasSuspenseQuery>;
export type SearchAliasQueryResult = Apollo.QueryResult<SearchAliasQuery, SearchAliasQueryVariables>;
export const ZiferblatAddressDocument = gql`
    query ZiferblatAddress($id: uuid!) {
  ziferblat(id: $id) {
    id
    city {
      city
      country
    }
    streetAddress {
      building
      floor
      street
      locale
      howToGet
    }
    addressSlug
  }
}
    `;

/**
 * __useZiferblatAddressQuery__
 *
 * To run a query within a React component, call `useZiferblatAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useZiferblatAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZiferblatAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useZiferblatAddressQuery(baseOptions: Apollo.QueryHookOptions<ZiferblatAddressQuery, ZiferblatAddressQueryVariables> & ({ variables: ZiferblatAddressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZiferblatAddressQuery, ZiferblatAddressQueryVariables>(ZiferblatAddressDocument, options);
      }
export function useZiferblatAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZiferblatAddressQuery, ZiferblatAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZiferblatAddressQuery, ZiferblatAddressQueryVariables>(ZiferblatAddressDocument, options);
        }
export function useZiferblatAddressSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ZiferblatAddressQuery, ZiferblatAddressQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ZiferblatAddressQuery, ZiferblatAddressQueryVariables>(ZiferblatAddressDocument, options);
        }
export type ZiferblatAddressQueryHookResult = ReturnType<typeof useZiferblatAddressQuery>;
export type ZiferblatAddressLazyQueryHookResult = ReturnType<typeof useZiferblatAddressLazyQuery>;
export type ZiferblatAddressSuspenseQueryHookResult = ReturnType<typeof useZiferblatAddressSuspenseQuery>;
export type ZiferblatAddressQueryResult = Apollo.QueryResult<ZiferblatAddressQuery, ZiferblatAddressQueryVariables>;
export const ZiferblatDescriptionDocument = gql`
    query ZiferblatDescription($id: uuid!) {
  ziferblat(id: $id) {
    id
    activeFrom
    i18n {
      ...ZiferblatI18n
    }
  }
}
    ${ZiferblatI18nFragmentDoc}`;

/**
 * __useZiferblatDescriptionQuery__
 *
 * To run a query within a React component, call `useZiferblatDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useZiferblatDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZiferblatDescriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useZiferblatDescriptionQuery(baseOptions: Apollo.QueryHookOptions<ZiferblatDescriptionQuery, ZiferblatDescriptionQueryVariables> & ({ variables: ZiferblatDescriptionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZiferblatDescriptionQuery, ZiferblatDescriptionQueryVariables>(ZiferblatDescriptionDocument, options);
      }
export function useZiferblatDescriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZiferblatDescriptionQuery, ZiferblatDescriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZiferblatDescriptionQuery, ZiferblatDescriptionQueryVariables>(ZiferblatDescriptionDocument, options);
        }
export function useZiferblatDescriptionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ZiferblatDescriptionQuery, ZiferblatDescriptionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ZiferblatDescriptionQuery, ZiferblatDescriptionQueryVariables>(ZiferblatDescriptionDocument, options);
        }
export type ZiferblatDescriptionQueryHookResult = ReturnType<typeof useZiferblatDescriptionQuery>;
export type ZiferblatDescriptionLazyQueryHookResult = ReturnType<typeof useZiferblatDescriptionLazyQuery>;
export type ZiferblatDescriptionSuspenseQueryHookResult = ReturnType<typeof useZiferblatDescriptionSuspenseQuery>;
export type ZiferblatDescriptionQueryResult = Apollo.QueryResult<ZiferblatDescriptionQuery, ZiferblatDescriptionQueryVariables>;
export const StaffDocument = gql`
    query Staff($ziferblatId: uuid!) {
  users(
    where: {grants: {objectId: {_eq: $ziferblatId}, _or: [{_not: {expires: {}}}, {expires: {expiresAt: {_gt: "now()"}}}]}}
  ) {
    ...StaffUser
  }
}
    ${StaffUserFragmentDoc}`;

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useStaffQuery(baseOptions: Apollo.QueryHookOptions<StaffQuery, StaffQueryVariables> & ({ variables: StaffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
      }
export function useStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
        }
export function useStaffSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StaffQuery, StaffQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
        }
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffSuspenseQueryHookResult = ReturnType<typeof useStaffSuspenseQuery>;
export type StaffQueryResult = Apollo.QueryResult<StaffQuery, StaffQueryVariables>;
export const TariffsDocument = gql`
    query Tariffs($ziferblatId: uuid!) {
  tariffs(where: {ziferblatId: {_eq: $ziferblatId}}, orderBy: {activeFrom: DESC}) {
    ...Tariff
  }
}
    ${TariffFragmentDoc}`;

/**
 * __useTariffsQuery__
 *
 * To run a query within a React component, call `useTariffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTariffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTariffsQuery({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useTariffsQuery(baseOptions: Apollo.QueryHookOptions<TariffsQuery, TariffsQueryVariables> & ({ variables: TariffsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TariffsQuery, TariffsQueryVariables>(TariffsDocument, options);
      }
export function useTariffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TariffsQuery, TariffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TariffsQuery, TariffsQueryVariables>(TariffsDocument, options);
        }
export function useTariffsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TariffsQuery, TariffsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TariffsQuery, TariffsQueryVariables>(TariffsDocument, options);
        }
export type TariffsQueryHookResult = ReturnType<typeof useTariffsQuery>;
export type TariffsLazyQueryHookResult = ReturnType<typeof useTariffsLazyQuery>;
export type TariffsSuspenseQueryHookResult = ReturnType<typeof useTariffsSuspenseQuery>;
export type TariffsQueryResult = Apollo.QueryResult<TariffsQuery, TariffsQueryVariables>;
export const TelegramLoginDocument = gql`
    mutation TelegramLogin($input: AuthTelegramInputInsertInput!) {
  telegramLogin(object: $input) {
    id
  }
}
    `;
export type TelegramLoginMutationFn = Apollo.MutationFunction<TelegramLoginMutation, TelegramLoginMutationVariables>;

/**
 * __useTelegramLoginMutation__
 *
 * To run a mutation, you first call `useTelegramLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTelegramLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [telegramLoginMutation, { data, loading, error }] = useTelegramLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTelegramLoginMutation(baseOptions?: Apollo.MutationHookOptions<TelegramLoginMutation, TelegramLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TelegramLoginMutation, TelegramLoginMutationVariables>(TelegramLoginDocument, options);
      }
export type TelegramLoginMutationHookResult = ReturnType<typeof useTelegramLoginMutation>;
export type TelegramLoginMutationResult = Apollo.MutationResult<TelegramLoginMutation>;
export type TelegramLoginMutationOptions = Apollo.BaseMutationOptions<TelegramLoginMutation, TelegramLoginMutationVariables>;
export const TicketCheckinDocument = gql`
    mutation TicketCheckin($object: CulturalEventPrepaidAttendanceInsertInput!) {
  insertCulturalEventPrepaidAttendanceOne(object: $object) {
    id
    visit {
      id
      name
    }
    transaction {
      ticket {
        culturalEvent {
          id
          title
          startsAt
        }
      }
    }
  }
}
    `;
export type TicketCheckinMutationFn = Apollo.MutationFunction<TicketCheckinMutation, TicketCheckinMutationVariables>;

/**
 * __useTicketCheckinMutation__
 *
 * To run a mutation, you first call `useTicketCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketCheckinMutation, { data, loading, error }] = useTicketCheckinMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useTicketCheckinMutation(baseOptions?: Apollo.MutationHookOptions<TicketCheckinMutation, TicketCheckinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TicketCheckinMutation, TicketCheckinMutationVariables>(TicketCheckinDocument, options);
      }
export type TicketCheckinMutationHookResult = ReturnType<typeof useTicketCheckinMutation>;
export type TicketCheckinMutationResult = Apollo.MutationResult<TicketCheckinMutation>;
export type TicketCheckinMutationOptions = Apollo.BaseMutationOptions<TicketCheckinMutation, TicketCheckinMutationVariables>;
export const AddAttendanceDocument = gql`
    mutation AddAttendance($checkinId: uuid!, $culturalEventId: uuid!) {
  toggleAttendance: addAttendance(
    object: {visitId: $checkinId, culturalEventId: $culturalEventId}
  ) {
    checkin {
      ...Checkin
    }
  }
}
    ${CheckinFragmentDoc}`;
export type AddAttendanceMutationFn = Apollo.MutationFunction<AddAttendanceMutation, AddAttendanceMutationVariables>;

/**
 * __useAddAttendanceMutation__
 *
 * To run a mutation, you first call `useAddAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAttendanceMutation, { data, loading, error }] = useAddAttendanceMutation({
 *   variables: {
 *      checkinId: // value for 'checkinId'
 *      culturalEventId: // value for 'culturalEventId'
 *   },
 * });
 */
export function useAddAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<AddAttendanceMutation, AddAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAttendanceMutation, AddAttendanceMutationVariables>(AddAttendanceDocument, options);
      }
export type AddAttendanceMutationHookResult = ReturnType<typeof useAddAttendanceMutation>;
export type AddAttendanceMutationResult = Apollo.MutationResult<AddAttendanceMutation>;
export type AddAttendanceMutationOptions = Apollo.BaseMutationOptions<AddAttendanceMutation, AddAttendanceMutationVariables>;
export const RemoveAttendanceDocument = gql`
    mutation removeAttendance($checkinId: uuid!, $culturalEventId: uuid!) {
  toggleAttendance: removeAttendance(
    where: {visitId: {_eq: $checkinId}, culturalEventId: {_eq: $culturalEventId}}
  ) {
    returning {
      checkin {
        ...Checkin
      }
    }
  }
}
    ${CheckinFragmentDoc}`;
export type RemoveAttendanceMutationFn = Apollo.MutationFunction<RemoveAttendanceMutation, RemoveAttendanceMutationVariables>;

/**
 * __useRemoveAttendanceMutation__
 *
 * To run a mutation, you first call `useRemoveAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAttendanceMutation, { data, loading, error }] = useRemoveAttendanceMutation({
 *   variables: {
 *      checkinId: // value for 'checkinId'
 *      culturalEventId: // value for 'culturalEventId'
 *   },
 * });
 */
export function useRemoveAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAttendanceMutation, RemoveAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAttendanceMutation, RemoveAttendanceMutationVariables>(RemoveAttendanceDocument, options);
      }
export type RemoveAttendanceMutationHookResult = ReturnType<typeof useRemoveAttendanceMutation>;
export type RemoveAttendanceMutationResult = Apollo.MutationResult<RemoveAttendanceMutation>;
export type RemoveAttendanceMutationOptions = Apollo.BaseMutationOptions<RemoveAttendanceMutation, RemoveAttendanceMutationVariables>;
export const AddBookingCheckinDocument = gql`
    mutation AddBookingCheckin($checkinId: uuid!, $bookingId: uuid!) {
  insertBookingCheckinOne(object: {checkinId: $checkinId, bookingId: $bookingId}) {
    checkin {
      ...Checkin
    }
  }
}
    ${CheckinFragmentDoc}`;
export type AddBookingCheckinMutationFn = Apollo.MutationFunction<AddBookingCheckinMutation, AddBookingCheckinMutationVariables>;

/**
 * __useAddBookingCheckinMutation__
 *
 * To run a mutation, you first call `useAddBookingCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBookingCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBookingCheckinMutation, { data, loading, error }] = useAddBookingCheckinMutation({
 *   variables: {
 *      checkinId: // value for 'checkinId'
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useAddBookingCheckinMutation(baseOptions?: Apollo.MutationHookOptions<AddBookingCheckinMutation, AddBookingCheckinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBookingCheckinMutation, AddBookingCheckinMutationVariables>(AddBookingCheckinDocument, options);
      }
export type AddBookingCheckinMutationHookResult = ReturnType<typeof useAddBookingCheckinMutation>;
export type AddBookingCheckinMutationResult = Apollo.MutationResult<AddBookingCheckinMutation>;
export type AddBookingCheckinMutationOptions = Apollo.BaseMutationOptions<AddBookingCheckinMutation, AddBookingCheckinMutationVariables>;
export const RemoveBookingCheckinDocument = gql`
    mutation RemoveBookingCheckin($checkinId: uuid!, $bookingId: uuid!) {
  deleteBookingCheckin(
    where: {checkinId: {_eq: $checkinId}, bookingId: {_eq: $bookingId}}
  ) {
    returning {
      checkin {
        ...Checkin
      }
    }
  }
}
    ${CheckinFragmentDoc}`;
export type RemoveBookingCheckinMutationFn = Apollo.MutationFunction<RemoveBookingCheckinMutation, RemoveBookingCheckinMutationVariables>;

/**
 * __useRemoveBookingCheckinMutation__
 *
 * To run a mutation, you first call `useRemoveBookingCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBookingCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBookingCheckinMutation, { data, loading, error }] = useRemoveBookingCheckinMutation({
 *   variables: {
 *      checkinId: // value for 'checkinId'
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useRemoveBookingCheckinMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBookingCheckinMutation, RemoveBookingCheckinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBookingCheckinMutation, RemoveBookingCheckinMutationVariables>(RemoveBookingCheckinDocument, options);
      }
export type RemoveBookingCheckinMutationHookResult = ReturnType<typeof useRemoveBookingCheckinMutation>;
export type RemoveBookingCheckinMutationResult = Apollo.MutationResult<RemoveBookingCheckinMutation>;
export type RemoveBookingCheckinMutationOptions = Apollo.BaseMutationOptions<RemoveBookingCheckinMutation, RemoveBookingCheckinMutationVariables>;
export const UpdateMediaLocationDocument = gql`
    mutation updateMediaLocation($id: uuid!, $location: String!) {
  updateZiferblatGalleryByPk(pkColumns: {id: $id}, _set: {location: $location}) {
    id
    location
  }
}
    `;
export type UpdateMediaLocationMutationFn = Apollo.MutationFunction<UpdateMediaLocationMutation, UpdateMediaLocationMutationVariables>;

/**
 * __useUpdateMediaLocationMutation__
 *
 * To run a mutation, you first call `useUpdateMediaLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaLocationMutation, { data, loading, error }] = useUpdateMediaLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useUpdateMediaLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMediaLocationMutation, UpdateMediaLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMediaLocationMutation, UpdateMediaLocationMutationVariables>(UpdateMediaLocationDocument, options);
      }
export type UpdateMediaLocationMutationHookResult = ReturnType<typeof useUpdateMediaLocationMutation>;
export type UpdateMediaLocationMutationResult = Apollo.MutationResult<UpdateMediaLocationMutation>;
export type UpdateMediaLocationMutationOptions = Apollo.BaseMutationOptions<UpdateMediaLocationMutation, UpdateMediaLocationMutationVariables>;
export const UpdateZiferblatDocument = gql`
    mutation UpdateZiferblat($id: uuid!, $activeFrom: timestamptz) {
  updateZiferblat(pkColumns: {id: $id}, _set: {activeFrom: $activeFrom}) {
    id
  }
}
    `;
export type UpdateZiferblatMutationFn = Apollo.MutationFunction<UpdateZiferblatMutation, UpdateZiferblatMutationVariables>;

/**
 * __useUpdateZiferblatMutation__
 *
 * To run a mutation, you first call `useUpdateZiferblatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZiferblatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZiferblatMutation, { data, loading, error }] = useUpdateZiferblatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      activeFrom: // value for 'activeFrom'
 *   },
 * });
 */
export function useUpdateZiferblatMutation(baseOptions?: Apollo.MutationHookOptions<UpdateZiferblatMutation, UpdateZiferblatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateZiferblatMutation, UpdateZiferblatMutationVariables>(UpdateZiferblatDocument, options);
      }
export type UpdateZiferblatMutationHookResult = ReturnType<typeof useUpdateZiferblatMutation>;
export type UpdateZiferblatMutationResult = Apollo.MutationResult<UpdateZiferblatMutation>;
export type UpdateZiferblatMutationOptions = Apollo.BaseMutationOptions<UpdateZiferblatMutation, UpdateZiferblatMutationVariables>;
export const UpsertCulturalEventDocument = gql`
    mutation UpsertCulturalEvent($input: CulturalEventInsertInput!, $compensationIds: [uuid!] = []) {
  deleteCulturalEventCompensation(where: {id: {_in: $compensationIds}}) {
    affectedRows
  }
  upsertCulturalEvent: addCulturalEvent(
    onConflict: {constraint: cultural_event_pkey, updateColumns: [endsAt, startsAt, publicDescription, internalComment, status, title, slug]}
    object: $input
  ) {
    ...CulturalEvent
  }
}
    ${CulturalEventFragmentDoc}`;
export type UpsertCulturalEventMutationFn = Apollo.MutationFunction<UpsertCulturalEventMutation, UpsertCulturalEventMutationVariables>;

/**
 * __useUpsertCulturalEventMutation__
 *
 * To run a mutation, you first call `useUpsertCulturalEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCulturalEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCulturalEventMutation, { data, loading, error }] = useUpsertCulturalEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *      compensationIds: // value for 'compensationIds'
 *   },
 * });
 */
export function useUpsertCulturalEventMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCulturalEventMutation, UpsertCulturalEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCulturalEventMutation, UpsertCulturalEventMutationVariables>(UpsertCulturalEventDocument, options);
      }
export type UpsertCulturalEventMutationHookResult = ReturnType<typeof useUpsertCulturalEventMutation>;
export type UpsertCulturalEventMutationResult = Apollo.MutationResult<UpsertCulturalEventMutation>;
export type UpsertCulturalEventMutationOptions = Apollo.BaseMutationOptions<UpsertCulturalEventMutation, UpsertCulturalEventMutationVariables>;
export const UpsertZiferblatI18nDocument = gql`
    mutation UpsertZiferblatI18n($data: ZiferblatZiferblatI18nInsertInput!) {
  insertZiferblatI18n(
    object: $data
    onConflict: {constraint: ziferblat_i18n_ziferblat_id_lang_key, updateColumns: [name, rules, description]}
  ) {
    id
    name
    rules
    description
  }
}
    `;
export type UpsertZiferblatI18nMutationFn = Apollo.MutationFunction<UpsertZiferblatI18nMutation, UpsertZiferblatI18nMutationVariables>;

/**
 * __useUpsertZiferblatI18nMutation__
 *
 * To run a mutation, you first call `useUpsertZiferblatI18nMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertZiferblatI18nMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertZiferblatI18nMutation, { data, loading, error }] = useUpsertZiferblatI18nMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertZiferblatI18nMutation(baseOptions?: Apollo.MutationHookOptions<UpsertZiferblatI18nMutation, UpsertZiferblatI18nMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertZiferblatI18nMutation, UpsertZiferblatI18nMutationVariables>(UpsertZiferblatI18nDocument, options);
      }
export type UpsertZiferblatI18nMutationHookResult = ReturnType<typeof useUpsertZiferblatI18nMutation>;
export type UpsertZiferblatI18nMutationResult = Apollo.MutationResult<UpsertZiferblatI18nMutation>;
export type UpsertZiferblatI18nMutationOptions = Apollo.BaseMutationOptions<UpsertZiferblatI18nMutation, UpsertZiferblatI18nMutationVariables>;
export const UpsertZiferblatI18nAddressDocument = gql`
    mutation UpsertZiferblatI18nAddress($data: ZiferblatAddressI18nInsertInput!) {
  insertZiferblatAddressI18n(
    object: $data
    onConflict: {constraint: address_i18n_locale_slug_key, updateColumns: [building, street, floor, howToGet]}
  ) {
    id
    building
    street
    floor
    howToGet
  }
}
    `;
export type UpsertZiferblatI18nAddressMutationFn = Apollo.MutationFunction<UpsertZiferblatI18nAddressMutation, UpsertZiferblatI18nAddressMutationVariables>;

/**
 * __useUpsertZiferblatI18nAddressMutation__
 *
 * To run a mutation, you first call `useUpsertZiferblatI18nAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertZiferblatI18nAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertZiferblatI18nAddressMutation, { data, loading, error }] = useUpsertZiferblatI18nAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertZiferblatI18nAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpsertZiferblatI18nAddressMutation, UpsertZiferblatI18nAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertZiferblatI18nAddressMutation, UpsertZiferblatI18nAddressMutationVariables>(UpsertZiferblatI18nAddressDocument, options);
      }
export type UpsertZiferblatI18nAddressMutationHookResult = ReturnType<typeof useUpsertZiferblatI18nAddressMutation>;
export type UpsertZiferblatI18nAddressMutationResult = Apollo.MutationResult<UpsertZiferblatI18nAddressMutation>;
export type UpsertZiferblatI18nAddressMutationOptions = Apollo.BaseMutationOptions<UpsertZiferblatI18nAddressMutation, UpsertZiferblatI18nAddressMutationVariables>;
export const ZiferblatInfoDocument = gql`
    query ZiferblatInfo($ziferblatId: uuid!) {
  ziferblat(id: $ziferblatId) {
    city {
      city
      country
    }
    streetAddress {
      building
      floor
      street
    }
    gallery {
      media {
        id
        contentType
        url
      }
      location
    }
    i18n {
      createdAt
      description
      id
      name
      rules
      locale
    }
    citySlug
    addressSlug
    activeFrom
  }
}
    `;

/**
 * __useZiferblatInfoQuery__
 *
 * To run a query within a React component, call `useZiferblatInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useZiferblatInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZiferblatInfoQuery({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useZiferblatInfoQuery(baseOptions: Apollo.QueryHookOptions<ZiferblatInfoQuery, ZiferblatInfoQueryVariables> & ({ variables: ZiferblatInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZiferblatInfoQuery, ZiferblatInfoQueryVariables>(ZiferblatInfoDocument, options);
      }
export function useZiferblatInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZiferblatInfoQuery, ZiferblatInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZiferblatInfoQuery, ZiferblatInfoQueryVariables>(ZiferblatInfoDocument, options);
        }
export function useZiferblatInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ZiferblatInfoQuery, ZiferblatInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ZiferblatInfoQuery, ZiferblatInfoQueryVariables>(ZiferblatInfoDocument, options);
        }
export type ZiferblatInfoQueryHookResult = ReturnType<typeof useZiferblatInfoQuery>;
export type ZiferblatInfoLazyQueryHookResult = ReturnType<typeof useZiferblatInfoLazyQuery>;
export type ZiferblatInfoSuspenseQueryHookResult = ReturnType<typeof useZiferblatInfoSuspenseQuery>;
export type ZiferblatInfoQueryResult = Apollo.QueryResult<ZiferblatInfoQuery, ZiferblatInfoQueryVariables>;