import { Flex, spacing } from "@gravity-ui/uikit"
import { createElement as $ } from "react"
import AddBooking from "./AddBooking"
import AddCulturalEvent from "./AddCulturalEvent"

const Content = () => 
  $(Flex, {
    direction: 'column', 
    width: '100%', 
    gap: 4, 
    className: spacing({ p: 4 }) 
    },
    $(AddBooking),
    $(AddCulturalEvent))

export default Content