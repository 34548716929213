import { useLayoutContext } from "@gravity-ui/uikit"
import { CulturalEventFragment } from "queries"
import { createElement as $, FC } from "react"
import Desktop from "./Desktop"
import Mobile from "./Mobile"

const AddTicket: FC<CulturalEventFragment> = (culturalEvent) => {
  const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's'
  const component = isMobile ? Mobile : Desktop

  return $(component, culturalEvent)
}

export default AddTicket