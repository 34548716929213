import { Card, Flex, Text, spacing } from '@gravity-ui/uikit'
import { MessageIds } from 'components/IntlProvider'
import { AttendanceQuery, CompensationCurrencyEnum as CompensationCurrency, CompensationUnitEnum as CompensationUnit, CulturalEventFragment, useAttendanceQuery } from 'queries'
import { createElement as $, FC, Fragment } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'

const Attendance: FC<AttendanceProps> = ({ id, compensation }) => {
  const { data } = useAttendanceQuery({ variables: { id } })
  if (!data) return null
  const sum = data.attendees.reduce((result, item) => result += item.sum!, 0)
  const factualSum = data.attendees.reduce((result, item) =>
    result += (item.visit?.sum || item.checkin?.sum || 0), 0)
  const count = data.attendees.length
  if (!count) return null

  return $(Fragment, null,
    !sum
      ? $('div', { className: spacing({ p: 2 }) })
      : $(Card, {
          view: 'filled',
          className: spacing({ p: 4 }),
          children: $(Stats, {
            count,
            sum,
            factualSum,
            compensation
          })
        }),
    data.attendees?.map(AttendanceIteratee))
}

const Stats: FC<StatsProps> = ({
  sum,
  count,
  factualSum,
  compensation
}) => {
  const intl = useIntl()
  return $(Fragment, null,
    $(Flex, { justifyContent: 'space-between' }, 
      $(Text, null, intl.formatMessage({ id: 'culturalEvent.stats.count' })), count),
    $(Flex, { justifyContent: 'space-between' }, 
      $(Text, null, intl.formatMessage({ id: 'culturalEvent.stats.sum.calculated' })),
      $(FormattedNumber, { value: sum, style: 'currency', currency: 'RUB', maximumFractionDigits: 0 })),
    compensation &&
      $(Compensation, { sum, count, compensation, label: 'culturalEvent.compensation.calculated' }),
    $(Flex, { justifyContent: 'space-between' },
      $(Text, null, intl.formatMessage({ id: 'culturalEvent.stats.sum.factual' })),
      $(FormattedNumber, { value: factualSum, style: 'currency', currency: 'RUB', maximumFractionDigits: 0 })),
    compensation &&
      $(Compensation, { sum: factualSum, count, compensation, label: 'culturalEvent.compensation.factual' }))
}

const Compensation: FC<CompensationProps> = ({
  sum,
  count,
  compensation,
  label
}) => {
  const intl = useIntl()
  let compensationSum = sum * (1 / (100 / compensation.amount))
  if (compensation?.unit === CompensationUnit.Fixed)
    compensationSum = count * compensation.amount
  return $(Flex, { justifyContent: 'space-between' },
    $(Text, null,
      intl.formatMessage({ id: label  })),
    compensation.currency === CompensationCurrency.Money
      ? $(FormattedNumber, { style: 'currency', currency: 'RUB', value: compensationSum, maximumFractionDigits: 0 })
      : $(FormattedMessage, { id: 'minutes', values: { minutes: compensationSum }}))
}

const AttendanceIteratee = (props: AttendanceItem) =>
  $(AttendanceListItem, { key: props.id, ...props })

const AttendanceListItem: FC<AttendanceItem> = ({
  sum,
  visit,
  checkin
}) =>
  $(Card, {
    view: 'filled',
    className: spacing({ px: 4, py: 2 }),
    children: $(Flex, { justifyContent: 'space-between' },
      $(Text, null, visit?.name || checkin?.name),
      $('div', null,
        $(Text, null,
          $(FormattedNumber, {
            value: visit?.sum!,
            style: 'currency',
            currency: 'RUB',
            maximumFractionDigits: 0
            }),
          ' / ',
          $(FormattedNumber, {
            value: sum!,
            style: 'currency',
            currency: 'RUB',
            maximumFractionDigits: 0
          }))))
      })

type AttendanceProps = Pick<CulturalEventFragment, 'id' | 'compensation'>
type AttendanceItem = AttendanceQuery['attendees'][number]
type StatsProps = {
  sum: number
  count: number
  factualSum: number
  compensation: CulturalEventFragment['compensation']
}
type CompensationProps = {
  compensation: NonNullable<StatsProps['compensation']>
  label: MessageIds
} & Omit<StatsProps, 'factualSum'>

export default Attendance
