import { CulturalEventStatusEnum } from 'queries'

export const groupCalendarEvents = (events: CalendarEvent[] | undefined): GroupedEvents | [] => {
  if (!events) return []
  const grouped: GroupedEvents = {}

	events.forEach((event: CalendarEvent) => {
		const date = new Date(event.startsAt)
		const year = date.getFullYear().toString()
		const month = (date.getMonth() + 1).toString().padStart(2, '0')
		const dateKey = date.toISOString().split('T')[0]

		if (!grouped[year]) {
			grouped[year] = {}
		}
		if (!grouped[year][month]) {
			grouped[year][month] = {}
		}
		if (!grouped[year][month][dateKey]) {
			grouped[year][month][dateKey] = []
		}
		grouped[year][month][dateKey].push(event)
	})

	return grouped
}

export type CalendarEvent = {
  __typename?: 'CulturalEvent' | 'Booking' | undefined
  id: string
  title?: string
  name?: string
  startsAt: Date
  endsAt?: Date | undefined
  status?: CulturalEventStatusEnum
  comment?: string,
  internalComment?: string
}

export type GroupedEvents = {
	[year: string]: {
		[month: string]: {
			[date: string]: CalendarEvent[]
		}
	}
}