import { ArrowLeft, Check, FloppyDisk } from "@gravity-ui/icons"
import { Button, Card, Icon, Text } from "@gravity-ui/uikit"
import { useFormikContext } from "formik"
import { createElement as $, FC, Fragment, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import './style.scss'

const EventControls: FC<ControlsProps> = ({ loading }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const formik = useFormikContext()
  const [submitted, setSubmitted] = useState(false)
  const submitting = formik.isSubmitting

  const onClick = () => {
    if (!submitted) {
      formik.submitForm()
      .then(() => setSubmitted(true))
      // .then(() => navigate('/calendar'))
    }
  }

  useEffect(() => {
    setTimeout(() => { 
      setSubmitted(false) 
    }, 2000)
  }, [submitting])

  return $(Card, {
    className: 'controls',
    view: 'filled',
    type: 'action',
    children: $(Fragment, null, 
      $(Button, {
        className: 'controls_button',
        size: 'l',
        view: 'flat',
        onClick: () => navigate('/calendar')
        },
        $(Icon, { data: ArrowLeft }),
        $(Text, null, intl.formatMessage({ id: 'main.calendar' }))),
      $(Button, {
        size: 'l',
        view: `outlined-${!submitted ? `action` : `success`}`,
        onClick,
        loading: loading || submitting,
        disabled: (!submitted && !formik.dirty) || !formik.isValid
        },
        $(Icon, { data: !submitted ? FloppyDisk : Check }))),
    })
}

type ControlsProps = {
  loading: boolean
}

export default EventControls