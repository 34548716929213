import { Flex, Skeleton, spacing, Spin, useLayoutContext } from '@gravity-ui/uikit'
import MessageContainer from 'components/MessageContainer'
import { useBookingQuery } from 'queries'
import { createElement as $, FC, useMemo } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import BookingForm from './BookingForm'
import FormWithData from './BookingWithData'
import CheckinsList from './CheckinsList'

const Booking: FC = () => {
	const params = useParams<'id'>()
  const id = params.id!
  const skip = id === 'new'
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialSkip = useMemo(() => skip, [])
	const { data, loading } = useBookingQuery({ 
    skip,
    variables: { id }
  })
  const booking = data?.bookingByPk
  
  if (!initialSkip)
    if (loading)
      return $(Loading)
    else if (!booking)
      return $(Navigate, { to: '/calendar' })

	return $(Flex, { 
    direction: 'column', 
    className: spacing({ p: 2 })
    }, 
    $(FormWithData, booking, $(BookingForm)),
    $(CheckinsList, booking),
    $('div', { style: { height: '8rem', width: '100%' }})
  )
}

const Loading = () => {
  const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's'
  if (isMobile)
    return $(MessageContainer, null, $(Spin, { size: 'xl' }))
  return $(Skeleton, { style: { maxWidth: '500px', height: '340px' }})
}

export default Booking
