import { FabElement } from 'pages/Main/Desktop/FabContext'
import { createElement as $, FC, Fragment, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Props } from '..'
import Menu from './Menu'
import MobileFabContext from './MobileFabContext'

const Mobile: FC<Props> = (props) => {
  const [mobileFab, setMobileFab] = useState<FabElement>()
  const propsWithFab: Props & { mobileFab: FabElement } = { ...props, mobileFab }
  
  return $(Fragment, null,
    $(MobileFabContext.Provider, { value: setMobileFab },
      $('div', { className: 'content' },
        $(Outlet)),
      $(Menu, propsWithFab)))
}

export default Mobile