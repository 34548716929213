import { Card, Flex, Label, Text, spacing } from '@gravity-ui/uikit'
import { MessageIds } from 'components/IntlProvider'
import { CulturalEventStatusEnum } from 'queries'
import { createElement as $, FC, Fragment, PropsWithChildren } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { CalendarEvent } from './groupCalendarEvents'

const CalendarEventListItemIteratee = (props: CalendarEvent) =>
  $(CalendarEventListItem, { key: props.id, ...props })

const CalendarEventListItem: FC<CalendarEvent> = ({
  id,
  name,
  title,
  startsAt,
  endsAt,
  status,
  comment,
  internalComment,
  __typename
}) => {
  const intl = useIntl()
  const date = !startsAt
    ? intl.formatMessage({ id: 'culturalEvent.noDate' })
    : $(Flex, { direction: 'column' }, 
      status === 'DRAFT' && $(FormattedDate, { value: startsAt, day: '2-digit', month: 'long' }),
      $(Flex, null,
        $(FormattedDate, {
          value: startsAt,
          timeStyle: 'short'
        }),
        endsAt && 
          $(Fragment, null, 
            ' – ',
            $(FormattedDate, {
              value: endsAt,
              timeStyle: 'short'
            })
        ))
      )

  const children = $(Flex, { direction: 'column', justifyContent: 'space-between', height: '100%', gap: 2 },
    $(Flex, { direction: 'column' },
      $(Text, { variant: 'body-2' }, 
        title || name || intl.formatMessage({ id: 'calendarEvent.noName' })),
      $(Text, { 
        variant: 'body-1', 
        color: 'hint' 
        }, date)),
    status && $(StatusChip, { status }))

  return __typename === 'CulturalEvent' 
    ? $(CulturalEventCard, { children, id })
    : $(BookingCard, { children, id })
}

const StatusChip: FC<{ status: string }> = ({ status }) => {
  const formattedStatus = Object.keys(CulturalEventStatusEnum).find(key => key.toLowerCase() === status.toLowerCase())
  const theme = status === 'CANCELED' ? 'danger' : 'unknown'

  if (status === 'ARCHIVED' || status === 'CANCELED') 
    return $('div', { style: { width: 'fit-content' }}, 
      $(Label, { theme }, 
        $(FormattedMessage, { id: `culturalEvent.status.${formattedStatus}` as MessageIds })))
}

const CulturalEventCard: FC<PropsWithChildren & { id: string }> = ({ children, id }) =>  {
  const navigate = useNavigate()

  return $(Card, {
    view: 'filled',
    className: spacing({ p: 4 }),
    type: 'action',
    style: { wordWrap: 'break-word', minHeight: '100px' },
    onClick: () => navigate(`/calendar/${id}`),
    children
  })
}

const BookingCard: FC<PropsWithChildren & { id: string }> = ({ children, id }) =>  {
  const navigate = useNavigate()

  return $('div', { // this is due to Gravity overwrites theme when type='action'
    onClick: () => navigate(`/calendar/booking/${id}`),
    style: { cursor: 'pointer', height: 'min-content' },
    }, 
    $(Card, {
      view: 'filled',
      theme: 'info',
      className: spacing({ px: 4, py: 2  }),
      onClick: () => navigate(`/calendar/booking/${id}`),
      style: { wordWrap: 'break-word' },
      children
    }))
}

export default CalendarEventListItemIteratee