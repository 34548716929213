import { Modal } from "@gravity-ui/uikit"
import { createElement as $, Dispatch, FC, SetStateAction } from "react"
import Content from "./Content"

const AddEvent: FC<Props> = ({ open, setOpen }) => 
  $(Modal, { 
    open, 
    onOutsideClick: () => setOpen(false),
    onEscapeKeyDown: () => setOpen(false)
    },
    $('div', { style: { minWidth: '256px' }}, 
      $(Content)))

type Props = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default AddEvent