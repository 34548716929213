import { Avatar, AvatarProps, Card, Portal, Tooltip } from "@gravity-ui/uikit"
import UserContent from "components/User"
import useOutsideClick from "hooks/useOutsideClick"
import { MeQuery } from "queries"
import { createElement as $, useRef, useState } from "react"
import { useIntl } from "react-intl"
import useLocalStorageState from "use-local-storage-state"
import './style.scss'
import { StaffType } from "pages/Staff/StaffListItem"

const PopupButton = (data: MeQuery) => {
  const intl = useIntl()
  const roles = data.me?.user?.roles || []
  const [ currentRole ] = useLocalStorageState<StaffType['grants'][0]['id']>('currentRole')
  const currentRoleData = roles.find(role => role.id === currentRole)
  const currentLogo = currentRoleData?.ziferblat?.avatarUrl || roles[0].ziferblat?.avatarUrl
  const buttonRef = useRef(null)
  const cardRef = useRef(null)
  const [open, setOpen] = useState(false)
 
  useOutsideClick(() => {
    setOpen(false)
  }, cardRef)

  const children = $('div', null, 
    $('div', {
      className: 'navigation-rail_userpic',
      ref: buttonRef,
      onClick: () => setOpen((prev) => !prev)
      }, 
      $<AvatarProps>(Avatar, {
        imgUrl: currentLogo,
        fallbackImgUrl: '/favicon.svg',
        size: 'm'
        })),
      $(Portal, null, 
        open && $('div', { style: CardStyles }, 
          $(Card, {
            ref: cardRef,
            type: 'action',
            view: 'raised',
            children: $(UserContent, data)
          }))))

  return $(Tooltip, { 
    className: 'navigation-rail_tooltip',
    content: intl.formatMessage({ id: 'main.profile' }), 
    placement: 'right-start', 
    style: { marginTop: '4px' },
    children 
  })
}

const CardStyles = {
  position: 'fixed',
  left: '.75rem',
  bottom: '.75rem'
}

export default PopupButton