import MobileList from 'components/MobileList'
import MobileMenuContainer from 'components/MobileMenuContainer'
import { createElement as $, FC } from 'react'
import { CalendarEventProps } from '..'
import Content from './Content'
import AddEvent from '../AddEvent/Mobile'

const CulturalEvents: FC<CalendarEventProps> = (props) =>
  $(MobileMenuContainer, {
    fab: $(AddEvent),
    label: 'header.button.search.events'
    },
    $(MobileList, null, 
      $(Content, props)))


export default CulturalEvents