import { Flex, Spin, useLayoutContext } from '@gravity-ui/uikit'
import MessageContainer from 'components/MessageContainer'
import { CulturalEventQuery, useCulturalEventQuery } from 'queries'
import { createElement as $ } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import Attendance from './Attendance'
import CulturalEventWithData from './CulturalEventWithData'
import Desktop from './Desktop'
import Tickets from './EventTickets'
import Mobile from './Mobile'

const CulturalEvent = () => {
	const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's'
	const component = isMobile ? Mobile : Desktop
	const params = useParams<'id'>()
	const id = params.id || ''
  const { data, loading } = useCulturalEventQuery({ variables: { id }, fetchPolicy: 'cache-and-network' })
	const culturalEvent = data?.culturalEvent
  const style = isMobile
    ? { padding: '.5rem', paddingBottom: '5rem' } 
    : { width: '500px' }

	if (loading) return isMobile 
    ? $(MessageContainer, null, $(Spin, { size: 'xl' })) 
    : null // FIXME add skeleton

	if (!culturalEvent) return $(Navigate, { to: '/calendar' })

  // TODO add checking user role for view only 
  return $(Flex, { direction: 'column', gap: 2, style },
		$(CulturalEventWithData, culturalEvent, 
      $(component, { culturalEvent, loading })),
    $(Tickets, culturalEvent),
		$(Attendance, { id, compensation: culturalEvent?.compensation }))
}

export type CulturalEventProps = {
  culturalEvent: CulturalEventQuery['culturalEvent']
  loading: boolean
}

export default CulturalEvent