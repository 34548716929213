import { Dots9 } from '@gravity-ui/icons'
import { createElement as $, FC, Fragment, useState } from 'react'
import AdornmentButton from './AdornmentButton'
import { Sheet } from '@gravity-ui/uikit'
import MenuContent from './MenuContent'

const Menu: FC = () => {
  const [visible, setOpen] = useState(false)
  return $(Fragment, null,
    $(AdornmentButton, { data: Dots9, onClick: () => setOpen(true) }),
    $(Sheet, { onClose: () => setOpen(false), visible },
      $(MenuContent)))
}

export default Menu