import { Card, Flex, spacing } from '@gravity-ui/uikit'
import { CulturalEventFragment } from 'queries'
import { createElement as $, FC } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

const TotalSum: FC<CulturalEventFragment> = ({ tickets }) => {
  if (!tickets.length) return null

  let value = 0
  
  for (const ticket of tickets)
    for (const transaction of ticket.transaction)
      value += transaction.sum

  return $(Card, {
    view: 'filled',
    className: spacing({ p: 4 }),
    children:
      $(Flex, { justifyContent: 'space-between' }, 
        $(FormattedMessage, { id: 'culturalEvent.tickets.totalSum' }),
        $('div', null,
          $(FormattedNumber, { value, style: 'currency', currency: 'RUB' }))
      )
    })
}

export default TotalSum