import { dateTime } from '@gravity-ui/date-utils'
import { Button } from '@gravity-ui/uikit'
import ZiferblatContext from 'components/ZiferblatContext'
import { useUpsertCulturalEventMutation } from 'queries'
import { createElement as $, FC, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

const AddCulturalEvent: FC = () => {
  const navigate = useNavigate()
  const { ziferblatId } = useContext(ZiferblatContext)
  const [mutate] = useUpsertCulturalEventMutation({
    variables: {
      input: {
        ziferblatId: ziferblatId,
        startsAt: dateTime().add(1, 'day').toDate(),
        endsAt: dateTime().add(1, 'day').add(1, 'hour').toDate()
      }
    },
    updateQueries: {
      CulturalEvents: (prev, next) => ({
        culturalEvents: [next.mutationResult.data?.upsertCulturalEvent, ...prev.culturalEvents]
      })
    }
  })

  const onClick = () => 
    mutate()
      .then(({ data }) => {
        data?.upsertCulturalEvent &&
          navigate(`/calendar/${data.upsertCulturalEvent.id}`)})
  
  const intl = useIntl()

  return $(Button, { 
    size: 'xl', 
    onClick 
    }, intl.formatMessage({ id: 'menu.addEvent' }))
}

export default AddCulturalEvent